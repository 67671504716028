import * as React from "react";
function SvgCalendarAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 20.125C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V9.5H3.25v10.625zm12.5-7.656a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H16.22a.47.47 0 01-.469-.469V12.47zm0 5a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H16.22a.47.47 0 01-.469-.469V17.47zm-5-5a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H11.22a.47.47 0 01-.469-.469V12.47zm0 5a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H11.22a.47.47 0 01-.469-.469V17.47zm-5-5A.47.47 0 016.219 12H7.78a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H6.22a.47.47 0 01-.469-.469V12.47zm0 5A.47.47 0 016.219 17H7.78a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H6.22a.47.47 0 01-.469-.469V17.47zM18.875 4.5H17V2.625A.627.627 0 0016.375 2h-1.25a.627.627 0 00-.625.625V4.5h-5V2.625A.627.627 0 008.875 2h-1.25A.627.627 0 007 2.625V4.5H5.125c-1.035 0-1.875.84-1.875 1.875V8.25h17.5V6.375c0-1.035-.84-1.875-1.875-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCalendarAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
