import * as React from "react";
function SvgXRaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.5 16c-.275 0-.5.225-.5.5s.225.5.5.5.5-.225.5-.5-.225-.5-.5-.5zm5 1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5-.5.225-.5.5.225.5.5.5zm7-13h-19c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h19c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zm0 14H20V7H4v11H2.5c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h19c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM17 11.75a.25.25 0 01-.25.25H12.5v1h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H12.5v1h2a1.5 1.5 0 11-1.5 1.5V16h-2v.5A1.5 1.5 0 119.5 15h2v-1H8.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.25v-1H7.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h4.25v-1H8.25A.25.25 0 018 9.75v-.5A.25.25 0 018.25 9h3.25v-.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V9h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H12.5v1h4.25a.25.25 0 01.25.25v.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgXRaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
