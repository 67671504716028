import * as React from "react";
function SvgCatSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.351 8.875c-.788 0-4.172.077-6.351 3.357V8.875a3.754 3.754 0 00-3.75-3.75 1.25 1.25 0 000 2.5c.69 0 1.25.56 1.25 1.25v10c0 1.379 1.121 2.5 2.5 2.5h6.875c.345 0 .625-.28.625-.625v-.625c0-.69-.56-1.25-1.25-1.25H12l5-3.75v5.625c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625v-8.052a4.95 4.95 0 01-1.25.177 5.008 5.008 0 01-4.899-4zm6.149-3.75H17l-2.5-2.5v5.25a3.75 3.75 0 107.5 0v-5.25l-2.5 2.5zM16.687 8.25a.625.625 0 110-1.25.625.625 0 010 1.25zm3.125 0a.625.625 0 110-1.25.625.625 0 010 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCatSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
