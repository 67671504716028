import * as React from "react";
function SvgPlayCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm4.665 10.968L9.57 17.04a.97.97 0 01-1.44-.846V7.806a.97.97 0 011.44-.846l7.096 4.314a.97.97 0 010 1.694z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlayCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
