import * as React from "react";
function SvgCrosshairsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.531 10.75h-1.186a8.443 8.443 0 00-7.095-7.095V2.469A.469.469 0 0012.781 2H11.22a.469.469 0 00-.469.469v1.186a8.443 8.443 0 00-7.095 7.095H2.469a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.186a8.443 8.443 0 007.095 7.095v1.186c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469v-1.186a8.443 8.443 0 007.095-7.095h1.186c.26 0 .469-.21.469-.469V11.22a.469.469 0 00-.469-.469zm-8.281 7.056v-1.587a.469.469 0 00-.469-.469H11.22a.469.469 0 00-.469.469v1.587a5.944 5.944 0 01-4.556-4.556h1.587c.26 0 .469-.21.469-.469V11.22a.469.469 0 00-.469-.469H6.194a5.944 5.944 0 014.556-4.556v1.587c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469V6.194a5.944 5.944 0 014.556 4.556h-1.587a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.587a5.944 5.944 0 01-4.556 4.556zm0-5.806a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCrosshairsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
