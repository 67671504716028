import * as React from "react";
function SvgVideoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.506 6H4.494C3.669 6 3 6.669 3 7.494v9.012C3 17.331 3.669 18 4.494 18h9.012c.825 0 1.494-.669 1.494-1.494V7.494C15 6.669 14.331 6 13.506 6zm5.919 1.178L16 9.541v4.918l3.425 2.36c.662.456 1.575-.01 1.575-.806V7.983c0-.793-.91-1.262-1.575-.806z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVideoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
