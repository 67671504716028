import * as React from "react";
function SvgBorderStyleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.714 19.143h-1.428a.714.714 0 00-.715.714v1.429a.715.715 0 00.715.714h1.428a.715.715 0 00.715-.714v-1.429a.714.714 0 00-.715-.714zm-4.285 0H7a.714.714 0 00-.714.714v1.429A.714.714 0 007 22h1.429a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zm8.571 0h-1.429a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714H17a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zm4.286-8.572h-1.429a.714.714 0 00-.714.715v1.428a.714.714 0 00.714.715h1.429a.715.715 0 00.714-.715v-1.428a.715.715 0 00-.714-.715zm0 4.286h-1.429a.714.714 0 00-.714.714V17a.714.714 0 00.714.714h1.429A.715.715 0 0022 17v-1.429a.714.714 0 00-.714-.714zm0 4.286h-1.429a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h1.429a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zm0-12.857h-1.429a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h1.429A.714.714 0 0022 8.429V7a.715.715 0 00-.714-.714zm0-4.286H3.429A1.429 1.429 0 002 3.429v17.857a.714.714 0 00.714.714h1.429a.714.714 0 00.714-.714V4.857h16.429A.714.714 0 0022 4.143V2.714A.715.715 0 0021.286 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBorderStyleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
