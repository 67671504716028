import * as React from "react";
function SvgSortAmountDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.875 18.25h-2.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm-5-2.5H7V3.875a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625V15.75H2.625a.625.625 0 00-.441 1.067l3.125 3.75a.625.625 0 00.884 0l3.125-3.75a.626.626 0 00-.443-1.067zm10-7.5h-7.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h7.5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm-2.5 5h-5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm5-10h-10a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h10A.625.625 0 0022 5.125v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortAmountDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
