import * as React from "react";
function SvgSpiderSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.249 8.922l.9.3h.162l.182-.907a3.45 3.45 0 01.111-.409L7.9 6.497l.826-2.479a.556.556 0 00-.351-.703l-.527-.175a.555.555 0 00-.703.351l-.899 2.697c-.093.28-.071.585.06.849l.943 1.885zm14.658 6.32l-1.832-2.747a1.11 1.11 0 00-.924-.494h-2.706l2.395-.846c.234-.079.436-.233.573-.438l1.861-2.792a.556.556 0 00-.154-.77l-.462-.309a.556.556 0 00-.77.154l-1.756 2.633-2.1.7h-1.254l-.36-1.8c-.076-.38-.603-2.088-2.418-2.088-1.815 0-2.342 1.707-2.418 2.088l-.36 1.8H7.97l-2.1-.7L4.111 7a.556.556 0 00-.77-.154l-.462.308a.556.556 0 00-.154.77l1.86 2.793c.138.205.34.36.574.438L7.556 12H4.849a1.111 1.111 0 00-.924.495l-1.832 2.748c-.17.255-.101.6.155.77l.462.308c.255.17.6.101.77-.154l1.667-2.5H6.78l-2.112 3.38a1.11 1.11 0 00-.169.589v2.698c0 .306.249.555.556.555h.555a.555.555 0 00.556-.555v-2.539l2.572-4.116c-.035.488-.072.976-.072 1.466 0 1.843 1.415 3.522 3.333 3.522 1.918 0 3.333-1.68 3.333-3.522 0-.49-.037-.978-.072-1.466l2.572 4.116v2.538c0 .307.249.556.556.556h.556a.555.555 0 00.555-.555v-2.699c0-.208-.059-.412-.169-.589l-2.112-3.38h1.634l1.667 2.5c.17.256.515.325.77.155l.463-.308a.555.555 0 00.154-.77zM16.1 6.498l-.704 1.409c.049.146.086.285.111.41l.181.906h.163l.9-.3.943-1.885a1.111 1.111 0 00.06-.849l-.9-2.697a.556.556 0 00-.702-.351l-.527.175a.556.556 0 00-.351.703l.826 2.479z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSpiderSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
