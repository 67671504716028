import * as React from "react";
function SvgParagraphSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.286 2.714v1.429a.714.714 0 01-.715.714H18.43v16.429a.715.715 0 01-.715.714h-1.428a.715.715 0 01-.715-.714V4.857h-1.428v16.429a.715.715 0 01-.714.714H12a.715.715 0 01-.714-.714v-5H9.857A7.143 7.143 0 119.857 2h10.714a.714.714 0 01.715.714z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgParagraphSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
