import * as React from "react";
function SvgFileImportSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.625 13.25a.627.627 0 00-.625.625v1.25c0 .344.281.625.625.625H7v-2.5H2.625zm19.102-7.148l-3.825-3.829A.937.937 0 0017.238 2H17v5h5v-.238a.935.935 0 00-.273-.66zm-5.977 1.21V2H7.937A.935.935 0 007 2.938V13.25h5v-2.547a.627.627 0 011.07-.441l3.735 3.77a.665.665 0 010 .937l-3.739 3.765a.627.627 0 01-1.07-.441V15.75H7v5.313c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileImportSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
