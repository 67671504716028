import * as React from "react";
function SvgDirectionsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.633 11.114l-8.747-8.747a1.253 1.253 0 00-1.772 0l-8.747 8.747a1.253 1.253 0 000 1.772l8.747 8.747c.49.49 1.282.49 1.772 0l8.747-8.747a1.253 1.253 0 000-1.772zm-3.944.49l-3.29 3.037a.313.313 0 01-.524-.23v-2.099h-3.75v2.5c0 .173-.14.313-.313.313h-1.25a.312.312 0 01-.312-.313v-3.125c0-.69.56-1.25 1.25-1.25h4.375V8.34c0-.273.324-.415.524-.23l3.29 3.036a.313.313 0 010 .46z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDirectionsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
