import * as React from "react";
function SvgEnvelopeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.621 9.453a.235.235 0 01.379.184v7.988c0 1.035-.84 1.875-1.875 1.875H3.875A1.875 1.875 0 012 17.625V9.641c0-.196.223-.305.379-.184.875.68 2.035 1.543 6.02 4.438.824.601 2.214 1.867 3.601 1.859 1.395.012 2.813-1.281 3.605-1.86 3.985-2.894 5.141-3.761 6.016-4.44zM12 14.5c.906.016 2.21-1.14 2.867-1.617 5.184-3.762 5.578-4.09 6.774-5.028A.935.935 0 0022 7.117v-.742C22 5.34 21.16 4.5 20.125 4.5H3.875C2.84 4.5 2 5.34 2 6.375v.742c0 .29.133.559.36.738 1.195.934 1.59 1.266 6.773 5.028.656.476 1.96 1.633 2.867 1.617z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEnvelopeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
