import * as React from "react";
function SvgCloneOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 2h-12.5C6.589 2 5.75 2.84 5.75 3.875V5.75H3.875C2.839 5.75 2 6.59 2 7.625v12.5C2 21.16 2.84 22 3.875 22h12.5c1.035 0 1.875-.84 1.875-1.875V18.25h1.875c1.035 0 1.875-.84 1.875-1.875v-12.5C22 2.839 21.16 2 20.125 2zm-3.984 18.125H4.109a.235.235 0 01-.234-.234V7.859a.234.234 0 01.234-.234H5.75v8.75c0 1.035.84 1.875 1.875 1.875h8.75v1.64a.235.235 0 01-.234.235zm3.75-3.75H7.859a.235.235 0 01-.234-.234V4.109a.234.234 0 01.234-.234h12.032a.235.235 0 01.234.234v12.032a.235.235 0 01-.234.234z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloneOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
