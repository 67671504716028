import * as React from "react";
function SvgUserCheckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9 12a4 4 0 100-8 4 4 0 000 8zm2.8 1h-.522a5.446 5.446 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h11a1.5 1.5 0 001.5-1.5v-1.3c0-2.319-1.881-4.2-4.2-4.2zm10.094-4.012l-.869-.879a.37.37 0 00-.525-.003l-3.275 3.25-1.422-1.431a.37.37 0 00-.525-.003l-.878.872a.37.37 0 00-.003.525l2.553 2.572a.37.37 0 00.525.003l4.416-4.382a.373.373 0 00.003-.524z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserCheckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
