import * as React from "react";
function SvgIdCardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 4.222H3.667C2.747 4.222 2 4.97 2 5.89v.555h20V5.89c0-.92-.747-1.667-1.667-1.667zM2 18.112c0 .92.747 1.666 1.667 1.666h16.666c.92 0 1.667-.747 1.667-1.667V7.556H2V18.11zm12.222-8.056c0-.153.125-.278.278-.278h5c.153 0 .278.125.278.278v.555a.279.279 0 01-.278.278h-5a.279.279 0 01-.278-.278v-.555zm0 2.222c0-.153.125-.278.278-.278h5c.153 0 .278.125.278.278v.555a.279.279 0 01-.278.278h-5a.279.279 0 01-.278-.278v-.555zm0 2.222c0-.153.125-.278.278-.278h5c.153 0 .278.125.278.278v.556a.279.279 0 01-.278.277h-5a.279.279 0 01-.278-.277V14.5zm-6.11-4.722c1.225 0 2.221.996 2.221 2.222a2.224 2.224 0 01-2.222 2.222A2.224 2.224 0 015.89 12c0-1.226.996-2.222 2.222-2.222zm-3.782 7.09a2.225 2.225 0 012.114-1.535h.285a3.583 3.583 0 002.764 0h.285c.986 0 1.823.643 2.114 1.535.112.344-.18.688-.541.688H4.87c-.36 0-.652-.348-.541-.688z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIdCardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
