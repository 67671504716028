import * as React from "react";
function SvgCoinsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 17.832V19.5C2 20.879 5.36 22 9.5 22c4.14 0 7.5-1.121 7.5-2.5v-1.668c-1.613 1.137-4.563 1.668-7.5 1.668-2.938 0-5.887-.531-7.5-1.668zM14.5 7C18.64 7 22 5.879 22 4.5S18.64 2 14.5 2C10.36 2 7 3.121 7 4.5S10.36 7 14.5 7zM2 13.734v2.016c0 1.379 3.36 2.5 7.5 2.5 4.14 0 7.5-1.121 7.5-2.5v-2.016c-1.613 1.329-4.566 2.016-7.5 2.016s-5.887-.688-7.5-2.016zm16.25.43C20.488 13.73 22 12.926 22 12v-1.668c-.906.64-2.238 1.078-3.75 1.348v2.484zM9.5 8.25C5.36 8.25 2 9.648 2 11.375S5.36 14.5 9.5 14.5c4.14 0 7.5-1.398 7.5-3.125S13.64 8.25 9.5 8.25zm8.566 2.2C20.41 10.026 22 9.2 22 8.25V6.582c-1.387.98-3.77 1.508-6.277 1.633 1.152.558 2 1.308 2.343 2.234z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCoinsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
