import * as React from "react";
function SvgBatteryFullSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 9v2h1v2h-1v2H4V9h15zm.5-2h-16A1.5 1.5 0 002 8.5v7A1.5 1.5 0 003.5 17h16a1.5 1.5 0 001.5-1.5V15h.25a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75H21v-.5A1.5 1.5 0 0019.5 7zM18 10H5v4h13v-4z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBatteryFullSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
