import * as React from "react";
function SvgCarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.53 8.875h-2.338l-.65-1.625A4.353 4.353 0 0014.48 4.5H9.52c-1.8 0-3.394 1.08-4.063 2.75l-.65 1.625H2.47a.469.469 0 00-.455.582l.235.938c.052.209.24.355.454.355h.784a2.482 2.482 0 00-.862 1.875V14.5c0 .63.24 1.198.625 1.638v2.112c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25V17h10v1.25c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25v-2.112c.384-.44.625-1.008.625-1.638v-1.875c0-.75-.338-1.417-.862-1.875h.784a.468.468 0 00.454-.355l.235-.938a.469.469 0 00-.455-.582zM7.779 8.179A1.875 1.875 0 019.52 7h4.961c.767 0 1.457.467 1.741 1.179L17 10.125H7l.778-1.946zM5.75 14.492c-.75 0-1.25-.498-1.25-1.246S5 12 5.75 12s1.875 1.121 1.875 1.87c0 .747-1.125.622-1.875.622zm12.5 0c-.75 0-1.875.125-1.875-.623S17.5 12 18.25 12s1.25.498 1.25 1.246-.5 1.246-1.25 1.246z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
