import * as React from "react";
function SvgSnowflakeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.45 15.484l-1.32-.761 1.015-.274a.604.604 0 00.426-.738l-.156-.582a.604.604 0 00-.739-.426l-2.765.742L14.415 12l2.492-1.441 2.765.742a.604.604 0 00.739-.426l.156-.582a.604.604 0 00-.426-.738l-1.015-.274 1.32-.761a.606.606 0 00.223-.825l-.606-1.047a.606.606 0 00-.824-.222l-1.32.761.273-1.015a.604.604 0 00-.426-.738l-.582-.157a.604.604 0 00-.738.426l-.742 2.766-2.453 1.414V6.855l2.097-2.097a.627.627 0 000-.883l-.441-.441a.627.627 0 00-.883 0l-.773.77V2.624A.627.627 0 0012.626 2h-1.25a.627.627 0 00-.625.625v1.578l-.77-.77a.627.627 0 00-.883 0l-.445.442a.62.62 0 000 .883l2.098 2.097v3.028L8.297 8.469l-.742-2.766a.604.604 0 00-.738-.426l-.582.157a.604.604 0 00-.426.738l.274 1.016-1.32-.762a.603.603 0 00-.825.222l-.605 1.043a.603.603 0 00.222.825l1.32.761-1.015.274a.606.606 0 00-.43.742l.157.582c.085.32.417.512.738.426l2.765-.742L9.583 12 7.09 13.441 4.325 12.7a.604.604 0 00-.738.426l-.157.582a.604.604 0 00.426.738l1.016.274-1.32.765a.606.606 0 00-.223.825l.605 1.046a.606.606 0 00.824.223l1.32-.762-.273 1.016a.604.604 0 00.426.738l.582.157a.604.604 0 00.738-.426l.743-2.766 2.453-1.414v3.027l-2.098 2.098a.62.62 0 000 .883l.441.441c.243.242.641.242.883 0l.77-.77v1.575c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625v-1.578l.77.77c.242.242.64.242.882 0l.441-.442a.627.627 0 000-.883l-2.085-2.098v-3.027l2.453 1.414.742 2.766c.086.32.418.512.738.426l.582-.157a.604.604 0 00.426-.738l-.273-1.015 1.32.761a.603.603 0 00.824-.222l.606-1.047a.597.597 0 00-.22-.82z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSnowflakeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
