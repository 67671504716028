import * as React from "react";
function SvgArrowsAltHSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.763 8.638v1.8H7.237v-1.8c0-.835-1.01-1.253-1.6-.663l-3.362 3.362a.937.937 0 000 1.326l3.361 3.362c.59.59 1.6.172 1.6-.663v-1.8h9.527v1.8c0 .835 1.01 1.253 1.6.663l3.362-3.362a.938.938 0 000-1.326l-3.361-3.362c-.59-.59-1.6-.172-1.6.663z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowsAltHSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
