import * as React from "react";
function SvgCompressAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.21 19.648l4.433-4.434-1.47-1.385C4.498 13.154 4.976 12 5.931 12h5c.592 0 1.069.48 1.069 1.071v5c0 .955-1.152 1.433-1.827.758l-1.387-1.472-4.434 4.434a.714.714 0 01-1.01 0l-1.133-1.133a.714.714 0 010-1.01zM21.79 4.352l-4.433 4.434 1.47 1.385c.675.675.197 1.829-.758 1.829h-5A1.07 1.07 0 0112 10.929v-5c0-.955 1.152-1.433 1.827-.758l1.387 1.472 4.434-4.434a.714.714 0 011.01 0l1.133 1.133a.714.714 0 010 1.01z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCompressAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
