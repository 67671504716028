import styled from "styled-components";

export const UiUpdateAvailabilityPage = styled.div`
	> * {
		padding: 10px 0;
	}

	.breadcrumb {
		display: flex;
	}

	.search {
		display: flex;
		column-gap: 10px;

		&__select {
			width: 200px;
		}

		&__text {
			width: 300px;
		}
	}

	.message {
		color: red;
	}

	.result {
		color: #fff;
		td {
			border: 0px solid transparent;
		}
		tr > td + td {
			border-left-width: 30px;
		}
		tr + tr > td {
			border-top-width: 10px;
		}
	}

	.actions {
		display: flex;
		margin-top: 20px;
		column-gap: 40px;
	}
`;

export const UiLoadingBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2999;
`;
