import * as React from "react";
function SvgChessBishopSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.063 13.245c0 2.018.864 2.884 2.187 3.305v1.7h7.5v-1.7c1.323-.42 2.188-1.29 2.188-3.305 0-1.195-.42-2.62-1.043-4.006l-3.918 3.92a.312.312 0 01-.442 0l-.442-.443a.312.312 0 010-.441l4.214-4.216c-.812-1.481-1.815-2.817-2.768-3.617.547-.134.961-.604.961-1.192A1.25 1.25 0 0013.25 2h-2.5A1.25 1.25 0 009.5 3.25c0 .586.414 1.058.96 1.192-2.061 1.73-4.398 5.937-4.398 8.803zM17.624 19.5H6.375a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h11.25a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChessBishopSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
