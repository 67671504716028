import * as React from "react";
function SvgCaretLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.671 3.348v17.304c0 1.199-1.449 1.799-2.296.951l-8.652-8.652a1.345 1.345 0 010-1.902l8.652-8.652c.847-.848 2.296-.248 2.296.95z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCaretLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
