import * as React from "react";
function SvgLinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.758 9.242a5.934 5.934 0 010 8.397l-2.625 2.625a5.944 5.944 0 01-8.397 0 5.944 5.944 0 010-8.397l1.45-1.45c.384-.384 1.046-.128 1.066.415.025.692.15 1.387.379 2.06a.629.629 0 01-.148.648l-.511.511c-1.095 1.095-1.13 2.877-.046 3.983a2.816 2.816 0 003.998.02l2.625-2.625a2.812 2.812 0 00-.404-4.312.628.628 0 01-.272-.493c-.015-.412.131-.838.457-1.164l.823-.822a.627.627 0 01.804-.068c.286.2.554.425.801.672zm5.506-5.506a5.944 5.944 0 00-8.397 0L9.242 6.361a5.934 5.934 0 00.802 9.069c.25.174.588.148.803-.068l.823-.822c.326-.326.472-.752.457-1.164a.626.626 0 00-.272-.493 2.812 2.812 0 01-.404-4.312l2.625-2.625a2.816 2.816 0 013.997.02c1.084 1.105 1.05 2.888-.045 3.983l-.51.511a.629.629 0 00-.149.649c.23.671.354 1.367.379 2.06.02.542.682.798 1.066.414l1.45-1.45a5.944 5.944 0 000-8.397z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
