import * as React from "react";
function SvgDungeonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.029 9.63L3.794 7.608c-.314-.196-.742-.085-.896.252a9.93 9.93 0 00-.877 3.487.627.627 0 00.63.653h3.795c.311 0 .55-.244.586-.553.043-.365.127-.716.244-1.052.1-.287.01-.604-.247-.765zm7.433-7.318a9.88 9.88 0 00-4.924 0c-.358.092-.543.493-.406.836l1.464 4.064a.625.625 0 00.588.413h1.632a.624.624 0 00.588-.413l1.464-4.064c.137-.343-.048-.743-.406-.836zM6.375 13.25h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm0 5h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm3.02-11.08L7.976 3.621c-.138-.345-.552-.507-.876-.324a10.051 10.051 0 00-2.797 2.34c-.237.285-.15.721.165.918l3.24 2.024c.254.16.572.103.785-.109.202-.2.421-.384.656-.548.245-.173.357-.476.246-.754zM17.554 12h3.794a.627.627 0 00.63-.653 9.928 9.928 0 00-.876-3.487c-.154-.337-.582-.448-.896-.252L16.971 9.63c-.257.16-.347.478-.247.764.117.337.201.689.244 1.053.036.309.275.553.586.553zm2.143-6.363a10.051 10.051 0 00-2.798-2.34c-.323-.182-.737-.02-.876.325L14.605 7.17c-.112.278 0 .581.246.753.234.164.453.348.656.55.212.21.53.267.785.108l3.24-2.025c.315-.196.402-.632.165-.918zm1.678 7.613h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm0 5h-3.75a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h3.75c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zm-10-9.312v11.5c0 .172.14.312.313.312h.624c.173 0 .313-.14.313-.313V8.938A3.705 3.705 0 0012 8.875c-.214 0-.42.029-.625.063zm-2.5 1.622v9.877c0 .173.14.313.313.313h.624c.173 0 .313-.14.313-.313V9.398c-.5.29-.931.682-1.25 1.163zm5-1.163v11.04c0 .173.14.313.313.313h.624c.173 0 .313-.14.313-.313V10.56a3.763 3.763 0 00-1.25-1.163z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDungeonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
