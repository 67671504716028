import * as React from "react";
function SvgFeatherSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.247 3.752c-2.443-2.44-6.315-2.53-9.854 1.005l-3.35 3.346c-2.361 2.359-2.75 5.892-2.486 8.249l6.97-6.963a.626.626 0 01.885.884L2.275 20.4a.937.937 0 101.327 1.326l2.582-2.58c2.043.614 6.714.71 9.642-2.146h-3.835l5.764-1.92c1.953-1.95 1.421-1.413 1.809-1.83h-3.82l5.139-1.71c1.775-2.91 1.34-5.815-.636-7.788z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFeatherSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
