import * as React from "react";
function SvgPlusOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.185 13.172h7.636v7.643A1.2 1.2 0 0012.006 22c.65 0 1.173-.548 1.173-1.185v-7.643h7.648C21.465 13.172 22 12.65 22 12c0-.65-.535-1.172-1.173-1.172H13.18V3.185c0-.637-.523-1.185-1.173-1.185a1.2 1.2 0 00-1.185 1.185v7.643H3.185C2.548 10.828 2 11.35 2 12c0 .65.548 1.172 1.185 1.172z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlusOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
