import * as React from "react";
function SvgMusicSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.374 2.058L7.875 5.75A1.25 1.25 0 007 6.941v10.212c-.41-.1-.829-.151-1.25-.154C3.68 17 2 18.12 2 19.5S3.68 22 5.75 22c2.07 0 3.75-1.12 3.75-2.5v-9.128l10-2.93v7.212c-.41-.1-.829-.151-1.25-.154-2.07 0-3.75 1.12-3.75 2.5s1.68 2.5 3.75 2.5C20.32 19.5 22 18.38 22 17V3.25a1.25 1.25 0 00-1.626-1.19z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMusicSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
