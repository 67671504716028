import * as React from "react";
function SvgFolderPlusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 7h-7.5l-2.5-2.5h-6.25A1.875 1.875 0 002 6.375v11.25A1.875 1.875 0 003.875 19.5h16.25A1.875 1.875 0 0022 17.625v-8.75A1.875 1.875 0 0020.125 7zm-4.082 6.563a.625.625 0 01-.625.624h-2.5v2.5a.625.625 0 01-.625.625h-.625a.625.625 0 01-.625-.625v-2.5h-2.5a.625.625 0 01-.625-.624v-.626a.625.625 0 01.625-.624h2.5v-2.5a.625.625 0 01.625-.626h.625a.625.625 0 01.625.626v2.5h2.5a.625.625 0 01.625.624v.626z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFolderPlusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
