import * as React from "react";
function SvgDiagnosesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.5 12c.275 0 .5-.225.5-.5s-.225-.5-.5-.5-.5.225-.5.5.225.5.5.5zM12 9.5a2.754 2.754 0 002.75-2.75A2.754 2.754 0 0012 4a2.754 2.754 0 00-2.75 2.75A2.754 2.754 0 0012 9.5zm-8.131 5.875a.996.996 0 001.34.306c.507-.3 1.757-.99 3.291-1.518V17h7v-2.834a18.494 18.494 0 013.29 1.518c.426.25 1.023.166 1.341-.306l.556-.834c.275-.413.238-1.082-.312-1.41a21.779 21.779 0 00-1.597-.856c-.878 1.44-3.106.556-2.74-1.097-1.247-.393-2.629-.681-4.038-.681-1.781 0-3.528.453-5 1.006-.006 1.256-1.487 1.978-2.475 1.125-.35.188-.666.363-.897.5-.55.328-.587.994-.312 1.41l.553.834zm9.631-.625c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm-3-3c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm-5 .25c.275 0 .5-.225.5-.5s-.225-.5-.5-.5-.5.225-.5.5.225.5.5.5zm16 6h-19c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h19c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDiagnosesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
