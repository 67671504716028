import * as React from "react";
function SvgWindowMaximizeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25H3.875C2.84 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.035-.84-1.875-1.875-1.875zm0 15.39a.235.235 0 01-.234.235H4.109a.235.235 0 01-.234-.234V9.5h16.25v9.14z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWindowMaximizeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
