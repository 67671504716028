import * as React from "react";
function SvgFileWordOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.948 5.827L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-1.476 1.176h-2.973V4.03l2.973 2.972zM6.376 20.125V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247H6.376zm8.596-8.123a.465.465 0 00-.457.371c-.804 3.816-.797 3.726-.82 4.042l-.027-.168c-.032-.199.011.008-.922-3.886a.468.468 0 00-.457-.359h-.52a.476.476 0 00-.456.355c-.953 3.867-.938 3.757-.969 4.05-.004-.043-.008-.097-.02-.164-.027-.203-.55-2.862-.745-3.866a.47.47 0 00-.461-.379h-.656a.47.47 0 00-.457.578c.312 1.273 1.042 4.277 1.296 5.311.051.211.239.356.457.356h.984a.464.464 0 00.453-.355l.7-2.789a4.83 4.83 0 00.117-.676l.113.676c.004.016.492 1.972.7 2.788.05.207.237.356.452.356h.965a.464.464 0 00.453-.355c.812-3.2 1.18-4.648 1.347-5.312a.467.467 0 00-.453-.582h-.617v.008z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileWordOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
