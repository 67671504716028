import * as React from "react";
function SvgArrowAltCircleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22C6.476 22 2 17.524 2 12S6.476 2 12 2s10 4.476 10 10-4.476 10-10 10zm4.677-11.774H12v-2.86a.484.484 0 00-.827-.342l-4.608 4.633a.48.48 0 000 .682l4.608 4.633a.484.484 0 00.827-.343v-2.855h4.677a.485.485 0 00.484-.484v-2.58a.485.485 0 00-.484-.484z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowAltCircleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
