import * as React from "react";
function SvgRssSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.718 19.141a2.859 2.859 0 11-5.718 0 2.859 2.859 0 015.718 0zm7.842 2.11C15.187 14.348 9.659 8.812 2.75 8.44a.714.714 0 00-.75.713V11.3c0 .376.29.69.665.715a10.003 10.003 0 019.32 9.32c.024.375.34.665.715.665h2.146a.713.713 0 00.714-.75zm6.44.012C21.625 10.825 13.226 2.377 2.736 2A.714.714 0 002 2.715v2.146c0 .385.305.698.69.714 8.535.35 15.385 7.202 15.735 15.735.016.385.329.69.714.69h2.146a.714.714 0 00.715-.737z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRssSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
