import * as React from "react";
function SvgBiohazardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 6.998c.646 0 1.257.132 1.834.334.461-.358.82-.844 1.024-1.413a7.175 7.175 0 00-2.854-.59c-1.011 0-1.976.208-2.852.586.205.57.563 1.056 1.025 1.413A5.562 5.562 0 0112 6.998zM7.684 18.344c.416-.41.708-.917.85-1.473a5.515 5.515 0 01-2.045-3.806 2.904 2.904 0 00-.917-.16 2.83 2.83 0 00-.781.108 7.232 7.232 0 002.893 5.33zm7.785-1.48c.143.556.434 1.066.851 1.476a7.21 7.21 0 002.882-5.33c-.25-.07-.51-.105-.77-.105-.32 0-.626.066-.924.164a5.518 5.518 0 01-2.039 3.795zm3.942-7.119a5.634 5.634 0 00-1.92-.69 6.05 6.05 0 00.346-1.952 6.044 6.044 0 00-1.423-3.897.272.272 0 00-.348-.062.287.287 0 00-.125.337c.157.479.23.913.23 1.337 0 2.354-1.869 4.268-4.167 4.268-2.3 0-4.168-1.914-4.168-4.268 0-.42.077-.858.23-1.337a.287.287 0 00-.126-.337.272.272 0 00-.347.062A6.063 6.063 0 006.17 7.103c0 .687.136 1.337.348 1.951a5.66 5.66 0 00-1.92.691 5.943 5.943 0 00-2.58 3.209.29.29 0 00.12.34c.115.07.261.045.348-.055a4.14 4.14 0 011.014-.872c1.99-1.177 4.542-.476 5.692 1.563 1.15 2.038.465 4.653-1.525 5.83a4.079 4.079 0 01-1.243.466.287.287 0 00.003.559 5.686 5.686 0 004-.684A5.73 5.73 0 0012 18.708a5.73 5.73 0 001.573 1.393 5.686 5.686 0 004 .684.28.28 0 00.226-.278.284.284 0 00-.222-.281 3.96 3.96 0 01-1.243-.466c-1.99-1.177-2.674-3.792-1.525-5.83 1.15-2.039 3.702-2.74 5.692-1.563.354.212.688.497 1.014.872.087.1.233.125.347.055a.288.288 0 00.122-.34 5.889 5.889 0 00-2.573-3.209zM12 14.225a1.667 1.667 0 110-3.335 1.667 1.667 0 010 3.335z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBiohazardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
