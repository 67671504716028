"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROLES = exports.EDUCATIONAL = exports.DATEFORMAT = exports.COOKIE_LOGIN_TOKEN_NAME = void 0;
const COOKIE_LOGIN_TOKEN_NAME = exports.COOKIE_LOGIN_TOKEN_NAME = "nani_oneclass_resource_platform_token";
const ROLES = exports.ROLES = {
  Editor: "編輯",
  Guest: "訪客"
};
const EDUCATIONAL = exports.EDUCATIONAL = [{
  name: "國小",
  value: "E"
}, {
  name: "國中",
  value: "J"
}, {
  name: "高中",
  value: "H"
}];
const DATEFORMAT = exports.DATEFORMAT = "YYYY-MM-DD";