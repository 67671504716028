import * as React from "react";
function SvgToolboxSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.634 10.384l-1.768-1.768a1.25 1.25 0 00-.884-.366H17V5.125c0-1.036-.84-1.875-1.875-1.875h-6.25C7.839 3.25 7 4.09 7 5.125V8.25H5.018c-.332 0-.65.132-.884.366l-1.768 1.768a1.25 1.25 0 00-.366.884V14.5h5v-.625c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625v.625h5v-.625c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625v.625h5v-3.232c0-.332-.132-.65-.366-.884zM14.5 8.25h-5v-2.5h5v2.5zm2.5 8.125c0 .345-.28.625-.625.625h-1.25a.625.625 0 01-.625-.625v-.625h-5v.625c0 .345-.28.625-.625.625h-1.25A.625.625 0 017 16.375v-.625H2v3.75c0 .69.56 1.25 1.25 1.25h17.5c.69 0 1.25-.56 1.25-1.25v-3.75h-5v.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgToolboxSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
