import * as React from "react";
function SvgCloudShowersHeavySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.184 16.457a.62.62 0 00-.852.234l-2.5 4.375a.622.622 0 00.543.934.628.628 0 00.543-.317l2.5-4.375a.624.624 0 00-.234-.851zm3.75 0a.62.62 0 00-.852.234l-2.5 4.375a.622.622 0 00.543.934.628.628 0 00.543-.317l2.5-4.375a.624.624 0 00-.234-.851zm-7.5 0a.62.62 0 00-.852.234l-2.5 4.375a.622.622 0 00.543.934.628.628 0 00.543-.317l2.5-4.375a.624.624 0 00-.234-.851zm15 0a.62.62 0 00-.852.234l-2.5 4.375a.622.622 0 00.543.934.628.628 0 00.543-.317l2.5-4.375a.624.624 0 00-.234-.851zm-3.75 0a.62.62 0 00-.852.234l-2.5 4.375a.622.622 0 00.543.934.628.628 0 00.543-.317l2.5-4.375a.624.624 0 00-.234-.851zM18.25 7c-.023 0-.043.008-.062.008.043-.203.062-.414.062-.633a3.124 3.124 0 00-3.125-3.125c-.96 0-1.808.442-2.383 1.125C12.016 2.97 10.567 2 8.875 2A4.375 4.375 0 004.5 6.375c0 .285.032.559.082.828A3.737 3.737 0 002 10.75a3.75 3.75 0 003.75 3.75h12.5a3.75 3.75 0 000-7.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudShowersHeavySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
