import * as React from "react";
function SvgMedkitSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.75 20.75h12.5V7H17V5.125c0-1.036-.84-1.875-1.875-1.875h-6.25C7.839 3.25 7 4.09 7 5.125V7H5.75v13.75zm3.75-15h5V7h-5V5.75zM22 8.875v10c0 1.035-.84 1.875-1.875 1.875H19.5V7h.625C21.16 7 22 7.84 22 8.875zM4.5 20.75h-.625A1.875 1.875 0 012 18.875v-10C2 7.839 2.84 7 3.875 7H4.5v13.75zm11.25-8.125v1.25c0 .345-.28.625-.625.625H13.25v1.875c0 .345-.28.625-.625.625h-1.25a.625.625 0 01-.625-.625V14.5H8.875a.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625h1.875v-1.875c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625V12h1.875c.345 0 .625.28.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMedkitSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
