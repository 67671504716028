import * as React from "react";
function SvgHandLizardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.333 19.778H22v-4.036c0-.63-.179-1.247-.515-1.78L15.824 5a1.667 1.667 0 00-1.41-.777H2.835A.833.833 0 002 5.056v.555c0 1.074.87 1.945 1.944 1.945h7.986c.446 0 .748.453.577.865l-.744 1.784a1.112 1.112 0 01-1.025.684H6.444a2.222 2.222 0 00-2.222 2.222v.278c0 .46.373.833.834.833h5.118c.316 0 .625.09.892.26l3.88 2.458a.833.833 0 01.387.704v2.134z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandLizardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
