import * as React from "react";
function SvgBullseyeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 17.42c-4.1 0-7.42-3.319-7.42-7.42C4.58 7.9 7.9 4.58 12 4.58c4.1 0 7.42 3.319 7.42 7.42 0 4.1-3.319 7.42-7.42 7.42zm0-12.581a5.161 5.161 0 100 10.323 5.161 5.161 0 000-10.323zm0 7.742A2.583 2.583 0 019.42 12 2.584 2.584 0 0112 9.42 2.583 2.583 0 0114.58 12 2.583 2.583 0 0112 14.58z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBullseyeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
