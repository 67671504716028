import * as React from "react";
function SvgLaptopMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.25 11H11v1.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25V11h1.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H13V7.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V9H9.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25zM20 5.5A1.505 1.505 0 0018.5 4h-13A1.504 1.504 0 004 5.5V16h16V5.5zM18 14H6V6h12v8zm3.5 3h-7.577c-.023.62-.46 1-1.023 1H11c-.584 0-1.031-.546-1.024-1H2.5a.5.5 0 00-.5.5v.5a2.006 2.006 0 002 2h16a2.006 2.006 0 002-2v-.5a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaptopMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
