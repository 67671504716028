import * as React from "react";
function SvgGrinBeamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 5.806c.96 0 2.125 1.182 2.258 2.88.028.346-.436.48-.6.18l-.384-.684c-.31-.553-.774-.872-1.27-.872s-.96.319-1.27.871l-.383.686c-.166.294-.63.161-.601-.182.125-1.697 1.29-2.879 2.25-2.879zm-6.452 0c.96 0 2.125 1.182 2.258 2.88.028.346-.435.48-.6.18l-.384-.684c-.31-.553-.774-.872-1.27-.872s-.96.319-1.27.871l-.383.686c-.17.298-.629.161-.6-.182.124-1.697 1.29-2.879 2.25-2.879zM12 19.096c-2.444 0-5.423-1.544-5.798-3.761a.646.646 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613.46-.15.911.246.834.722-.375 2.217-3.354 3.762-5.798 3.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinBeamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
