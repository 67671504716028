import * as React from "react";
function SvgChessKingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 19.5H5.125a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h13.75a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zM19.5 8.25h-6.25V6.375h1.563a.313.313 0 00.312-.313V4.188a.312.312 0 00-.313-.312H13.25V2.312A.312.312 0 0012.937 2h-1.874a.313.313 0 00-.313.313v1.562H9.188a.313.313 0 00-.313.313v1.875a.313.313 0 00.313.312h1.562V8.25H4.5a1.25 1.25 0 00-1.192 1.623l2.855 8.377h11.674l2.855-8.377A1.251 1.251 0 0019.5 8.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChessKingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
