import * as React from "react";
function SvgTrophySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.167 5.333h-3.611V3.944a.831.831 0 00-.834-.833H7.278a.831.831 0 00-.834.833v1.39h-3.61A.831.831 0 002 6.166V8.11c0 1.24.781 2.514 2.15 3.497 1.093.788 2.423 1.288 3.819 1.447 1.09 1.81 2.364 2.556 2.364 2.556v2.5H8.667c-1.226 0-2.223.719-2.223 1.944v.417c0 .23.188.417.417.417H17.14c.23 0 .417-.188.417-.417v-.417c0-1.225-.997-1.944-2.223-1.944h-1.666v-2.5s1.274-.746 2.364-2.556c1.4-.16 2.73-.66 3.82-1.447C21.215 10.625 22 9.35 22 8.11V6.167a.831.831 0 00-.833-.834zM5.447 9.806c-.846-.612-1.225-1.292-1.225-1.695v-.555h2.23c.034 1.131.2 2.125.444 2.993a5.598 5.598 0 01-1.448-.743zm14.33-1.695c0 .56-.614 1.254-1.225 1.695a5.62 5.62 0 01-1.451.743c.243-.868.41-1.862.444-2.993h2.233v.555z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTrophySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
