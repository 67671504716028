import * as React from "react";
function SvgImagesOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.667 17.555v.556c0 .92-.747 1.667-1.667 1.667H3.667c-.92 0-1.667-.746-1.667-1.667V9.222c0-.92.746-1.666 1.667-1.666h.555v1.666h-.347a.208.208 0 00-.208.209v8.472a.208.208 0 00.208.208h12.917a.208.208 0 00.208-.208v-.348h1.667zM20.125 5.89H7.208A.208.208 0 007 6.097v8.472a.208.208 0 00.208.209h12.917a.208.208 0 00.208-.209V6.097a.208.208 0 00-.208-.208zm.208-1.667c.92 0 1.667.746 1.667 1.667v8.889c0 .92-.746 1.666-1.667 1.666H7c-.92 0-1.667-.746-1.667-1.666v-8.89c0-.92.747-1.666 1.667-1.666h13.333zm-9.166 3.89a1.389 1.389 0 11-2.778-.001 1.389 1.389 0 012.778 0zm-2.5 3.332l1.372-1.372a.417.417 0 01.589 0L12 11.444l3.594-3.594a.417.417 0 01.59 0l2.483 2.483v2.778h-10v-1.667z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgImagesOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
