import * as React from "react";
function SvgGiftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 19.5c0 .691.559 1.25 1.25 1.25h6.25V14.5h-7.5v5zm10 1.25h6.25c.691 0 1.25-.559 1.25-1.25v-5h-7.5v6.25zm7.5-12.5h-1.645c.243-.473.395-.996.395-1.563a3.443 3.443 0 00-3.438-3.437c-1.625 0-2.675.832-4.023 2.668C10.691 4.082 9.641 3.25 8.016 3.25a3.443 3.443 0 00-3.438 3.438c0 .566.149 1.09.395 1.562H3.25C2.559 8.25 2 8.809 2 9.5v3.125c0 .344.281.625.625.625h18.75a.627.627 0 00.625-.625V9.5c0-.691-.559-1.25-1.25-1.25zm-12.738 0a1.562 1.562 0 110-3.125c.777 0 1.351.129 3.363 3.125H8.012zm8.05 0H12.7c2.008-2.988 2.567-3.125 3.364-3.125a1.562 1.562 0 110 3.125z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGiftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
