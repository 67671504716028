import * as React from "react";
function SvgAngleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.983 10.938L14.48 2.44a1.493 1.493 0 012.118 0l1.412 1.413a1.493 1.493 0 010 2.118L11.993 12l6.024 6.023a1.493 1.493 0 010 2.118l-1.413 1.419a1.493 1.493 0 01-2.118 0L5.99 13.062a1.495 1.495 0 01-.006-2.124z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
