import * as React from "react";
function SvgArrowDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.441 10.504l.991.99c.42.42.42 1.099 0 1.514l-8.673 8.677c-.42.42-1.098.42-1.513 0l-8.678-8.677a1.067 1.067 0 010-1.513l.99-.991a1.073 1.073 0 011.532.017l5.124 5.38V3.07c0-.593.478-1.071 1.072-1.071h1.428c.594 0 1.072.478 1.072 1.071v12.83l5.124-5.38a1.065 1.065 0 011.531-.018z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
