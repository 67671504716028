import * as React from "react";
function SvgMaleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm1.875 5.625h-.444a3.433 3.433 0 01-2.862 0h-.444c-1.036 0-1.875.84-1.875 1.875v5.313c0 .517.42.937.938.937h.624v5.313c0 .517.42.937.938.937h2.5c.518 0 .938-.42.938-.938V15.75h.624c.518 0 .938-.42.938-.938V9.5c0-1.036-.84-1.875-1.875-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMaleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
