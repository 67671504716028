import * as React from "react";
function SvgLongArrowAltDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.786 16.015V2.535A.536.536 0 0013.25 2h-2.5a.536.536 0 00-.536.536v13.48H8.158c-.954 0-1.432 1.153-.758 1.828l3.842 3.842a1.071 1.071 0 001.516 0l3.841-3.842c.675-.675.198-1.829-.757-1.829h-2.056z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLongArrowAltDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
