import * as React from "react";
function SvgKeyboardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 5.333H3.667C2.747 5.333 2 6.08 2 7v10c0 .92.746 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V7c0-.92-.746-1.667-1.667-1.667zM20.611 17a.278.278 0 01-.278.278H3.667A.278.278 0 013.389 17V7c0-.153.125-.278.278-.278h16.666c.154 0 .278.125.278.278v10zM7.903 12.486v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .417-.187.417-.417zm3.333 0v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.416.417v.972c0 .23.186.417.416.417h.972c.23 0 .417-.187.417-.417zm3.333 0v-.972a.417.417 0 00-.416-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .416-.187.416-.417zm3.334 0v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .417-.187.417-.417zM6.236 15.333v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.416.417v.972c0 .23.186.417.416.417h.972c.23 0 .417-.187.417-.417zm13.333 0v-.972a.417.417 0 00-.416-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .416-.187.416-.417zM6.236 9.64v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.416.417v.972c0 .23.186.417.416.417h.972c.23 0 .417-.187.417-.417zm3.333 0v-.972a.417.417 0 00-.416-.417H8.18a.417.417 0 00-.417.417v.972c0 .23.186.417.417.417h.972c.23 0 .416-.187.416-.417zm3.334 0v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .417-.187.417-.417zm3.333 0v-.972a.417.417 0 00-.417-.417h-.972a.417.417 0 00-.416.417v.972c0 .23.186.417.416.417h.972c.23 0 .417-.187.417-.417zm3.333 0v-.972a.417.417 0 00-.416-.417h-.972a.417.417 0 00-.417.417v.972c0 .23.187.417.417.417h.972c.23 0 .416-.187.416-.417zm-3.402 5.486v-.556a.417.417 0 00-.417-.416h-7.5a.417.417 0 00-.417.416v.556c0 .23.187.417.417.417h7.5c.23 0 .417-.187.417-.417z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKeyboardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
