import * as React from "react";
function SvgUmbrellaBeachSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.606 8.278l3.19 1.162c1.1-2.548 2.695-4.509 4.344-5.428a9.35 9.35 0 00-7.766 3.494c-.21.262-.083.658.232.772zm4.132 1.505l7.453 2.714c1.117-3.793.584-7.24-1.332-7.937a2.092 2.092 0 00-.722-.125c-1.813 0-4.008 2.161-5.399 5.348zm8.558-3.892c.194.51.338 1.081.413 1.724.179 1.56-.045 3.383-.593 5.219L21.323 14c.316.115.666-.107.674-.443a9.356 9.356 0 00-3.7-7.667zM19.5 17.999h-7.467l2.03-5.577-1.88-.684-2.278 6.261H2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.501h17a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUmbrellaBeachSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
