import * as React from "react";
function SvgBurnSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2c-4.387 3.957-7.5 8.629-7.5 11.738C4.5 18.602 7.586 22 12 22s7.5-3.398 7.5-8.262c0-3.12-3.133-7.797-7.5-11.738zm0 17.5c-2.207 0-3.75-1.523-3.75-3.703 0-.527.18-2.402 3.75-6.297 3.57 3.895 3.75 5.77 3.75 6.297 0 2.18-1.543 3.703-3.75 3.703z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBurnSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
