import * as React from "react";
function SvgStraightOneColumnsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.551 11.337v.603a.301.301 0 01-.301.301H7.551a.301.301 0 01-.301-.301v-.603c0-.166.135-.301.301-.301h8.699c.166 0 .301.135.301.301zm0-2.41v.602a.301.301 0 01-.301.301h-3.877a.301.301 0 01-.302-.3v-.604c0-.166.135-.3.302-.3h3.877c.166 0 .301.134.301.3zm0-2.411v.602a.301.301 0 01-.301.302h-3.877a.301.301 0 01-.302-.302v-.602c0-.167.135-.302.302-.302h3.877c.166 0 .301.135.301.302zM16.551 14.551v-.602a.301.301 0 00-.301-.302H7.551a.301.301 0 00-.301.302v.602c0 .167.135.302.301.302h8.699a.301.301 0 00.301-.302zM9.765 7.826c-.07-.244-.157-.667-.157-.667h-.01s-.086.423-.156.667l-.223.753h.765l-.219-.753zM7.893 9.832l1.155-3.397a.24.24 0 01.229-.162h.653c.102 0 .195.066.229.162l1.155 3.397a.242.242 0 01-.229.32h-.46a.242.242 0 01-.231-.173l-.189-.64h-1.21l-.182.638a.243.243 0 01-.231.175h-.46a.243.243 0 01-.23-.32zM16.551 17.14v-.602a.301.301 0 00-.301-.301H7.571a.301.301 0 00-.3.301v.603c0 .166.134.301.3.301h8.679a.301.301 0 00.301-.301z"
        fill="currentColor"
      />
      <path
        d="M16.551 17.14v-.602a.301.301 0 00-.301-.301H7.571a.301.301 0 00-.3.301v.603c0 .166.134.301.3.301h8.679a.301.301 0 00.301-.301z"
        fill="currentColor"
      />
      <path
        d="M16.551 17.14v-.602a.301.301 0 00-.301-.301H7.571a.301.301 0 00-.3.301v.603c0 .166.134.301.3.301h8.679a.301.301 0 00.301-.301z"
        fill="currentColor"
      />
      <path
        d="M16.551 17.14v-.602a.301.301 0 00-.301-.301H7.571a.301.301 0 00-.3.301v.603c0 .166.134.301.3.301h8.679a.301.301 0 00.301-.301z"
        fill="currentColor"
      />
      <path
        d="M18.821 19.795V4.205c0-.665-.54-1.205-1.205-1.205H6.206C5.54 3 5 3.54 5 4.205v15.59C5 20.46 5.54 21 6.205 21h11.411c.666 0 1.205-.54 1.205-1.205zm-12.616-.151V4.356a.15.15 0 01.151-.15h11.11a.15.15 0 01.15.15v15.288a.15.15 0 01-.15.15H6.355a.15.15 0 01-.15-.15z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStraightOneColumnsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
