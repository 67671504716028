import * as React from "react";
function SvgCarAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.277 10.18l-.468-1.17-.83-2.077A4.644 4.644 0 0014.646 4H9.354a4.644 4.644 0 00-4.333 2.933l-.83 2.077-.468 1.17A2.661 2.661 0 002 12.667v2c0 .671.257 1.278.667 1.747v2.253C2.667 19.403 3.264 20 4 20h1.333c.737 0 1.334-.597 1.334-1.333v-1.334h10.666v1.334c0 .736.597 1.333 1.334 1.333H20c.736 0 1.333-.597 1.333-1.333v-2.253c.41-.469.667-1.075.667-1.747v-2c0-1.14-.718-2.104-1.723-2.487zM7.498 7.924a2 2 0 011.857-1.257h5.292a2 2 0 011.857 1.257l.83 2.076H6.667l.83-2.076zm-2.164 6.734c-.8 0-1.333-.531-1.333-1.329C4 12.532 4.533 12 5.333 12c.8 0 2 1.196 2 1.994 0 .797-1.2.664-2 .664zm13.334 0c-.8 0-2 .133-2-.664 0-.798 1.2-1.994 2-1.994S20 12.532 20 13.33c0 .797-.533 1.328-1.333 1.328z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCarAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
