import * as React from "react";
function SvgAngleDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.943 18.017L2.441 9.515a1.494 1.494 0 010-2.12l1.413-1.412a1.494 1.494 0 012.12 0L12 12.009l6.027-6.026a1.494 1.494 0 012.12 0l1.412 1.412a1.494 1.494 0 010 2.12l-8.502 8.502a1.487 1.487 0 01-2.114 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngleDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
