import * as React from "react";
function SvgSolarPanelSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.5 18l-1.5.002V17h-4v1.006l-1.499.002a.5.5 0 00-.5.5v.991a.5.5 0 00.5.5l6.998-.007a.5.5 0 00.5-.5l.001-.991a.5.5 0 00-.5-.5zm4.788-13.164A1.013 1.013 0 0019.283 4H4.717c-.498 0-.923.353-1.005.836C1.896 15.536 2.002 14.877 2.001 15c0 .541.447 1 1.019 1h17.96A1.01 1.01 0 0022 15.007c0-.128.104.53-1.711-10.171zM10.12 6h3.76l.305 3h-4.37l.305-3zm-2.35 8H4.223l.594-3.5h3.311L7.771 14zm.51-5H5.07l.51-3h3.005L8.28 9zm1.025 5l.357-3.5h4.676l.356 3.5H9.305zm6.11-8h3.006l.509 3h-3.21l-.305-3zm.814 8l-.356-3.5h3.311l.594 3.5H16.23z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSolarPanelSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
