import * as React from "react";
function SvgStepBackwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.857 21.464V2.536c0-.295.241-.536.536-.536h2.143c.294 0 .536.241.536.536v7.875l8.727-8.08c.92-.764 2.344-.125 2.344 1.098V20.57c0 1.223-1.424 1.862-2.344 1.098l-8.727-8.03v7.825a.537.537 0 01-.536.536H5.393a.537.537 0 01-.536-.536z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStepBackwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
