import * as React from "react";
function SvgCropSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.063 15.75H19.5V6.268l2.317-2.317a.625.625 0 000-.884l-.884-.884a.625.625 0 00-.884 0L17.732 4.5H9.5v3.75h4.482L8.25 13.982V2.938A.937.937 0 007.312 2H5.438a.937.937 0 00-.937.938V4.5H2.937A.937.937 0 002 5.438v1.875c0 .517.42.937.938.937H4.5v10.313c0 .517.42.937.938.937H14.5v-3.75h-4.482l5.732-5.732v11.044c0 .518.42.938.938.938h1.875c.517 0 .937-.42.937-.938V19.5h1.563c.517 0 .937-.42.937-.938v-1.875a.937.937 0 00-.938-.937z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCropSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
