import * as React from "react";
function SvgThermometerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.625 2.799c-1.465-1.2-3.73-1.027-5.152.398l-1.785 1.797 1.972 1.972a.314.314 0 010 .442l-.44.441a.314.314 0 01-.442 0l-1.969-1.972-1.761 1.773 1.964 1.968a.314.314 0 010 .442l-.441.441a.314.314 0 01-.441 0l-1.965-1.96-1.761 1.773 1.956 1.96a.313.313 0 010 .442l-.441.441a.314.314 0 01-.441 0l-1.957-1.96-1.77 1.784v3.941l-3.476 3.476a.933.933 0 000 1.324.933.933 0 001.324 0l3.477-3.476h3.901l9.929-9.893c1.363-1.363 1.648-3.965-.281-5.554z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThermometerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
