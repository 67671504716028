import * as React from "react";
function SvgInfinitySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.722 7C14.656 7 13.04 8.29 12 9.456 10.96 8.291 9.344 7 7.278 7 4.368 7 2 9.244 2 12s2.369 5 5.278 5c2.066 0 3.681-1.29 4.722-2.456C13.04 15.709 14.656 17 16.722 17 19.632 17 22 14.756 22 12s-2.369-5-5.278-5zm-9.444 7C6.022 14 5 13.103 5 12s1.022-2 2.278-2c1.194 0 2.294 1.128 2.938 2-.638.863-1.747 2-2.938 2zm9.444 0c-1.194 0-2.294-1.128-2.938-2 .638-.863 1.747-2 2.938-2 1.256 0 2.278.897 2.278 2s-1.022 2-2.278 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgInfinitySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
