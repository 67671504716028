import * as React from "react";
function SvgFileInvoiceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.75 12h-7.5v2.5h7.5V12zm3.477-5.898l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-5.977 1.21V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM7 4.813c0-.172.14-.312.313-.312h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 5.437v-.625zm0 2.5C7 7.14 7.14 7 7.313 7h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 7.937v-.625zm10 11.875c0 .173-.14.313-.313.313h-3.125a.312.312 0 01-.312-.313v-.625c0-.172.14-.312.313-.312h3.124c.173 0 .313.14.313.313v.625zm0-7.812v3.75c0 .345-.28.625-.625.625h-8.75A.625.625 0 017 15.125v-3.75c0-.345.28-.625.625-.625h8.75c.345 0 .625.28.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileInvoiceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
