import * as React from "react";
function SvgSpaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.73 9.778c-1.007.004-4.687.214-7.424 2.882-1.15 1.029-1.853 2.198-2.306 3.294-.453-1.096-1.156-2.265-2.306-3.294C6.957 9.992 3.277 9.782 2.27 9.778a.264.264 0 00-.269.271c.008.97.248 4.38 3.082 6.92C8 19.81 10.89 19.779 12 19.779c1.111 0 4 .033 6.918-2.808 2.834-2.541 3.074-5.951 3.082-6.92a.264.264 0 00-.27-.272zM12 13.618a9.548 9.548 0 011.53-1.754 10.392 10.392 0 012.093-1.569c-.571-2.448-1.796-4.62-3.36-5.98a.417.417 0 00-.525 0c-1.562 1.36-2.787 3.53-3.359 5.975a10.21 10.21 0 012.056 1.541A9.815 9.815 0 0112 13.618z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSpaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
