import * as React from "react";
function SvgArrowLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.496 20.441l-.99.991c-.42.42-1.099.42-1.514 0L2.315 12.76a1.067 1.067 0 010-1.513l8.677-8.678c.42-.42 1.098-.42 1.513 0l.991.991a1.073 1.073 0 01-.017 1.531l-5.38 5.125h12.83c.593 0 1.071.477 1.071 1.07v1.43c0 .593-.478 1.07-1.071 1.07H8.099l5.38 5.125c.437.415.446 1.107.018 1.531z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
