import styled from "styled-components";

export const UiQuestionsStatisticsPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.checkedCount {
		align-items: center;
		padding: 0 4px;
		color: #fff;
		line-height: 32px;
	}

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}
		}
	}
	.tableContainer {
		padding: 12px 0 24px;

		.ant-table-content {
			th {
				background-color: rgb(210, 225, 241);
				border-color: #999 !important;
				&:nth-child(1),
				&:nth-child(2) {
					background-color: rgb(167, 196, 229);
				}
			}
			tr {
				td,
				th {
					&:nth-last-child(2) {
						background-color: rgb(254, 243, 209);
					}
					&:last-child {
						background-color: rgb(238, 206, 204);
					}
				}
				td {
					&:first-child {
						background-color: unset;
					}
				}
			}
		}
	}
`;
