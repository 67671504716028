import * as React from "react";
function SvgTimesCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064A8.062 8.062 0 013.935 12 8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064zm4.105-10.572L13.597 12l2.508 2.508c.19.19.19.496 0 .686l-.912.91a.484.484 0 01-.685 0L12 13.598l-2.508 2.508a.484.484 0 01-.686 0l-.91-.912a.484.484 0 010-.685L10.402 12 7.895 9.492a.484.484 0 010-.686l.911-.91c.19-.19.496-.19.686 0L12 10.402l2.508-2.508c.19-.19.496-.19.686 0l.91.911c.19.19.19.496 0 .686z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTimesCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
