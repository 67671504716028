import * as React from "react";
function SvgWaveSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.875 19h-4.75A1.125 1.125 0 0111 17.875V7H8v4.875A1.125 1.125 0 016.875 13H2.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H6V6.125A1.125 1.125 0 017.125 5h4.75A1.125 1.125 0 0113 6.125V17h3v-4.875A1.125 1.125 0 0117.125 11H21.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H18v4.875A1.125 1.125 0 0116.875 19z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWaveSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
