import * as React from "react";
function SvgOilCanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.682 9.01L15 11l-1.578-.789a2.002 2.002 0 00-.894-.211H10.75V8.5h1.75A.5.5 0 0013 8v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V8a.5.5 0 00.5.5h1.75V10H7.5L3.18 9.214A.997.997 0 002 10.198v2.967a1 1 0 00.821.984L5 14.546V16a1 1 0 001 1h8.582a1 1 0 00.712-.297l6.633-6.711A.25.25 0 0022 9.815V9.25a.25.25 0 00-.318-.24zM5 13.02l-1.5-.273v-1.95l1.5.272v1.951zm14.167 2.646a1.334 1.334 0 002.667 0C21.834 14.93 20.5 13 20.5 13s-1.333 1.93-1.333 2.667z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgOilCanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
