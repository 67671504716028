import * as React from "react";
function SvgChurchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.514 11.709L13 9.6V8h1.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H13V4.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V6H9.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H11v1.6l-3.514 2.109a1 1 0 00-.486.857V20h3v-3a2 2 0 114 0v3h3v-7.434a1 1 0 00-.486-.857zM2 16.374V19.5a.5.5 0 00.5.5H6v-6l-3.394 1.454a1 1 0 00-.606.92zm19.394-.92L18 14v6h3.5a.5.5 0 00.5-.5v-3.126a1 1 0 00-.606-.92z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChurchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
