import * as React from "react";
function SvgAnkhSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.313 12H15.57C16.392 10.672 17 9.096 17 7.625 17 4.173 14.761 2 12 2S7 4.173 7 7.625c0 1.47.607 3.047 1.43 4.375H6.688a.937.937 0 00-.938.938v1.25c0 .517.42.937.938.937h3.75v5.938c0 .517.42.937.937.937h1.25c.518 0 .938-.42.938-.938v-5.937h3.75c.517 0 .937-.42.937-.938v-1.25a.937.937 0 00-.938-.937zM12 5.125c1.157 0 1.875.958 1.875 2.5 0 1.354-1.06 3.052-1.875 3.94-.815-.887-1.875-2.586-1.875-3.94 0-1.542.718-2.5 1.875-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAnkhSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
