import { Api } from "common-components";
import { stringify } from "query-string";
/**
 * 取得試題查詢selection
 */
export const getBankSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/Selection`);
	return response;
};

/**
 * 取得試題查詢冊次selection
 */
export const getBankBookSelection = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/BookSelection?${queryString}`);
	return response;
};

// 取得 單一題目之 HTML
export const getQuestionHtml = async (eduSubject, uid) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/${eduSubject}/${uid}/html`);
	return response;
};

// 取得題目上下架狀況
export const getQuestionAvailability = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/QuestionAvailability`, payload);
	return response;
};

// 修改題目上下架狀況
export const updateQuestionAvailability = async (eduSubject, uid, payload) => {
	const queryString = stringify({ eduSubject, UID: uid });
	const response = await Api.put(
		`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/Availability/${uid}?${queryString}`,
		payload,
	);
	return response;
};

const CheckStatusMap = {
	0: "None",
	1: "Waiting",
	2: "QuickWaiting",
	3: "PaperWaiting",
	4: "EduWaiting",
	5: "CMSWaiting",
	10: "Finish",
};
export const getCheckQuestion = async ({
	year,
	education,
	subjectCode,
	version,
	bookCode,
	checkStatus,
	CMS,
	isPeriod,
}) => {
	const queryString = stringify(
		{ CMS, year, education, subjectCode, version, bookCode, checkStatus: CheckStatusMap[checkStatus], isPeriod },
		{
			skipEmptyString: true,
			skipNull: true,
		},
	);

	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/Check?${queryString}`);
	return response;
};

// 更新試題檢查狀態
export const updateQuestionCheckStatus = async (checkStatus, payload) => {
	const queryString = stringify({ checkStatus: CheckStatusMap[checkStatus] });
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/Check/CheckStatus?${queryString}`, {
		questions: payload,
	});
	return response;
};

// 建立檢查用測驗
export const createCheckExam = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/Check/GetQuiz`, {
		questions: payload,
	});
	return response;
};

// 快速檢查頁- 批次下載
export const downloadCheckQuestions = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/Batch/Check/Export`, payload);
	return response;
};

export const getBookIdChapter = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/bankv2/bookid/chapter`, payload);
	return response;
};

export const getBankQuestion = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/bankv2/question`, payload);
	return response;
};

export const getQuestionUids = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/bankv2/question/uids`, payload);
	return response;
};

// 錯題回報列表
export const getErrorQuestionReport = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/questionstatus/deprecationquestion?${queryString}`,
	);
	return response;
};
