import * as React from "react";
function SvgOmSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.086 4.38a.408.408 0 00.577 0l.842-.842a.407.407 0 000-.576l-.843-.843a.408.408 0 00-.576 0l-.843.843a.407.407 0 000 .576l.843.842zm2.012 5.12a3.878 3.878 0 00-2.76 1.142l-.947.948c-.264.264-.616.41-.991.41h-1.83c.412-.864.554-1.88.302-2.939-.394-1.662-1.81-2.973-3.497-3.25-1.412-.231-2.77.197-3.75 1.125a.637.637 0 00.044.974l1.021.766a.608.608 0 00.75-.008 1.877 1.877 0 013.058 1.457A1.877 1.877 0 017.623 12H6.377a.628.628 0 00-.563.91l.629 1.256a.622.622 0 00.514.334h1.29c1.38 0 2.501 1.12 2.501 2.5 0 1.379-1.121 2.5-2.5 2.5-3.751 0-4.78-2.11-5.67-3.595-.177-.295-.577-.14-.578.204-.004 2.14 1.607 5.89 6.248 5.89 2.757 0 5-2.242 5-5a4.94 4.94 0 00-.695-2.5h.847a3.877 3.877 0 002.76-1.142l.947-.947a1.403 1.403 0 012.393.991v3.911c0 .517-.734.938-1.25.938-1.54 0-2.605-.947-3.197-1.676a.312.312 0 00-.555.196v1.48s0 2.5 3.751 2.5c1.896 0 3.751-1.542 3.751-3.438v-3.91A3.906 3.906 0 0018.098 9.5zm1.648-4.872c-3.342 2.543-6.604.107-6.742.002a.625.625 0 00-.93.795c.063.115 1.578 2.825 4.641 2.825 3.122 0 3.859-1.225 3.975-1.47a.616.616 0 00.06-.268V5.125a.626.626 0 00-1.004-.497z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgOmSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
