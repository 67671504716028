import * as React from "react";
function SvgSpellCheckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.444 12h3.172c1.5 0 2.847-1.118 2.934-2.616a2.772 2.772 0 00-.877-2.19c.255-.478.365-1.02.315-1.559-.131-1.462-1.471-2.524-2.94-2.524h-2.604a.556.556 0 00-.556.556v7.777a.555.555 0 00.556.556zm1.389-6.944h1.389a.833.833 0 110 1.666h-1.39V5.056zm0 3.333h1.944a.833.833 0 110 1.667h-1.944V8.389zM7.386 3.884a1.111 1.111 0 00-1.059-.773H5.45a1.111 1.111 0 00-1.059.773l-2.37 7.412a.555.555 0 00.534.704h.866a.556.556 0 00.535-.407l.415-1.26h3.035l.415 1.26a.556.556 0 00.535.407h.866a.556.556 0 00.535-.704L7.386 3.884zM5.103 8.111l.785-2.385.786 2.385H5.103zm16.735 4.6l-1.57-1.573a.55.55 0 00-.784 0l-5.26 5.26-1.924-1.926a.55.55 0 00-.784 0l-1.573 1.572a.555.555 0 000 .785l3.889 3.896a.551.551 0 00.784 0l7.222-7.23a.556.556 0 000-.784z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSpellCheckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
