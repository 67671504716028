import * as React from "react";
function SvgUsersSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5 11c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm14 0c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm1 1h-2c-.55 0-1.047.222-1.41.581A4.571 4.571 0 0118.939 16H21c.553 0 1-.447 1-1v-1c0-1.103-.897-2-2-2zm-8 0c1.934 0 3.5-1.566 3.5-3.5S13.934 5 12 5a3.498 3.498 0 00-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5zm2.4 1h-.26c-.65.313-1.371.5-2.14.5a4.933 4.933 0 01-2.14-.5H9.6A3.6 3.6 0 006 16.6v.9A1.5 1.5 0 007.5 19h9a1.5 1.5 0 001.5-1.5v-.9a3.6 3.6 0 00-3.6-3.6zm-6.99-.419A1.994 1.994 0 006 12H4c-1.103 0-2 .897-2 2v1c0 .553.447 1 1 1h2.06a4.582 4.582 0 012.35-3.419z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUsersSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
