import * as React from "react";
function SvgSearchLocationSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.728 19.291l-3.895-3.894a.937.937 0 00-.664-.273h-.637a8.084 8.084 0 001.72-5A8.124 8.124 0 0010.125 2a8.124 8.124 0 100 16.248c1.886 0 3.621-.64 5-1.718v.636c0 .25.098.489.274.664l3.894 3.895a.934.934 0 001.325 0l1.105-1.106a.941.941 0 00.004-1.328zm-11.602-3.543A5.621 5.621 0 014.5 10.124 5.621 5.621 0 0110.126 4.5a5.621 5.621 0 015.625 5.624 5.621 5.621 0 01-5.625 5.624zm0-9.373a2.884 2.884 0 00-2.884 2.884c0 1.287 1.885 3.635 2.608 4.487a.36.36 0 00.553 0c.723-.852 2.608-3.2 2.608-4.487a2.884 2.884 0 00-2.884-2.884zm0 3.75a.938.938 0 110-1.875.938.938 0 010 1.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSearchLocationSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
