import * as React from "react";
function SvgChairSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.626 7c0-1.152.633-2.148 1.563-2.691V12h1.875V3.875h1.875V12h1.875V4.309A3.116 3.116 0 0116.376 7v5h1.875V7a5 5 0 00-5-5h-2.5a5 5 0 00-5 5v5h1.875V7zm13.059 8.355l-.418-1.25a1.252 1.252 0 00-1.188-.855H4.92c-.539 0-1.016.344-1.187.855l-.418 1.25A1.253 1.253 0 004.5 17v4.375c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625V17h10v4.375c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625V17a1.25 1.25 0 001.184-1.645z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChairSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
