import * as React from "react";
function SvgMoneyCheckAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 5H3a1 1 0 00-1 1v12a1 1 0 001 1h18a1 1 0 001-1V6a1 1 0 00-1-1zM7.5 14.246v.504a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.51a1.789 1.789 0 01-.98-.354.25.25 0 01-.018-.38l.367-.35a.257.257 0 01.317-.022c.12.075.258.116.4.116h.879c.203 0 .368-.185.368-.412 0-.186-.112-.35-.274-.398l-1.406-.422a1.414 1.414 0 01-.987-1.356c0-.766.596-1.389 1.334-1.408V8.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.51c.353.017.696.14.98.354a.25.25 0 01.018.38l-.367.35a.257.257 0 01-.317.022.75.75 0 00-.4-.116h-.879c-.203 0-.369.185-.369.412 0 .186.113.35.274.398l1.407.422c.58.174.986.732.986 1.356 0 .766-.595 1.389-1.333 1.408zm7.5-.496a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v.5zm5 0a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v.5zm0-3a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoneyCheckAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
