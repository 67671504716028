import * as React from "react";
function SvgDiceTwoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.143 2H4.857A2.857 2.857 0 002 4.857v14.286A2.857 2.857 0 004.857 22h14.286A2.857 2.857 0 0022 19.143V4.857A2.857 2.857 0 0019.143 2zM7.714 9.143a1.429 1.429 0 110-2.858 1.429 1.429 0 010 2.858zm8.572 8.571a1.429 1.429 0 110-2.857 1.429 1.429 0 010 2.857z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDiceTwoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
