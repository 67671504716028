import * as React from "react";
function SvgTextSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.15 22v-1.252c2.055 0 3.082-.834 3.082-2.504V4.772c0-.596-.405-.894-1.215-.894-.612 0-1.146.11-1.6.328-.435.198-.82.506-1.156.924-.317.417-.593.944-.83 1.58a17.674 17.674 0 00-.593 2.175L3 8.617 3.83 2c.514.08 1.077.14 1.69.179.612.04 1.096.06 1.452.06H18.68c.435 0 .968-.02 1.6-.06.633-.04 1.206-.1 1.72-.179v6.528l-1.72.149c-.098-1.57-.434-2.743-1.007-3.518-.553-.774-1.522-1.162-2.905-1.162-.85 0-1.274.258-1.274.775v13.472c0 1.67 1.047 2.504 3.142 2.504V22H7.15z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTextSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
