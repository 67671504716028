import * as React from "react";
function SvgFileOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.948 5.827L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-1.476 1.176h-2.973V4.03l2.973 2.972zM6.376 20.125V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247H6.376z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
