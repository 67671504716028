import * as React from "react";
function SvgFireExtinguisherSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.204 3.028l-6.562 1.094c-.443.074-.392.526-.392.69h-2.279A2.189 2.189 0 008.875 2a2.189 2.189 0 00-2.04 2.977c-2.033.51-2.945 2.117-3.518 3.55a.937.937 0 101.741.696c1.124-2.81 2.37-2.536 4.755-2.536v1.058C8.19 8.17 7 9.668 7 11.422v9.64c0 .518.42.938.938.938h5.625c.517 0 .937-.42.937-.938v-9.687a3.752 3.752 0 00-2.813-3.632V6.687h1.563c0 .117-.066.614.392.69l6.562 1.095a.469.469 0 00.546-.463V3.491c0-.29-.26-.51-.546-.463zM8.875 4.812a.626.626 0 11.001-1.251.626.626 0 01-.001 1.252z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFireExtinguisherSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
