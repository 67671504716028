import * as React from "react";
function SvgHamsaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.896 14.002a1.248 1.248 0 00-1.146-.752h-2.5V5.125c0-.86-.703-1.563-1.563-1.563-.859 0-1.562.704-1.562 1.563v5.234a.39.39 0 01-.39.391h-.782a.39.39 0 01-.39-.39V3.562C13.563 2.703 12.858 2 12 2c-.86 0-1.563.703-1.563 1.563v6.796a.39.39 0 01-.39.391h-.781a.39.39 0 01-.391-.39V5.124c0-.86-.703-1.563-1.563-1.563-.859 0-1.562.704-1.562 1.563v8.125h-2.5a1.248 1.248 0 00-.914 2.103l4.011 4.298C7.741 21.144 9.8 22 12 22c2.2 0 4.259-.856 5.653-2.35l4.011-4.297a1.25 1.25 0 00.232-1.351zM12 18.25c-2.071 0-3.75-2.5-3.75-2.5s1.679-2.5 3.75-2.5c2.071 0 3.75 2.5 3.75 2.5s-1.679 2.5-3.75 2.5zm0-3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHamsaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
