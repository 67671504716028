import * as React from "react";
function SvgThumbsDownOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.839 10.801a3.629 3.629 0 00-.351-2.46 3.767 3.767 0 00-.678-2.617C19.773 3.54 18.41 2 15.398 2h-.867c-4.047 0-5.305 1.563-6.906 1.563h-.424a1.245 1.245 0 00-.826-.313h-2.5c-.69 0-1.25.56-1.25 1.25v9.375c0 .69.56 1.25 1.25 1.25h2.5a1.25 1.25 0 001.082-.625h.276c.748.662 1.797 2.37 2.686 3.258.534.534.396 4.242 2.803 4.242 2.25 0 3.721-1.247 3.721-4.091a4.99 4.99 0 00-.345-1.818h1.425c1.898 0 3.352-1.624 3.352-3.343 0-.748-.194-1.367-.536-1.947zM5.125 13.562a.937.937 0 110-1.874.937.937 0 010 1.874zm12.898.654H13.96c0 1.477 1.108 2.163 1.108 3.693 0 .928 0 2.216-1.846 2.216-.739-.739-.37-2.585-1.477-3.693-1.038-1.038-2.586-3.807-3.693-3.807h-.427V5.353c2.094 0 3.906-1.478 6.705-1.478h1.477c1.387 0 2.376.67 2.075 2.575.594.318 1.035 1.423.545 2.248.843.797.73 1.995.203 2.564.37 0 .873.738.87 1.477-.003.738-.653 1.477-1.477 1.477z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThumbsDownOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
