import * as React from "react";
function SvgWeightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 4.5h-1.015A7.443 7.443 0 0119.5 8.25c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5c0-1.367.373-2.645 1.015-3.75H4.5A2.503 2.503 0 002 7v12.5C2 20.878 3.121 22 4.5 22h15c1.378 0 2.5-1.122 2.5-2.5V7c0-1.379-1.122-2.5-2.5-2.5zm-7.5 10A6.25 6.25 0 1012 2a6.25 6.25 0 000 12.5zm-.012-5.935L13.3 5.504a.624.624 0 111.15.492l-1.315 3.068c.26.28.428.649.428 1.061a1.563 1.563 0 11-1.575-1.56z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWeightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
