import * as React from "react";
function SvgVolumeStrongSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 18.883c.34.219.784.124 1.02-.233C21.26 16.791 22 14.502 22 12.002c0-2.508-.761-4.782-1.98-6.648a.735.735 0 00-1.02-.24c-.362.225-.42.67-.17 1.07a10.56 10.56 0 011.722 5.818c0 2.194-.658 4.199-1.722 5.817-.25.394-.192.846.17 1.064zm-9.287-.663c.555 0 .968-.408.968-.948V6.761a.962.962 0 00-.975-.977c-.37 0-.643.146-1.042.517L5.775 8.977a.24.24 0 01-.155.058H3.648C2.584 9.035 2 9.618 2 10.726v2.559c0 1.108.584 1.698 1.648 1.698h1.965c.066 0 .118.015.155.051l2.896 2.697c.37.343.673.489 1.05.489zm6.369-1.225a.728.728 0 001.005-.211c.916-1.305 1.448-3.018 1.448-4.79 0-1.77-.525-3.498-1.448-4.789a.728.728 0 00-1.005-.211c-.37.219-.421.67-.155 1.071.731 1.08 1.152 2.479 1.152 3.93 0 1.45-.428 2.843-1.152 3.929-.259.4-.214.853.155 1.071zm-2.918-1.866a.724.724 0 00.982-.19c.577-.765.916-1.83.916-2.944 0-1.116-.34-2.173-.916-2.953a.714.714 0 00-.982-.175c-.37.234-.444.671-.148 1.116.384.54.59 1.26.59 2.012 0 .75-.213 1.465-.59 2.012-.288.452-.222.882.148 1.122z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVolumeStrongSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
