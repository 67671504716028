import * as React from "react";
function SvgTruckLoadingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.569 15.738a.501.501 0 00.612.353l6.763-1.813a.501.501 0 00.353-.612L9.744 7.869a.501.501 0 00-.613-.353l-2.412.65.775 2.896-1.931.516-.775-2.897-2.416.647a.501.501 0 00-.353.613l1.55 5.796zM14 4c-.553 0-1 .447-1 1v10.113l-10.816 2.95a.249.249 0 00-.175.306l.394 1.447a.249.249 0 00.306.175l12.303-3.357A3.496 3.496 0 0022 16.5V4h-8zm4.5 14a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTruckLoadingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
