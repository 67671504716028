import * as React from "react";
function SvgPenFancySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.093 13.052a1.25 1.25 0 00-.79.791L2 20.75l.183.183 3.629-3.628c-.026-.1-.062-.197-.062-.305A1.25 1.25 0 117 18.25c-.108 0-.204-.035-.304-.061l-3.629 3.628.183.183 6.907-2.302a1.25 1.25 0 00.79-.791l1.293-3.284-3.863-3.862-3.284 1.291zm11.33-9.946L9.272 10.9l3.822 3.822 7.793-7.153c3.317-2.932-1.553-7.753-4.462-4.462z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPenFancySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
