import * as React from "react";
function SvgInfoCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.478 2 2 6.48 2 12c0 5.524 4.478 10 10 10 5.523 0 10-4.476 10-10 0-5.52-4.477-10-10-10zm0 4.435a1.694 1.694 0 110 3.388 1.694 1.694 0 010-3.388zm2.258 10.242a.484.484 0 01-.484.484h-3.548a.484.484 0 01-.484-.484v-.967c0-.268.217-.484.484-.484h.484v-2.58h-.484a.484.484 0 01-.484-.485v-.967c0-.268.217-.484.484-.484h2.58c.268 0 .484.216.484.483v4.033h.484c.267 0 .484.216.484.484v.967z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgInfoCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
