import * as React from "react";
function SvgPaletteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.98 2.197c-3.883.758-7.012 3.879-7.777 7.75-1.446 7.305 5.144 12.75 10.11 11.98 1.609-.25 2.398-2.132 1.66-3.582-.903-1.773.386-3.843 2.378-3.843h3.114c1.398 0 2.531-1.157 2.535-2.551-.02-6.157-5.621-11-12.02-9.754zM5.75 14.502c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .691-.559 1.25-1.25 1.25zm1.25-5c-.692 0-1.25-.56-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .69-.559 1.25-1.25 1.25zm5-2.5c-.692 0-1.25-.56-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .69-.559 1.25-1.25 1.25zm5 2.5c-.692 0-1.25-.56-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .69-.559 1.25-1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPaletteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
