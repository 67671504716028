import * as React from "react";
function SvgSurpriseOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm0-7.096a2.583 2.583 0 00-2.58 2.58A2.583 2.583 0 0012 18.13a2.583 2.583 0 002.58-2.58A2.583 2.583 0 0012 12.967zm-1.935-2.903c0-.714-.577-1.29-1.29-1.29-.715 0-1.291.576-1.291 1.29 0 .713.576 1.29 1.29 1.29.714 0 1.29-.577 1.29-1.29zm5.16-1.29c-.713 0-1.29.576-1.29 1.29a1.289 1.289 0 102.58 0c.001-.714-.575-1.29-1.29-1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSurpriseOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
