import * as React from "react";
function SvgSprayCanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.75 3.25c0-.69-.56-1.25-1.25-1.25H7c-.69 0-1.25.56-1.25 1.25V7h5V3.25zm10 3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-10 1.25h-5A3.75 3.75 0 002 12v8.75c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V12a3.75 3.75 0 00-3.75-3.75zm-2.5 10a3.125 3.125 0 110-6.25 3.125 3.125 0 010 6.25zm12.5-12.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM17 7a1.25 1.25 0 100 2.5A1.25 1.25 0 0017 7zm-3.75-3.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm3.75 0a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm3.75 7.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSprayCanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
