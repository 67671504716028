import * as React from "react";
function SvgMapSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 7.197V19.22c0 .394.397.662.762.517l4.794-2.182V4.222L2.699 6.165A1.111 1.111 0 002 7.197zm6.667 10.359l6.666 2.222V6.444L8.667 4.222v13.334zM21.238 4.263l-4.794 2.181v13.334l4.857-1.943A1.111 1.111 0 0022 16.803V4.778a.556.556 0 00-.762-.515z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMapSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
