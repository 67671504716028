"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOnClickOutside = void 0;
var _react = require("react");
const useOnClickOutside = (ref, handler) => {
  (0, _react.useEffect)(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};
exports.useOnClickOutside = useOnClickOutside;