import * as React from "react";
function SvgKhandaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.243 4.578a.624.624 0 00-.831.88 6.394 6.394 0 01.984 3.415 6.236 6.236 0 01-2.784 5.172L12.62 16.13v-.785l1.72-1.41a.553.553 0 00.11-.597l-.306-.673c1.321-.752 2.224-2.158 2.224-3.789 0-1.593-.86-2.974-2.133-3.738l.204-.447a.556.556 0 00-.14-.627L11.996 2 9.69 4.063a.557.557 0 00-.14.626l.205.447a4.358 4.358 0 00-2.133 3.74c0 1.63.902 3.036 2.225 3.788l-.307.673a.554.554 0 00.11.597l1.72 1.41v.777L8.38 14.038a6.227 6.227 0 01-2.775-5.163c0-1.214.34-2.395.984-3.417.152-.24.094-.538-.1-.745-.196-.209-.535-.242-.783-.106-3.067 1.674-4.576 5.412-3.183 8.955.276.703.699 1.343 1.19 1.916l2.186 2.553c.19.223.51.283.768.146l3.1-1.65 1.14.794-1.839 1.28c-.065-.014-.126-.039-.195-.039a.937.937 0 000 1.875.926.926 0 00.91-.81l1.588-1.105v1.17c-.367.217-.625.6-.625 1.058a1.25 1.25 0 102.499 0c0-.457-.258-.84-.625-1.058v-1.178l1.598 1.112c.065.455.44.811.912.811a.937.937 0 000-1.875c-.07 0-.13.025-.196.04l-1.84-1.28 1.142-.795 3.1 1.65a.625.625 0 00.767-.146l2.051-2.394c.737-.86 1.328-1.856 1.611-2.953.845-3.268-.642-6.534-3.521-8.106zm-3.75 4.297c0 .87-.449 1.637-1.126 2.085l-.22-.485a4.376 4.376 0 010-2.951l.305-.671a2.496 2.496 0 011.041 2.022zm-4.997 0c0-.833.413-1.567 1.041-2.022l.306.671a4.376 4.376 0 010 2.952l-.22.484a2.498 2.498 0 01-1.127-2.085z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKhandaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
