import * as React from "react";
function SvgBugSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 13.285c-.02.681-.595 1.215-1.276 1.215h-2.162v.625c0 .854-.19 1.663-.53 2.389l2.352 2.352a1.25 1.25 0 01-1.768 1.768l-2.138-2.138a5.601 5.601 0 01-3.54 1.254v-9.531a.469.469 0 00-.47-.469h-.937a.469.469 0 00-.469.469v9.531a5.601 5.601 0 01-3.54-1.254l-2.138 2.138a1.25 1.25 0 01-1.768-1.768l2.353-2.352a5.603 5.603 0 01-.532-2.389V14.5H3.276c-.681 0-1.257-.534-1.276-1.215A1.25 1.25 0 013.25 12h2.187V9.705l-1.82-1.821a1.25 1.25 0 011.767-1.768L7.518 8.25h8.964l2.134-2.134a1.25 1.25 0 011.768 1.768l-1.822 1.821V12h2.188A1.25 1.25 0 0122 13.285zM12.039 2a4.375 4.375 0 00-4.375 4.375h8.75A4.375 4.375 0 0012.039 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBugSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
