import * as React from "react";
function SvgSdCardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 2H9.5l-5 5v12.5C4.5 20.879 5.621 22 7 22h10c1.379 0 2.5-1.121 2.5-2.5v-15C19.5 3.121 18.379 2 17 2zm-6.25 6.25H8.875V4.5h1.875v3.75zm3.125 0H12V4.5h1.875v3.75zm3.125 0h-1.875V4.5H17v3.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSdCardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
