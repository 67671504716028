import * as React from "react";
function SvgTrafficLightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 9.5H17V8.02a3.739 3.739 0 002.5-3.52H17V3.25C17 2.56 16.44 2 15.75 2h-7.5C7.56 2 7 2.56 7 3.25V4.5H4.5A3.739 3.739 0 007 8.02V9.5H4.5A3.739 3.739 0 007 13.02v1.48H4.5c0 1.673 1.104 3.074 2.617 3.557A4.998 4.998 0 0012 22a4.998 4.998 0 004.883-3.943c1.514-.483 2.617-1.884 2.617-3.557H17v-1.48a3.739 3.739 0 002.5-3.52zM12 18.25a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75zm0-5a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75zm0-5a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTrafficLightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
