import * as React from "react";
function SvgDemocratSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.916 12.028l-.613-.92A4.725 4.725 0 0017.363 9H10L7.462 6.462a1.835 1.835 0 00.235-2.31.308.308 0 00-.475-.047L5.915 5.412l-1.34-1.338a.252.252 0 00-.388.038 1.503 1.503 0 00.19 1.887c.104.103.229.166.354.235-.069.053-.147.096-.2.168L2.2 9.506a1 1 0 00-.094 1.046l.447.894c.168.338.515.553.894.553h.968a.998.998 0 00.707-.293l1.19-1.082L8 14h11v-2.43c.507.38.572.55 1.253 1.571.154.231.463.29.694.138l.832-.553a.506.506 0 00.137-.697zm-10.66-.425l-.515.503.122.71a.156.156 0 01-.226.165L10 12.646l-.638.335a.156.156 0 01-.225-.166l.122-.71-.515-.502a.156.156 0 01.087-.266l.713-.103.318-.647c.057-.116.222-.116.282 0l.318.647.713.103a.155.155 0 01.081.266zm3.5 0l-.515.503.122.71a.156.156 0 01-.225.165l-.638-.335-.637.335a.156.156 0 01-.226-.166l.122-.71-.515-.502a.156.156 0 01.087-.266l.713-.103.319-.647c.056-.116.221-.116.28 0l.32.647.712.103a.155.155 0 01.081.266zm3.5 0l-.515.503.122.71a.156.156 0 01-.225.165L17 12.646l-.637.335a.156.156 0 01-.225-.166l.122-.71-.516-.502a.156.156 0 01.087-.266l.713-.103.319-.647c.056-.116.222-.116.281 0l.319.647.712.103a.155.155 0 01.081.266zM8 19.5c0 .275.225.5.5.5h2c.275 0 .5-.225.5-.5V17h5v2.5c0 .275.225.5.5.5h2c.275 0 .5-.225.5-.5V15H8v4.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDemocratSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
