import * as React from "react";
function SvgThLargeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.563 3.25h7.5c.517 0 .937.42.937.938v6.25c0 .517-.42.937-.938.937h-7.5a.937.937 0 01-.937-.938v-6.25c0-.517.42-.937.938-.937zm-3.126 0h-7.5A.937.937 0 002 4.188v6.25c0 .517.42.937.938.937h7.5c.517 0 .937-.42.937-.938v-6.25a.937.937 0 00-.938-.937zM2 13.563v6.25c0 .517.42.937.938.937h7.5c.517 0 .937-.42.937-.938v-6.25a.937.937 0 00-.938-.937h-7.5a.937.937 0 00-.937.938zm11.563 7.187h7.5c.517 0 .937-.42.937-.938v-6.25a.937.937 0 00-.938-.937h-7.5a.937.937 0 00-.937.938v6.25c0 .517.42.937.938.937z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThLargeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
