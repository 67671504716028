import * as React from "react";
function SvgBookmarkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 22V3.875C4.5 2.839 5.34 2 6.375 2h11.25c1.035 0 1.875.84 1.875 1.875V22L12 17.625 4.5 22z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBookmarkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
