import * as React from "react";
function SvgMittenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 18.25H5.126a.627.627 0 00-.625.625v2.5c0 .344.281.625.625.625h12.5a.627.627 0 00.624-.625v-2.5a.627.627 0 00-.625-.625zm2.227-8.168a2.497 2.497 0 00-3.52.32l-.816.977-1.156-5.015c-.703-3.027-3.726-4.918-6.75-4.219a5.627 5.627 0 00-4.214 6.746l1.87 8.11h12.07l2.832-3.4a2.497 2.497 0 00-.316-3.519z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMittenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
