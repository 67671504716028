import * as React from "react";
function SvgCogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.328 14.403l-1.717-.992a7.766 7.766 0 000-2.83l1.717-.992a.487.487 0 00.222-.565 10.043 10.043 0 00-2.206-3.814.485.485 0 00-.596-.093l-1.718.992a7.61 7.61 0 00-2.452-1.415v-1.98a.483.483 0 00-.379-.472 10.137 10.137 0 00-4.403 0 .483.483 0 00-.379.472v1.984a7.85 7.85 0 00-2.451 1.415L5.252 5.12a.48.48 0 00-.597.093A9.982 9.982 0 002.45 9.028a.482.482 0 00.222.565l1.718.992a7.767 7.767 0 000 2.83l-1.718.992a.487.487 0 00-.222.565 10.044 10.044 0 002.206 3.814.485.485 0 00.597.093l1.718-.992a7.61 7.61 0 002.451 1.415v1.984c0 .226.157.424.38.472 1.479.33 2.995.315 4.402 0a.483.483 0 00.38-.472v-1.984a7.851 7.851 0 002.451-1.415l1.718.992a.48.48 0 00.597-.093 9.983 9.983 0 002.205-3.814.498.498 0 00-.226-.569zm-9.33.819a3.23 3.23 0 01-3.226-3.226 3.23 3.23 0 013.226-3.226 3.23 3.23 0 013.226 3.226 3.23 3.23 0 01-3.226 3.226z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
