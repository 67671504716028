import * as React from "react";
function SvgReplyAllSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.733 9.703l6.111-5.278c.535-.462 1.378-.087 1.378.631V7.93C18.708 8.285 22 9.743 22 14.303c0 2.133-1.374 4.247-2.893 5.352-.475.345-1.15-.088-.975-.647 1.337-4.277-.137-5.885-3.91-6.32v2.923c0 .718-.843 1.092-1.378.63l-6.111-5.277a.834.834 0 010-1.261zm-4.444 1.261l6.11 5.277c.535.462 1.379.088 1.379-.63v-.55l-3.771-3.256a1.943 1.943 0 010-2.943l3.77-3.257v-.549c0-.718-.842-1.093-1.377-.63L2.288 9.702a.834.834 0 000 1.261z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgReplyAllSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
