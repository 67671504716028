import * as React from "react";
function SvgTeethSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zM7 15.5a1.5 1.5 0 01-3 0v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 113 0v2zm4.5 3.75a1.75 1.75 0 11-3.5 0V13.5a.5.5 0 01.5-.5H11a.5.5 0 01.5.5v1.75zm0-3.75a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5V8.75a1.75 1.75 0 113.5 0v2.75zm4.5 3.75a1.75 1.75 0 11-3.5 0V13.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v1.75zm0-3.75a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5V8.75a1.75 1.75 0 113.5 0v2.75zm4 4a1.5 1.5 0 01-3 0v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0v2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTeethSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
