import * as React from "react";
function SvgGreaterThanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.037 9.596L5.084 2.157a1.671 1.671 0 00-2.22.808L2.157 4.48c-.39.836-.028 1.83.808 2.22l11.376 5.305-11.378 5.307a1.667 1.667 0 00-.806 2.215l.704 1.51a1.667 1.667 0 002.215.806L21.038 14.4A1.667 1.667 0 0022 12.89v-1.783c0-.648-.375-1.237-.963-1.51z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGreaterThanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
