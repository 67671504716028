import * as React from "react";
function SvgFileDownloadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.986 8.256l-3.766 3.738a.666.666 0 01-.94 0l-3.766-3.738a.625.625 0 01.44-1.069h2.546v-3.125c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625V14.5h2.546c.558 0 .836.675.44 1.069zm2.99-9.467l-3.824-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileDownloadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
