import * as React from "react";
function SvgPeaceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm7.42 10c0 1.287-.331 2.499-.91 3.555l-5.22-4.175V4.7c3.477.614 6.13 3.65 6.13 7.3zm-8.71 7.3a7.386 7.386 0 01-3.6-1.735l3.6-2.88V19.3zm2.58-4.616l3.6 2.88a7.386 7.386 0 01-3.6 1.735v-4.615zM10.71 4.7v6.68l-5.22 4.175A7.37 7.37 0 014.58 12c0-3.65 2.653-6.686 6.13-7.3z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPeaceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
