import * as React from "react";
function SvgLessThanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.034 17.3L9.658 11.994l11.379-5.307a1.667 1.667 0 00.806-2.215l-.704-1.51a1.667 1.667 0 00-2.215-.806L2.962 9.6A1.668 1.668 0 002 11.11v1.784c0 .647.376 1.236.962 1.51l15.954 7.439c.836.39 1.83.028 2.22-.808l.707-1.515c.39-.836.028-1.83-.809-2.22z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLessThanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
