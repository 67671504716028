import styled from "styled-components";

export const UiQuestionSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.checkedCount {
		align-items: center;
		padding: 0 4px;
		color: #fff;
		line-height: 32px;
	}

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}
		}

		.actionBox {
			display: flex;

			> * {
				margin-left: 8px;
			}
		}
	}

	.questionSearchContent {
		display: flex;
		overflow-y: auto;
		height: 100%;

		.leftSideBar {
			margin-right: 8px;
			height: 100%;
			background-color: #fff;
			flex: 0.2;

			.leftSideBarHeader {
				padding: 18px 24px 12px;
			}

			.leftSideBarContent {
				overflow: auto;
				height: calc(100% - 110px);

				& > div {
					display: flex;
					flex-direction: column;

					& > div {
						flex: 1 0 50px;
					}
				}
			}

			.leftSideBarFooter {
				display: flex;
				justify-content: flex-end;
				padding: 12px 16px 12px;

				& > button {
					flex: 0.5;
				}
			}
		}
	}

	.questionSearchMain {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;

		.filterBar {
			flex: 0 0 36px;
			margin-bottom: 8px;
			position: relative;
			z-index: 10;

			.filterSelect {
				display: flex;
				min-width: unset;
				max-width: 100px;

				.ant-select-selector {
					background-color: #626262;
					border: 1px solid #98989e;
				}

				.ant-select-clear {
					background-color: #626262;
				}
			}
		}

		.questionSearchTableContent {
			height: calc(100% - 44px);

			.questionSearchTable {
				height: calc(100% - 38px);
				margin-bottom: 8px;

				.tableNoData {
					margin: 24px auto;
					width: 50%;
					font-size: 24px;
					text-align: center;
					color: #fff;
				}

				& > div {
					.tableItem {
						flex: 1 0 200px;
					}

					.checkTableItem {
						min-width: 60px;
						text-align: left;
						flex: 1 0 25px;

						.tableCheckHeader {
							width: 100%;
						}

						.tableCheck {
							display: flex;
							align-items: center;
							justify-content: space-between;
							flex: 1;

							> div {
								:hover {
									color: #ffb800;
								}
							}

							.ant-checkbox-wrapper {
								align-items: center;

								.ant-checkbox {
									top: 0;
								}

								& + div {
									position: relative;
									right: 0.3em;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const UiQuesDetail = styled.div`
	.ant-modal-body {
		padding: 6px 40px 40px;
	}
`;

export const UiPopupContainer = styled.div`
	.ant-tabs-tab-btn {
		display: flex;
		align-items: center;

		i {
			margin-right: 5px;
		}
	}

	.ant-image-preview-img-wrapper::before {
		height: 25%;
	}

	.ant-image-preview-img {
		margin: auto;
		width: 100%;
		max-width: 660px;
	}

	.container {
		position: relative;
		display: flex;
		justify-content: space-between;
		overflow-x: hidden;
		margin-top: 20px;
		max-height: 600px;

		&::after {
			position: absolute;
			top: 0;
			right: calc(50% - 2px);
			width: 3px;
			height: 100%;
			background-color: #f0f0f0;
			content: "";
		}

		> .content {
			position: relative;
			display: flex;
			justify-content: space-between;
			width: 45%;
			flex-direction: column;

			> .meta {
				padding: 2px 0;
				color: rgba(0, 0, 0, 0.85);

				> .metaContent {
					padding: 0 5px;
					color: rgba(0, 0, 0, 0.65);
				}
			}

			> .title {
				position: sticky;
				top: 0;
				padding: 10px;
				font-size: 16px;
				background-color: #dfeeff;
				margin-bottom: 10px;
			}

			.img {
				display: block;
				font-size: 0;
				flex: 1;
			}

			.info {
				margin-top: 10px;
				padding: 5px 0;
				background-color: #fafafa;

				li {
					font-size: 14px;
				}
			}
		}

		.bookTag {
			display: flex;
			align-items: center;

			& > div {
				display: flex;
				margin-bottom: 8px;

				& > div {
					margin-right: 8px;
				}
			}
		}
	}
`;
