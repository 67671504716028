import * as React from "react";
function SvgClinicMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 7.104l-7.588 6.692c-.056.05-.128.074-.19.116v6.421a.556.556 0 00.556.556h14.444a.555.555 0 00.556-.556v-6.42c-.06-.04-.13-.063-.183-.11L12 7.103zm3.333 9.063a.278.278 0 01-.277.277H13.11v1.945a.278.278 0 01-.277.278h-1.667a.278.278 0 01-.278-.278v-1.944H8.945a.278.278 0 01-.278-.278V14.5a.278.278 0 01.278-.278h1.944v-1.944a.278.278 0 01.278-.278h1.667a.278.278 0 01.277.278v1.944h1.945a.278.278 0 01.277.278v1.667zm6.483-4.852l-8.887-7.847a1.384 1.384 0 00-1.856 0l-8.889 7.847a.557.557 0 00-.042.785l.744.827a.556.556 0 00.784.042l7.963-7.023a.556.556 0 01.735 0l7.962 7.023a.556.556 0 00.785-.042l.743-.827a.556.556 0 00-.042-.785z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgClinicMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
