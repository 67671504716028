import * as React from "react";
function SvgFlushedOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm3.871-12.58a3.225 3.225 0 100 6.451 3.225 3.225 0 100-6.451zm0 5.161a1.936 1.936 0 11.001-3.872 1.936 1.936 0 01-.001 3.872zm0-2.903a.965.965 0 00-.968.968c0 .536.432.967.968.967a.965.965 0 00.968-.967.965.965 0 00-.968-.968zm-4.516.968a3.225 3.225 0 10-6.452 0 3.225 3.225 0 106.452 0zm-3.226 1.935a1.936 1.936 0 11.001-3.872 1.936 1.936 0 01-.001 3.872zm0-2.903a.965.965 0 00-.968.968c0 .536.432.967.968.967a.965.965 0 00.968-.967.965.965 0 00-.968-.968zm6.452 5.806H9.419a.97.97 0 00-.967.968.97.97 0 00.967.968h5.162a.97.97 0 00.967-.968.97.97 0 00-.967-.968z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFlushedOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
