import * as React from "react";
function SvgRaisehandSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18.469v2.139c0 .571-.463 1.034-1.035 1.034h-1.479a.62.62 0 01-.62-.62v-2.553a.517.517 0 00-1.035 0v2.553a.62.62 0 01-.62.62H7.392a1.035 1.035 0 01-1.035-1.034V17c0-.305-.136-.592-.36-.798A12.253 12.253 0 012 7.13c0-1.192.17-2.345.486-3.435A.982.982 0 013.44 3h1.78c.793 0 1.307.87 1.07 1.628-.246.79-.38 1.63-.38 2.501 0 4.606 3.72 8.34 8.31 8.34h4.646c2.1.258 3.134 1.164 3.134 3zm-7.821-5.657c2.7 0 4.888-2.197 4.888-4.906 0-2.71-2.189-4.906-4.888-4.906-2.7 0-4.888 2.196-4.888 4.906 0 2.71 2.188 4.906 4.888 4.906z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRaisehandSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
