import * as React from "react";
function SvgSwatchbookSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.979 8.551l-3.522-3.53a1.243 1.243 0 00-1.758-.002l-.002.003-2.947 2.95v10.59l8.229-8.246a1.25 1.25 0 000-1.765zM20.75 14.5h-4.176l-7.282 7.285c-.08.081-.176.14-.26.215H20.75A1.25 1.25 0 0022 20.75v-5a1.25 1.25 0 00-1.25-1.25zM9.5 3.25A1.25 1.25 0 008.25 2h-5A1.25 1.25 0 002 3.25v15a3.75 3.75 0 007.5 0v-15zM5.75 19.188a.938.938 0 110-1.876.938.938 0 010 1.875zM7 12H4.5V9.5H7V12zm0-5H4.5V4.5H7V7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSwatchbookSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
