import * as React from "react";
function SvgQuranSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 16V3c0-.625-.375-1-1-1H7C4.875 2 3.25 3.625 3.25 5.75v12.5C3.25 20.375 4.875 22 7 22h12.75c.5 0 1-.375 1-1v-.625c0-.25-.125-.5-.375-.75-.125-.625-.125-2.375 0-2.875.25-.125.375-.375.375-.75zm-5.74-8.304a.126.126 0 01.115-.071c.045 0 .09.024.114.071l.437.885.976.142a.127.127 0 01.071.217l-.707.689.167.972a.128.128 0 01-.185.134l-.873-.459-.873.46a.128.128 0 01-.185-.134l.167-.973-.707-.69a.127.127 0 01.07-.216l.977-.142.437-.885zM12.75 5a4.48 4.48 0 011.577.29.264.264 0 11-.093.512c-.026 0-.12-.008-.161-.008A3.71 3.71 0 0010.367 9.5a3.71 3.71 0 003.706 3.705c.04 0 .136-.008.161-.008a.265.265 0 01.085.516c-.51.19-1.039.286-1.57.286C10.27 14 8.25 11.981 8.25 9.5S10.268 5 12.75 5zm5.375 14.5H7c-.75 0-1.25-.5-1.25-1.25S6.375 17 7 17h11.125v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgQuranSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
