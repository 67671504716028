import * as React from "react";
function SvgPhoneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.273 2.961l-4.062-.937a.944.944 0 00-1.074.543l-1.875 4.375a.935.935 0 00.27 1.093l2.366 1.938a14.477 14.477 0 01-6.921 6.922l-1.938-2.367a.936.936 0 00-1.093-.27L2.57 16.133a.949.949 0 00-.547 1.078l.937 4.063a.937.937 0 00.914.726C13.88 22 22 13.895 22 3.875c0-.437-.3-.816-.727-.914z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPhoneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
