import * as React from "react";
function SvgCannabisSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.667 16.072c-.061-.032-1.265-.66-3-1.008 2.51-2.934 3.284-6.315 3.318-6.47a.626.626 0 00-.744-.746c-.152.033-3.38.765-6.273 3.116v-.172c0-4.64-2.343-8.349-2.442-8.504a.623.623 0 00-1.052 0c-.1.155-2.443 3.864-2.443 8.504v.172C6.14 8.614 2.912 7.882 2.76 7.848a.626.626 0 00-.734.436.625.625 0 00-.01.31c.034.155.808 3.536 3.317 6.47-1.733.348-2.937.976-2.999 1.008a.625.625 0 000 1.105c.095.05 2.373 1.24 5.214 1.24.24 0 .467-.005.684-.013a10.95 10.95 0 00-.657 1.532.625.625 0 00.782.785c.071-.022 1.473-.468 3.02-1.534v2.5c0 .173.139.313.312.313h.624c.173 0 .313-.14.313-.313v-2.5c1.546 1.066 2.948 1.512 3.02 1.535a.625.625 0 00.781-.785 11.007 11.007 0 00-.656-1.532c.216.008.444.012.683.012 2.84 0 5.119-1.189 5.215-1.24a.625.625 0 00-.001-1.105z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCannabisSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
