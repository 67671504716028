import * as React from "react";
function SvgLineSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.25c0 .69-.56 1.25-1.25 1.25H3.25a1.25 1.25 0 110-2.5h17.5c.69 0 1.25.56 1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLineSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
