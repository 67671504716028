import * as React from "react";
function SvgMaskSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.02 6C-1.81 6 .848 18 7.07 18c1.247 0 2.421-.646 3.17-1.746l.803-1.18c.49-.719 1.468-.719 1.958 0l.804 1.18c.748 1.1 1.922 1.746 3.169 1.746 5.933 0 9.082-12-4.952-12zm-4.27 7.636c-1.283 0-2.118-.802-2.503-1.283a.571.571 0 010-.707c.385-.481 1.22-1.283 2.503-1.283 1.283 0 2.117.802 2.502 1.283a.571.571 0 010 .707c-.385.482-1.219 1.283-2.502 1.283zm8.5 0c-1.283 0-2.117-.802-2.502-1.283a.571.571 0 010-.707c.385-.481 1.219-1.283 2.502-1.283 1.283 0 2.117.802 2.503 1.283a.571.571 0 010 .707c-.386.482-1.22 1.283-2.503 1.283z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMaskSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
