import * as React from "react";
function SvgVolumeWeakSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.681 17.272c0 .54-.413.948-.968.948-.376 0-.68-.146-1.049-.489l-2.896-2.697c-.037-.036-.089-.05-.155-.05H3.648c-1.064 0-1.648-.591-1.648-1.7v-2.558c0-1.108.584-1.691 1.648-1.691H5.62a.24.24 0 00.155-.058L8.665 6.3c.398-.372.672-.517 1.041-.517.562 0 .975.437.975.977v10.511zM14.146 14.94a.724.724 0 01-.982.189c-.37-.24-.436-.67-.148-1.123.377-.546.59-1.26.59-2.011 0-.751-.206-1.473-.59-2.012-.296-.445-.222-.883.148-1.116a.714.714 0 01.982.175c.577.78.916 1.837.916 2.953 0 1.115-.34 2.18-.916 2.945z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVolumeWeakSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
