import * as React from "react";
function SvgAngrySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-4.516 9.355c0-.375.165-.706.423-.944l-1.25-.375a.64.64 0 01-.431-.802c.1-.343.46-.532.802-.432l3.226.968a.646.646 0 01-.185 1.262c-.02 0-.045-.008-.069-.008.028.11.069.214.069.33a1.292 1.292 0 01-2.585 0zm6.774 6.218c-1.12-1.347-3.395-1.347-4.52 0-.544.657-1.54-.17-.992-.827a4.222 4.222 0 013.25-1.524c1.258 0 2.444.556 3.25 1.524.556.665-.448 1.476-.988.827zm3.089-7.537l-1.25.375c.254.234.423.569.423.944a1.29 1.29 0 01-1.29 1.29c-.714 0-1.29-.576-1.29-1.29 0-.117.036-.226.068-.33-.024.003-.044.007-.068.007a.646.646 0 01-.186-1.262l3.226-.968c.338-.1.701.093.802.432a.645.645 0 01-.435.802z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngrySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
