import * as React from "react";
function SvgHouseDamageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 7.103l-7.588 6.693c-.056.05-.128.074-.19.116v6.421c0 .307.249.556.556.556h5.181L8.667 18.36l3.614-2.223L10.194 12l5.14 4.75-3.615 2.222 1.386 1.918h6.118a.555.555 0 00.556-.556v-6.42c-.06-.04-.13-.063-.183-.11L12 7.102zm9.816 4.212l-2.038-1.8V4.779a.555.555 0 00-.556-.556H17a.555.555 0 00-.556.556v1.795L12.93 3.469a1.388 1.388 0 00-1.856-.001l-8.889 7.847a.556.556 0 00-.042.785l.743.827a.556.556 0 00.785.042l7.963-7.023a.556.556 0 01.735 0l7.962 7.023c.228.205.58.186.785-.042l.743-.827a.555.555 0 00-.043-.785z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHouseDamageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
