import * as React from "react";
function SvgFileUploadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.546 8.437H13.25v3.125c0 .346-.28.625-.625.625h-1.25a.625.625 0 01-.625-.625V15.75H8.204a.625.625 0 01-.44-1.068l3.766-3.739a.666.666 0 01.94 0l3.765 3.739a.625.625 0 01-.439 1.068zm3.43-9.648l-3.824-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileUploadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
