import * as React from "react";
function SvgRetweetSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.778 14.766l-3.18 3.18a.758.758 0 01-1.071 0l-3.18-3.18a.758.758 0 010-1.072l.342-.341a.758.758 0 011.087.015l1.276 1.351V8.97H11.13a.758.758 0 01-.536-.222l-.505-.506a.758.758 0 01.536-1.293h7.69c.419 0 .758.339.758.758v7.013l1.276-1.35a.758.758 0 011.087-.016l.342.341a.758.758 0 010 1.072zm-8.372.487a.758.758 0 00-.536-.222H6.948v-5.75l1.276 1.35a.758.758 0 001.087.016l.341-.341a.758.758 0 000-1.072l-3.179-3.18a.758.758 0 00-1.072 0l-3.179 3.18a.758.758 0 000 1.072l.342.342a.758.758 0 001.087-.016L4.927 9.28v7.013c0 .42.34.758.758.758h7.69a.758.758 0 00.536-1.293l-.505-.506z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRetweetSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
