import * as React from "react";
function SvgMoneyBillWaveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.41 5.702C20.2 5.194 18.987 5 17.774 5c-3.849 0-7.697 1.948-11.546 1.948-.966 0-1.93-.122-2.896-.429a1.069 1.069 0 00-.323-.05A.994.994 0 002 7.463v9.914c0 .395.226.769.589.92 1.212.51 2.425.703 3.637.703 3.85 0 7.699-1.948 11.547-1.948.966 0 1.93.122 2.896.428.108.035.217.05.323.05A.994.994 0 0022 16.538V6.623c0-.395-.226-.769-.59-.921zM3.5 8.132c.629.157 1.285.237 1.96.279a2.002 2.002 0 01-1.96 1.61v-1.89zm0 8.906v-1.493a2 2 0 011.99 1.919 7.552 7.552 0 01-1.99-.426zM12 15c-1.381 0-2.5-1.344-2.5-3 0-1.657 1.12-3 2.5-3s2.5 1.343 2.5 3-1.12 3-2.5 3zm8.5.868a10.744 10.744 0 00-1.698-.264 1.996 1.996 0 011.698-1.54v1.804zm0-7.378a1.996 1.996 0 01-1.744-1.924 7.51 7.51 0 011.744.396V8.49z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoneyBillWaveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
