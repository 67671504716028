import * as React from "react";
function SvgUserAltSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.805 18.316l-7.631-5.9c1.381-.77 2.325-2.225 2.325-3.916 0-2.484-2.016-4.5-4.5-4.5-2.097 0-3.844 1.44-4.344 3.381L3.421 4.106a.502.502 0 00-.703.088l-.613.787a.498.498 0 00.088.7l18.387 14.21a.502.502 0 00.703-.088l.613-.79a.495.495 0 00-.09-.697zM8.2 14a4.201 4.201 0 00-4.2 4.2v.3A1.5 1.5 0 005.5 20h11.944L9.68 14H8.2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserAltSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
