import * as React from "react";
function SvgFrownOpenOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm-1.935-10a1.289 1.289 0 10-2.58 0c-.001.714.575 1.29 1.29 1.29.713 0 1.29-.576 1.29-1.29zm5.16-1.29a1.289 1.289 0 100 2.58 1.289 1.289 0 100-2.58zM12 13.29c-1.435 0-3.58.86-3.863 2.468a.648.648 0 00.827.73c1.258-.387 2.395-.617 3.036-.617s1.778.23 3.036.617a.648.648 0 00.827-.73C15.58 14.15 13.435 13.29 12 13.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFrownOpenOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
