import * as React from "react";
function SvgMoneyCheckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 18a1 1 0 001 1h18a1 1 0 001-1V8H2v10zm14-6.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1zm0 3.75a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-.5zm-12-3a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25v-.5zm0 3a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-.5zM21.5 5h-19a.5.5 0 00-.5.5V7h20V5.5a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoneyCheckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
