import * as React from "react";
function SvgMicrophoneAltSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.807 18.316l-4.924-3.806c.39-.753.617-1.604.617-2.51v-1.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5V12c0 .56-.124 1.088-.335 1.569l-.83-.642c.097-.293.165-.6.165-.927V7a3 3 0 00-6 0v1.418L3.42 4.105a.5.5 0 00-.701.088l-.614.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087l.614-.79a.5.5 0 00-.088-.701zM12.75 18.5h1.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h1.75v-1.067c-2.751-.38-4.75-2.877-4.75-5.678v-.214l1.626 1.257c.389 1.663 1.729 3.012 3.474 3.183.205.02.402.007.6-.005.012 0 .024 0 .036-.002l1.575 1.218c-.34.119-.695.202-1.061.252V18.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMicrophoneAltSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
