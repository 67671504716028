import * as React from "react";
function SvgSortNumericUpAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.147 2.21a.714.714 0 00-1.01 0l-3.57 4.285a.715.715 0 00.504 1.22h2.143v13.57a.714.714 0 00.714.715h1.429a.714.714 0 00.714-.714V7.714h2.143c.634 0 .954-.77.505-1.219L7.147 2.209zm13.067 16.933H19.5v-5a.715.715 0 00-.715-.715h-2.143a.713.713 0 00-.637.395l-.715 1.428a.714.714 0 00.638 1.035h.714v2.857h-.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h4.286a.715.715 0 00.714-.714v-1.429a.715.715 0 00-.714-.714zM17.097 2.13a3.527 3.527 0 00-2.456 2.418 3.585 3.585 0 003.073 4.577c-.28.237-.594.431-.93.577-.339.151-.483.556-.366.908l.442.893c.128.385.56.602.933.442 2.59-1.106 3.85-2.75 3.85-5.893v-.48a3.582 3.582 0 00-4.546-3.442zm.974 4.334a.893.893 0 110-1.785.893.893 0 010 1.785z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortNumericUpAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
