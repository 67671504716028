import * as React from "react";
function SvgThermometerQuarterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 17a2.5 2.5 0 11-3.75-2.165V13.25a1.25 1.25 0 112.5 0v1.585A2.498 2.498 0 0114.5 17zm1.25-3.307A5 5 0 0112 22h-.036C9.218 21.98 6.986 19.72 7 16.974a4.98 4.98 0 011.25-3.28V5.75a3.75 3.75 0 017.5 0v7.943zM15.125 17c0-1.341-.757-2.039-1.25-2.598V5.75A1.877 1.877 0 0012 3.875a1.877 1.877 0 00-1.875 1.875v8.652c-.497.564-1.243 1.254-1.25 2.582a3.138 3.138 0 003.102 3.14l.023.001A3.129 3.129 0 0015.125 17z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThermometerQuarterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
