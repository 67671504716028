import * as React from "react";
function SvgHandPointerOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.241 9.006a2.57 2.57 0 00-3.12-.746 2.567 2.567 0 00-2.21-.766V4.617C11.91 3.174 10.749 2 9.32 2 7.894 2 6.732 3.174 6.732 4.617v6.294a2.557 2.557 0 00-2.425.347 2.634 2.634 0 00-.568 3.639l4.285 6.015A2.587 2.587 0 0010.125 22h6.964c1.204 0 2.25-.84 2.522-2.023l1.072-4.649a2.65 2.65 0 00.067-.594v-3.28c0-1.83-1.821-3.097-3.509-2.448zm-10.828 3.92l1.06 1.487c.351.493 1.134.246 1.134-.363V4.617c0-.981 1.429-.983 1.429 0v6.836c0 .345.28.625.625.625h.268c.345 0 .624-.28.624-.625v-1.367c0-.981 1.43-.983 1.43 0v1.367c0 .345.279.625.624.625h.268c.345 0 .625-.28.625-.625v-.82c0-.981 1.429-.983 1.429 0v.82c0 .345.28.625.625.625h.267c.346 0 .625-.28.625-.625 0-.981 1.429-.983 1.429 0v3.281a.77.77 0 01-.02.173l-1.071 4.649a.718.718 0 01-.695.569h-6.964a.709.709 0 01-.574-.3l-4.286-6.016c-.562-.79.586-1.673 1.148-.883zm3.718 4.699v-3.75c0-.345.244-.625.546-.625h.235c.302 0 .547.28.547.625v3.75c0 .345-.245.625-.547.625h-.235c-.302 0-.546-.28-.546-.625zm2.946 0v-3.75c0-.345.245-.625.547-.625h.234c.302 0 .547.28.547.625v3.75c0 .345-.245.625-.547.625h-.234c-.302 0-.547-.28-.547-.625zm2.946 0v-3.75c0-.345.245-.625.547-.625h.235c.302 0 .547.28.547.625v3.75c0 .345-.245.625-.547.625h-.235c-.302 0-.547-.28-.547-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandPointerOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
