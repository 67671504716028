import * as React from "react";
function SvgEyeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.88 11.493c-1.884-3.674-5.612-6.16-9.88-6.16-4.268 0-7.997 2.488-9.88 6.16a1.123 1.123 0 000 1.014c1.884 3.674 5.612 6.16 9.88 6.16 4.268 0 7.997-2.488 9.88-6.16a1.124 1.124 0 000-1.014zM12 17a5 5 0 110-10 5 5 0 010 10zm0-8.333a3.31 3.31 0 00-.879.131 1.661 1.661 0 01-2.323 2.323A3.326 3.326 0 1012 8.667z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEyeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
