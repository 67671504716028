import * as React from "react";
function SvgLessThanEqualSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.957 10.258l12.56 4.995c.766.251 1.612-.106 1.89-.798l.504-1.254c.278-.692-.117-1.457-.884-1.708l-7.31-2.835 7.315-2.837c.762-.25 1.156-1.012.88-1.7l-.502-1.247c-.276-.689-1.119-1.044-1.881-.794L4.957 7.078C4.384 7.267 4 7.771 4 8.336v.665c0 .565.384 1.069.957 1.257zM20.334 18H3.666a1 1 0 00-1 1v2a1 1 0 001 1h16.668a1 1 0 001-1v-2a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLessThanEqualSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
