import * as React from "react";
function SvgFileAltOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.75 11.688v1.093a.47.47 0 01-.469.469H8.72a.47.47 0 01-.469-.469v-1.094a.47.47 0 01.469-.468h6.562a.47.47 0 01.469.469zm-.469 2.812H8.72a.47.47 0 00-.469.469v1.094c0 .257.21.468.469.468h6.562a.47.47 0 00.469-.468v-1.094a.47.47 0 00-.469-.469zM19.5 7.152v12.973c0 1.035-.84 1.875-1.875 1.875H6.375A1.875 1.875 0 014.5 20.125V3.875C4.5 2.84 5.34 2 6.375 2h7.973c.496 0 .972.2 1.324.55l3.277 3.278c.352.348.551.828.551 1.324zm-5-3.125V7h2.973L14.5 4.027zm3.125 16.098V8.875h-4.063a.935.935 0 01-.937-.938V3.875h-6.25v16.25h11.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileAltOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
