import * as React from "react";
function SvgVolumeOffSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.53 2.366l-4.634 4.635H6.584a1.25 1.25 0 00-1.25 1.25v7.498A1.25 1.25 0 006.585 17H11.9l4.631 4.635c.781.78 2.135.232 2.135-.886V3.252c0-1.118-1.354-1.667-2.135-.886z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVolumeOffSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
