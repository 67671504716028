import * as React from "react";
function SvgXmarkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.353 19.91c-.464.464-.477 1.273 0 1.737.477.464 1.273.464 1.737 0l7.917-7.916 7.903 7.916c.477.464 1.273.477 1.737 0 .464-.477.464-1.273 0-1.737l-7.916-7.917 7.916-7.903c.464-.477.477-1.273 0-1.737a1.258 1.258 0 00-1.737 0l-7.903 7.917L4.09 2.353c-.464-.464-1.273-.477-1.737 0-.464.477-.464 1.273 0 1.724l7.917 7.916-7.917 7.917z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgXmarkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
