export const ROLES = {
	Editor: "編輯",
};

export const COMMON_TEXT = {
	CREATE: "create",
	EDIT: "edit",
	KNOWLEDGE: "KNOWLEDGE",
	SUB_TOPIC: "SUB_TOPIC",
	TOPIC: "TOPIC",
	NO_HIERARCHY: "NO_HIERARCHY",
	OTHER: "OTHER",
};

export const POPUP_TITLE = {
	create: "新增",
	edit: "編輯",
};

export const TOPIC = {
	"{0}-{1}": "舊綱",
	"{0}{1}": "新綱",
};

export const LANGUAGE_GROUP = ["PC", "EN", "EL", "CH"];

export const EDUCATIONAL = [
	{
		name: "國小",
		value: "E",
	},
	{
		name: "國中",
		value: "J",
	},
	{
		name: "高中",
		value: "H",
	},
];

export const AUTHORITY = {
	View: 0,
	Edit: 0,
	Lock: 0,
	Import: 0,
};

export const EDU_NAME = {
	E: "國小",
	J: "國中",
	H: "高中",
};

export const SUBJECT_NAME = {
	BI: "生物",
	CE: "化學",
	CH: "國語",
	CN: "華語",
	CO: "綜合活動",
	CS: "公民與社會",
	CT: "公民",
	EA: "地球科學",
	EN: "英語",
	EW: "英文",
	GE: "地理",
	HI: "歷史",
	IT: "資訊科技",
	LI: "生活",
	LT: "生活科技",
	MA: "數學",
	NA: "自然與生活科技",
	NT: "自然",
	PC: "國文",
	PE: "健康與體育",
	PH: "物理",
	PY: "理化",
	SO: "社會",
	TC: "科技",
};

export const API_BATCH_QUEUE = Object.freeze({
	PRODUCT_TYPE: {
		standard: 1,
		equationDojo: 2,
		livePreTest: 3,
		mockExam: 4,
		validationQuestions: 5,
		vocabularyTest: 6,
		eduService: 7,
		englishListening: 9,
	},
	QUEUE_TYPE: {
		create: 0,
		update: 1,
	},
});

export const EDU_SUBJECT = [
	{ code: "ECH", name: "國小國語" },
	{ code: "ECN", name: "國小華語" },
	{ code: "EEN", name: "國小英語" },
	{ code: "EMA", name: "國小數學" },
	{ code: "ENA", name: "國小自然與生活科技" },
	{ code: "ESO", name: "國小社會" },
	{ code: "ELI", name: "國小生活" },
	{ code: "EPE", name: "國小健康與體育" },
	{ code: "JPC", name: "國中國文" },
	{ code: "JCN", name: "國中華語" },
	{ code: "JEN", name: "國中英語" },
	{ code: "JMA", name: "國中數學" },
	{ code: "JNA", name: "國中自然與生活科技" },
	{ code: "JBI", name: "國中生物" },
	{ code: "JPY", name: "國中理化" },
	{ code: "JEA", name: "國中地球科學" },
	{ code: "JSO", name: "國中社會" },
	{ code: "JGE", name: "國中地理" },
	{ code: "JHI", name: "國中歷史" },
	{ code: "JCT", name: "國中公民" },
	{ code: "JPE", name: "國中健康與體育" },
	{ code: "JCO", name: "國中綜合活動" },
	{ code: "JTC", name: "國中科技" },
	{ code: "JIT", name: "國中資訊科技" },
	{ code: "JLT", name: "國中生活科技" },
	{ code: "HPC", name: "高中國文" },
	{ code: "HCN", name: "高中華語" },
	{ code: "HEW", name: "高中英文" },
	{ code: "HMA", name: "高中數學" },
	{ code: "HBI", name: "高中生物" },
	{ code: "HPH", name: "高中物理" },
	{ code: "HCE", name: "高中化學" },
	{ code: "HEA", name: "高中地球科學" },
	{ code: "HGE", name: "高中地理" },
	{ code: "HHI", name: "高中歷史" },
	{ code: "HCS", name: "高中公民與社會" },
];

const defaultParams = {
	paper: false,
	quick: false,
	onlineTest: false,
	collectedEdition: false,
	mockExam: false,
	selfTaughtProduct: false,
	freeProduct: false,
	equationDojo: false,
	validation: false,
	optionQuick: false,
	vocabularyTest: false,
	englishListening: false,
};

// 一般試題
export const questionReviseParams = {
	...defaultParams,
	paper: true,
	quick: true,
	onlineTest: true,
	collectedEdition: true,
};

// 無限命題
export const equationDojoParams = {
	...defaultParams,
	equationDojo: true,
};

// Live試聽前測卷
export const livePreTestParams = {
	...defaultParams,
	collectedEdition: true,
};

// 模擬考
export const mockExamParams = {
	...defaultParams,
	mockExam: true,
};

// 檢定試題
export const validationQuestionsParams = {
	...defaultParams,
	validation: true,
};

// 英文單字刷題
export const vocabularytestParams = {
	...defaultParams,
	vocabularyTest: true,
};

// 教務試題
export const eduserviceParams = {
	...defaultParams,
	collectedEdition: true,
};

// 英聽入庫
export const englishListeningParams = {
	...defaultParams,
	englishListening: true,
};

export const defaultParamsList = {
	1: questionReviseParams,
	2: equationDojoParams,
	3: livePreTestParams,
	4: mockExamParams,
	5: validationQuestionsParams,
	6: vocabularytestParams,
	7: eduserviceParams,
	9: englishListeningParams,
};
