import * as React from "react";
function SvgGrinTongueSquintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.79 16.55c-.571-.258-1.234.088-1.373.699l-.072.31c-.083.365-.603.365-.686 0l-.072-.31c-.139-.611-.801-.957-1.373-.699-.036.016.012-.008-.75.373v2.5c0 1.398 1.111 2.56 2.504 2.577a2.543 2.543 0 002.576-2.54v-2.54c-.774-.382-.722-.354-.754-.37zM12 2a9.841 9.841 0 00-9.843 9.843c0 4.22 2.66 7.807 6.39 9.208a3.798 3.798 0 01-.357-1.587v-1.806c-.98-.643-1.727-1.513-1.897-2.533a.636.636 0 01.821-.71c1.199.385 2.98.603 4.886.603 1.905 0 3.687-.218 4.886-.603a.637.637 0 01.821.71c-.17 1.02-.916 1.89-1.897 2.533v1.806c0 .567-.135 1.103-.357 1.587 3.73-1.4 6.39-4.989 6.39-9.208A9.841 9.841 0 0012 2zm-1.341 8.347l-3.176 1.905c-.456.27-.952-.302-.61-.715l1.333-1.599-1.334-1.6c-.341-.408.15-.988.611-.714L10.66 9.53a.48.48 0 010 .818zm6.469 1.19c.345.413-.155.985-.611.715l-3.175-1.905a.476.476 0 010-.818l3.175-1.905c.464-.274.948.306.611.714l-1.334 1.6 1.334 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinTongueSquintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
