import * as React from "react";
function SvgThumbtackSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.142 10.37l-.478-4.62h1.649c.517 0 .937-.42.937-.938V2.938A.937.937 0 0017.312 2H6.688a.937.937 0 00-.937.938v1.874c0 .518.42.938.938.938h1.648l-.478 4.62c-1.895.88-3.358 2.46-3.358 4.443 0 .517.42.937.938.937h5.312v4.063c0 .048.011.096.033.14l.938 1.875c.114.23.443.23.559 0l.937-1.875a.312.312 0 00.033-.14V15.75h5.313c.517 0 .937-.42.937-.938 0-1.999-1.484-3.57-3.358-4.442z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThumbtackSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
