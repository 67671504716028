import * as React from "react";
function SvgHatCowboySideSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.15 12.611c-.895-.717-1.937-1.096-3.012-1.096-2.42 0-4.467 1.96-5.093 4.877a1.94 1.94 0 00.268 1.479c.273.41.682.645 1.12.645H20.5c-3.225 0-4.844-1.159-7.287-3.265l-3.063-2.64zm7.333-.572l-.846-4.892a2 2 0 00-2.387-1.619L8.277 6.797A2 2 0 006.703 8.54l-.215 2.038c.215-.026.428-.063.65-.063 1.305 0 2.562.455 3.665 1.339l3.062 2.64c2.221 1.913 3.6 3.021 6.635 3.021a1.5 1.5 0 001.5-1.5c0-.793-.925-3.729-4.517-3.976z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHatCowboySideSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
