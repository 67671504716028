import * as React from "react";
function SvgSmokingBanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.75 13.875c0 .344.281.625.625.625h4.59l-3.75-3.75h-.84a.627.627 0 00-.625.625v2.5zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 17.5c-4.137 0-7.5-3.363-7.5-7.5 0-1.617.52-3.113 1.395-4.34L16.34 18.105A7.448 7.448 0 0112 19.5zm1.766-7.5H17v1.25h-1.984L13.766 12zm4.34 4.34l-1.84-1.84h1.359a.627.627 0 00.625-.625v-2.5a.627.627 0 00-.625-.625h-5.11L7.66 5.895A7.448 7.448 0 0112 4.5c4.137 0 7.5 3.363 7.5 7.5 0 1.617-.52 3.113-1.395 4.34zM14.522 7a1.25 1.25 0 01-1.226-1.012.303.303 0 00-.3-.238h-.634a.319.319 0 00-.312.367 2.503 2.503 0 002.472 2.133c.61 0 1.118.438 1.227 1.012.027.14.156.238.3.238h.634c.195 0 .34-.176.312-.367A2.503 2.503 0 0014.523 7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSmokingBanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
