import * as React from "react";
function SvgZoomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.74 19.288l-3.908-3.903c-.196-.156-.43-.273-.665-.273h-.625a8.128 8.128 0 001.72-4.995C18.262 5.668 14.587 2 10.13 2 5.635 2 2 5.668 2 10.117c0 4.488 3.635 8.117 8.13 8.117a8.088 8.088 0 005.004-1.717v.663c0 .235.079.469.274.664l3.87 3.863c.391.39.977.39 1.33 0l1.094-1.092c.39-.352.39-.937.039-1.327zm-11.61-4.176a4.984 4.984 0 01-5.003-4.995 5.009 5.009 0 015.004-4.995c2.736 0 5.003 2.263 5.003 4.995 0 2.77-2.267 4.995-5.003 4.995z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgZoomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
