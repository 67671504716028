import * as React from "react";
function SvgBrailleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6 12.5a2 2 0 11-4 0 2 2 0 014 0zm-2 4a1 1 0 100 2 1 1 0 000-2zm0-11a2 2 0 100 4 2 2 0 000-4zm5 6a1 1 0 100 2 1 1 0 000-2zm0 5a1 1 0 100 2 1 1 0 000-2zm0-11a2 2 0 100 4 2 2 0 000-4zm7 6a1 1 0 100 2 1 1 0 000-2zm0 5a1 1 0 100 2 1 1 0 000-2zm0-11a2 2 0 100 4 2 2 0 000-4zm5 6a1 1 0 100 2 1 1 0 000-2zm0 5a1 1 0 100 2 1 1 0 000-2zm0-10a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBrailleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
