import * as React from "react";
function SvgCalendarPlusOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.375 13.406v.938a.47.47 0 01-.469.469h-2.969v2.968a.47.47 0 01-.468.469h-.938a.47.47 0 01-.469-.469v-2.968H8.095a.47.47 0 01-.469-.47v-.937a.47.47 0 01.469-.469h2.969V9.97a.47.47 0 01.468-.469h.938a.47.47 0 01.469.469v2.969h2.968a.47.47 0 01.469.468zm4.375-7.031v13.75c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V6.375c0-1.035.84-1.875 1.875-1.875H7V2.469A.47.47 0 017.469 2H9.03a.47.47 0 01.469.469V4.5h5V2.469A.47.47 0 0114.969 2h1.562a.47.47 0 01.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875zm-1.875 13.516V8.25H5.125v11.64c0 .13.105.235.234.235h13.282a.235.235 0 00.234-.234z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCalendarPlusOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
