import * as React from "react";
function SvgEditOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.97 15.088l1.11-1.111a.28.28 0 01.476.198v5.049c0 .92-.747 1.666-1.667 1.666H3.667c-.92 0-1.667-.746-1.667-1.666V7c0-.92.747-1.667 1.667-1.667h9.496a.28.28 0 01.198.476l-1.11 1.111a.275.275 0 01-.199.08H3.667v12.223h12.222v-3.941c0-.073.028-.143.08-.195zm5.437-7.007L12.288 17.2l-3.139.348c-.91.1-1.684-.667-1.583-1.584l.347-3.139 9.119-9.118a2.027 2.027 0 012.871 0l1.5 1.5c.795.795.795 2.083.004 2.875zm-3.431 1.073l-2.017-2.017-6.452 6.455-.253 2.267 2.267-.254 6.455-6.451zm2.25-2.767l-1.5-1.5a.363.363 0 00-.514 0L17.14 5.96l2.018 2.017 1.073-1.073a.37.37 0 00-.004-.517z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEditOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
