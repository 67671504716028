import * as React from "react";
function SvgParachuteBoxSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.996 8.836c-.355-2.953-3.063-5.172-6.183-6.2 1.421 1.54 2.437 3.93 2.437 6.864h1.098l-4.555 5.059c-.098-.024-.188-.059-.293-.059h-1.875v-5H17C17 5 14.309 2 12 2S7 5 7 9.5h4.375v5H9.5c-.105 0-.195.035-.293.059L4.652 9.5H5.75c0-2.934 1.016-5.324 2.438-6.863-3.122 1.031-5.829 3.25-6.184 6.199-.043.355.266.664.625.664h.34l5.34 5.934c-.028.101-.063.203-.063.316v5c0 .691.559 1.25 1.25 1.25h5c.691 0 1.25-.559 1.25-1.25v-5c0-.113-.035-.211-.062-.316L21.027 9.5h.34c.363 0 .672-.305.629-.664z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgParachuteBoxSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
