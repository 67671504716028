import * as React from "react";
function SvgPuzzlePieceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.036 13.134c-1.442 0-2.066 1.097-2.85 1.097-2.082 0-.186-6.12-.186-6.12s-6.816 2.778-6.816-.114c0-1.244 1.26-1.606 1.26-2.986 0-1.233-.976-1.9-2.134-1.9-1.203 0-2.304.656-2.304 1.957 0 1.436 1.101 2.058 1.101 2.838 0 2.417-6.107.995-6.107.995v11.571s6.203 1.425 6.203-.995c0-.78-1.39-1.393-1.39-2.829 0-1.3 1.016-1.957 2.208-1.957 1.17 0 2.146.668 2.146 1.9 0 1.38-1.26 1.742-1.26 2.986 0 2.111 4.502.893 6.292.893 0 0-1.205-4.17.897-4.17 1.249 0 1.612 1.255 2.997 1.255 1.237 0 1.907-.972 1.907-2.137 0-1.187-.658-2.284-1.964-2.284z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPuzzlePieceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
