import * as React from "react";
function SvgCompressArrowsAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.813 13.248H5.436c-.835 0-1.253 1.008-.664 1.602l1.286 1.21-3.875 3.88a.627.627 0 000 .882l.992.992c.242.243.64.243.883 0l3.878-3.878 1.215 1.289c.59.59 1.598.171 1.598-.665v-4.374a.935.935 0 00-.938-.938zm4.374-2.5h4.376c.835 0 1.253-1.012.664-1.602l-1.29-1.21 3.88-3.88a.627.627 0 000-.882l-.993-.992a.627.627 0 00-.883 0L16.063 6.06l-1.215-1.29c-.59-.59-1.598-.171-1.598.665V9.81c0 .52.418.937.938.937zm3.75 5.313l1.29-1.215c.59-.59.171-1.598-.665-1.598h-4.375a.935.935 0 00-.937.938v4.375c0 .835 1.012 1.253 1.602.664l1.21-1.286 3.88 3.88c.242.242.64.242.882 0l.992-.993a.627.627 0 000-.883l-3.878-3.883zM9.149 4.775l-1.21 1.286-3.88-3.88a.627.627 0 00-.882 0l-.992.993a.627.627 0 000 .883l3.878 3.879L4.774 9.15c-.59.59-.171 1.598.665 1.598h4.375c.519 0 .937-.418.937-.937V5.436c0-.832-1.012-1.25-1.602-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCompressArrowsAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
