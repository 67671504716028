import * as React from "react";
function SvgSadTearOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm.323-6.129a.97.97 0 00-.968.968.97.97 0 00.968.968c.96 0 1.867.423 2.483 1.161a.967.967 0 101.488-1.238 5.165 5.165 0 00-3.971-1.859zm-3.549-2.58c.714 0 1.29-.577 1.29-1.29 0-.714-.576-1.29-1.29-1.29-.714 0-1.29.576-1.29 1.29 0 .713.576 1.29 1.29 1.29zm6.452-2.58c-.714 0-1.29.576-1.29 1.29 0 .713.576 1.29 1.29 1.29a1.289 1.289 0 100-2.58zm-6.678 3.983c-.46.617-1.467 2.04-1.467 2.746 0 .915.758 1.657 1.693 1.657.936 0 1.694-.742 1.694-1.657 0-.706-1.008-2.129-1.468-2.746a.284.284 0 00-.452 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSadTearOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
