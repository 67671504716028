import * as React from "react";
function SvgPollHSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 19.857V4.143C22 2.96 21.04 2 19.857 2H4.143C2.96 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22h15.714C21.04 22 22 21.04 22 19.857zM7 9.143a.714.714 0 01-.714-.714V7c0-.395.32-.714.714-.714h5.714c.395 0 .715.32.715.714v1.429c0 .394-.32.714-.715.714H7zm0 4.286a.714.714 0 01-.714-.715v-1.428c0-.395.32-.715.714-.715h10c.395 0 .714.32.714.715v1.428c0 .395-.32.715-.714.715H7zm0 4.285A.714.714 0 016.286 17v-1.429c0-.394.32-.714.714-.714h2.857c.395 0 .714.32.714.714V17c0 .395-.32.714-.714.714H7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPollHSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
