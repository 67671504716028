import * as React from "react";
function SvgArrowCircleDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0 5.524-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2s10 4.476 10 10zm-5.79-1.165l-2.92 3.044V6.516a.965.965 0 00-.967-.968h-.646a.965.965 0 00-.967.968v7.363l-2.92-3.044a.969.969 0 00-1.383-.017l-.44.444a.964.964 0 000 1.367l5.348 5.35c.379.38.992.38 1.367 0l5.35-5.35a.964.964 0 000-1.367l-.44-.444a.969.969 0 00-1.382.017z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowCircleDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
