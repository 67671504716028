import * as React from "react";
function SvgUnlinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.878 17.856a.469.469 0 010 .663l-1.745 1.745a5.944 5.944 0 01-8.397 0 5.944 5.944 0 010-8.397l1.745-1.745a.469.469 0 01.663 0l1.547 1.546a.469.469 0 010 .664l-1.745 1.744a2.816 2.816 0 000 3.978 2.816 2.816 0 003.977 0l1.745-1.745a.469.469 0 01.664 0l1.546 1.547zM11.668 7.69c.184.183.48.183.664 0l1.744-1.745a2.816 2.816 0 013.978 0 2.816 2.816 0 010 3.977l-1.745 1.745a.469.469 0 000 .663l1.547 1.547c.183.183.48.183.663 0l1.745-1.745a5.944 5.944 0 000-8.397 5.944 5.944 0 00-8.397 0l-1.745 1.745a.469.469 0 000 .663l1.546 1.547zm9.174 14.034l.883-.884a.937.937 0 000-1.325L4.485 2.275a.937.937 0 00-1.327 0l-.883.884a.937.937 0 000 1.325l17.24 17.241a.938.938 0 001.326 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUnlinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
