import * as React from "react";
function SvgPlaceOfWorshipSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.394 15.455L18 14v6h3.5a.5.5 0 00.5-.5v-3.126a1 1 0 00-.606-.92zM2 16.374V19.5a.5.5 0 00.5.5H6v-6l-3.394 1.455a1 1 0 00-.606.919zm14.514-4.665L15 10.8V7.207a1 1 0 00-.293-.707l-2.354-2.353a.5.5 0 00-.706 0L9.293 6.5A1 1 0 009 7.207V10.8l-1.514.909a1 1 0 00-.486.857V20h3v-3a2 2 0 014 0v3h3v-7.434a1 1 0 00-.486-.857z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlaceOfWorshipSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
