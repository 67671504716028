import * as React from "react";
function SvgRulerCombinedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 13.25H6.062a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313H8.25V9.5H6.062a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313H8.25v-2.5H6.062a.312.312 0 01-.312-.313v-.625c0-.172.14-.312.313-.312H8.25V3.25C8.25 2.56 7.69 2 7 2H3.25C2.56 2 2 2.56 2 3.25v17.5c0 .108.036.205.061.305l6.189-6.189V13.25zm12.5 2.5H19.5v2.188c0 .172-.14.312-.313.312h-.625a.312.312 0 01-.312-.313V15.75h-2.5v2.188c0 .172-.14.312-.313.312h-.624a.312.312 0 01-.313-.313V15.75H12v2.188c0 .172-.14.312-.313.312h-.624a.312.312 0 01-.313-.313V15.75H9.134l-6.189 6.189c.1.026.197.061.305.061h17.5c.69 0 1.25-.56 1.25-1.25V17c0-.69-.56-1.25-1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRulerCombinedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
