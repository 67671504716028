import * as React from "react";
function SvgGrinBeamOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm4.258-6.104c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.554.554 0 00-.714.617c.319 1.899 2.875 3.225 4.972 3.225 2.097 0 4.65-1.326 4.972-3.225a.555.555 0 00-.714-.617zm-9.512-2.94a.328.328 0 00.375-.149l.383-.686c.31-.552.774-.87 1.27-.87s.96.318 1.27.87l.383.686c.085.15.25.19.375.15a.323.323 0 00.23-.336C10.9 8.988 9.734 7.806 8.774 7.806s-2.125 1.182-2.258 2.88c-.012.149.085.29.23.334zm6.452 0a.328.328 0 00.375-.149l.383-.686c.31-.552.774-.87 1.27-.87s.96.318 1.27.87l.383.686c.085.15.25.19.375.15a.323.323 0 00.23-.336c-.133-1.697-1.299-2.879-2.258-2.879-.96 0-2.125 1.182-2.258 2.88-.012.149.084.29.23.334z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinBeamOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
