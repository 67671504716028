import * as React from "react";
function SvgSkiingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.876 5.752A1.876 1.876 0 1018.875 2a1.876 1.876 0 000 3.752zm2.851 13.91a.934.934 0 00-1.324 0c-.473.473-1.191.602-1.762.34l-5.305-2.742 1.922-2.883a1.875 1.875 0 00-.234-2.367l-1.578-1.582-4.18-2.075a2.492 2.492 0 00.691 2.125l2.934 2.938-1.793 2.688-7.73-3.997a.939.939 0 00-1.263.403.933.933 0 00.403 1.262l15.31 7.91a3.478 3.478 0 003.91-.7.936.936 0 000-1.32zM6.688 5.58l-.449.879A1.53 1.53 0 007.91 6.27l1.844.914c-.004.004-.004.008-.008.012l4.473 2.219 1.266-.508.25.746c.156.473.492.86.938 1.082l2.27 1.133a1.249 1.249 0 101.117-2.235l-2.036-1.019-.668-2c-.316-.945-1.598-2.211-3.3-1.531l-3.173 1.27-2.441-1.212a1.514 1.514 0 00-.817-1.39l-.433.847h-.008L5.84 4.326a.2.2 0 00-.195.066.204.204 0 00.02.29l1.023.898z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSkiingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
