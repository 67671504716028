import * as React from "react";
function SvgHospitalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 21.219V22H3.25v-.781c0-.26.21-.469.469-.469H4.5V6.687c0-.517.42-.937.938-.937h3.437V2.937c0-.517.42-.937.938-.937h4.374c.518 0 .938.42.938.938V5.75h3.438c.517 0 .937.42.937.938V20.75h.781c.26 0 .469.21.469.469zM15.281 9.5H13.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469V9.97a.469.469 0 00-.469-.469zM8.72 12h1.562c.26 0 .469-.21.469-.469V9.97a.469.469 0 00-.469-.469H8.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469zm4.062 5H11.22a.469.469 0 00-.469.469v3.281h2.5v-3.281a.469.469 0 00-.469-.469zm2.5-3.75H13.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469V13.72a.469.469 0 00-.469-.469zm-4.531.469a.469.469 0 00-.469-.469H8.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469V13.72zm-.39-7.969h1.015v1.016a.235.235 0 00.234.234h.782a.235.235 0 00.234-.234V5.75h1.016a.235.235 0 00.234-.234v-.782a.235.235 0 00-.234-.234h-1.016V3.484a.235.235 0 00-.234-.234h-.782a.235.235 0 00-.234.234V4.5h-1.016a.235.235 0 00-.234.234v.782a.235.235 0 00.234.234z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHospitalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
