import * as React from "react";
function SvgUserCogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.08 15.666c.081-.44.081-.89 0-1.331l.807-.466a.226.226 0 00.103-.266 4.69 4.69 0 00-1.038-1.794.228.228 0 00-.281-.043l-.807.465a3.668 3.668 0 00-1.153-.665v-.932c0-.106-.075-.2-.178-.222a4.717 4.717 0 00-2.069 0 .228.228 0 00-.178.222v.932c-.422.15-.813.375-1.153.665l-.807-.465a.229.229 0 00-.281.043 4.69 4.69 0 00-1.038 1.794.23.23 0 00.104.266l.806.466c-.081.44-.081.89 0 1.331l-.806.466a.226.226 0 00-.104.265c.21.675.57 1.285 1.038 1.794a.228.228 0 00.281.044l.807-.466c.34.291.731.516 1.153.666v.931c0 .107.075.2.178.222.697.157 1.406.15 2.069 0a.228.228 0 00.178-.222v-.931c.422-.15.813-.375 1.153-.666l.807.466c.09.053.21.034.281-.044a4.69 4.69 0 001.038-1.794.23.23 0 00-.103-.265l-.807-.466zm-3.578.85A1.517 1.517 0 0115.986 15a1.52 1.52 0 011.516-1.515A1.52 1.52 0 0119.017 15c0 .835-.678 1.516-1.515 1.516zM9 12a4 4 0 100-8 4 4 0 000 8zm6.288 7.079c-.072-.038-.144-.081-.213-.122l-.247.144a1.25 1.25 0 01-.612.165c-.34 0-.669-.143-.903-.393a5.7 5.7 0 01-1.257-2.176 1.222 1.222 0 01.56-1.428l.247-.144a3.197 3.197 0 010-.243l-.247-.144a1.221 1.221 0 01-.56-1.428c.028-.091.07-.182.1-.272-.118-.01-.234-.038-.356-.038h-.522a5.446 5.446 0 01-4.557 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h11.001c.316 0 .61-.1.85-.265a1.218 1.218 0 01-.062-.369v-.287z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserCogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
