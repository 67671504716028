import * as React from "react";
function SvgCopyrightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.723 13.982c-.064.075-1.604 1.844-4.43 1.844-3.414 0-5.825-2.55-5.825-5.87 0-3.278 2.5-5.782 5.797-5.782 2.7 0 4.111 1.505 4.17 1.569a.484.484 0 01.05.59l-.903 1.397a.484.484 0 01-.735.092c-.01-.008-1.07-.963-2.495-.963-1.86 0-2.98 1.354-2.98 3.068 0 1.597 1.028 3.214 2.995 3.214 1.56 0 2.632-1.143 2.642-1.155a.484.484 0 01.747.043l.99 1.354a.484.484 0 01-.023.599z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCopyrightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
