import * as React from "react";
function SvgListAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 20.75H3.875A1.875 1.875 0 012 18.875V5.125c0-1.036.84-1.875 1.875-1.875h16.25C21.16 3.25 22 4.09 22 5.125v13.75c0 1.035-.84 1.875-1.875 1.875zM7 6.687a1.563 1.563 0 100 3.126 1.563 1.563 0 000-3.126zm0 3.75a1.563 1.563 0 100 3.126 1.563 1.563 0 000-3.126zm0 3.75a1.563 1.563 0 100 3.126 1.563 1.563 0 000-3.125zm11.25-5.312v-1.25a.469.469 0 00-.469-.469H9.97a.469.469 0 00-.469.469v1.25c0 .259.21.469.469.469h7.812c.26 0 .469-.21.469-.469zm0 3.75v-1.25a.469.469 0 00-.469-.469H9.97a.469.469 0 00-.469.469v1.25c0 .259.21.469.469.469h7.812c.26 0 .469-.21.469-.469zm0 3.75v-1.25a.469.469 0 00-.469-.469H9.97a.469.469 0 00-.469.469v1.25c0 .259.21.469.469.469h7.812c.26 0 .469-.21.469-.469z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgListAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
