import * as React from "react";
function SvgFanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.772 7c-1.097 0-2.113.177-3.01.502l.485-4.809c.042-.407-.317-.739-.726-.688C9.407 2.395 7 5.033 7 8.228c0 1.097.176 2.113.501 3.01l-4.809-.485c-.407-.042-.739.317-.688.726.39 3.114 3.028 5.52 6.223 5.52 1.097 0 2.113-.176 3.01-.501l-.485 4.808a.64.64 0 00.726.689c3.114-.39 5.52-3.028 5.52-6.223 0-1.097-.176-2.113-.501-3.01l4.808.485a.64.64 0 00.689-.726C21.605 9.407 18.967 7 15.772 7zM12 13.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
