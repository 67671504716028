"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiWaringModal = exports.UiModal = exports.UiErrorModal = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _antd = require("antd");
var _templateObject, _templateObject2, _templateObject3;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiModal = exports.UiModal = (0, _styledComponents.default)(_antd.Modal)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t.ant-modal-body {\n\t\t& > div + div {\n\t\t\tmargin-top: 10px;\n\t\t}\n\t}\n\n\t.ant-modal-title {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcolor: #40a9ff;\n\n\t\t& > i {\n\t\t\tmargin-right: 5px;\n\t\t\tcolor: #40a9ff;\n\t\t}\n\t}\n\n\t.ant-dropdown-menu-item {\n\t\t& > div {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t}\n\n\t\ti {\n\t\t\tmargin-right: 5px;\n\t\t\tfont-size: 14px;\n\t\t}\n\t}\n\n\t.ant-modal-footer {\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t\tpadding: 4px 16px 16px;\n\t\tborder-top: none;\n\t}\n"])));
const UiWaringModal = exports.UiWaringModal = (0, _styledComponents.default)(_antd.Modal)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t.ant-modal-body {\n\t\tpadding: 16px 16px 4px;\n\t}\n\n\t.ant-modal-footer {\n\t\tdisplay: flex;\n\t\tjustify-content: flex-end;\n\t\tpadding: 4px 16px 16px;\n\t\tborder-top: none;\n\t}\n\n\t.content {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t> .icon {\n\t\t\tflex: 0 0 14px;\n\t\t\tdisplay: inline-block;\n\t\t\tmargin-right: 8px;\n\t\t\twidth: 14px;\n\t\t\theight: 14px;\n\t\t\tfont-size: 10px;\n\t\t\ttext-align: center;\n\t\t\tcolor: #fff;\n\t\t\tbackground-color: #ffb802;\n\t\t\tborder-radius: 50%;\n\t\t\tline-height: 14px;\n\t\t}\n\t}\n"])));
const UiErrorModal = exports.UiErrorModal = (0, _styledComponents.default)(UiWaringModal)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t.content {\n\t\t> .icon {\n\t\t\tbackground-color: #f5222d;\n\t\t}\n\t}\n"])));