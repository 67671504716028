import * as React from "react";
function SvgShieldAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.224 5.269l-7.501-3.125a1.881 1.881 0 00-1.442 0l-7.5 3.125c-.7.289-1.157.973-1.157 1.73 0 7.755 4.473 13.115 8.653 14.857.461.192.98.192 1.442 0 3.348-1.394 8.657-6.211 8.657-14.856 0-.758-.457-1.442-1.152-1.731zm-8.22 14.165L12 4.55l6.872 2.864c-.13 5.914-3.208 10.2-6.868 12.02z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShieldAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
