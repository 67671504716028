import * as React from "react";
function SvgGavelSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.725 9.788l-.883-.884a.938.938 0 00-1.326 0l-.221.22-4.42-4.419.221-.22a.938.938 0 000-1.327l-.884-.883a.937.937 0 00-1.325 0l-4.862 4.86a.937.937 0 000 1.327l.884.884a.938.938 0 001.326 0l.22-.221 1.548 1.546-3.166 3.166-.22-.22a1.25 1.25 0 00-1.769 0l-4.482 4.481a1.25 1.25 0 000 1.768l1.768 1.768a1.25 1.25 0 001.768 0l4.482-4.482a1.25 1.25 0 000-1.768l-.221-.221 3.166-3.166 1.546 1.547-.22.221a.937.937 0 000 1.326l.883.884a.937.937 0 001.326 0l4.861-4.862a.938.938 0 000-1.325z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGavelSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
