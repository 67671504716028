import * as React from "react";
function SvgArrowUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.561 13.496l-.99-.99a1.067 1.067 0 010-1.514l8.672-8.677c.42-.42 1.098-.42 1.514 0l8.673 8.673c.42.42.42 1.098 0 1.513l-.991.991a1.072 1.072 0 01-1.531-.018L13.788 8.1v12.829c0 .593-.478 1.071-1.072 1.071h-1.428a1.069 1.069 0 01-1.071-1.071V8.099l-5.125 5.38a1.065 1.065 0 01-1.53.018z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
