import * as React from "react";
function SvgWeightHangingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.933 19.416L19.08 8.005C18.932 7.412 18.438 7 17.874 7h-2.353c.14-.392.23-.81.23-1.25a3.75 3.75 0 10-7.5 0c0 .44.09.858.23 1.25H6.126c-.564 0-1.058.412-1.206 1.005L2.067 19.416C1.742 20.718 2.64 22 3.877 22h16.247c1.236 0 2.134-1.282 1.809-2.584zM12 7c-.69 0-1.25-.561-1.25-1.25 0-.69.56-1.25 1.25-1.25s1.25.56 1.25 1.25C13.25 6.439 12.69 7 12 7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWeightHangingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
