import * as React from "react";
function SvgRulerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.866 9.224L19.38 4.989a1.006 1.006 0 00-1.36-.359l-2.156 1.222L17.73 9.03a.242.242 0 01-.09.334l-.432.244a.25.25 0 01-.34-.09L15 6.341l-1.725.979.872 1.48a.243.243 0 01-.09.335l-.432.244a.25.25 0 01-.34-.09l-.872-1.479-1.725.978 1.868 3.178a.243.243 0 01-.09.335l-.431.243a.25.25 0 01-.341-.09L9.829 9.277l-1.724.978.871 1.48a.243.243 0 01-.09.335l-.431.244a.25.25 0 01-.341-.09l-.872-1.485-1.725.978 1.866 3.178a.243.243 0 01-.09.335l-.432.243a.25.25 0 01-.34-.09l-1.866-3.169-2.156 1.222a.972.972 0 00-.366 1.337l2.487 4.238c.275.468.885.628 1.36.359l15.524-8.809a.97.97 0 00.362-1.337z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRulerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
