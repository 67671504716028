import { Modal } from "antd";
import { useState } from "react";
import { Button, Upload } from "common-components";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import openNotificationWithIcon from "utils/hooks/useNotification";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { convertQueueEdit } from "api/convert";

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;
export const UploadModal = ({ visible, onCancel }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const { upload } = useFirebaseStorage();

	const doConvertQueue = async (payload) => {
		const { isSuccess, message } = await convertQueueEdit(payload);
		if (!isSuccess) openNotificationWithIcon("error", message);
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setIsLoading(true);

		await Promise.all(
			files.map(async (file) => {
				const uid = uuidv4();
				const res = await upload(`convert/${uid}_${file.name}`, file);
				const payload = {
					fileUrl: res.url,
					fileName: file.name,
					uid,
				};

				await doConvertQueue(payload);
			}),
		);

		onCancel(false);
		setFiles([]);
		setIsLoading(false);
		openNotificationWithIcon("success", "上傳成功");
	};

	return (
		<Modal
			width={600}
			open={visible}
			onCancel={onCancel}
			onOk={submitFiles}
			title="上傳修題"
			footer={
				<UiButtons>
					<Button variant="white" full={false} onClick={onCancel} Key="cancel" loading={isLoading}>
						取消
					</Button>
					<Button
						variant="orange"
						full={false}
						onClick={submitFiles}
						Key="ok"
						loading={isLoading}
						disabled={files.length === 0}>
						確認
					</Button>
				</UiButtons>
			}>
			<Upload
				fileList={files}
				multiple={true}
				onChange={changeFileList}
				prompt={
					<>
						僅限上傳999個Microsoft Word 檔<br />
						(副檔名 : .doc/.docx)
					</>
				}
				acceptFileType=".doc,.docx"
			/>
		</Modal>
	);
};
