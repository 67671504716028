import * as React from "react";
function SvgCommentDotsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.627 10.125c-.691 0-1.25.559-1.25 1.25s.559 1.25 1.25 1.25 1.25-.559 1.25-1.25-.559-1.25-1.25-1.25zm4.374 0c-.691 0-1.25.559-1.25 1.25s.559 1.25 1.25 1.25c.692 0 1.25-.559 1.25-1.25s-.558-1.25-1.25-1.25zm4.375 0c-.692 0-1.25.559-1.25 1.25s.558 1.25 1.25 1.25c.691 0 1.25-.559 1.25-1.25s-.559-1.25-1.25-1.25zM12 3.251c-5.522 0-9.998 3.636-9.998 8.124 0 1.86.777 3.562 2.066 4.933-.582 1.539-1.793 2.843-1.813 2.86a.937.937 0 00.684 1.581c2.402 0 4.296-1.004 5.433-1.808 1.129.355 2.347.558 3.628.558 5.523 0 9.999-3.636 9.999-8.124s-4.476-8.124-9.999-8.124zm0 14.373c-1.042 0-2.074-.16-3.062-.472l-.886-.282-.762.54a8.397 8.397 0 01-2.246 1.132 9.798 9.798 0 00.777-1.57l.414-1.097-.804-.852c-.707-.754-1.555-2-1.555-3.648 0-3.445 3.644-6.249 8.124-6.249s8.124 2.804 8.124 6.25c0 3.444-3.644 6.248-8.124 6.248z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentDotsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
