import * as React from "react";
function SvgLaptopCodeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.97 12.177a.5.5 0 00.707 0l.353-.354a.5.5 0 000-.707L9.914 10l1.116-1.116a.5.5 0 000-.707l-.353-.354a.5.5 0 00-.708 0L8.146 9.646a.5.5 0 000 .707l1.824 1.824zm3-.354l.353.354a.5.5 0 00.708 0l1.823-1.823a.5.5 0 000-.707L14.03 7.823a.5.5 0 00-.708 0l-.353.354a.5.5 0 000 .707L14.086 10l-1.116 1.116a.5.5 0 000 .707zM21.5 17h-7.577c-.023.62-.46 1-1.023 1H11c-.584 0-1.032-.546-1.024-1H2.5c-.275 0-.5.225-.5.5v.5c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-.5c0-.275-.225-.5-.5-.5zM20 5.5c0-.825-.675-1.5-1.5-1.5h-13C4.675 4 4 4.675 4 5.5V16h16V5.5zM18 14H6V6h12v8z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaptopCodeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
