import * as React from "react";
function SvgDoorOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 18H19V7.545C19 6.693 18.327 6 17.5 6H14v2h3v12h4.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM11.758 4.032l-6 1.554A1.03 1.03 0 005 6.59V18H2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H13V5.037c0-.675-.611-1.17-1.242-1.005zM10.25 13c-.414 0-.75-.448-.75-1s.336-1 .75-1 .75.448.75 1-.336 1-.75 1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDoorOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
