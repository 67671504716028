import * as React from "react";
function SvgWarehouseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.75 15H6.262a.25.25 0 00-.25.25l-.003 1.5c0 .137.113.25.25.25H17.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zm0 3H6.253a.25.25 0 00-.25.25L6 19.75c0 .137.112.25.25.25h11.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zm0-6H6.269a.25.25 0 00-.25.25l-.003 1.5c0 .137.112.25.25.25H17.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zm3.328-4.344l-8.503-3.54a1.505 1.505 0 00-1.153 0l-8.5 3.54A1.504 1.504 0 002 9.04v10.71c0 .137.112.25.25.25h2.5a.25.25 0 00.25-.25V12c0-.55.456-1 1.019-1H17.98c.563 0 1.019.45 1.019 1v7.75c0 .137.113.25.25.25h2.5a.25.25 0 00.25-.25V9.04c0-.606-.366-1.15-.922-1.384z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWarehouseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
