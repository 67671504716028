import * as React from "react";
function SvgGrinTearsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.659 12.003c-.807.116-2.626.428-3.154.956-.684.685-.672 1.81.028 2.51.7.7 1.822.713 2.51.028.528-.528.84-2.347.956-3.153a.303.303 0 00-.34-.34zm15.835.956c-.528-.528-2.347-.84-3.153-.956a.298.298 0 00-.338.338c.116.806.429 2.625.957 3.153.684.684 1.81.672 2.51-.028.696-.697.709-1.822.024-2.507zm-3.956 1.929A6.251 6.251 0 0112 18.25a6.251 6.251 0 01-5.538-3.362c-.39 1.168-.788 1.372-.885 1.453A7.747 7.747 0 0012 19.75a7.747 7.747 0 006.422-3.41c-.1-.084-.497-.288-.884-1.453zM5.834 11.015C6.309 8.037 8.89 5.75 12 5.75c3.11 0 5.69 2.288 6.166 5.266.066-.006.162-.075 1.547.22a7.753 7.753 0 00-15.426-.003c1.39-.295 1.472-.226 1.547-.216zM12 16.5c1.622 0 3.603-1.028 3.853-2.5a.428.428 0 00-.553-.478c-.81.26-2.013.41-3.3.41-1.288 0-2.488-.15-3.3-.41a.43.43 0 00-.554.478c.25 1.472 2.232 2.5 3.854 2.5zm4.072-5.26a.25.25 0 00.178-.259c-.103-1.316-1.006-2.231-1.75-2.231s-1.647.915-1.75 2.231a.253.253 0 00.178.26.254.254 0 00.29-.116l.298-.531c.24-.429.6-.675.984-.675s.744.246.985.675l.296.53a.255.255 0 00.291.117zM9.5 9.92c.384 0 .743.246.984.675l.297.53c.066.117.194.148.29.117a.25.25 0 00.179-.26C11.147 9.665 10.243 8.75 9.5 8.75c-.744 0-1.647.915-1.75 2.231a.253.253 0 00.178.26.254.254 0 00.29-.116l.297-.531c.24-.432.6-.675.985-.675z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinTearsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
