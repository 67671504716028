import * as React from "react";
function SvgMapSignsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.817 5.308l-1.692-1.692a1.25 1.25 0 00-.884-.366H13.25v-.625A.625.625 0 0012.625 2h-1.25a.625.625 0 00-.625.625v.625H4.187a.938.938 0 00-.937.938v3.125c0 .517.42.937.937.937h15.054c.332 0 .65-.132.884-.366l1.692-1.692a.625.625 0 000-.884zM10.75 21.375c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625V17h-2.5v4.375zm9.063-10.625H13.25V9.5h-2.5v1.25H4.759c-.332 0-.65.132-.884.366l-1.692 1.692a.625.625 0 000 .884l1.692 1.692c.234.234.552.366.884.366h15.053c.518 0 .938-.42.938-.938v-3.124a.938.938 0 00-.938-.938z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMapSignsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
