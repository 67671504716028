import { Api } from "common-components";

/**
 * 匯出試題
 */
export const exportFile = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Bank/Question/ExportFile`, payload);
	return response;
};

// 取得 匯出列表
export const getExportList = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/ExportFileRecord`);
	return response;
};
