import * as React from "react";
function SvgFileCodeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.356 15.638l-.008-.008-1.281-1.129 1.281-1.128a.345.345 0 00.031-.484l-.008-.008-.68-.727a.341.341 0 00-.483-.015l-2.254 2.113a.342.342 0 000 .5l2.254 2.112a.348.348 0 00.234.094.337.337 0 00.25-.11l.68-.726a.344.344 0 00-.016-.484zm8.592-9.81L15.67 2.55A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zM14.499 4.03l2.973 2.972h-2.973V4.03zm3.125 16.094H6.376V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247zm-4.937-9.763a.341.341 0 00-.425.234l-2.137 7.346a.34.34 0 00.235.426l.953.277a.343.343 0 00.425-.234l2.137-7.35a.34.34 0 00-.235-.426l-.953-.273zm.957 3.003l.008.008 1.281 1.129-1.281 1.128a.345.345 0 00-.031.484l.008.008.68.727a.344.344 0 00.483.015l2.254-2.113a.342.342 0 000-.5l-2.254-2.112a.34.34 0 00-.484.015l-.68.727a.344.344 0 00.016.484z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileCodeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
