import * as React from "react";
function SvgHourglassStartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.563 2H5.438a.937.937 0 00-.938.938v.624c0 .518.42.938.938.938 0 3.553 1.992 6.552 4.72 7.5-2.728.948-4.72 3.947-4.72 7.5a.937.937 0 00-.938.938v.625c0 .517.42.937.938.937h13.125c.517 0 .937-.42.937-.938v-.625a.937.937 0 00-.938-.937c0-3.553-1.992-6.552-4.72-7.5 2.728-.948 4.72-3.947 4.72-7.5.518 0 .938-.42.938-.938v-.624A.937.937 0 0018.562 2zm-2.5 17.5H7.938c0-3.026 1.804-5.625 4.062-5.625 2.257 0 4.063 2.598 4.063 5.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHourglassStartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
