import * as React from "react";
function SvgStarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.927 3.095l-2.441 4.95-5.462.795c-.98.142-1.372 1.35-.661 2.042l3.951 3.85-.935 5.44c-.168.982.868 1.719 1.735 1.259L12 18.863l4.886 2.568c.867.456 1.903-.277 1.735-1.26l-.935-5.439 3.951-3.85c.71-.692.318-1.9-.661-2.042l-5.462-.796-2.441-4.95c-.438-.882-1.705-.893-2.146 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
