import * as React from "react";
function SvgCopyOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.2 4.576l-2.026-2.027A1.875 1.875 0 0016.848 2h-6.723C9.089 2 8.25 2.84 8.25 3.875V5.75H5.125c-1.036 0-1.875.84-1.875 1.875v12.5C3.25 21.16 4.09 22 5.125 22h8.75c1.036 0 1.875-.84 1.875-1.875V18.25h3.125c1.035 0 1.875-.84 1.875-1.875V5.902c0-.498-.198-.975-.55-1.326zm-6.56 15.549H5.36a.235.235 0 01-.235-.234V7.859a.234.234 0 01.234-.234H8.25v8.75c0 1.035.84 1.875 1.875 1.875h3.75v1.64a.235.235 0 01-.234.235zm5-3.75h-8.28a.235.235 0 01-.235-.234V4.109a.235.235 0 01.234-.234H14.5v3.438c0 .517.42.937.938.937h3.437v7.89a.235.235 0 01-.234.235zm.235-10h-2.5v-2.5h.376c.062 0 .122.025.166.069l1.89 1.889a.236.236 0 01.068.166v.376z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCopyOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
