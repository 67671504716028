import * as React from "react";
function SvgTabletSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 2H5.125C4.09 2 3.25 2.84 3.25 3.875v16.25C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V3.875C20.75 2.84 19.91 2 18.875 2zM12 20.75c-.691 0-1.25-.559-1.25-1.25s.559-1.25 1.25-1.25 1.25.559 1.25 1.25-.559 1.25-1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTabletSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
