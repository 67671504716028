import * as React from "react";
function SvgCampgroundSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 18h-.771L13.236 7.68l1.669-2.299a.5.5 0 00-.11-.698l-.81-.587a.5.5 0 00-.699.11L12 5.979l-1.287-1.772a.5.5 0 00-.698-.11l-.809.587a.5.5 0 00-.11.698l1.669 2.299L3.27 18H2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM12 13l3.636 5H8.364L12 13z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCampgroundSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
