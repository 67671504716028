import * as React from "react";
function SvgPiggyBankSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.444 10.889H20.42a5.574 5.574 0 00-1.299-1.823l.657-2.622h-1.111c-1.021 0-1.924.469-2.535 1.191-.264-.038-.525-.08-.799-.08H10.89A5.561 5.561 0 005.444 12h-1.5a.834.834 0 01-.816-1c.076-.396.448-.667.85-.667h.035a.209.209 0 00.209-.208V9.43a.209.209 0 00-.209-.208c-.99 0-1.871.708-1.996 1.688a1.946 1.946 0 001.927 2.201h1.389c0 1.813.882 3.407 2.222 4.42v2.803c0 .305.25.555.556.555h2.222c.305 0 .556-.25.556-.555v-1.667h4.444v1.667c0 .305.25.555.556.555h2.222c.305 0 .555-.25.555-.555v-2.802c.41-.31.775-.674 1.087-1.087h1.691c.306 0 .556-.25.556-.556v-4.445a.557.557 0 00-.556-.555zM17 13.11a.557.557 0 01-.556-.555c0-.306.25-.556.556-.556.305 0 .555.25.555.556 0 .305-.25.555-.555.555zm-6.111-6.667h4.444c.188 0 .372.014.552.028 0-.01.004-.017.004-.028a3.334 3.334 0 00-6.667 0c0 .073.017.143.02.216a6.6 6.6 0 011.646-.216z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPiggyBankSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
