import * as React from "react";
function SvgAnchorSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.419 15.344h1.128c.76 3.58 4.758 5.574 8.453 5.574 3.7 0 7.694-1.999 8.453-5.574h1.128c.373 0 .56-.45.296-.713l-2.335-2.335a.418.418 0 00-.592 0l-2.335 2.335a.418.418 0 00.296.713h1.224c-.707 1.893-2.96 3.017-5.02 3.275V12h1.811c.231 0 .418-.187.418-.418v-1.394a.418.418 0 00-.418-.418h-1.811v-.19a3.346 3.346 0 002.23-3.153 3.355 3.355 0 00-3.297-3.345 3.344 3.344 0 00-1.163 6.498v.19H9.074a.418.418 0 00-.418.418v1.394c0 .23.187.418.418.418h1.811v6.62c-2.053-.258-4.313-1.383-5.02-3.276h1.224c.372 0 .559-.45.296-.713L5.05 12.296a.418.418 0 00-.592 0L2.123 14.63a.418.418 0 00.296.713zM12 5.311a1.116 1.116 0 010 2.23 1.116 1.116 0 010-2.23z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAnchorSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
