import * as React from "react";
function SvgMicrophoneAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 10h-.5a.5.5 0 00-.5.5V12a4.005 4.005 0 01-4.4 3.98C9.522 15.779 8 13.91 8 11.823V10.5a.5.5 0 00-.5-.5H7a.5.5 0 00-.5.5v1.255c0 2.801 2 5.298 4.75 5.678V18.5H9.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5h-1.75v-1.055c2.678-.368 4.75-2.667 4.75-5.445v-1.5a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
      <path d="M15 12a3 3 0 11-6 0V7a3 3 0 116 0v5z" fill="currentColor" />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMicrophoneAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
