import * as React from "react";
function SvgBookOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.827 4.224c-1.902.108-5.684.501-8.02 1.93a.534.534 0 00-.252.458v12.634c0 .401.439.655.809.469 2.402-1.21 5.876-1.54 7.594-1.63.586-.03 1.042-.5 1.042-1.064V5.29c0-.615-.533-1.102-1.173-1.066zm-9.635 1.93c-2.334-1.429-6.116-1.822-8.02-1.93C2.534 4.188 2 4.675 2 5.29v11.731c0 .564.456 1.034 1.042 1.065 1.719.09 5.195.42 7.597 1.63.368.186.806-.067.806-.467V6.605a.523.523 0 00-.253-.45z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBookOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
