import * as React from "react";
function SvgMagicSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.75 5.75l.625-1.25 1.25-.625-1.25-.625L10.75 2l-.625 1.25-1.25.625 1.25.625.625 1.25zm-5.625 2.5l1.041-2.083L8.25 5.125 6.166 4.083 5.125 2 4.084 4.083 2 5.125l2.084 1.042L5.125 8.25zm13.75 5l-1.041 2.083-2.084 1.042 2.084 1.042 1.041 2.083 1.041-2.083L22 16.375l-2.084-1.042-1.041-2.083zm2.759-7.569l-3.315-3.314A1.243 1.243 0 0017.436 2c-.32 0-.64.122-.884.367L2.366 16.552a1.25 1.25 0 000 1.767l3.315 3.315a1.246 1.246 0 001.767 0L21.634 7.448a1.249 1.249 0 000-1.767zM16.04 9.948l-1.989-1.989 3.383-3.383 1.989 1.989-3.383 3.383z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMagicSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
