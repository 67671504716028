import { useState, useEffect } from "react";
import { DatePicker, Checkbox, Spin, Pagination as AntdPagination } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getTime, format } from "date-fns";
import { xor } from "lodash";

import { Breadcrumb } from "components";
import { Select, Button, Input, Table, Pagination } from "common-components";
import {
	UiAudiosImportPage,
	StyledModal,
	UiSuccessText,
	UiErrorText,
	UiLoadingText,
	UiDeleteModal,
} from "./AudiosImportPage.style";
import { sliceTableData, getStartDay, getEndDay } from "utils/common";
import { getAudiosRecord, getAudiosDetail, getAudiosRecordCheck, deleteAudiosRecord } from "api/audios";
import openNotificationWithIcon from "utils/hooks/useNotification";
import UploadAudioFile from "./UploadAudioFile";

export const audioTypeMap = {
	all: "0",
	commonAudios: "1",
	questionAudios: "2",
	questionTypeAudios: "3",
};

const uploadStatusMap = {
	all: 0,
	pending: 3,
	downloadFail: 4,
	dataNull: 5,
	partialUploadFail: 6,
	finish: 10,
	exception: 99,
};

export const audioTypeList = [
	{
		label: "定義音檔",
		value: audioTypeMap.commonAudios,
	},
	{
		label: "題目音檔",
		value: audioTypeMap.questionAudios,
	},
	{
		label: "題型音檔",
		value: audioTypeMap.questionTypeAudios,
	},
];

const allAudioTypeMap = [{ label: "所有音檔", value: audioTypeMap.all }, ...audioTypeList].map((item) => ({
	value: item.value,
	name: item.label,
}));

const detailStatusMap = [
	{
		name: "所有狀態",
		value: uploadStatusMap.all,
	},
	{
		name: "上傳完成",
		value: uploadStatusMap.finish,
	},
	{
		name: "上傳失敗",
		value: uploadStatusMap.exception,
	},
];

const breadcrumbList = [
	{
		icon: "unarchive",
		name: "音檔入庫",
		path: "/audiosImport",
	},
];

const today = new Date();
const before1Month = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
const dateFormat = "YYYY-MM-DD";

const initSendFormData = {
	fileName: "",
	audioType: allAudioTypeMap[0].value,
	userName: "",
	startDateTime: getStartDay(before1Month),
	EndDateTime: getEndDay(today),
};

const { RangePicker } = DatePicker;
const { Header, Body, Row, Item } = Table;

export const AudiosImportPage = () => {
	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [deleteGroup, setDeleteGroup] = useState([]);
	// 最外層的換頁功能
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	// detail modal 內的翻頁相關功能
	const [detailPageListState, setDetailListPage] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [detailPage, setDetailPage] = useState(1);

	const [detailPopup, setDetailPopup] = useState({
		visible: false,
	});
	const [detailPopupData, setDetailPopupData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadStatus, setUploadStatus] = useState(uploadStatusMap.all);
	const [deletePopupState, setDeletePopupState] = useState(false);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
	const [isDetailLoading, setIsDetailLoading] = useState(false);

	const goTop = () => {
		const scroll = document.querySelector(".CheckListTable > .body");
		scroll.scrollTop = 0;
	};

	const toggleDetailVisible = () => {
		setDetailPopup({
			...detailPopup,
			visible: !detailPopup.visible,
		});
	};

	const pageChange = (currentPage, pageSize) => {
		setDeleteGroup([]);
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		goTop();
	};

	const onSelectChange = (key, value) => {
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
	};

	// 取得音檔入庫總表
	const [stateGetAudiosList, sendGetAudiosList] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getAudiosRecord(event.payload);
				const { audioRecord, total } = response.data;

				setIsLoading(false);

				return {
					audioData: audioRecord,
					audioTotal: total,
				};
			},
		},
	});

	const { audioData, audioTotal } = stateGetAudiosList.context.result || {};

	// 取得音檔詳細資料
	const [stateAudioDetail, sendAudioDetail] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data } = await getAudiosDetail(event.payload);

				setIsDetailLoading(false);

				return {
					detailModalData: data.audioDetail,
				};
			},
		},
	});

	const { detailModalData } = stateAudioDetail.context.result || {};

	// 音檔入庫總表完成作業
	const [, sendAudioCheck] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				await getAudiosRecordCheck(event.payload);

				handleGetAudioList();
			},
		},
	});

	// 刪除音檔
	const [, sendDeleteAudios] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await deleteAudiosRecord(event.payload);

				if (response.error === 200) {
					openNotificationWithIcon("success", "刪除成功！");
				} else {
					openNotificationWithIcon("error", response.message || "刪除失敗");
				}
				handleGetAudioList();
			},
		},
	});

	const handleGetAudioList = (isReset = false) => {
		setIsLoading(true);

		const payload = isReset
			? { ...initSendFormData, pagenumber: 1, pagesize: 100, audioType: allAudioTypeMap[0].value }
			: {
					fileName: sendFormData.fileName,
					userName: sendFormData.userName,
					pagenumber: pageState.currentPage,
					pagesize: pageState.limit,
					audioType: sendFormData.audioType,
					startDateTime: sendFormData.startDateTime.toISOString(),
					EndDateTime: sendFormData.EndDateTime.toISOString(),
			  };

		if (isReset) {
			setSendFormData(initSendFormData);
			setPageState({
				currentPage: 1,
				limit: 100,
			});
		}

		sendGetAudiosList(FetchEventType.Fetch, {
			payload: payload,
		});
	};

	const handleDateChange = (e) => {
		if (!e) return;
		setSendFormData((prev) => ({
			...prev,
			startDateTime: new Date(e[0]._d),
			EndDateTime: getEndDay(new Date(e[1]._d)),
		}));
	};

	const onDetailPopupHandle = (item) => {
		setIsDetailLoading(true);
		sendAudioDetail(FetchEventType.Fetch, {
			payload: {
				fileUID: item.uid,
				type: item.type,
			},
		});

		setDetailPopup({
			visible: true,
		});
	};

	useEffect(() => {
		handleGetAudioList();
	}, []);

	const handleDeleteCheckboxClick = () => {
		// 如果全選的
		if (audioData) {
			if (deleteGroup.length === audioData.length) {
				setDeleteGroup([]);
			} else {
				const allData = audioData.map((item) => item.uid);
				setDeleteGroup(allData);
			}
		}
	};

	const handleAudioCheck = (item) => {
		sendAudioCheck(FetchEventType.Fetch, {
			payload: [
				{
					UID: item.uid,
					isChecked: !item.isChecked,
				},
			],
		});
	};

	/**
	 * 下載
	 */
	const handlerDownloadUrl = (fileName, fileUrl) => {
		const link = document.createElement("a");
		link.href = fileUrl;
		// link.target = "_blank";
		link.download = fileName;
		link.click();
	};

	const detailPageChange = (currentPage, pageSize) => {
		setDetailListPage({
			...detailPageListState,
			currentPage,
			limit: pageSize,
		});
	};

	// 音檔入庫詳細頁 Table 的資料
	useEffect(() => {
		const filterData =
			uploadStatus !== uploadStatusMap.all
				? detailModalData?.filter((item) => item.status === uploadStatus)
				: detailModalData;
		const data = sliceTableData(filterData, detailPageListState.currentPage, detailPageListState.limit);
		setDetailPopupData(data);
	}, [detailModalData, detailPageListState, uploadStatus]);

	const statusFilter = (status) => {
		switch (status) {
			case uploadStatusMap.pending:
				return <UiLoadingText>上傳中</UiLoadingText>;
			case uploadStatusMap.downloadFail:
				return <UiErrorText>檔案下載失敗</UiErrorText>;
			case uploadStatusMap.dataNull:
				return <UiErrorText>無音檔資料</UiErrorText>;
			case uploadStatusMap.partialUploadFail:
				return <UiErrorText>部分檔案上傳失敗</UiErrorText>;
			case uploadStatusMap.finish:
				return <UiSuccessText>上傳完成</UiSuccessText>;
			case uploadStatusMap.exception:
				return <UiErrorText>上傳失敗</UiErrorText>;
			default:
				<UiSuccessText>上傳完成</UiSuccessText>;
		}
	};

	const handleConfirmDelete = () => {
		const payload = deleteGroup.map((item) => ({
			UID: item,
			isHidden: true,
		}));
		sendDeleteAudios(FetchEventType.Fetch, {
			payload: payload,
		});

		setDeletePopupState(false);
	};

	useEffect(() => {
		handleGetAudioList();
	}, [pageState.currentPage, pageState.limit]);

	const toggleUploadPopupVisible = () => {
		setUploadPopupVisible(!uploadPopupVisible);
	};

	return (
		<UiAudiosImportPage id="AudiosImport">
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="searchToolBar">
				<div className="selectSearchGroup">
					<Select
						placeholder="音檔類型"
						options={allAudioTypeMap}
						value={sendFormData.audioType}
						onChange={(val) => {
							onSelectChange("audioType", val);
						}}
					/>
					<div className="dateFilterBox">
						<RangePicker
							value={[moment(sendFormData?.startDateTime, dateFormat), moment(sendFormData?.EndDateTime, dateFormat)]}
							onChange={handleDateChange}
							allowClear={false}
						/>
					</div>
					<Input
						placeholder="請輸入檔案名稱"
						maxLength={30}
						value={sendFormData.fileName}
						onChange={(e) => {
							onSelectChange("fileName", e.target.value);
						}}
						style={{ width: "130px" }}
					/>
					<Input
						type="text"
						placeholder="請輸入上傳者"
						maxLength={30}
						value={sendFormData.userName}
						onChange={(e) => {
							onSelectChange("userName", e.target.value);
						}}
					/>
					<Button.IconButton
						variant="blue"
						full={false}
						icon={
							<i className="material-icons" style={{ fontSize: "20px" }}>
								search
							</i>
						}
						onClick={() => handleGetAudioList()}>
						搜尋
					</Button.IconButton>
					<Button.IconButton
						variant="green"
						full={false}
						iconName="restart_alt"
						onClick={() => handleGetAudioList(true)}>
						清除搜尋
					</Button.IconButton>
				</div>
				<div className="actionBox">
					<Button.IconButton
						variant="red"
						full={false}
						iconName="delete_forever"
						onClick={() => setDeletePopupState(true)}>
						批次刪除
					</Button.IconButton>
					<Button.IconButton
						variant="blue"
						full={false}
						iconName="cloud_upload"
						onClick={() => setUploadPopupVisible(true)}>
						上傳音檔
					</Button.IconButton>
				</div>
			</div>
			<Table margin="3" className={"CheckListTable"}>
				<Table.Header className="header">
					<Table.Row>
						<Table.Item className="checkboxItem" flex="0.1">
							<Checkbox
								checked={audioData?.length > 0 && deleteGroup.length === audioData.length}
								onChange={handleDeleteCheckboxClick}
							/>
						</Table.Item>
						<Table.Item flex="3">檔案名稱</Table.Item>
						<Table.Item flex="1">檔案狀態</Table.Item>
						<Table.Item flex="1">上傳日期</Table.Item>
						<Table.Item flex="1">上傳者</Table.Item>
						<Table.Item flex="2">操作</Table.Item>
						<Table.Item flex="1">完成作業</Table.Item>
					</Table.Row>
				</Table.Header>
				<Table.Body className="body">
					{isLoading ? (
						<div className="loading">
							<Spin indicator={<Loading3QuartersOutlined spin={true} />} size="large" />
						</div>
					) : audioData && audioData.length > 0 ? (
						audioData.map((item) => (
							<Table.Row key={item.cms + item.uid}>
								<Table.Item className="checkboxItem" flex="0.1">
									<Checkbox
										checked={deleteGroup.includes(item.uid)}
										onChange={() => {
											setDeleteGroup((prev) => xor(prev, [item.uid]));
										}}
									/>
								</Table.Item>
								<Table.Item flex="3">{item.fileName}</Table.Item>
								<Table.Item flex="1">{statusFilter(item.status)}</Table.Item>
								<Table.Item flex="1">{format(getTime(new Date(item?.createTime)), "yyyy-MM-dd HH:mm")}</Table.Item>
								<Table.Item flex="1">{item.userName}</Table.Item>
								<Table.Item flex="2">
									<div className="btnGroup">
										<Button.IconButton
											variant={"orange"}
											full={false}
											iconName="visibility"
											onClick={() => onDetailPopupHandle(item)}
											disabled={
												item.status === uploadStatusMap.downloadFail ||
												item.status === uploadStatusMap.pending ||
												item.status === uploadStatusMap.dataNull
											}>
											看更多
										</Button.IconButton>
										<Button.IconButton
											disabled={item.status === uploadStatusMap.pending}
											variant={item?.failedUrl || item?.fileUrl ? "blue" : "ui01"}
											full={false}
											iconName="save_alt"
											onClick={() => handlerDownloadUrl(item?.fileName, item?.fileUrl)}>
											下載
										</Button.IconButton>
									</div>
								</Table.Item>
								<Table.Item flex="1">
									<Checkbox checked={item.isChecked} onChange={() => handleAudioCheck(item)} />
								</Table.Item>
							</Table.Row>
						))
					) : (
						<div className="tableNoData">
							<img src={`/assets/noData.png`} alt="noData" />
							無資料顯示
						</div>
					)}
				</Table.Body>
			</Table>
			<Pagination
				total={audioTotal || 0}
				defaultCurrent={1}
				current={pageState.currentPage}
				onChange={pageChange}
				defaultPageSize={pageState.limit}
			/>
			<StyledModal
				visible={detailPopup.visible}
				zIndex={1001}
				onCancel={toggleDetailVisible}
				// getContainer={document.getElementById("ScheduleListPage")}
				id={"detailModal"}
				title={"音檔列表"}
				footer={null}>
				<Select
					placeholder="檔案狀態"
					options={detailStatusMap}
					value={uploadStatus}
					onChange={(val) => {
						setUploadStatus(val);
					}}
					className="select"
				/>

				{!isDetailLoading && (
					<Table margin="3" className={"questionListTable"}>
						<Header>
							<Row>
								<Item flex="2">檔案名稱</Item>
								<Item flex="2">音檔UID</Item>
								<Item flex="1">檔案狀態</Item>
							</Row>
						</Header>
						<Body>
							{isDetailLoading && (
								<div className="loading">
									<Spin indicator={<Loading3QuartersOutlined spin={true} />} size="large" />
								</div>
							)}
							{!isDetailLoading && detailPopupData?.length > 0 ? (
								detailPopupData.map((item) => {
									return (
										<Row key={item.uid}>
											<Item flex="2">{item.audioName}</Item>
											<Item flex="2">{item.uid}</Item>
											<Item flex="1">{statusFilter(item.status)}</Item>
										</Row>
									);
								})
							) : (
								<div className="tableNoData">
									<img src={`/assets/noData.png`} alt="noData" />
									音檔已重新上傳，請查看入庫紀錄
								</div>
							)}
						</Body>
					</Table>
				)}

				{!isDetailLoading && detailModalData?.length > 0 && (
					<div style={{ textAlign: "center" }}>
						<AntdPagination
							total={detailModalData?.length || 0}
							defaultCurrent={1}
							current={detailPageListState.currentPage}
							onChange={detailPageChange}
							defaultPageSize={detailPageListState.limit}
							// textcolor="#000"
						/>
					</div>
				)}
			</StyledModal>
			<UiDeleteModal
				open={deletePopupState}
				onOk={() => handleConfirmDelete()}
				onCancel={() => setDeletePopupState(false)}
				okText="確定"
				cancelText="取消">
				<div className="content">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
						<g clipPath="url(#clip0_6298_5061)">
							<path
								d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z"
								fill="#FAAD14"
							/>
							<path
								d="M9.82104 15.3214C9.82104 15.634 9.94522 15.9338 10.1662 16.1548C10.3873 16.3758 10.687 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.687 14.1429 10.3873 14.267 10.1662 14.4881C9.94522 14.7091 9.82104 15.0089 9.82104 15.3214ZM10.4103 12.5714H11.5889C11.6969 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.6969 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z"
								fill="#FAAD14"
							/>
						</g>
						<defs>
							<clipPath id="clip0_6298_5061">
								<rect width="22" height="22" fill="white" />
							</clipPath>
						</defs>
					</svg>
					確定要刪除紀錄嗎？
				</div>
			</UiDeleteModal>
			<UploadAudioFile
				visible={uploadPopupVisible}
				toggleUploadPopupVisible={toggleUploadPopupVisible}
				handleGetAudioList={handleGetAudioList}
			/>
		</UiAudiosImportPage>
	);
};
