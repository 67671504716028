import * as React from "react";
function SvgSortAlphaDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.857 16.286H7.714V2.714A.714.714 0 007 2H5.572a.714.714 0 00-.715.714v13.572H2.714a.715.715 0 00-.504 1.219l3.572 4.286a.714.714 0 001.01 0l3.571-4.286c.447-.449.129-1.22-.506-1.22zm10.715-2.857h-5.715a.714.714 0 00-.714.714v1.428a.714.714 0 00.714.715h2.5l-2.735 3.145a1.427 1.427 0 00-.48 1.068v.787a.715.715 0 00.715.714h5.715a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.715-.714h-2.5l2.735-3.145a1.43 1.43 0 00.48-1.068v-.787a.714.714 0 00-.715-.714zm1.386-3.812l-2.646-7.143A.714.714 0 0018.64 2h-1.85a.714.714 0 00-.672.474L13.47 9.617a.713.713 0 00.672.954h1.108a.714.714 0 00.68-.494l.198-.577h3.17l.196.577a.714.714 0 00.682.494h1.109a.714.714 0 00.672-.954zM16.983 7l.731-2.143L18.446 7h-1.463z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortAlphaDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
