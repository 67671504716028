import * as React from "react";
function SvgLightbulbSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.877 19.748c0 .245.073.486.21.69l.667 1.004a1.25 1.25 0 001.04.558h2.411a1.25 1.25 0 001.041-.558l.668-1.003a1.25 1.25 0 00.209-.691l.002-1.498h-6.25l.002 1.498zM5.125 8.875c0 1.733.643 3.314 1.702 4.522.645.737 1.654 2.275 2.04 3.573l.003.03h6.26l.004-.03c.385-1.298 1.394-2.836 2.04-3.573a6.836 6.836 0 001.7-4.522A6.875 6.875 0 0011.98 2c-3.985.012-6.854 3.241-6.854 6.875zM12 5.75a3.129 3.129 0 00-3.125 3.125.625.625 0 11-1.25 0A4.38 4.38 0 0112 4.5a.625.625 0 110 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLightbulbSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
