import * as React from "react";
function SvgMedalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.74 7.107l-2.7-4.5A1.25 1.25 0 006.968 2H2.626a.625.625 0 00-.512.984l4.347 6.209a8.084 8.084 0 014.28-2.086zM21.374 2h-4.342c-.439 0-.846.23-1.072.607l-2.7 4.5c1.642.257 3.118 1.001 4.28 2.086l4.346-6.21A.625.625 0 0021.374 2zM12 8.25A6.875 6.875 0 1012 22a6.875 6.875 0 000-13.75zm3.614 6.143l-1.482 1.444.35 2.04a.449.449 0 01-.65.472L12 17.386l-1.832.963a.448.448 0 01-.65-.472l.35-2.04-1.482-1.444a.449.449 0 01.248-.765l2.048-.299.916-1.856a.442.442 0 01.401-.25c.16 0 .321.084.404.25l.915 1.856 2.048.299a.449.449 0 01.248.765z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMedalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
