import * as React from "react";
function SvgFunnelDollarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.546 9.186l3.162-3.496c.624-.624.182-1.69-.7-1.69H2.992c-.882 0-1.324 1.066-.7 1.69L8 12v4.872c0 .394.185.764.5 1l2.5 1.875c.652.49 1.514.218 1.85-.424A5.98 5.98 0 0111 15c0-2.806 1.939-5.162 4.546-5.814zM17 10a5 5 0 100 10 5 5 0 000-10zm.5 7.496V18a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.509a1.79 1.79 0 01-.98-.355.25.25 0 01-.018-.38l.367-.35a.256.256 0 01.317-.022c.12.075.258.116.4.116h.879c.203 0 .368-.185.368-.412 0-.186-.112-.35-.274-.398l-1.406-.422a1.414 1.414 0 01-.987-1.356c0-.766.595-1.389 1.334-1.408V12a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.51c.352.017.696.14.98.354a.25.25 0 01.018.38l-.367.35a.256.256 0 01-.317.022.75.75 0 00-.4-.116h-.879c-.203 0-.369.185-.369.412 0 .186.113.35.274.398l1.407.422c.58.174.986.732.986 1.356 0 .766-.595 1.389-1.333 1.408z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFunnelDollarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
