import * as React from "react";
function SvgFillSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.634 10.479L13.52 2.366a1.246 1.246 0 00-1.768 0L8.568 5.553 5.2 2.186a.625.625 0 00-.884 0l-.884.884a.626.626 0 000 .883L6.8 7.32 3.1 11.02a3.75 3.75 0 000 5.303l4.577 4.578A3.739 3.739 0 0010.327 22c.96 0 1.92-.366 2.652-1.098l8.655-8.655a1.25 1.25 0 000-1.768zm-4.54 2.772H4.576c.053-.15.139-.312.29-.462l.513-.514 3.188-3.188 2.29 2.29a1.25 1.25 0 001.767-1.768l-2.29-2.289 2.303-2.302 6.346 6.345-1.889 1.888z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFillSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
