import * as React from "react";
function SvgDatabaseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 4.857v1.786C20.75 8.217 16.83 9.5 12 9.5S3.25 8.217 3.25 6.643V4.857C3.25 3.283 7.17 2 12 2s8.75 1.283 8.75 2.857zm0 4.018v4.018c0 1.573-3.92 2.857-8.75 2.857s-8.75-1.284-8.75-2.857V8.875c1.88 1.295 5.32 1.897 8.75 1.897 3.43 0 6.87-.602 8.75-1.897zm0 6.25v4.018C20.75 20.716 16.83 22 12 22s-8.75-1.284-8.75-2.857v-4.018c1.88 1.295 5.32 1.897 8.75 1.897 3.43 0 6.87-.602 8.75-1.897z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDatabaseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
