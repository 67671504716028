import * as React from "react";
function SvgWindowCloseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25H3.875C2.84 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.035-.84-1.875-1.875-1.875zm-3.266 11.348a.48.48 0 010 .68l-1.582 1.581a.48.48 0 01-.68 0L12 14.24l-2.598 2.62a.48.48 0 01-.68 0l-1.581-1.582a.48.48 0 010-.68L9.76 12l-2.62-2.598a.48.48 0 010-.68l1.582-1.581a.48.48 0 01.68 0L12 9.76l2.598-2.62a.48.48 0 01.68 0l1.581 1.582a.48.48 0 010 .68L14.24 12l2.62 2.598z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWindowCloseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
