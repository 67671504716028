import * as React from "react";
function SvgIdCardAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 5.333h-5v3.334H8.667V5.333h-5C2.747 5.333 2 6.08 2 7v12.222c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V7c0-.92-.747-1.667-1.667-1.667zM12 10.89c1.226 0 2.222.996 2.222 2.222A2.224 2.224 0 0112 15.333a2.224 2.224 0 01-2.222-2.222c0-1.226.996-2.222 2.222-2.222zm3.24 7.778H8.76c-.36 0-.652-.348-.541-.688a2.223 2.223 0 012.114-1.535h.285a3.583 3.583 0 002.764 0h.285c.986 0 1.823.643 2.114 1.535.111.34-.18.688-.541.688zM14.222 4.222a1.11 1.11 0 00-1.11-1.11h-2.223a1.11 1.11 0 00-1.111 1.11v3.334h4.444V4.222z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIdCardAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
