import * as React from "react";
function SvgBoldSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.893 11.196c.68-.83 1.099-1.843 1.205-2.91C19.428 4.878 16.755 2 13.425 2H4.523a.714.714 0 00-.715.714v2.143a.714.714 0 00.715.714h1.423V18.43H4.523a.714.714 0 00-.715.714v2.143a.714.714 0 00.715.714h9.344c3.161 0 5.989-2.31 6.295-5.464.212-2.163-.732-4.11-2.27-5.34zM9.508 5.571h3.917a2.143 2.143 0 110 4.286H9.508V5.571zm3.917 12.858H9.508v-5h3.917a2.5 2.5 0 110 5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBoldSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
