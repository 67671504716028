import * as React from "react";
function SvgLaughSquintOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm5.702 15.702A8.01 8.01 0 0112 20.064a8.01 8.01 0 01-5.702-2.362A8.01 8.01 0 013.935 12a8.01 8.01 0 012.363-5.702A8.01 8.01 0 0112 3.935a8.01 8.01 0 015.702 2.363A8.01 8.01 0 0120.064 12a8.01 8.01 0 01-2.362 5.702zM15.855 9.58l1.355-1.625c.346-.416-.154-1-.621-.726l-3.226 1.935a.484.484 0 000 .831l3.226 1.935c.463.275.968-.306.62-.725L15.856 9.58zM7.41 11.93l3.226-1.935a.484.484 0 000-.83L7.411 7.23c-.467-.278-.967.31-.62.726L8.144 9.58 6.79 11.206c-.35.419.154 1 .621.726zm9.202 1.36H7.387a.541.541 0 00-.544.604c.302 2.387 2.375 4.234 4.883 4.234h.548c2.508 0 4.58-1.847 4.883-4.234a.541.541 0 00-.544-.605z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaughSquintOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
