import * as React from "react";
function SvgLowVisionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.769 11.154A11.318 11.318 0 0012 5.61c-.989 0-1.948.126-2.863.362L7.305 3.467a.833.833 0 00-1.16-.205l-.456.318a.833.833 0 00-.205 1.161L6.982 6.77a11.277 11.277 0 00-4.75 4.383l-.001.002a1.666 1.666 0 000 1.692 11.28 11.28 0 006.002 4.908l-4.312-6.16c.292-.443.62-.86.982-1.247L9.05 16.27l1.411 2.015c.909.123 1.83.137 2.741.04L6.605 8.903a9.64 9.64 0 011.346-.749l8.744 12.38a.833.833 0 001.16.205l.456-.319a.833.833 0 00.205-1.16l-1.497-2.027a11.262 11.262 0 004.75-4.386 1.665 1.665 0 000-1.692zm-6.226 3.969l-.662-.946a3.61 3.61 0 00.305-3.877A1.487 1.487 0 1113.7 8.815h.001a3.616 3.616 0 00-2.76-.267l-.662-.946A4.714 4.714 0 0112 7.278 4.72 4.72 0 0116.722 12c0 1.198-.445 2.29-1.18 3.123z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLowVisionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
