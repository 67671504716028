import * as React from "react";
function SvgGlobeAfricaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm6.452 8.69v.279a.625.625 0 01-.346.559l-.62.31a.624.624 0 01-.627-.039l-.734-.49a.625.625 0 00-.544-.072l-.107.035a.625.625 0 00-.322.94l.534.8c.115.174.31.279.52.279h.33c.345 0 .625.28.625.625v.457a.626.626 0 01-.125.375l-.755 1.007a.63.63 0 00-.114.26l-.174.92a.626.626 0 01-.192.345c-.382.35-.72.745-1.008 1.176l-.525.788a1.12 1.12 0 01-1.932-.12A3.182 3.182 0 0112 17.701v-1.205a.625.625 0 00-.625-.625h-1.043a2.204 2.204 0 01-2.203-2.203v-.567c0-.693.326-1.346.88-1.762l1.113-.834c.381-.286.845-.44 1.322-.44h.035c.342 0 .68.079.986.232l.593.296c.149.075.32.087.477.034l1.908-.635a.625.625 0 00-.198-1.218h-.407a.625.625 0 01-.442-.183l-.279-.28a.624.624 0 00-.442-.182h-3.63a.625.625 0 01-.626-.625v-.178c0-.286.196-.536.474-.606l.582-.146a.626.626 0 00.369-.26l.326-.488a.624.624 0 01.52-.278h.976c.345 0 .625-.28.625-.625v-.875a8.076 8.076 0 016.529 6.017h-.743a.625.625 0 00-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGlobeAfricaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
