import * as React from "react";
function SvgCapsulesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.281 13.531L16.73 7.028a3.97 3.97 0 00-5.531-.976A3.955 3.955 0 009.757 7.92a3.875 3.875 0 00-3.868-3.698A3.887 3.887 0 002 8.112v7.777c0 2.15 1.74 3.889 3.889 3.889 2.15 0 3.889-1.74 3.889-3.89v-5.176c.114.298.253.594.444.868l4.556 6.503a3.962 3.962 0 003.257 1.695 3.97 3.97 0 003.247-6.247zM7.556 12H4.222V8.111a1.667 1.667 0 013.334 0V12zm6.763 1.559l-2.277-3.253A1.727 1.727 0 0111.754 9c.08-.458.336-.861.718-1.129a1.744 1.744 0 012.434.43l2.278 3.255-2.865 2.003z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCapsulesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
