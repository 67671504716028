import * as React from "react";
function SvgShareSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.739 9.272l-5 4.722c-.527.498-1.406.129-1.406-.606V10.89c-5.02.033-7.137 1.219-5.721 5.95.156.52-.446.922-.868.601-1.353-1.028-2.577-2.993-2.577-4.978 0-4.998 4.083-5.99 9.166-6.018v-2.5c0-.735.88-1.103 1.406-.606l5 4.722a.834.834 0 010 1.211zm-6.406 7.003v2.392H4.223V7.556H5.99a.416.416 0 00.3-.129c.52-.54 1.12-.968 1.772-1.308.386-.201.243-.786-.193-.786H3.667C2.747 5.333 2 6.08 2 7v12.222c0 .92.746 1.667 1.667 1.667h12.222c.92 0 1.667-.746 1.667-1.667V16.14a.417.417 0 00-.556-.393c-.38.135-.788.175-1.188.117a.417.417 0 00-.479.412z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShareSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
