import * as React from "react";
function SvgRecycleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.21 12.23c.126.547-.474.963-.94.671l-1.592-.994-1.987 3.18c-.519.83.08 1.913 1.06 1.913h2.03c.26 0 .469.21.469.469v1.562c0 .26-.21.469-.469.469H5.754c-2.942 0-4.738-3.244-3.18-5.738l1.986-3.179-1.591-.994a.625.625 0 01.19-1.139l4.307-.994a.625.625 0 01.75.468l.993 4.307zm3.85-7.145l1.612 2.582-1.591.994a.625.625 0 00.19 1.139l4.307.994a.625.625 0 00.75-.468l.993-4.306a.625.625 0 00-.94-.671l-1.59.993-1.611-2.581c-1.466-2.345-4.891-2.35-6.36 0l-.702 1.123a.469.469 0 00.15.646l1.324.828c.22.137.51.07.646-.149l.702-1.123c.497-.794 1.64-.768 2.12 0zm8.365 8.677l-1.075-1.72a.469.469 0 00-.646-.15l-1.322.827a.469.469 0 00-.15.645l1.077 1.723c.518.83-.08 1.913-1.06 1.913H14.5v-1.874a.625.625 0 00-1.067-.442l-3.125 3.124a.625.625 0 000 .884l3.125 3.125a.625.625 0 001.067-.442V19.5h3.745c2.94 0 4.74-3.242 3.18-5.738z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRecycleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
