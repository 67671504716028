import * as React from "react";
function SvgStrikethroughSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 10.75H13.48l-3.405-1.048a1.701 1.701 0 01.501-3.327h2.61a1.95 1.95 0 011.744 1.077.625.625 0 00.838.28l1.677-.839a.625.625 0 00.28-.838l-.02-.04A5 5 0 0013.23 3.25h-2.656A4.831 4.831 0 005.77 8.548c.078.816.394 1.556.85 2.202H2.626a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h18.75a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zm-7.04 3.75a1.68 1.68 0 01.79 1.424 1.703 1.703 0 01-1.701 1.701h-2.61a1.949 1.949 0 01-1.744-1.077.625.625 0 00-.838-.28l-1.677.839a.625.625 0 00-.28.838l.02.04a5 5 0 004.474 2.765h2.656a4.83 4.83 0 004.805-5.298 4.465 4.465 0 00-.209-.952h-3.687z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStrikethroughSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
