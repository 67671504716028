import * as React from "react";
function SvgMousePointerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.554 14.857H13.41l2.181 5.312a.726.726 0 01-.369.937l-1.92.837a.691.691 0 01-.912-.38l-2.072-5.044-3.385 3.482c-.451.464-1.183.107-1.183-.502V2.715c0-.64.778-.953 1.183-.502l11.11 11.427c.448.437.117 1.216-.489 1.216z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMousePointerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
