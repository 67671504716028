import * as React from "react";
function SvgCreditCardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 18.111c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V12H2v6.111zm6.667-2.361c0-.23.187-.417.416-.417h4.723c.229 0 .416.188.416.417v1.389c0 .229-.187.416-.416.416H9.083a.418.418 0 01-.416-.416v-1.39zm-4.445 0c0-.23.188-.417.417-.417h2.5c.23 0 .417.188.417.417v1.389c0 .229-.188.416-.417.416h-2.5a.418.418 0 01-.417-.416v-1.39zM22 5.889v1.667H2V5.889c0-.92.747-1.667 1.667-1.667h16.666c.92 0 1.667.747 1.667 1.667z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCreditCardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
