import * as React from "react";
function SvgProceduresSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.5 11h-8c-.275 0-.5.226-.5.5V16H4V8.5c0-.274-.225-.5-.5-.5h-1c-.275 0-.5.226-.5.5v11c0 .276.225.5.5.5h1c.275 0 .5-.224.5-.5V18h16v1.5c0 .276.225.5.5.5h1c.275 0 .5-.224.5-.5v-5c0-1.934-1.566-3.5-3.5-3.5zM6.25 7h3.94l.863 1.726a.5.5 0 00.894 0L13.5 5.62 14.19 7H18c.275 0 .5-.224.5-.5 0-.274-.225-.5-.5-.5h-3.19l-.863-1.724a.5.5 0 00-.894 0L11.5 7.382l-.622-1.244a.251.251 0 00-.225-.137H6.25a.25.25 0 00-.25.25v.5c0 .137.112.25.25.25zM7 15c1.103 0 2-.896 2-2 0-1.102-.897-2-2-2s-2 .898-2 2c0 1.104.897 2 2 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgProceduresSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
