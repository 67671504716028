import * as React from "react";
function SvgFillDripSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.778 14.222s-2.223 3.217-2.223 4.445a2.222 2.222 0 004.445 0c0-1.228-2.222-4.445-2.222-4.445zm-.326-3.574l-7.211-7.212a1.108 1.108 0 00-1.571 0L7.837 6.27 4.845 3.276a.555.555 0 00-.786 0l-.785.786a.556.556 0 000 .785L6.267 7.84l-3.29 3.29a3.333 3.333 0 000 4.713l4.068 4.07c.651.65 1.504.976 2.357.976a3.32 3.32 0 002.357-.976l7.693-7.694a1.111 1.111 0 000-1.571zm-4.035 2.464H4.289c.047-.133.124-.277.258-.41l.457-.457L7.837 9.41l2.035 2.035a1.11 1.11 0 001.571-1.571L9.408 7.84l2.047-2.047 5.64 5.64-1.678 1.68z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFillDripSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
