import * as React from "react";
function SvgRainbowSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.316 4.246C6.006 4.6 2 9.253 2 14.576v4.646c0 .305.25.555.556.555h1.11c.306 0 .556-.25.556-.555v-5c0-4.625 4.06-8.323 8.802-7.712 3.92.503 6.754 4.031 6.754 7.982v4.73c0 .305.25.555.555.555h1.111c.306 0 .556-.25.556-.555v-5c0-5.74-4.861-10.368-10.684-9.976zm-.195 3.365c-3.357.43-5.788 3.455-5.788 6.843v4.768c0 .305.25.555.556.555H7c.306 0 .556-.25.556-.555v-5c0-2.598 2.24-4.68 4.888-4.424 2.31.226 4 2.299 4 4.622v4.802c0 .305.25.555.556.555h1.111c.306 0 .556-.25.556-.555v-5A6.676 6.676 0 0011.12 7.61zm.216 3.343c-1.584.31-2.67 1.788-2.67 3.4v4.868c0 .305.25.555.555.555h1.111c.306 0 .556-.25.556-.555v-5a1.112 1.112 0 012.222 0v5c0 .305.25.555.556.555h1.11c.306 0 .556-.25.556-.555v-5a3.337 3.337 0 00-3.996-3.268z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRainbowSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
