import * as React from "react";
function SvgCommentsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.47 16.52c.955-.94 1.528-2.121 1.528-3.409 0-2.777-2.656-5.072-6.118-5.482-1.093-2-3.662-3.406-6.658-3.406C5.232 4.223 2 6.71 2 9.778c0 1.285.573 2.465 1.528 3.41a9.091 9.091 0 01-1.309 1.905.8.8 0 00-.153.868.8.8 0 00.736.486c1.858 0 3.357-.7 4.347-1.347.32.073.65.129.986.17 1.09 1.993 3.649 3.396 6.642 3.396.722 0 1.416-.084 2.076-.236.99.642 2.485 1.347 4.346 1.347a.804.804 0 00.584-1.354 8.847 8.847 0 01-1.313-1.903zM6.833 13.316l-.594.385c-.49.316-.99.566-1.496.743a11.7 11.7 0 00.278-.514l.538-1.08-.861-.85c-.469-.465-1.031-1.226-1.031-2.222 0-2.107 2.545-3.888 5.555-3.888s5.555 1.78 5.555 3.888c0 2.107-2.545 3.888-5.555 3.888a7.563 7.563 0 01-1.702-.194l-.687-.156zM19.3 15.333l-.857.847.538 1.08c.09.177.184.35.278.514a7.065 7.065 0 01-1.497-.743l-.593-.386-.691.16a7.569 7.569 0 01-1.702.194c-1.874 0-3.548-.697-4.558-1.725 3.517-.375 6.225-2.687 6.225-5.496 0-.118-.014-.233-.024-.347 2.235.503 3.912 1.972 3.912 3.68 0 .996-.562 1.757-1.03 2.222z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
