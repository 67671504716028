import * as React from "react";
function SvgKeySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 8.875a6.875 6.875 0 01-8.157 6.755l-.938 1.055a.936.936 0 01-.701.315H10.75v1.563c0 .517-.42.937-.938.937H8.25v1.563c0 .517-.42.937-.938.937H2.938A.937.937 0 012 21.062v-3.049c0-.248.099-.487.275-.663l6.32-6.32A6.875 6.875 0 0115.125 2 6.86 6.86 0 0122 8.875zM15.125 7a1.875 1.875 0 103.75 0 1.875 1.875 0 00-3.75 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKeySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
