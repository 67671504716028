import * as React from "react";
function SvgMehBlankSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-3.226 9.355c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.714 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29zm6.452 0c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMehBlankSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
