import * as React from "react";
function SvgGlassMartiniAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.611 4.25c.83-.83.242-2.25-.931-2.25H3.32C2.146 2 1.56 3.42 2.39 4.25l8.361 8.36v7.515H8.562c-.862 0-1.562.7-1.562 1.563 0 .172.14.312.313.312h9.375c.172 0 .312-.14.312-.313 0-.862-.7-1.562-1.563-1.562H13.25v-7.514l8.361-8.361zm-2.276-.375L17.46 5.75H6.54L4.667 3.875h14.669z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGlassMartiniAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
