import * as React from "react";
function SvgEnvelopeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 4.5H3.875C2.839 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V6.375c0-1.036-.84-1.875-1.875-1.875zm0 1.875v1.594c-.876.713-2.272 1.822-5.257 4.16-.658.517-1.961 1.76-2.868 1.746-.907.014-2.21-1.229-2.868-1.746-2.984-2.337-4.381-3.447-5.257-4.16V6.375h16.25zm-16.25 11.25v-7.25c.895.713 2.164 1.713 4.1 3.228.853.672 2.348 2.156 4.025 2.147 1.669.009 3.145-1.453 4.026-2.146a669.323 669.323 0 004.099-3.229v7.25H3.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEnvelopeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
