import * as React from "react";
function SvgSmogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 15.5h-17c-.275 0-.5.225-.5.5v.5c0 .275.225.5.5.5h17c.275 0 .5-.225.5-.5V16c0-.275-.225-.5-.5-.5zm-15 3h-4c-.275 0-.5.225-.5.5v.5c0 .275.225.5.5.5h4c.275 0 .5-.225.5-.5V19c0-.275-.225-.5-.5-.5zm13 0H9c-.275 0-.5.225-.5.5v.5c0 .275.225.5.5.5h10.5c.275 0 .5-.225.5-.5V19c0-.275-.225-.5-.5-.5zM6.5 13h4.878c.703.616 1.613 1 2.622 1 1.01 0 1.916-.384 2.622-1H18.5c1.934 0 3.5-1.566 3.5-3.5S20.434 6 18.5 6c-.563 0-1.084.144-1.553.378C16.187 4.968 14.713 4 13 4c-1.281 0-2.431.54-3.25 1.4A4.474 4.474 0 006.5 4a4.501 4.501 0 000 9z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSmogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
