import * as React from "react";
function SvgWifiSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.32 11.108c.164.182.43.173.603-.019 2.13-2.258 4.937-3.447 8.073-3.447 3.164 0 5.98 1.198 8.1 3.456.165.174.421.174.586-.009l1.198-1.207c.155-.155.155-.356.027-.52C19.86 6.837 16.01 5 11.997 5 7.981 5 4.132 6.838 2.084 9.361c-.119.165-.119.366.037.521l1.197 1.226zm3.602 3.602c.192.192.439.164.621-.028 1.043-1.16 2.734-1.984 4.462-1.974 1.737-.01 3.429.84 4.49 1.993.164.192.402.192.585 0l1.353-1.326c.146-.146.164-.347.027-.512-1.316-1.61-3.758-2.798-6.455-2.798-2.706 0-5.138 1.189-6.464 2.798-.137.165-.119.348.028.512l1.353 1.335zm5.083 4.736c.192 0 .366-.082.704-.42l2.112-2.03a.37.37 0 00.055-.503c-.585-.74-1.655-1.372-2.87-1.372-1.253 0-2.332.659-2.908 1.427-.092.146-.055.31.082.448l2.112 2.03c.338.329.512.42.713.42z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWifiSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
