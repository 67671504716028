import * as React from "react";
function SvgHorseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.997 5.77c0-.282-.104-.55-.297-.756-.132-.14-.298-.317-.476-.504.384-.237.677-.607.77-1.064a.28.28 0 00-.275-.335h-4.166a4.445 4.445 0 00-4.445 4.444H7.556c-1.002 0-1.89.451-2.5 1.15v-.038A3.059 3.059 0 002 11.722v1.945c0 .307.249.555.556.555h.555a.555.555 0 00.556-.555v-1.945c0-.459.238-.847.582-1.1-.007.09-.027.176-.027.267 0 .96.411 1.818 1.06 2.426l-.892 2.382a2.22 2.22 0 00-.075 1.32l.862 3.451a.555.555 0 00.539.421h2.29c.362 0 .627-.34.54-.69l-.914-3.655.827-2.208 4.652.775v5.222c0 .307.249.556.556.556h2.222a.555.555 0 00.556-.556V14.16a3.873 3.873 0 001.11-2.716l-.002-.022V7.864l.555.247.657 1.31c.258.515.87.747 1.406.533l1.13-.452A1.11 1.11 0 0022 8.469l-.003-2.698zm-2.222.674a.555.555 0 110-1.11.555.555 0 010 1.11z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHorseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
