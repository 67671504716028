import * as React from "react";
function SvgShuttleVanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.652 10.583L17.45 5.54A1.5 1.5 0 0016.298 5H3a1 1 0 00-1 1v9a1 1 0 001 1h1a3 3 0 106 0h4a3 3 0 106 0h1a1 1 0 001-1v-3.457c0-.35-.123-.69-.348-.96zM4 10V7h3v3H4zm3 7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5-7.5H9V7h3v3zm5 7.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM14 10V7h2.063l2.5 3H14z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShuttleVanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
