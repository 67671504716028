import * as React from "react";
function SvgTimesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.79 12l5.687-5.686a1.787 1.787 0 000-2.527l-1.264-1.264a1.787 1.787 0 00-2.527 0L12 8.21 6.314 2.523a1.787 1.787 0 00-2.527 0L2.523 3.787a1.787 1.787 0 000 2.527L8.21 12l-5.686 5.686a1.787 1.787 0 000 2.527l1.264 1.264c.698.697 1.83.697 2.527 0L12 15.79l5.686 5.686c.698.697 1.83.697 2.527 0l1.264-1.264a1.787 1.787 0 000-2.527L15.79 12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTimesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
