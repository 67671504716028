import * as React from "react";
function SvgTapeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9 10c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zm12.5 7h-7.606A6.984 6.984 0 0016 12a7 7 0 10-7 7h12.5c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM9 15a3 3 0 11.002-6.002A3 3 0 019 15z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTapeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
