import * as React from "react";
function SvgHotdogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.086 2.914a3.119 3.119 0 00-4.41 0L2.952 16.638a3.118 3.118 0 104.41 4.41L21.087 7.324a3.119 3.119 0 000-4.41zm-1.947 3.711c-.764.764-1.462.884-2.024.98-.505.087-.87.15-1.353.633-.484.484-.546.848-.633 1.353-.096.562-.216 1.26-.98 2.025-.764.763-1.463.883-2.025.98-.505.087-.869.15-1.352.633-.484.483-.546.848-.633 1.352-.096.562-.216 1.26-.98 2.025-.765.763-1.464.884-2.025.98-.504.087-.87.15-1.353.633a.624.624 0 01-.882-.882c.764-.764 1.463-.884 2.024-.98.507-.087.87-.15 1.353-.633.484-.484.546-.848.633-1.353.096-.562.216-1.26.98-2.024s1.463-.884 2.025-.98c.506-.087.869-.15 1.352-.633.484-.484.546-.849.633-1.354.097-.561.217-1.26.98-2.024.765-.764 1.464-.884 2.025-.98.505-.087.87-.15 1.353-.633a.624.624 0 01.882.882zM3.264 14.561L14.6 3.226l-.45-.45c-.974-.975-2.489-1.04-3.383-.145l-8.097 8.096c-.895.895-.83 2.41.145 3.384l.45.45zm17.51-5.16L9.44 20.736l.45.45c.975.975 2.49 1.04 3.384.145l8.096-8.097c.895-.894.83-2.409-.145-3.383l-.45-.45z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHotdogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
