import * as React from "react";
function SvgBackspaceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20 6H8.414A1.999 1.999 0 007 6.586l-4.707 4.707a1 1 0 000 1.414L7 17.414A2 2 0 008.414 18H20a2 2 0 002-2V8a2 2 0 00-2-2zm-2.646 7.94a.5.5 0 010 .707l-.707.706a.5.5 0 01-.708 0L14 13.414l-1.94 1.94a.5.5 0 01-.707 0l-.706-.707a.5.5 0 010-.708L12.586 12l-1.94-1.94a.5.5 0 010-.707l.707-.706a.5.5 0 01.708 0L14 10.586l1.938-1.94a.5.5 0 01.708 0l.707.707a.5.5 0 010 .708L15.414 12l1.94 1.94z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBackspaceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
