import * as React from "react";
function SvgCubeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.342 2.29L3.215 5.338A1.878 1.878 0 002 7.096v8.795c0 .71.402 1.36 1.035 1.676l8.127 4.063a1.862 1.862 0 001.676 0l8.127-4.063A1.872 1.872 0 0022 15.89V7.096c0-.781-.485-1.48-1.215-1.754l-8.127-3.048a1.843 1.843 0 00-1.316-.004zm.66 2.427l7.501 2.813v.043l-7.501 3.047L4.5 7.573V7.53l7.502-2.813zm1.25 13.908v-5.817l6.251-2.54V15.5l-6.25 3.125z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCubeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
