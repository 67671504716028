import * as React from "react";
function SvgUserAssignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15 12a4 4 0 100-8 4 4 0 000 8zm2.8 1h-.522a5.445 5.445 0 01-4.556 0H12.2A4.201 4.201 0 008 17.2v1.3A1.5 1.5 0 009.5 20h11a1.5 1.5 0 001.5-1.5v-1.3c0-2.319-1.881-4.2-4.2-4.2zM2 11.459v1.08c0 .18.15.325.335.325h3.234v1.81c0 .29.36.435.572.23l2.76-2.675a.318.318 0 000-.46l-2.76-2.674c-.212-.203-.572-.06-.572.23v1.81H2.335a.33.33 0 00-.335.324z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserAssignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
