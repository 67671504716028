import * as React from "react";
function SvgHandHoldingUsdSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.412 8.122l1.884.496a.298.298 0 01.23.281c0 .16-.142.292-.316.292h-1.237c-.133 0-.265-.026-.388-.076-.182-.077-.392-.06-.531.07l-.66.607a.406.406 0 00-.132.238.395.395 0 00.188.4c.366.22.775.356 1.199.4v.614c0 .306.272.556.603.556h.603c.332 0 .603-.25.603-.556v-.61c1.144-.126 2.009-1.077 1.858-2.188-.11-.799-.78-1.434-1.617-1.656l-1.884-.497a.298.298 0 01-.23-.281c0-.16.142-.292.316-.292h1.237c.133 0 .266.026.39.077.181.076.391.059.53-.07l.66-.607a.392.392 0 00.13-.237.396.396 0 00-.188-.402 2.91 2.91 0 00-1.198-.399v-.615c0-.306-.272-.556-.603-.556h-.603c-.332 0-.603.25-.603.556v.61c-1.142.126-2.01 1.077-1.858 2.188.109.799.781 1.434 1.617 1.657zm10.215 6.381c-.41-.371-1.049-.347-1.48 0L16.94 17.07a2.21 2.21 0 01-1.39.486h-4.106a.555.555 0 110-1.11h2.719c.552 0 1.066-.38 1.154-.924a1.11 1.11 0 00-1.095-1.299H8.667c-.937 0-1.846.323-2.573.913l-1.615 1.31H2.556A.556.556 0 002 17v3.333a.556.556 0 00.556.556h12.387c.505 0 .995-.171 1.389-.486l5.25-4.202a1.11 1.11 0 00.045-1.698z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandHoldingUsdSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
