import * as React from "react";
function SvgLaughSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 6.129c.713 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm-6.452 0c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm3.549 10.968h-.646a5.812 5.812 0 01-5.762-5.08.648.648 0 01.641-.727h10.887c.388 0 .69.339.642.726a5.812 5.812 0 01-5.762 5.08z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaughSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
