import * as React from "react";
function SvgPlaneDepartureSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 17.5h-19a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM4.517 14.165c.196.214.472.335.76.334l4.08-.005c.322 0 .64-.077.926-.223l9.092-4.614c.836-.424 1.585-1.029 2.095-1.822.572-.89.634-1.534.408-1.989-.225-.455-.773-.79-1.82-.858-.933-.06-1.86.185-2.696.61l-3.079 1.561L7.45 4.595a.556.556 0 00-.562-.035L4.832 5.603a.571.571 0 00-.162.892L9.552 9.56l-3.225 1.637-2.261-1.14a.556.556 0 00-.502 0l-1.254.637a.571.571 0 00-.174.878l2.381 2.593z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlaneDepartureSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
