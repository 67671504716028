import * as React from "react";
function SvgVectorSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 7V3.25C22 2.56 21.44 2 20.75 2H17c-.69 0-1.25.56-1.25 1.25h-7.5C8.25 2.56 7.69 2 7 2H3.25C2.56 2 2 2.56 2 3.25V7c0 .69.56 1.25 1.25 1.25v7.5C2.56 15.75 2 16.31 2 17v3.75c0 .69.56 1.25 1.25 1.25H7c.69 0 1.25-.56 1.25-1.25h7.5c0 .69.56 1.25 1.25 1.25h3.75c.69 0 1.25-.56 1.25-1.25V17c0-.69-.56-1.25-1.25-1.25v-7.5C21.44 8.25 22 7.69 22 7zm-3.75-2.5h1.25v1.25h-1.25V4.5zM4.5 4.5h1.25v1.25H4.5V4.5zm1.25 15H4.5v-1.25h1.25v1.25zm13.75 0h-1.25v-1.25h1.25v1.25zm-1.25-3.75H17c-.69 0-1.25.56-1.25 1.25v1.25h-7.5V17c0-.69-.56-1.25-1.25-1.25H5.75v-7.5H7c.69 0 1.25-.56 1.25-1.25V5.75h7.5V7c0 .69.56 1.25 1.25 1.25h1.25v7.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVectorSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
