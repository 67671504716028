"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiTableRow = exports.UiTableItem = exports.UiTableHeader = exports.UiTableBody = exports.UiTable = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styledSystem = require("styled-system");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiTableItem = exports.UiTableItem = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tflex: 1;\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 4px 15px;\n\tmin-width: 100px;\n\ttext-overflow: ellipsis;\n\t", "\n\t", "\n"])), _ref => {
  let {
    issticky
  } = _ref;
  return issticky && (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\t\tposition: sticky;\n\t\t\tleft: 0;\n\t\t"])));
}, _styledSystem.flexbox);
const UiTableRow = exports.UiTableRow = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\twidth: 100%;\n\tflex-grow: 0;\n\tflex-direction: row;\n\tflex-wrap: nowrap;\n\t", "\n"])), _ref2 => {
  let {
    issticky
  } = _ref2;
  return issticky && (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\t\tposition: sticky;\n\t\t\ttop: 0;\n\t\t\tz-index: 2;\n\t\t"])));
});
const UiTableHeader = exports.UiTableHeader = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\n\t", " {\n\t\tfont-size: 14px;\n\t\tfont-weight: 700;\n\t}\n"])), UiTableItem);
const UiTableBody = exports.UiTableBody = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\tposition: relative;\n\tflex: 1;\n\n\t", " {\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n"])), UiTableItem);
const UiTable = exports.UiTable = _styledComponents.default.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\toverflow: auto;\n\theight: 100%;\n\tflex: 1;\n\tflex-direction: column;\n\t", "\n\t", "\n\t", " {\n\t\t", " {\n\t\t\t", "\n\t\t}\n\t\t", " {\n\t\t\t", "\n\t\t}\n\t}\n\t", " {\n\t\t", " {\n\t\t\t", "\n\n\t\t\t&:nth-child(odd) > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&:nth-child(even) > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&:first-child > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t& + ", " > ", " {\n\t\t\t\t", "\n\t\t\t}\n\t\t}\n\t}\n"])), _styledSystem.space, (0, _styledSystem.variant)({
  variants: {
    default: {
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
    }
  }
}), UiTableHeader, UiTableItem, (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "lightblue",
      color: "white"
    },
    light: {
      backgroundColor: "#fafafa",
      color: "#000"
    }
  }
}), UiTableRow, (0, _styledSystem.variant)({
  variants: {
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
}), UiTableBody, UiTableRow, (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "white"
    },
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "dark"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "gray"
    }
  }
}), _ref3 => {
  let {
    issticky
  } = _ref3;
  return issticky && (0, _styledComponents.css)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\t\t\t\t\tcolor: ", ";\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t"])), _ref4 => {
    let {
      theme
    } = _ref4;
    return theme.colors.white;
  }, _ref5 => {
    let {
      theme
    } = _ref5;
    return theme.colors.lightblue;
  });
}, UiTableRow, UiTableItem, (0, _styledSystem.variant)({
  variants: {
    default: {
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)"
    }
  }
}));