import * as React from "react";
function SvgMehSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM8.774 8.774c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm7.097 7.742H8.129c-.855 0-.855-1.29 0-1.29h7.742c.855 0 .855 1.29 0 1.29zm-.645-5.161c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMehSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
