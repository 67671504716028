import * as React from "react";
function SvgKissSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-3.226 9.355c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.714 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29zm5.484 6.29c0 .774-1.157 1.674-2.883 1.774-.343.033-.488-.476-.145-.62l.685-.29c.525-.223.839-.545.839-.868 0-.322-.314-.645-.839-.867l-.685-.29c-.242-.1-.246-.492 0-.597l.685-.29c.525-.222.839-.545.839-.867 0-.323-.314-.645-.839-.867l-.685-.29c-.347-.146-.194-.666.145-.621 1.726.1 2.883 1 2.883 1.774 0 .524-.54 1.1-1.42 1.451.88.367 1.42.944 1.42 1.468zm.968-6.29c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKissSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
