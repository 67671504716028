import * as React from "react";
function SvgChevronDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.223 17.747L2.322 8.846a1.1 1.1 0 010-1.555L3.36 6.253a1.1 1.1 0 011.553-.002L12 13.305l7.087-7.054a1.1 1.1 0 011.553.002l1.038 1.038a1.1 1.1 0 010 1.555l-8.9 8.9a1.1 1.1 0 01-1.555 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
