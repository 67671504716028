import * as React from "react";
function SvgStampSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 22h17.5v-2.5H3.25V22zm15-10h-2.6a1.15 1.15 0 01-1.15-1.15v-.37c0-1.069.347-2.086.838-3.036.356-.687.504-1.5.354-2.36-.265-1.515-1.503-2.761-3.018-3.025a3.753 3.753 0 00-4.424 3.69c0 .554.122 1.077.34 1.547.52 1.127.91 2.31.91 3.551v.003A1.15 1.15 0 018.35 12h-2.6A3.75 3.75 0 002 15.75V17c0 .69.56 1.25 1.25 1.25h17.5c.69 0 1.25-.56 1.25-1.25v-1.25A3.75 3.75 0 0018.25 12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStampSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
