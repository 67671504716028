import * as React from "react";
function SvgCookieSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.936 11.953l-.472-2.98a5.175 5.175 0 00-1.451-2.849l-2.14-2.138a5.156 5.156 0 00-2.84-1.447l-2.995-.475a5.151 5.151 0 00-3.145.498l-2.7 1.376a5.166 5.166 0 00-2.257 2.257l-1.371 2.69a5.18 5.18 0 00-.501 3.162l.472 2.98a5.176 5.176 0 001.451 2.85l2.14 2.138a5.16 5.16 0 002.84 1.447l2.995.474a5.154 5.154 0 003.145-.498l2.7-1.375a5.169 5.169 0 002.258-2.258l1.37-2.69a5.183 5.183 0 00.501-3.162zm-13.06 4.422a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm1.25-6.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm6.25 5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCookieSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
