import * as React from "react";
function SvgSmileSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 6.774a1.289 1.289 0 110 2.58c-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm-6.452 0c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm7.855 6.863A6.007 6.007 0 0112 17.807c-1.794 0-3.48-.79-4.629-2.17-.548-.657.444-1.48.992-.827A4.712 4.712 0 0012 16.512a4.725 4.725 0 003.637-1.702c.54-.653 1.536.17.992.827z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSmileSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
