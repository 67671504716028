import * as React from "react";
function SvgPhoneSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.38 15.919l-1.55-1.894a.75.75 0 00-.875-.216l-3.5 1.5a.75.75 0 00-.434.86l.75 3.25a.75.75 0 00.731.581c3.147 0 6.053-1.012 8.428-2.715l-2.5-1.932c-.34.203-.69.397-1.05.566zm11.425 2.397l-4.96-3.831C18.809 12.013 20 8.902 20 5.5a.75.75 0 00-.581-.731l-3.25-.75a.755.755 0 00-.86.434l-1.5 3.5a.748.748 0 00.216.875l1.894 1.55a11.222 11.222 0 01-1.438 2.275L3.42 4.106a.502.502 0 00-.702.088l-.613.787a.498.498 0 00.088.7l18.387 14.21a.502.502 0 00.703-.088l.613-.79a.495.495 0 00-.09-.697z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPhoneSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
