import * as React from "react";
function SvgUndoAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.982 2a9.966 9.966 0 00-6.89 2.77l-1.44-1.44C3.042 2.72 2 3.151 2 4.013v5.405c0 .535.433.968.968.968h5.405c.863 0 1.294-1.042.685-1.652L7.374 7.052a6.725 6.725 0 014.566-1.826c3.726-.032 6.866 2.983 6.834 6.832-.03 3.652-2.991 6.716-6.774 6.716A6.725 6.725 0 017.54 17.1a.483.483 0 00-.66.022l-1.6 1.6a.485.485 0 00.02.702A9.964 9.964 0 0012 22c5.523 0 10-4.477 10-10 0-5.516-4.502-10.01-10.018-10z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUndoAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
