import styled from "styled-components";
export const StyledCheckListPage = styled.div`
	.CheckListTable {
		/* overflow: hidden; */
		height: calc(100vh - 320px) !important;
	}
	.checkboxItem {
		min-width: 30px;
	}

	.titleControlBox {
		width: 100%;
		position: relative;
		display: flex;

		.flexStartBox {
			display: flex;
			justify-content: flex-start;
			padding: 10px 0;
		}

		.selectionBar {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			> div {
				margin-right: 8px;
				width: 130px;
			}
		}
	}

	.buttonGroup {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 30px;

		.btn {
			margin-left: 8px;
		}
	}

	.countText {
		color: #fff;
		padding: 10px 0;
	}

	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const UiPopupContainer = styled.div`
	.ant-tabs-tab-btn {
		display: flex;
		align-items: center;

		i {
			margin-right: 5px;
		}
	}

	.ant-image-preview-img-wrapper::before {
		height: 25%;
	}

	.ant-image-preview-img {
		margin: auto;
		width: 100%;
		max-width: 70vw;
	}
`;

export const StyledAvailability = styled.div`
	> p {
		display: flex;
		align-items: center;
	}
`;

export const UiLoadingBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2999;
`;
