import * as React from "react";
function SvgHospitalSymbolSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.375 14.688c0 .171-.14.312-.313.312h-1.875a.313.313 0 01-.312-.313V13.25h-3.75v3.438c0 .171-.14.312-.313.312H7.938a.313.313 0 01-.313-.313V7.313c0-.171.14-.312.313-.312h1.875c.171 0 .312.14.312.313v3.437h3.75V7.312c0-.171.14-.312.313-.312h1.874c.172 0 .313.14.313.313v9.375z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHospitalSymbolSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
