import * as React from "react";
function SvgDoveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 8.53V7.432a10.58 10.58 0 01-2.113-4.89c-.082-.527-.742-.734-1.086-.324a10.532 10.532 0 00-1.91 3.379 9.386 9.386 0 005.11 2.933zm4.375-4.031A3.127 3.127 0 0014.5 7.628v2.32A10.609 10.609 0 015.4 3.632c-.215-.489-.906-.516-1.133-.035a10.543 10.543 0 00-1.015 4.519c0 2.766 1.332 5.348 3.324 7.261.515.496 1.02.907 1.52 1.282l-5.622 1.406a.629.629 0 00-.37.953c.679 1.05 2.358 2.832 5.983 2.98.313.012.625-.101.864-.308l2.546-2.192H14.5a6.245 6.245 0 006.25-6.246V7l1.25-2.5h-4.375zm0 3.754A.627.627 0 0117 7.628c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDoveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
