import * as React from "react";
function SvgCommentDollarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.25c-5.523 0-10 3.638-10 8.125 0 1.936.836 3.711 2.227 5.107-.49 1.965-2.122 3.72-2.141 3.74a.312.312 0 00.227.528c2.588 0 4.53-1.24 5.492-2.007 1.277.48 2.695.757 4.195.757 5.523 0 10-3.637 10-8.125 0-4.487-4.477-8.125-10-8.125zm.938 11.814v.686c0 .345-.28.625-.626.625h-.624a.625.625 0 01-.626-.625v-.693a2.853 2.853 0 01-1.24-.447.468.468 0 01-.061-.718l.684-.685c.146-.146.364-.165.551-.079.124.057.26.087.4.087h1.281a.33.33 0 00.091-.646l-1.956-.559c-.869-.248-1.563-.965-1.676-1.862a2.198 2.198 0 011.927-2.462V7c0-.345.28-.625.624-.625h.626c.345 0 .624.28.624.625v.693c.447.052.87.202 1.242.447.243.16.266.512.06.718l-.684.685a.482.482 0 01-.551.079.958.958 0 00-.4-.087h-1.281a.33.33 0 00-.091.646l1.956.559c.869.248 1.563.965 1.676 1.862a2.198 2.198 0 01-1.927 2.462z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentDollarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
