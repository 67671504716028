import * as React from "react";
function SvgVialSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.903 9.09l-7.009-6.992a.335.335 0 00-.47 0L13.007 3.51a.334.334 0 000 .471l.466.463-10.1 10.08c-1.617 1.612-1.88 4.25-.392 5.98.859 1 2.063 1.5 3.267 1.495 1.1 0 2.2-.417 3.038-1.254l10.263-10.238.466.462c.13.13.342.13.471 0l1.417-1.412a.33.33 0 000-.467zm-6.654 2.913H8.706l6.168-6.155 3.27 3.263-2.895 2.892z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVialSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
