import * as React from "react";
function SvgQaToolSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5a2 2 0 00-2 2v11a2 2 0 002 2h16a2 2 0 002-2v-11a2 2 0 00-2-2H4zm4.18 11.23l.68 1.12h2.62l-1.44-1.94c.387-.333.69-.737.91-1.21.22-.48.33-1.003.33-1.57 0-.693-.163-1.317-.49-1.87a3.383 3.383 0 00-1.31-1.29 3.65 3.65 0 00-1.83-.47c-.667 0-1.28.157-1.84.47a3.454 3.454 0 00-1.32 1.29A3.608 3.608 0 004 12.13c0 .693.163 1.317.49 1.87.327.553.767.987 1.32 1.3.56.313 1.173.47 1.84.47.2 0 .377-.013.53-.04zm-1.56-2.45c-.24-.287-.36-.67-.36-1.15 0-.487.12-.87.36-1.15.24-.287.583-.43 1.03-.43.44 0 .78.143 1.02.43.24.28.36.663.36 1.15 0 .48-.12.863-.36 1.15-.24.28-.58.42-1.02.42-.447 0-.79-.14-1.03-.42zm7.29 2.42l.35-1.07h2.36l.35 1.07h2.34l-2.58-7.08h-2.56l-2.59 7.08h2.33zm1.53-4.71l.64 1.97H14.8l.64-1.97z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgQaToolSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
