import * as React from "react";
function SvgEyeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 8.111a3.852 3.852 0 00-1.085.174c.163.286.25.608.252.937a1.945 1.945 0 01-1.945 1.945 1.923 1.923 0 01-.937-.252A3.879 3.879 0 1012 8.111zm9.88 3.382c-1.884-3.674-5.612-6.16-9.88-6.16-4.268 0-7.997 2.488-9.88 6.16a1.123 1.123 0 000 1.014c1.884 3.674 5.612 6.16 9.88 6.16 4.268 0 7.997-2.488 9.88-6.16a1.123 1.123 0 000-1.014zM12 17c-3.425 0-6.566-1.91-8.261-5C5.434 8.91 8.574 7 12 7c3.426 0 6.566 1.91 8.262 5-1.696 3.09-4.836 5-8.262 5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEyeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
