import * as React from "react";
function SvgTransgenderSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.031 2h-3.086a.47.47 0 00-.332.8l.66.66-3.152 3.153A5.626 5.626 0 108.875 16.86v1.078H7.469a.47.47 0 00-.469.47v1.562c0 .258.21.468.469.468h1.406v1.094c0 .258.21.469.469.469h1.562a.47.47 0 00.469-.469v-1.093h1.406a.47.47 0 00.469-.47v-1.562a.47.47 0 00-.469-.468h-1.406v-1.079a5.63 5.63 0 003.512-8.48l3.152-3.152.66.66c.297.297.801.086.801-.332V2.469A.47.47 0 0019.031 2zm-8.906 12.5A3.13 3.13 0 017 11.375a3.13 3.13 0 013.125-3.125 3.13 3.13 0 013.125 3.125 3.13 3.13 0 01-3.125 3.125z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTransgenderSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
