import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { QuestionTypeContent } from "components";
import { getSpecificationQuestionType } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";

/**
 * 規格新增-題型表
 */

export const RuleAddQuestionTypeContainer = ({ addQuestionTypeData = () => {}, uid, data }) => {
	const [chooseQuestionTypeOptions, setChooseQuestionTypeOptions] = useState(data);
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationQuestionType(uid);
				const {
					isSuccess,
					message,
					data: {
						attributeMap,
						selections: { QUES_GROUP: quesGroup },
					},
				} = result;
				let questionTypeOptions = [];
				if (quesGroup) {
					for (const prop in quesGroup) {
						if (!attributeMap[quesGroup[prop].code]) continue;
						questionTypeOptions = [
							...questionTypeOptions,
							{
								title: quesGroup[prop].name,
								code: quesGroup[prop].code,
								options: Object.entries(attributeMap[quesGroup[prop].code]).map(([key, value]) => ({
									label: value.name,
									value: value.code,
									printCode: value.print.code,
								})),
							},
						];
					}
				}
				return {
					isSuccess,
					message,
					questionTypeOptions,
				};
			},
		},
	});

	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	const { questionTypeOptions } = state.context.result || [];

	const questionTypeOptionsCount = useMemo(() => {
		if (!questionTypeOptions) return;
		return questionTypeOptions.map((questionType) => questionType.options.length).reduce((a, b) => a + b);
	}, [questionTypeOptions]);

	const onCheckAllChange = (e) => {
		setChooseQuestionTypeOptions(e.target.checked ? [...questionTypeOptions] : []);
	};

	const clearData = () => {
		setChooseQuestionTypeOptions([]);
	};
	const findQuesGroupOptions = (code) => {
		let result = [];
		for (var i = 0; i < questionTypeOptions.length; i++) {
			if (code === questionTypeOptions[i].code) {
				result = questionTypeOptions[i].options;
				break;
			}
		}
		return result;
	};
	const addData = (title, code, val) => {
		const nextData = chooseQuestionTypeOptions;
		const codeData = findQuesGroupOptions(code);
		let isExist = false;
		for (var i = 0; i < nextData.length; i++) {
			if (nextData[i].code === code) {
				isExist = true;
				if (val.length < 1) {
					nextData.splice(i, 1);
				} else {
					nextData[i] = {
						title,
						code,
						options: val.map((item) => codeData.find((ele) => ele.value === item)),
					};
				}

				break;
			}
		}
		if (isExist) {
			setChooseQuestionTypeOptions([...nextData]);
		} else {
			setChooseQuestionTypeOptions([
				...chooseQuestionTypeOptions,
				{
					title,
					code,
					options: val.map((item) => codeData.find((ele) => ele.value === item)),
				},
			]);
		}
	};

	useEffect(() => {
		if (!uid) return;

		send(FetchEventType.Fetch);
	}, [uid]);
	useEffect(() => {
		addQuestionTypeData && addQuestionTypeData(chooseQuestionTypeOptions);
	}, [chooseQuestionTypeOptions]);

	useEffect(() => {
		if (!chooseQuestionTypeOptions || !questionTypeOptionsCount) return;

		const chooseCount = chooseQuestionTypeOptions
			.map((questionType) => questionType.options.length)
			.reduce((a, b) => a + b, 0);
		setIndeterminate(!!chooseCount && chooseCount < questionTypeOptionsCount);
		setCheckAll(chooseCount === questionTypeOptionsCount);
	}, [chooseQuestionTypeOptions, questionTypeOptionsCount]);

	return (
		<QuestionTypeContent
			questionTypeOptions={questionTypeOptions}
			chooseQuestionTypeOptions={chooseQuestionTypeOptions}
			clearData={clearData}
			addData={addData}
			checkAll={checkAll}
			indeterminate={indeterminate}
			onCheckAllChange={onCheckAllChange}
		/>
	);
};

RuleAddQuestionTypeContainer.propTypes = {
	addQuestionTypeData: PropTypes.func,
	data: PropTypes.object,
	uid: PropTypes.string,
};
