import * as React from "react";
function SvgBombSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.207 5.459L17.176 7.49l1.035 1.035a.934.934 0 010 1.325l-.68.68a8.124 8.124 0 01-7.406 11.469A8.12 8.12 0 012 13.876a8.124 8.124 0 0111.469-7.406l.68-.68a.934.934 0 011.324 0l1.035 1.035 2.031-2.031.668.664zm2.324-1.113h-.937a.47.47 0 00-.469.468c0 .258.21.47.469.47h.937a.47.47 0 00.469-.47.47.47 0 00-.469-.468zm-2.343-2.344a.47.47 0 00-.47.469v.937a.47.47 0 00.937 0v-.937a.47.47 0 00-.468-.47zm1.324 2.148l.664-.664a.47.47 0 10-.664-.664l-.664.664a.47.47 0 10.664.664zm-2.649 0a.47.47 0 10.664-.664l-.664-.664a.47.47 0 10-.664.664l.664.664zm2.649 1.329a.47.47 0 10-.664.664l.664.664a.47.47 0 10.664-.664l-.664-.664zM6.375 12.627c0-1.379 1.121-2.5 2.5-2.5a.627.627 0 00.625-.625.627.627 0 00-.625-.625 3.756 3.756 0 00-3.75 3.75c0 .344.281.625.625.625a.627.627 0 00.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBombSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
