import styled from "styled-components";

export const UiAudiosSearchPage = styled.div`
	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			.dateFilterBox {
				width: 250px;
			}

			> div {
				margin-right: 8px;
				width: 130px;
			}

			> button {
				margin-right: 8px;
			}
		}

		.actionBox {
			display: flex;

			> * {
				margin-left: 8px;
			}
		}

		.btnGroup {
			> button {
				gap: 16px;
			}
		}
	}

	.CheckListTable {
		overflow: hidden;
		height: calc(100vh - 250px) !important;

		> div > div > div {
			padding: 15px 15px;
		}

		.body {
			overflow: auto;
			height: calc(100vh - 364.5px) !important;

			.btnGroup {
				display: flex;
				flex-wrap: wrap;
				gap: 16px;
			}

			.loading {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.tableNoData {
				margin: 24px auto;
				width: 50%;
				font-size: 24px;
				text-align: center;
				color: #fff;
			}
		}
	}
`;
