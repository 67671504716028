import * as React from "react";
function SvgStarOfLifeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.062 15.064L15.752 12l5.31-3.064a.625.625 0 00.229-.854l-1.25-2.165a.626.626 0 00-.855-.229l-5.31 3.064V2.625A.625.625 0 0013.251 2h-2.502a.625.625 0 00-.625.625v6.127L4.814 5.69a.626.626 0 00-.854.229L2.709 8.082a.625.625 0 00.229.854L8.248 12l-5.31 3.064a.625.625 0 00-.229.854l1.25 2.165a.626.626 0 00.855.229l5.31-3.064v6.127c0 .345.28.625.625.625h2.502c.345 0 .625-.28.625-.625v-6.127l5.31 3.063c.3.173.681.07.854-.229l1.251-2.165a.625.625 0 00-.229-.853z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStarOfLifeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
