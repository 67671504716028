import * as React from "react";
function SvgSortDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.682 6.162h16.636c1.496 0 2.244 1.81 1.188 2.866l-8.318 8.317a1.67 1.67 0 01-2.37 0L2.495 9.028c-1.056-1.056-.308-2.866 1.188-2.866z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
