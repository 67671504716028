import * as React from "react";
function SvgRegisteredSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.184 10.043c0 .75-.396 1.147-1.146 1.147h-1.205V8.926h.943c1.155 0 1.408.354 1.408 1.117zM22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-5.669 4.21c-1.884-3.42-1.746-3.17-1.802-3.265.944-.612 1.53-1.733 1.53-3.004 0-2.187-1.27-3.598-4.254-3.598h-2.85a.968.968 0 00-.968.967v9.367c0 .535.434.968.968.968h.91a.968.968 0 00.968-.968v-2.89h1.03l1.78 3.345a.967.967 0 00.854.513h.987a.968.968 0 00.847-1.435z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRegisteredSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
