import { Api } from "common-components";

/**
 * 取得題量統計 excel
 */
export const exportQuestionsStatistics = async (payload) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/report/questionstatistics/export`, payload);
	return response;
};

/**
 * 取得題量統計
 */
export const getQuestionsStatistics = async (payload) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/report/questionstatistics`, payload);
	return response;
};
