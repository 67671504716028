import React, { useState, useMemo, useEffect } from "react";
import { Select, Button } from "common-components";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBankSelection } from "api/bank";
import { Breadcrumb } from "components";
import { setSelectOptions, calStudentYear } from "utils/common";
import { UiQuestionsStatisticsPage } from "./QuestionsStatisticsPage.style";
import { exportQuestionsStatistics } from "api/report";
import { getQuestionsStatistics } from "api/report";
import { Table } from "antd";

/**
 * 題量統計頁面
 */
const breadcrumbList = [
	{
		icon: "equalizer",
		name: "題量統計",
		path: "/questionsStatistics",
	},
];

const initSendFormData = {
	edu: "",
	version: "",
	year: "",
};

export const QuestionsStatisticsPage = () => {
	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getBankSelection();
				const {
					data: { yearMap, eduMap, eduVersion, eduSubject },
				} = result;
				return {
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
				};
			},
		},
	});

	const { eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const [getQuestionsStatisticsData, sendGetQuestionsStatistics] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data } = await getQuestionsStatistics(event.payload);
				return {
					data: data.questionStatisticsDetail,
				};
			},
		},
	});

	const { data = [] } = getQuestionsStatisticsData.context.result || {};

	const [exportQuestionsStatisticsData, sendExportQuestionsStatistics] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data } = await exportQuestionsStatistics(event.payload);
				window.open(data.url);
			},
		},
	});

	const eduVersionOption = useMemo(() => {
		if (!sendFormData.edu || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.edu}`]);
	}, [sendFormData.edu]);

	const columns = useMemo(() => {
		const subjectCount = data.reduce((acc, cur, index) => {
			if (index === 0) {
				acc[cur.subject] = { startIndex: 0, endIndex: 0 };
			} else {
				if (data[index - 1].subject !== cur.subject) {
					acc[cur.subject] = { startIndex: index, endIndex: index };
				} else {
					acc[cur.subject].endIndex = index;
				}
			}
			return acc;
		}, {});

		return [
			{
				title: "科目",
				dataIndex: "subject",
				key: "subject",
				onCell: ({ subject }, index) => {
					const { startIndex, endIndex } = subjectCount[subject];
					let rowSpan = 0;
					if (index === startIndex) {
						rowSpan = endIndex - startIndex + 1;
					}
					return { rowSpan };
				},
				align: "center",
				width: "100px",
			},
			{
				title: "冊次",
				dataIndex: "volume",
				key: "volume",
				align: "center",
			},
			{
				title: <div style={{ whiteSpace: "pre-wrap" }}>{`入庫失敗\n排打格式錯誤\n(轉檔錯誤訊息)`}</div>,
				dataIndex: "convertErrorCount",
				key: "convertErrorCount",
				align: "center",
			},
			{
				title: "入庫成功題量",
				dataIndex: "questionCount",
				key: "questionCount",
				align: "center",
			},
			{
				title: "英聽上架題量",
				dataIndex: "englishListeningCount",
				key: "englishListeningCount",
				align: "center",
			},
			{
				title: "無限命題上架題量",
				dataIndex: "equationDojoCount",
				key: "equationDojoCount",
				align: "center",
			},
			{
				title: "叢書成卷上架題量",
				dataIndex: "collectedEditionCount",
				key: "collectedEditionCount",
				align: "center",
			},
			{
				title: "速測上架題量",
				dataIndex: "quickCount",
				key: "quickCount",
				align: "center",
			},
			{
				title: "紙本上架題量",
				dataIndex: "paperCount",
				key: "paperCount",
				align: "center",
			},
		];
	}, [data]);

	const onSelectChange = (key, value) => {
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
	};

	const handleSubmit = () => {
		if (!Object.values(sendFormData).every(Boolean)) return;
		sendGetQuestionsStatistics(FetchEventType.Fetch, {
			payload: sendFormData,
		});
	};

	const exportFile = () => {
		if (!Object.values(sendFormData).every(Boolean)) return;
		sendExportQuestionsStatistics(FetchEventType.Fetch, {
			payload: sendFormData,
		});
	};

	useEffect(() => {
		sendSelect(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (eduVersionOption.length === 0) return;
		setSendFormData({
			...sendFormData,
			version: eduVersionOption[0].value,
		});
	}, [eduVersionOption]);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			const nowYear = yearMap.find((item) => item.value === `${calStudentYear()}`);
			setSendFormData({
				...sendFormData,
				year: nowYear ? nowYear.value : yearMap[0].value,
				edu: eduMap[0].value,
			});
		}
	}, [stateSelect.value]);

	return (
		<UiQuestionsStatisticsPage>
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="searchToolBar">
				<div className="selectSearchGroup">
					<Select
						placeholder="請選擇學年度"
						options={yearMap}
						value={sendFormData.year}
						onChange={(val) => onSelectChange("year", val)}
					/>
					<Select
						placeholder="請選擇學制"
						options={eduMap}
						value={sendFormData.edu}
						onChange={(val) => onSelectChange("edu", val)}
					/>
					{eduVersionOption.length > 0 && (
						<Select
							placeholder="請選擇版本"
							options={eduVersionOption}
							value={sendFormData.version}
							onChange={(val) => onSelectChange("version", val)}
						/>
					)}
					<Button.IconButton
						variant="blue"
						full={false}
						icon={
							<i className="material-icons" style={{ fontSize: "20px" }}>
								search
							</i>
						}
						loading={getQuestionsStatisticsData.matches(FetchStates.Loading)}
						onClick={handleSubmit}>
						搜尋
					</Button.IconButton>
					<Button.IconButton
						variant="green"
						full={false}
						icon={
							<i className="material-icons" style={{ fontSize: "20px" }}>
								get_app
							</i>
						}
						loading={exportQuestionsStatisticsData.matches(FetchStates.Loading)}
						onClick={exportFile}>
						匯出
					</Button.IconButton>
				</div>
			</div>
			<div className="tableContainer">
				<Table
					columns={columns}
					dataSource={data}
					loading={getQuestionsStatisticsData.matches(FetchStates.Loading)}
					rowKey={(record) => record.volume + record.subject}
					pagination={false}
					bordered
				/>
			</div>
		</UiQuestionsStatisticsPage>
	);
};
