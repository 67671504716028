import * as React from "react";
function SvgNewspaperSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.167 5.333H5.056a.833.833 0 00-.834.834v.277H2.833A.833.833 0 002 7.278v9.444c0 1.074.87 1.945 1.944 1.945h16.39c.92 0 1.666-.747 1.666-1.667V6.167a.833.833 0 00-.833-.834zM3.944 17a.278.278 0 01-.277-.278v-8.61h.555v8.61a.278.278 0 01-.278.278zm8.195-.556H6.86a.417.417 0 01-.417-.416v-.278c0-.23.187-.417.417-.417h5.278c.23 0 .417.187.417.417v.278c0 .23-.187.416-.417.416zm7.222 0h-5.278a.417.417 0 01-.416-.416v-.278c0-.23.186-.417.416-.417h5.278c.23 0 .417.187.417.417v.278c0 .23-.187.416-.417.416zm-7.222-3.333H6.86a.417.417 0 01-.417-.417v-.277c0-.23.187-.417.417-.417h5.278c.23 0 .417.187.417.417v.277c0 .23-.187.417-.417.417zm7.222 0h-5.278a.417.417 0 01-.416-.417v-.277c0-.23.186-.417.416-.417h5.278c.23 0 .417.187.417.417v.277c0 .23-.187.417-.417.417zm0-3.333h-12.5a.417.417 0 01-.417-.417V7.972c0-.23.187-.416.417-.416h12.5c.23 0 .417.186.417.416v1.39c0 .23-.187.416-.417.416z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgNewspaperSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
