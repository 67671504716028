import * as React from "react";
function SvgSatelliteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.633 14.111l-3.777 3.778a1.245 1.245 0 01-1.758 0l-3.138-3.141-.387.387a7.444 7.444 0 01-.21 6.583c-.177.332-.641.374-.907.109l-4.2-4.2-.696.695c.028.102.063.196.063.305a1.25 1.25 0 11-1.25-1.25c.11 0 .203.035.305.063l.695-.696-4.2-4.2c-.265-.266-.223-.726.11-.906a7.443 7.443 0 016.583-.211l.382-.383-3.137-3.141a1.25 1.25 0 010-1.762L9.89 2.363A1.235 1.235 0 0110.77 2c.33 0 .647.13.882.363L14.789 5.5l1.867-1.87a1.313 1.313 0 011.856 0l1.856 1.855a1.314 1.314 0 010 1.856L18.5 9.21l3.137 3.142a1.241 1.241 0 01-.004 1.758zM10.577 9.712L13.46 6.83l-2.692-2.692L7.885 7.02l2.692 2.692zm9.286 3.52l-2.691-2.692-2.884 2.884 2.692 2.691 2.883-2.883z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSatelliteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
