import * as React from "react";
function SvgFolderOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 7h-7.5l-2.134-2.134a1.25 1.25 0 00-.884-.366H3.875C2.839 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875v-8.75C22 7.839 21.16 7 20.125 7zm0 10.625H3.875V6.375h5.473l2.134 2.134c.235.234.553.366.884.366h7.759v8.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFolderOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
