import * as React from "react";
function SvgPenNibSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.336 7.422a2.5 2.5 0 00-1.692 1.615L2 19.969l.574.574 5.863-5.864a1.856 1.856 0 01-.187-.804 1.875 1.875 0 111.875 1.875c-.29 0-.56-.07-.804-.188l-5.864 5.864.574.574 10.932-3.644a2.5 2.5 0 001.615-1.692l1.672-5.914-5-5-5.914 1.672zM21.45 4.897L19.103 2.55a1.876 1.876 0 00-2.653 0L14.24 4.76l5 5 2.21-2.209a1.876 1.876 0 000-2.653z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPenNibSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
