import * as React from "react";
function SvgUserShieldSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.447 12.472l-3.6-1.406a.969.969 0 00-.694 0l-3.6 1.406a.845.845 0 00-.553.778c0 3.488 2.147 5.9 4.153 6.684.3.116.563.05.694 0C19.45 19.31 22 17.141 22 13.25a.845.845 0 00-.553-.778zM17.5 18.45v-5.91l2.984 1.166c-.175 2.722-1.903 4.232-2.984 4.744zM9 12a4 4 0 100-8 4 4 0 000 8zm3 1.25c0-.078.025-.15.034-.225-.078-.003-.153-.025-.234-.025h-.522a5.445 5.445 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h11c.213 0 .416-.047.6-.125-1.688-1.34-3.1-3.647-3.1-6.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserShieldSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
