import * as React from "react";
function SvgWindSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.12 12H2.626a.627.627 0 00-.625.625v1.25c0 .343.281.624.625.624h5.554c.621 0 1.203.426 1.305 1.04a1.251 1.251 0 01-2.422.605c-.082-.246-.336-.395-.593-.395H5.187c-.382 0-.691.344-.62.72a3.759 3.759 0 003.679 3.03 3.754 3.754 0 003.718-4.242C11.726 13.367 10.024 12 8.121 12zm-5.495-1.25h13.124a3.757 3.757 0 003.664-4.559 3.737 3.737 0 00-2.855-2.855 3.758 3.758 0 00-4.488 2.949c-.074.375.238.715.617.715h1.281c.262 0 .512-.149.594-.395a1.257 1.257 0 011.187-.855c.758 0 1.364.68 1.235 1.46-.102.614-.68 1.04-1.305 1.04H2.625A.627.627 0 002 8.875v1.25c0 .343.281.625.625.625zm15 1.25h-6.106a4.937 4.937 0 011.555 2.5h4.55a1.875 1.875 0 010 3.75c-.699 0-1.3-.387-1.625-.954-.113-.195-.34-.297-.566-.297h-1.32c-.426 0-.742.422-.598.825.695 1.976 2.754 3.312 5.055 2.824 1.609-.34 2.933-1.625 3.308-3.23A4.384 4.384 0 0017.624 12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWindSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
