import * as React from "react";
function SvgAlignLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.573 16.286h11.711a.57.57 0 00.573-.573V14a.572.572 0 00-.573-.572H2.573A.57.57 0 002 14v1.712a.572.572 0 00.573.573zm0-11.429h11.711a.572.572 0 00.573-.573V2.573A.573.573 0 0014.284 2H2.573A.572.572 0 002 2.573v1.711a.572.572 0 00.573.573zm18.713 2.857H2.714A.714.714 0 002 8.43v1.428a.714.714 0 00.714.714h18.572A.714.714 0 0022 9.857V8.43a.714.714 0 00-.714-.715zm0 11.429H2.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h18.572a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAlignLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
