import * as React from "react";
function SvgChevronCircleUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12C2 6.476 6.476 2 12 2s10 4.476 10 10-4.476 10-10 10S2 17.524 2 12zm9.315-4.593L5.85 12.871a.964.964 0 000 1.367l.685.685a.964.964 0 001.367 0L12 10.827l4.097 4.096a.964.964 0 001.367 0l.685-.685a.964.964 0 000-1.367l-5.464-5.464a.969.969 0 00-1.37 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronCircleUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
