import * as React from "react";
function SvgToggleOffSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.333 5.333H8.667a6.667 6.667 0 100 13.334h6.666a6.667 6.667 0 000-13.334zM4.223 12a4.442 4.442 0 014.444-4.445A4.442 4.442 0 0113.11 12a4.442 4.442 0 01-4.444 4.444A4.442 4.442 0 014.222 12zm11.11 4.444h-1.698a6.664 6.664 0 000-8.889h1.698A4.442 4.442 0 0119.778 12a4.442 4.442 0 01-4.445 4.444z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgToggleOffSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
