import * as React from "react";
function SvgBookReaderSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.75 5.75a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0zm-4.626 5.668c-2.317-1.419-6.07-1.809-7.96-1.916C2.529 9.466 2 9.95 2 10.56v8.703c0 .56.453 1.027 1.035 1.057 1.705.09 5.155.417 7.54 1.618.366.184.8-.067.8-.464v-9.608a.52.52 0 00-.25-.448zm9.712-1.916c-1.89.107-5.643.497-7.96 1.916a.53.53 0 00-.25.454v9.601c0 .398.434.65.802.465 2.384-1.2 5.832-1.528 7.537-1.617.582-.03 1.035-.497 1.035-1.057V10.56c0-.61-.53-1.094-1.164-1.058z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBookReaderSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
