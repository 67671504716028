import * as React from "react";
function SvgRestroomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.158 7.79a2.107 2.107 0 002.105-2.106 2.107 2.107 0 00-2.105-2.105 2.107 2.107 0 00-2.105 2.105c0 1.161.944 2.105 2.105 2.105zm12.632 0a2.107 2.107 0 002.105-2.106 2.107 2.107 0 00-2.105-2.105 2.107 2.107 0 00-2.105 2.105c0 1.161.944 2.105 2.105 2.105zm4.188 7.45l-1.5-6.112c-.109-.444-.51-.756-.98-.796a3.14 3.14 0 01-1.711.51 3.14 3.14 0 01-1.71-.51c-.471.04-.873.352-.981.796l-1.5 6.112c-.115.497.28.97.812.97h1.803v3.422c0 .437.352.79.79.79h1.579c.437 0 .79-.353.79-.79V16.21h1.802c.533 0 .927-.474.806-.97zM12 3.58h-1.052a.528.528 0 00-.527.526v15.79c0 .29.237.526.527.526H12c.29 0 .527-.236.527-.526V4.105A.528.528 0 0012 3.579zm-5.128 4.75a3.126 3.126 0 01-1.714.513c-.632 0-1.22-.19-1.714-.513C2.634 8.398 2 9.069 2 9.895v4.473c0 .438.352.79.79.79h.263v4.474c0 .437.352.79.79.79h2.63c.438 0 .79-.353.79-.79v-4.474h.264c.437 0 .79-.352.79-.79V9.895c0-.826-.636-1.497-1.445-1.566z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRestroomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
