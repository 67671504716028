import * as React from "react";
function SvgDragonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.572 11.993L8 11l-2.853 2.146a.5.5 0 00.354.854h6.96A7.73 7.73 0 0112 11.393v-1.32L8.258 7.334a2 2 0 00-2.408.143l-3.668 3.636c-.387.322-.108.95.39.88zm17.403 1.066l-3.146-1.572a1.5 1.5 0 01-.83-1.342V9h2l.879.707a1 1 0 00.707.293h.968a1 1 0 00.894-.553l.447-.894a1 1 0 00-.094-1.048L19.47 4.4a.999.999 0 00-.8-.399h-7.42a.249.249 0 00-.176.425L13 6l-1.862.776a.25.25 0 000 .447L13 8v3.394A6.738 6.738 0 0016 17c-6.112.213-10.768 1.281-13.566 1.903A.555.555 0 002.555 20h15.596c1.978 0 3.738-1.486 3.844-3.461a3.647 3.647 0 00-2.02-3.48zM17.287 6.07l1.426.357a.74.74 0 01-.754.57c-.405-.022-.808-.391-.672-.927z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDragonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
