import * as React from "react";
function SvgFileMedicalAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 7.313V2H6.687a.935.935 0 00-.937.938V12H3.562a.313.313 0 00-.312.313v.624c0 .172.14.313.313.313h5.503c.118 0 .227.066.282.172l.777 1.555 2.219-4.442a.312.312 0 01.558 0l1.356 2.715H17c.344 0 .625.281.625.625A.627.627 0 0117 14.5h-3.512l-.863-1.727-2.219 4.442a.312.312 0 01-.558 0L8.488 14.5H5.75v6.563c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm5.977-1.211l-3.825-3.829A.937.937 0 0015.988 2h-.238v5h5v-.238a.935.935 0 00-.273-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileMedicalAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
