import * as React from "react";
function SvgChevronCircleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2c5.524 0 10 4.476 10 10s-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2zm4.593 9.315L11.129 5.85a.964.964 0 00-1.367 0l-.685.685a.964.964 0 000 1.367L13.173 12l-4.096 4.097a.964.964 0 000 1.367l.685.685a.964.964 0 001.367 0l5.464-5.464a.969.969 0 000-1.37z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronCircleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
