import styled from "styled-components";

export const StyledFileCheck = styled.div`
	span {
		position: unset !important;
		top: unset !important;
		bottom: unset !important;
		right: unset !important;
		left: unset !important;
	}

	.fileSource {
		a {
			color: unset !important;
		}

		img {
			width: unset !important;
			max-width: 100% !important;
			height: unset !important;
		}
	}

	.content {
		display: flex;
		height: 85%;
	}

	.content > div {
		margin: 0 10px;
		padding: 10px 10px;
		width: 50%;
		background-color: #fff;
		border-radius: 10px;
	}

	.content .fileSource {
		overflow-y: auto;
	}

	.content #checkOption {
		overflow-y: auto;
		opacity: 0;
		transition: 1s;
	}

	.content .option {
		margin: 10px 0;
		margin-left: 30px;
	}

	.content #checkOption .questionOptionBox .option {
		display: flex;
	}

	.content #checkOption .questionOptionBox .option > div {
		margin-right: 10px;
	}

	.content #checkOption #questionOptionDetail .subQuestion {
		margin: 15px 0;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 10px;
	}

	.hr {
		margin: 10px 0;
		width: 100%;
		height: 1px;
		background-color: #eee;
	}

	.hide {
		opacity: 0;
		visibility: hidden;
	}
`;
