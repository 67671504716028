import { useState } from "react";
import { Modal as AntdModal, Select } from "antd";
import { Upload, Button } from "common-components";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";

import { UiUploadAudioModal } from "./AudiosImportPage.style";
import { audioTypeList, audioTypeMap } from "./AudiosImportPage";
import { importCommonAudios, importQuestionAudios, importQuestionTypeAudios } from "api/audios";
import openNotificationWithIcon from "utils/hooks/useNotification";

const commonAudioMap = {
	city: 0,
	school: 1,
	year: 2,
	semester: 3,
	grade: 4,
	examType: 5,
	reminder: 6,
	music: 7,
	majorSectionNumber: 8,
	minorSectionNumber: 9,
};

export const commonAudioList = [
	{
		label: "縣市",
		value: commonAudioMap.city,
	},
	{
		label: "學校",
		value: commonAudioMap.school,
	},
	{
		label: "學年度",
		value: commonAudioMap.year,
	},
	{
		label: "學期",
		value: commonAudioMap.semester,
	},
	{
		label: "年級",
		value: commonAudioMap.grade,
	},
	{
		label: "考試別(評量)",
		value: commonAudioMap.examType,
	},
	{
		label: "提醒(開始/結束)",
		value: commonAudioMap.reminder,
	},
	{
		label: "音樂(前置、結束音樂)",
		value: commonAudioMap.music,
	},
	{
		label: "大題號",
		value: commonAudioMap.majorSectionNumber,
	},
	{
		label: "小題號",
		value: commonAudioMap.minorSectionNumber,
	},
];

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;

const UploadAudioFile = ({ visible, toggleUploadPopupVisible, handleGetAudioList }) => {
	const [audioType, setAudioType] = useState(audioTypeMap.commonAudios);
	const [commonAudioType, setCommonAudioType] = useState(commonAudioMap.city);
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const { upload } = useFirebaseStorage();

	const changeFileList = (value) => {
		setFiles(value);
	};

	const resetUploadData = () => {
		setFiles([]);
		setAudioType(audioTypeMap.commonAudios);
		setCommonAudioType(commonAudioMap.city);
	};

	// 上傳音檔
	const [, sendImportAudios] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const uploadPayload = await Promise.all(
					event.payload.files.map(async (file) => {
						const uid = uuidv4();
						const res = await upload(`convert/${uid}_${file.name}`, file);
						return {
							fileUrl: res.url,
							fileName: file.name,
						};
					}),
				);

				// 如果是 定義音檔 需加入 音檔用途
				const payload =
					audioType === audioTypeMap.commonAudios
						? {
								audioFiles: uploadPayload,
								commonAudioType: commonAudioType,
						  }
						: uploadPayload;

				const url = (payload) => {
					switch (audioType) {
						case audioTypeMap.commonAudios:
							return importCommonAudios(payload);
						case audioTypeMap.questionAudios:
							return importQuestionAudios(payload);
						case audioTypeMap.questionTypeAudios:
							return importQuestionTypeAudios(payload);
						default:
							return null;
					}
				};

				const response = await url(payload);

				setIsLoading(false);
				resetUploadData();

				if (response.error === 200) {
					openNotificationWithIcon("success", "上傳成功");
					handleGetAudioList();
				} else {
					openNotificationWithIcon("success", "上傳失敗");
					toggleUploadPopupVisible();
				}

				setFiles([]);
				toggleUploadPopupVisible();
			},
		},
	});

	const handleConfirmUpload = () => {
		setIsLoading(true);
		sendImportAudios(FetchEventType.Fetch, {
			payload: {
				files: files,
			},
		});
	};

	return (
		<AntdModal
			visible={visible}
			width={576}
			title="上傳音檔"
			onOk={handleConfirmUpload}
			onCancel={toggleUploadPopupVisible}
			footer={
				<UiButtons>
					<Button
						variant="white"
						full={false}
						onClick={() => {
							toggleUploadPopupVisible();
							resetUploadData();
						}}
						Key="cancel"
						loading={isLoading}>
						取消
					</Button>
					<Button variant="blue" full={false} onClick={handleConfirmUpload} Key="ok" loading={isLoading}>
						開始上傳
					</Button>
				</UiButtons>
			}>
			<UiUploadAudioModal>
				<div className="content">
					<div>音檔類型</div>
					<Select
						style={{ width: "100%" }}
						onChange={(value) => {
							setAudioType(value);
							setFiles([]);
						}}
						options={audioTypeList}
						value={audioType}
					/>
				</div>

				{audioType === audioTypeMap.commonAudios && (
					<div className="content">
						<div>音檔用途</div>
						<Select
							style={{ width: "100%" }}
							value={commonAudioType}
							onChange={(value) => {
								setCommonAudioType(value);
								setFiles([]);
							}}
							options={commonAudioList}
						/>
					</div>
				)}

				<Upload
					fileList={files}
					multiple={true}
					onChange={changeFileList}
					prompt={<>僅限上傳壓縮檔(.rar/.zip)及音檔(.mp3)</>}
					acceptFileType=".rar,.mp3,.zip"
				/>
			</UiUploadAudioModal>
		</AntdModal>
	);
};

export default UploadAudioFile;
