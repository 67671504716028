import * as React from "react";
function SvgHighlighterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 20.235l3.674 1.177 1.303-1.303-2.465-2.465L2 20.234zm4.581-8.824a1.346 1.346 0 00-.396 1.4l.48 1.575-1.873 1.873 3.538 3.538 1.87-1.87 1.57.48c.506.155 1.054 0 1.404-.395l1.306-1.531-6.372-6.373L6.58 11.41zM21.41 5.503l-2.324-2.324a2.018 2.018 0 00-2.762-.086L9.005 9.34l6.242 6.242 6.248-7.318c.705-.8.667-2.009-.086-2.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHighlighterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
