import * as React from "react";
function SvgMinusCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM6.677 13.613a.485.485 0 01-.483-.484v-2.258c0-.266.217-.484.483-.484h10.646c.266 0 .483.218.483.484v2.258a.485.485 0 01-.483.484H6.677z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMinusCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
