import * as React from "react";
function SvgUndoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.42 10.903H2.476A.476.476 0 012 10.427V2.482c0-.262.213-.475.476-.475h1.903c.263 0 .476.213.476.475V5.58a9.81 9.81 0 017.383-3.256A9.811 9.811 0 0122 12.18c-.012 5.422-4.41 9.813-9.835 9.813a9.799 9.799 0 01-6.603-2.546.477.477 0 01-.018-.69l1.347-1.348a.475.475 0 01.65-.02 6.953 6.953 0 004.624 1.75 6.976 6.976 0 006.98-6.98 6.976 6.976 0 00-6.98-6.98 6.967 6.967 0 00-5.642 2.869h3.897c.263 0 .476.213.476.475v1.904a.476.476 0 01-.476.476z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUndoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
