import * as React from "react";
function SvgQuidditchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.016 10.775l2.709 3.413s-.519 3.2-2.394 4.69C8.46 20.37 2 19.944 2 19.944s.119-.722.344-1.731L5.3 14.707c.125-.147-.028-.363-.206-.297l-1.888.69c.45-1.303 1.022-2.5 1.707-3.047 1.871-1.493 5.103-1.278 5.103-1.278zm7.437 4.22a2.497 2.497 0 000 4.993 2.495 2.495 0 002.494-2.497 2.495 2.495 0 00-2.494-2.497zm4.438-10.026l-.62-.781a.5.5 0 00-.7-.081l-7.265 5.559-1.065-1.344a.344.344 0 00-.582.069l-.79 1.706 2.71 3.413 1.837-.388a.343.343 0 00.197-.55l-1.066-1.34 7.266-5.56a.5.5 0 00.078-.703z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgQuidditchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
