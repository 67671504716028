import * as React from "react";
function SvgFighterJetSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 11l-4-.5-1.5-.5h-.75L9.099 5.375h1.234c.365 0 .667-.082.667-.188 0-.105-.302-.187-.667-.187H6.75v.375h.5V10.5h-1.5L3.667 8H2.583l-.333.333V10.5h.25v.5H4v.083l-2 .25v1.334l2 .25V13H2.5v.5h-.25v2.167l.333.333h1.084l2.083-2.5h1.5v5.125h-.5V19h3.583c.365 0 .667-.082.667-.188 0-.105-.302-.187-.667-.187H9.1L12.75 14h.75l1.5-.5 4-.5c3-.667 3-.83 3-1 0-.17 0-.333-3-1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFighterJetSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
