import * as React from "react";
function SvgTableTennisSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.383 13.58a8.425 8.425 0 00-1.844-9.116 8.38 8.38 0 00-11.869 0L5.483 6.655l8.26 8.26c1.8-2.425 5.136-3.022 7.64-1.335zm-8.51 3.113L4.263 8.088c-1.066 1.77-.847 4.101.676 5.629l1.347 1.35-4.022 3.493a.77.77 0 00-.04 1.124l2.086 2.09a.765.765 0 001.121-.04L8.92 17.7l1.394 1.395a4.545 4.545 0 002.746 1.3 5.57 5.57 0 01-.43-2.152c-.004-.539.098-1.054.242-1.55zm5.377-2.195a3.75 3.75 0 000 7.499 3.75 3.75 0 000-7.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTableTennisSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
