import * as React from "react";
function SvgFastForwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 4.968V19.03a.47.47 0 01-.469.47H19.97a.47.47 0 01-.469-.47v-5.933l-6.7 6.113c-.804.672-2.05.11-2.05-.96v-5.153l-6.7 6.113c-.804.672-2.05.11-2.05-.96V5.75c0-1.071 1.246-1.63 2.05-.962l6.7 6.07V5.75c0-1.07 1.246-1.629 2.05-.96l6.7 6.07V4.967a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFastForwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
