import * as React from "react";
function SvgPassportSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.313 8.875H9.84c.058-1.056.256-2.006.555-2.75a3.744 3.744 0 00-2.082 2.75zm0 1.25c.207 1.22 1 2.233 2.082 2.75-.3-.744-.497-1.694-.555-2.75H8.313zM12 13.199c.3-.291.811-1.345.915-3.074h-1.83c.104 1.729.615 2.783.915 3.074zm-.915-4.324h1.83c-.103-1.729-.614-2.783-.915-3.074-.3.291-.811 1.345-.915 3.074zm2.52 4a3.744 3.744 0 002.082-2.75H14.16c-.058 1.056-.255 2.006-.555 2.75zM19.5 2H5.75a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5H19.5c.69 0 1.25-.56 1.25-1.25V3.25c0-.69-.56-1.25-1.25-1.25zm-3.125 16.25h-8.75A.627.627 0 017 17.625c0-.344.281-.625.625-.625h8.75c.344 0 .625.281.625.625a.627.627 0 01-.625.625zM12 14.5a5 5 0 110-10 5 5 0 010 10zm1.605-8.375c.3.744.497 1.694.555 2.75h1.527a3.744 3.744 0 00-2.082-2.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPassportSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
