import * as React from "react";
function SvgAsteriskSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.68 15.05L15.125 12l5.555-3.05a.937.937 0 00.36-1.291l-.76-1.318a.938.938 0 00-1.298-.333l-5.42 3.286.136-6.336A.937.937 0 0012.761 2h-1.522a.937.937 0 00-.937.958l.136 6.336-5.42-3.286a.938.938 0 00-1.298.333l-.76 1.318a.938.938 0 00.36 1.29L8.875 12 3.32 15.05a.937.937 0 00-.36 1.291l.76 1.318a.938.938 0 001.298.333l5.42-3.286-.136 6.336a.937.937 0 00.937.958h1.522a.938.938 0 00.937-.958l-.136-6.336 5.42 3.286a.938.938 0 001.298-.333l.76-1.318a.938.938 0 00-.36-1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAsteriskSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
