import * as React from "react";
function SvgRepublicanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 10c0-2.763-2.238-5-5-5H7c-2.763 0-5 2.237-5 5v2h17v-2zM7.51 9.325l-.62.603.147.853a.187.187 0 01-.271.197L6 10.575l-.766.403a.188.188 0 01-.271-.197l.146-.853-.618-.603a.186.186 0 01.103-.32l.856-.124.381-.775a.186.186 0 01.335 0l.38.775.857.125c.156.022.216.21.106.319zm4.5 0l-.62.603.147.853a.187.187 0 01-.271.197l-.766-.403-.766.403a.188.188 0 01-.271-.197l.146-.853-.618-.603a.186.186 0 01.103-.32l.856-.124.381-.775a.186.186 0 01.335 0l.38.775.857.125c.156.022.216.21.106.319zm4.5 0l-.62.603.148.853a.187.187 0 01-.272.197L15 10.575l-.766.403a.188.188 0 01-.271-.197l.146-.853-.618-.603a.186.186 0 01.103-.32l.856-.124.381-.775a.186.186 0 01.335 0l.38.775.857.125c.156.022.216.21.106.319zM21.5 14h-1c-.275 0-.5.225-.5.5v2c0 .274-.225.5-.5.5a.501.501 0 01-.5-.5V13H2v5.5c0 .274.225.5.5.5h3c.275 0 .5-.226.5-.5V16h6v2.5c0 .274.225.5.5.5h3c.275 0 .5-.226.5-.5V15h1v1.353c0 1.306.938 2.503 2.238 2.634A2.504 2.504 0 0022 16.5v-2c0-.275-.225-.5-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRepublicanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
