import * as React from "react";
function SvgFilmSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.063 4.5h-.313v.781a.47.47 0 01-.469.469H18.72a.47.47 0 01-.469-.469V4.5H5.75v.781a.47.47 0 01-.469.469H3.72a.47.47 0 01-.469-.469V4.5h-.313A.935.935 0 002 5.438v13.125c0 .519.418.937.938.937h.312v-.781a.47.47 0 01.469-.469H5.28a.47.47 0 01.469.469v.781h12.5v-.781a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v.781h.313c.519 0 .937-.418.937-.938V5.438a.935.935 0 00-.938-.937zM5.75 16.531a.47.47 0 01-.469.469H3.72a.47.47 0 01-.469-.469V14.97a.47.47 0 01.469-.469H5.28a.47.47 0 01.469.469v1.562zm0-3.75a.47.47 0 01-.469.469H3.72a.47.47 0 01-.469-.469V11.22a.47.47 0 01.469-.469H5.28a.47.47 0 01.469.469v1.562zm0-3.75a.47.47 0 01-.469.469H3.72a.47.47 0 01-.469-.469V7.47A.47.47 0 013.719 7H5.28a.47.47 0 01.469.469V9.03zm10.625 8.125a.47.47 0 01-.469.469H8.094a.47.47 0 01-.469-.469v-3.75a.47.47 0 01.469-.469h7.812a.47.47 0 01.469.47v3.75zm0-6.562a.47.47 0 01-.469.469H8.094a.47.47 0 01-.469-.47v-3.75a.47.47 0 01.469-.468h7.812a.47.47 0 01.469.469v3.75zm4.375 5.937a.47.47 0 01-.469.469H18.72a.47.47 0 01-.469-.469V14.97a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562zm0-3.75a.47.47 0 01-.469.469H18.72a.47.47 0 01-.469-.469V11.22a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v1.562zm0-3.75a.47.47 0 01-.469.469H18.72a.47.47 0 01-.469-.469V7.47A.47.47 0 0118.719 7h1.562a.47.47 0 01.469.469V9.03z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFilmSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
