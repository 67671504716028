import * as React from "react";
function SvgExclamationCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.355 22.122c5.466 0 9.995-4.528 9.995-9.994 0-5.467-4.539-9.995-10.006-9.995-5.466 0-9.994 4.528-9.994 9.995 0 5.466 4.538 9.994 10.005 9.994zm-.01-8.22c-.527 0-.826-.3-.836-.846l-.134-5.168c-.02-.536.382-.928.96-.928.566 0 .99.392.969.939l-.145 5.157c-.01.557-.31.846-.815.846zm0 3.3c-.61 0-1.135-.474-1.135-1.072 0-.598.516-1.073 1.134-1.073.62 0 1.125.464 1.125 1.073 0 .608-.516 1.072-1.124 1.072z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExclamationCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
