import * as React from "react";
function SvgCommentsDollarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.444 9.778c0-3.069-3.233-5.556-7.222-5.556C5.233 4.222 2 6.71 2 9.778c0 1.19.49 2.29 1.318 3.194-.463 1.05-1.23 1.88-1.242 1.893a.278.278 0 00.202.468c1.27 0 2.324-.425 3.08-.867a8.814 8.814 0 003.864.867c3.99 0 7.222-2.487 7.222-5.555zM8.667 13.11v-.566a1.988 1.988 0 01-1.09-.394.278.278 0 01-.02-.421l.409-.39a.285.285 0 01.351-.025c.135.084.287.13.445.13h.977c.225 0 .41-.206.41-.459 0-.206-.126-.388-.305-.442l-1.563-.468c-.645-.194-1.096-.814-1.096-1.507 0-.851.661-1.543 1.481-1.565v-.56c0-.153.125-.277.278-.277H9.5c.153 0 .277.124.277.277v.566c.392.02.774.157 1.09.394a.278.278 0 01.02.422l-.409.389a.285.285 0 01-.351.025.833.833 0 00-.445-.129h-.976c-.226 0-.41.206-.41.458 0 .207.125.389.304.442l1.563.469c.645.194 1.096.813 1.096 1.506 0 .852-.661 1.543-1.481 1.565v.56a.278.278 0 01-.278.278h-.556a.278.278 0 01-.277-.278zm12.014 4.305c.828-.904 1.319-2.004 1.319-3.194 0-2.324-1.857-4.312-4.49-5.141.03.229.046.461.046.697 0 3.676-3.739 6.666-8.334 6.666-.374 0-.74-.026-1.102-.065 1.095 1.997 3.664 3.399 6.658 3.399 1.423 0 2.745-.321 3.863-.868.757.442 1.811.868 3.081.868a.277.277 0 00.201-.47c-.01-.01-.778-.841-1.242-1.892z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentsDollarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
