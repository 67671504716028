import * as React from "react";
function SvgCubesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.086 11.773l-3.773-1.414V6.121c0-.586-.364-1.11-.915-1.316L12.492 3.34a1.39 1.39 0 00-.988 0L7.598 4.805c-.551.207-.914.73-.914 1.316v4.238L2.91 11.773c-.547.207-.91.73-.91 1.317v4.3c0 .532.3 1.02.777 1.258l3.907 1.954c.394.199.863.199 1.257 0L12 18.57l4.059 2.032c.394.199.863.199 1.257 0l3.907-1.954c.476-.238.777-.726.777-1.257V13.09c0-.586-.363-1.11-.914-1.317zm-5.102-1.382l-3.32 1.246V8.973l3.32-1.446v2.864zM8.016 6.066L12 4.574l3.984 1.492v.024L12 7.707 8.016 6.09v-.024zm3.28 11.371l-3.32 1.66v-3.09l3.32-1.515v2.945zm0-4.375L7.314 14.68l-3.985-1.617v-.024l3.985-1.492 3.984 1.492v.024zm9.376 4.376l-3.32 1.66v-3.09l3.32-1.516v2.945zm0-4.375l-3.985 1.617-3.984-1.617v-.024l3.985-1.492 3.984 1.492v.024z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCubesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
