import * as React from "react";
function SvgUniversitySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 6.667v.666a.333.333 0 01-.333.334h-1v.5a.5.5 0 01-.5.5H3.833a.5.5 0 01-.5-.5v-.5h-1A.333.333 0 012 7.333v-.666a.333.333 0 01.206-.308l9.666-3.667a.333.333 0 01.255 0l9.667 3.667a.333.333 0 01.206.308zm-1 12.666H3a1 1 0 00-1 1V21a.333.333 0 00.333.333h19.334A.333.333 0 0022 21v-.667a1 1 0 00-1-1zm-15.667-10v8h-1.5a.5.5 0 00-.5.5v.834h17.334v-.834a.5.5 0 00-.5-.5h-1.5v-8H16v8h-2.667v-8h-2.666v8H8v-8H5.333z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUniversitySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
