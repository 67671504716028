import * as React from "react";
function SvgGlassWhiskeySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 3.252H3.25a1.25 1.25 0 00-1.238 1.426L4.2 18.603a2.5 2.5 0 002.476 2.144H17.34c1.243 0 2.301-.914 2.477-2.144l2.172-13.925a1.25 1.25 0 00-1.239-1.425zm-1.461 2.5l-1.172 7.5H5.89l-1.18-7.5H19.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGlassWhiskeySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
