import * as React from "react";
function SvgExclamationTriangleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.775 18.39c.64 1.11-.164 2.499-1.444 2.499H3.67c-1.283 0-2.084-1.39-1.444-2.5l8.331-14.445c.642-1.112 2.248-1.11 2.888 0l8.33 14.445zM12 15.402a1.597 1.597 0 100 3.194 1.597 1.597 0 000-3.194zm-1.516-5.741l.257 4.722a.417.417 0 00.416.394h1.686a.417.417 0 00.416-.394l.257-4.722a.417.417 0 00-.416-.44h-2.2c-.24 0-.43.2-.416.44z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExclamationTriangleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
