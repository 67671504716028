import * as React from "react";
function SvgListAltOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25H3.875C2.839 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.036-.84-1.875-1.875-1.875zm-.234 15.625H4.109a.235.235 0 01-.234-.234V5.359a.234.234 0 01.234-.234h15.782a.235.235 0 01.234.234v13.282a.235.235 0 01-.234.234zm-1.641-3.594v.938c0 .259-.21.468-.469.468H9.97a.469.469 0 01-.469-.468v-.938c0-.259.21-.469.469-.469h7.812c.26 0 .469.21.469.47zm0-3.75v.938c0 .259-.21.469-.469.469H9.97a.469.469 0 01-.469-.47v-.937c0-.259.21-.469.469-.469h7.812c.26 0 .469.21.469.47zm0-3.75v.938c0 .259-.21.469-.469.469H9.97a.469.469 0 01-.469-.47v-.937c0-.259.21-.468.469-.468h7.812c.26 0 .469.21.469.468zm-9.844.469a1.406 1.406 0 11-2.812 0 1.406 1.406 0 012.812 0zm0 3.75a1.406 1.406 0 11-2.812 0 1.406 1.406 0 012.812 0zm0 3.75a1.406 1.406 0 11-2.812 0 1.406 1.406 0 012.812 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgListAltOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
