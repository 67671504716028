import * as React from "react";
function SvgChevronCircleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22C6.476 22 2 17.524 2 12S6.476 2 12 2s10 4.476 10 10-4.476 10-10 10zm-4.593-9.315l5.464 5.464a.964.964 0 001.367 0l.685-.685a.964.964 0 000-1.367L10.827 12l4.096-4.097a.964.964 0 000-1.367l-.685-.685a.964.964 0 00-1.367 0l-5.464 5.464a.969.969 0 000 1.37z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronCircleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
