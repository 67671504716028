import * as React from "react";
function SvgCloudSunSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.975 14.177c.006-.06.025-.116.025-.175 0-1.104-.897-2-2-2a2 2 0 00-1.066.312 3.5 3.5 0 00-3.184-2.063 3.5 3.5 0 00-3.5 3.5c0 .094.022.182.028.272a2.992 2.992 0 00.222 5.979H19a3 3 0 003-3 2.99 2.99 0 00-2.025-2.825zm-13.45-.707a3.499 3.499 0 114.95-4.947c.303.304.528.654.697 1.022.306-.115.628-.187.96-.234l.93-2.778a.474.474 0 00-.6-.6l-2.743.915-1.297-2.587a.473.473 0 00-.847 0L7.281 6.848l-2.747-.915a.476.476 0 00-.6.6l.916 2.744-2.587 1.296a.473.473 0 000 .847l2.587 1.294-.916 2.744a.474.474 0 00.6.6l2.378-.79c.191-.388.438-.742.738-1.048a3.447 3.447 0 01-1.125-.75zm-.15-2.475c0 1.275.916 2.338 2.122 2.572.25-.147.51-.275.787-.366a4.504 4.504 0 012.107-3.28A2.63 2.63 0 009 8.372a2.626 2.626 0 00-2.625 2.622z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudSunSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
