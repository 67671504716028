import * as React from "react";
function SvgTrademarkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.143 7H2.375A.376.376 0 002 7.375v1.347c0 .206.169.375.375.375h2.66v7.528c0 .206.168.375.374.375h1.697a.376.376 0 00.375-.375V9.097h2.66a.376.376 0 00.374-.375V7.375A.371.371 0 0010.143 7zM22 16.593l-.75-9.249A.375.375 0 0020.874 7H18.83a.374.374 0 00-.353.253l-1.368 3.972c-.225.644-.504 1.65-.504 1.65h-.028s-.278-1.006-.503-1.65l-1.368-3.972A.377.377 0 0014.352 7H12.31a.38.38 0 00-.375.344l-.763 9.25a.374.374 0 00.375.406h1.703c.197 0 .36-.153.375-.35l.285-4.153c.056-.756 0-1.678 0-1.678h.028s.334 1.05.56 1.678l.959 2.647a.375.375 0 00.353.246h1.572c.159 0 .3-.1.353-.246l.959-2.647c.225-.628.56-1.678.56-1.678h.027s-.056.922 0 1.678l.285 4.153c.012.197.178.35.375.35h1.684c.219 0 .39-.188.375-.407z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTrademarkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
