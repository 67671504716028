import * as React from "react";
function SvgQrcodeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 10.571h8.571V2H2v8.571zm2.857-5.714h2.857v2.857H4.857V4.857zM13.43 2v8.571H22V2h-8.571zm5.714 5.714h-2.857V4.857h2.857v2.857zM2 22h8.571v-8.571H2V22zm2.857-5.714h2.857v2.857H4.857v-2.857zm15.714-2.857H22v5.714h-4.286v-1.429h-1.428V22h-2.857v-8.571h4.285v1.428h2.857V13.43zm0 7.142H22V22h-1.429v-1.429zm-2.857 0h1.429V22h-1.429v-1.429z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgQrcodeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
