import * as React from "react";
function SvgCheeseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 13.25v6.25a1.25 1.25 0 001.25 1.25h17.5A1.25 1.25 0 0022 19.5v-6.25H2zm11.712-10a1.25 1.25 0 00-.825.274L2 12h20c0-4.683-3.672-8.507-8.288-8.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCheeseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
