import * as React from "react";
function SvgStepForwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.143 2.536v18.928a.537.537 0 01-.536.536h-2.143a.537.537 0 01-.535-.536v-7.875L7.2 21.67c-.92.764-2.344.125-2.344-1.098V3.43c0-1.223 1.424-1.862 2.344-1.098l8.728 8.03V2.537c0-.295.24-.536.535-.536h2.143c.295 0 .536.241.536.536z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStepForwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
