import * as React from "react";
function SvgPowerOffSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.714 4.147a9.822 9.822 0 014.127 8.012c0 5.428-4.396 9.83-9.821 9.841-5.417.012-9.853-4.405-9.861-9.825A9.842 9.842 0 016.278 4.15a.955.955 0 011.389.305l.627 1.115a.952.952 0 01-.262 1.23 6.634 6.634 0 00-2.699 5.354A6.662 6.662 0 0012 18.825c3.635 0 6.69-2.944 6.667-6.71a6.67 6.67 0 00-2.703-5.317.947.947 0 01-.258-1.227l.627-1.115a.951.951 0 011.381-.31zm-4.127 8.33V2.951A.95.95 0 0012.635 2h-1.27a.95.95 0 00-.952.952v9.524a.95.95 0 00.952.953h1.27a.95.95 0 00.952-.953z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPowerOffSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
