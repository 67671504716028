import * as React from "react";
function SvgPenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.357 5.642l5 5L7.5 21.503l-4.458.492a.938.938 0 01-1.035-1.036l.496-4.461L13.357 5.642zm8.094-.744L19.103 2.55a1.876 1.876 0 00-2.653 0l-2.209 2.209 5 5 2.21-2.209a1.876 1.876 0 000-2.652z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
