import * as React from "react";
function SvgCarrotSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.648 8.118c-2.06-1.004-4.473-.41-5.868 1.281l2.156 2.156a.624.624 0 01-.883.883l-1.96-1.96L2.087 20.74a.886.886 0 000 .77.878.878 0 001.172.402l5.22-2.547-1.923-1.922a.62.62 0 010-.883.627.627 0 01.883 0l2.227 2.226 3.985-1.945a4.855 4.855 0 00-.004-8.723zm3.598-1.364c.761-1.59.367-3.469-1.184-4.754-1.965 1.629-2.051 4.2-.309 5.934l.313.313C17.8 9.989 20.375 9.903 22 7.937c-1.285-1.55-3.165-1.945-4.755-1.183z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCarrotSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
