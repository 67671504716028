import * as React from "react";
function SvgArrowRightOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12.179c0-.294-.113-.566-.34-.78l-7.07-7.06c-.248-.237-.497-.339-.769-.339-.577 0-1.018.419-1.018.995a.98.98 0 00.294.724l2.399 2.444 3.427 3.133-2.545-.158H3.029c-.6 0-1.029.43-1.029 1.04 0 .6.43 1.03 1.03 1.03h13.348l2.556-.158-3.439 3.133-2.398 2.444a1.026 1.026 0 00-.294.724c0 .577.441.995 1.018.995.272 0 .51-.102.747-.317l7.093-7.081c.226-.215.339-.487.339-.77z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowRightOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
