import * as React from "react";
function SvgHandSpockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.956 7.675l-2.668 11.204A4.062 4.062 0 0115.337 22h-4.975a5.312 5.312 0 01-3.64-1.443l-4.23-3.982a1.562 1.562 0 012.14-2.275L7 16.528c0-1.065-.153-2.123-.456-3.144L4.876 7.757a1.563 1.563 0 012.997-.888l1.451 4.898a.326.326 0 00.627-.173L7.986 3.952a1.562 1.562 0 113.026-.78l2.191 8.523a.407.407 0 00.793-.02l1.472-7.176a1.563 1.563 0 012.004-1.175c.776.246 1.206 1.08 1.042 1.878l-1.322 6.444a.295.295 0 00.576.128l1.149-4.823a1.562 1.562 0 113.04.724z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandSpockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
