import * as React from "react";
function SvgPercentageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.69 7.69a3.334 3.334 0 10-4.715-4.714A3.334 3.334 0 007.69 7.69zm13.333 8.62a3.334 3.334 0 10-4.714 4.714 3.334 3.334 0 004.714-4.714zm-.1-12.054l-1.179-1.178a1.666 1.666 0 00-2.357 0l-14.31 14.31a1.666 1.666 0 000 2.356l1.179 1.179a1.666 1.666 0 002.357 0l14.31-14.31a1.666 1.666 0 000-2.357z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPercentageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
