import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { SourceContent } from "components";
import { getSpecificationSource } from "api/specification";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";

/**
 * 規格新增-出處表
 */

export const RuleAddSourceContainer = ({ addSourceData = () => {}, uid, data = [] }) => {
	const [checkedList, setCheckedList] = useState(data);
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const result = await getSpecificationSource(uid);
				const {
					isSuccess,
					message,
					data: { attributeMap },
				} = result;
				return {
					isSuccess,
					message,
					sourceOptions:
						attributeMap?.SOURCE.map((item) => ({
							label: item.name,
							value: item.uid,
						})) || [],
				};
			},
		},
	});
	const { sourceOptions } = state.context.result || [];

	const chooseSourceOptions = useMemo(() => {
		if (!sourceOptions) return [];
		return sourceOptions.filter((item) => checkedList.findIndex((ele) => item.value === ele) !== -1) || [];
	}, [checkedList, sourceOptions]);

	const sourceOptionsValue = useMemo(() => {
		if (!sourceOptions) return;
		return sourceOptions.map((item) => item.value);
	}, [sourceOptions]);

	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	const onCheckAllChange = (e) => {
		setCheckedList(e.target.checked ? [...sourceOptionsValue] : []);
	};

	const addData = (val) => {
		setCheckedList(val);
	};

	const clearData = () => {
		setCheckedList([]);
	};
	useEffect(() => {
		if (!uid) return;
		send(FetchEventType.Fetch);
	}, [uid]);

	useEffect(() => {
		if (!checkedList) return;
		addSourceData && addSourceData(checkedList);
	}, [checkedList]);

	useEffect(() => {
		if (!sourceOptionsValue || !checkedList) return;
		setCheckAll(sourceOptionsValue.length === checkedList.length);
		setIndeterminate(!!checkedList.length && checkedList.length < sourceOptionsValue.length);
	}, [sourceOptionsValue, checkedList]);

	return (
		<SourceContent
			sourceOptions={sourceOptions}
			chooseSourceOptions={chooseSourceOptions}
			checkedList={checkedList}
			clearData={clearData}
			addData={addData}
			checkAll={checkAll}
			indeterminate={indeterminate}
			onCheckAllChange={onCheckAllChange}
		/>
	);
};

RuleAddSourceContainer.propTypes = {
	addSourceData: PropTypes.func,
	uid: PropTypes.string,
};
