import * as React from "react";
function SvgArchiveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 19.5c0 .691.559 1.25 1.25 1.25h15c.691 0 1.25-.559 1.25-1.25V8.25H3.25V19.5zm6.25-8.281a.47.47 0 01.469-.469h4.062a.47.47 0 01.469.469v.312a.47.47 0 01-.469.469H9.97a.47.47 0 01-.469-.469v-.312zM20.75 3.25H3.25C2.559 3.25 2 3.809 2 4.5v1.875c0 .344.281.625.625.625h18.75A.627.627 0 0022 6.375V4.5c0-.691-.559-1.25-1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArchiveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
