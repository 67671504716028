import * as React from "react";
function SvgBezierCurveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.5 5h-3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V6a1 1 0 00-1-1zm-5 1.75H5.852A1.998 1.998 0 002 7.5a2 2 0 003.852.75h2.491A7.257 7.257 0 004.92 13h1.544a5.74 5.74 0 012.22-3.174A1.978 1.978 0 018.5 9V6.75zM7 14H4a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1zm13-8.5c-.839 0-1.555.518-1.852 1.25H15.5V9c0 .295-.068.574-.184.826A5.74 5.74 0 0117.536 13h1.544a7.257 7.257 0 00-3.423-4.75h2.491A1.998 1.998 0 0022 7.5a2 2 0 00-2-2zm0 8.5h-3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBezierCurveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
