import * as React from "react";
function SvgBellSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.807 18.316l-2.832-2.19c.006-.043.025-.082.025-.126a.975.975 0 00-.269-.679c-.604-.649-1.734-1.624-1.734-4.821 0-2.428-1.702-4.372-3.998-4.85V5a1 1 0 10-1.998 0v.651a5.02 5.02 0 00-3.05 1.956L3.42 4.105a.5.5 0 00-.702.088l-.614.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087l.614-.79a.5.5 0 00-.088-.701zM6.913 11.86c-.269 2.123-1.137 2.916-1.644 3.46A.976.976 0 005 16a1 1 0 001.003 1h7.56l-6.65-5.14zM12 20a2 2 0 002-2h-4a2 2 0 002 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBellSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
