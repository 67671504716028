import * as React from "react";
function SvgMagnetSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.409 7.783h-5.94A.469.469 0 012 7.314V4.19a1.406 1.406 0 011.406-1.406H7.47A1.406 1.406 0 018.875 4.19v3.125a.464.464 0 01-.466.47zM22 7.314V4.186a1.406 1.406 0 00-1.406-1.407H16.53a1.406 1.406 0 00-1.406 1.407V7.31a.469.469 0 00.469.468h5.937A.465.465 0 0022 7.314zm-6.406 1.72a.469.469 0 00-.469.468v2.031c0 5.004-6.25 4.996-6.25 0V9.502a.469.469 0 00-.469-.469H2.473a.469.469 0 00-.47.473c.005.836.024 1.574 0 2.082 0 5.883 5.32 9.633 10.03 9.633 4.71 0 9.961-3.75 9.961-9.637-.023-.5-.008-1.29 0-2.078a.468.468 0 00-.469-.473h-5.931z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMagnetSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
