import * as React from "react";
function SvgRadiationSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.233 11.106h6.113c.366 0 .677-.31.653-.678a9.99 9.99 0 00-4.133-7.426.646.646 0 00-.915.194l-3.242 5.176a3.234 3.234 0 011.524 2.734zm-1.524 2.73c-.495.31-1.08.5-1.709.5s-1.21-.19-1.71-.5l-3.241 5.18a.641.641 0 00.226.903A9.937 9.937 0 0012 21.113c1.71 0 3.318-.436 4.725-1.194a.642.642 0 00.226-.903l-3.242-5.18zM12 13.04a1.936 1.936 0 10-.001-3.871A1.936 1.936 0 0012 13.04zm-9.346-1.935h6.113c0-1.153.612-2.157 1.524-2.73L7.048 3.2c-.193-.31-.617-.412-.915-.194A9.99 9.99 0 002 10.432c-.024.363.287.674.653.674z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRadiationSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
