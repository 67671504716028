import * as React from "react";
function SvgWifiWeakSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.013 15.153c.502 0 .84-.33.85-.85.027-3.072.064-6.354.091-9.389 0-.54-.43-.914-.95-.914-.53 0-.951.375-.951.914.027 3.035.073 6.317.1 9.389.019.52.348.85.86.85zM9.763 5.124c-3.144.54-5.987 2.103-7.678 4.178a.384.384 0 00.036.521l1.198 1.225a.4.4 0 00.603-.018C5.54 9.31 7.561 8.223 9.8 7.812l-.036-2.688zm4.462.01l-.027 2.678c2.24.393 4.241 1.508 5.896 3.227.164.174.411.165.585-.01l1.197-1.206c.165-.155.156-.365.028-.52-1.71-2.058-4.507-3.667-7.68-4.17zm-.046 5.146l-.036 2.807a6.113 6.113 0 012.349 1.554c.174.183.402.174.594-.01l1.335-1.316c.155-.155.164-.356.036-.512-.96-1.152-2.495-2.12-4.278-2.523zm-4.351 0c-1.783.43-3.31 1.344-4.288 2.523-.128.156-.128.348.037.512l1.344 1.335c.192.192.439.165.621-.037a5.993 5.993 0 012.322-1.545l-.036-2.788zm2.176 9.124a1.38 1.38 0 001.38-1.38c0-.76-.613-1.372-1.38-1.372-.76 0-1.38.612-1.38 1.371s.62 1.38 1.38 1.38z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWifiWeakSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
