import * as React from "react";
function SvgTruckMonsterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 11H21V9a1 1 0 00-1-1h-2.3l-2.6-3.25A2 2 0 0013.54 4H10a1 1 0 00-1 1v3H3.5a.5.5 0 00-.5.5V11h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.522C3.936 11.792 5.37 11 7 11c1.63 0 3.065.792 3.977 2h2.046c.913-1.208 2.347-2 3.977-2s3.065.792 3.978 2h.522a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM11 8V6h2.539l1.6 2H11zm9.5 7h-.163a3.442 3.442 0 00-.27-.653l.115-.114a.5.5 0 000-.708l-.707-.707a.5.5 0 00-.708 0l-.114.115a3.465 3.465 0 00-.653-.27V12.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.162c-.23.07-.446.159-.653.27l-.114-.114a.5.5 0 00-.708 0l-.707.707a.5.5 0 000 .707l.115.115a3.465 3.465 0 00-.27.653H13.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.162c.07.23.159.446.27.653l-.114.114a.5.5 0 000 .708l.707.707a.5.5 0 00.707 0l.115-.115c.207.112.424.202.653.27v.163a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.163c.23-.068.447-.158.653-.27l.115.115a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.708l-.114-.114c.112-.208.202-.427.27-.653h.163a.5.5 0 00.5-.5v-1a.5.5 0 00-.501-.5zM17 17.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM10.5 15h-.162a3.442 3.442 0 00-.27-.653l.114-.114a.5.5 0 000-.708l-.707-.707a.5.5 0 00-.707 0l-.115.115a3.464 3.464 0 00-.653-.27V12.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.162c-.23.07-.446.159-.653.27l-.115-.114a.5.5 0 00-.707 0l-.707.707a.5.5 0 000 .707l.115.115a3.462 3.462 0 00-.27.653H3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.163c.068.229.158.446.27.652l-.115.115a.5.5 0 000 .707l.707.708a.5.5 0 00.707 0l.115-.115c.207.111.424.201.653.27v.163a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.163c.23-.069.447-.158.653-.27l.115.115a.5.5 0 00.707 0l.707-.708a.5.5 0 000-.707l-.114-.114c.112-.208.203-.427.27-.653h.163a.5.5 0 00.5-.5v-1a.5.5 0 00-.501-.5zM7 17.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTruckMonsterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
