import * as React from "react";
function SvgHryvniaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 11.286c.395 0 .714-.32.714-.715V9.143a.714.714 0 00-.714-.714h-1.869a4.49 4.49 0 00-.184-4.2C16.971 2.797 15.353 2 13.694 2h-3.518c-1.086 0-2.137.38-2.97 1.075l-.526.438a1.071 1.071 0 00-.137 1.51l.915 1.097a1.071 1.071 0 001.509.137l.524-.436c.192-.161.436-.25.687-.25h3.733a.948.948 0 01.642 1.643l-1.316 1.215H4.143a.714.714 0 00-.714.714v1.428c0 .395.32.715.714.715h5.999l-1.548 1.428H4.143a.714.714 0 00-.714.715v1.428c0 .395.32.714.714.714h1.869a4.49 4.49 0 00.184 4.2C7.029 21.202 8.647 22 10.306 22h3.518c1.086 0 2.137-.38 2.97-1.075l.526-.438a1.071 1.071 0 00.137-1.51l-.915-1.097a1.071 1.071 0 00-1.509-.137l-.524.437c-.193.16-.436.249-.686.249h-3.734a.948.948 0 01-.642-1.642l1.316-1.216h9.094c.395 0 .714-.32.714-.714V13.43a.714.714 0 00-.714-.715H13.86l1.547-1.428h4.451z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHryvniaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
