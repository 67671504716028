import * as React from "react";
function SvgSchoolSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 10.969v8.5c0 .281.219.5.5.5H5v-10H3c-.563 0-1 .469-1 1zm11.25-1.5h-.75v-1.25a.269.269 0 00-.25-.25h-.5a.247.247 0 00-.25.25v2c0 .156.094.25.25.25h1.5c.125 0 .25-.094.25-.25v-.5a.269.269 0 00-.25-.25zM17.531 7.5l-5-3.344C12.375 4.031 12.187 4 12 4c-.219 0-.406.031-.563.156l-5 3.344c-.28.156-.437.469-.437.813v11.656h4v-4.5c0-.25.219-.5.5-.5h3c.25 0 .5.25.5.5v4.5h4V8.312a.932.932 0 00-.469-.812zM12 11.969a2.468 2.468 0 01-2.5-2.5c0-1.375 1.094-2.5 2.5-2.5 1.375 0 2.5 1.125 2.5 2.5 0 1.406-1.125 2.5-2.5 2.5zm9-2h-2v10h2.5c.25 0 .5-.219.5-.5v-8.5c0-.531-.469-1-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSchoolSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
