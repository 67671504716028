import * as React from "react";
function SvgFileWordSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM15.48 12h.934c.3 0 .523.277.457.574l-1.484 6.563a.465.465 0 01-.457.363h-1.485a.468.468 0 01-.453-.355c-1.008-4.043-.812-3.172-1-4.317h-.02c-.042.559-.093.68-1 4.317a.468.468 0 01-.452.355H9.07a.468.468 0 01-.457-.367L7.137 12.57a.467.467 0 01.457-.57h.957c.222 0 .418.156.46.379.61 3.047.786 4.277.821 4.773.063-.398.285-1.277 1.148-4.793a.465.465 0 01.457-.355h1.137c.219 0 .406.148.457.36.938 3.921 1.125 4.843 1.156 5.054-.007-.438-.101-.695.844-5.047a.453.453 0 01.45-.371zm4.02-5.238V7h-5V2h.238c.25 0 .489.098.664.273l3.825 3.829a.935.935 0 01.273.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileWordSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
