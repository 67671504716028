import * as React from "react";
function SvgUserLockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9 12a4 4 0 100-8 4 4 0 000 8zm3 2c.002-.334.09-.662.253-.953-.15-.016-.297-.047-.453-.047h-.522a5.44 5.44 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h8.778A1.986 1.986 0 0112 19v-5zm9-1h-1v-2.5a2.5 2.5 0 00-5 0V13h-1a1 1 0 00-1 1v5a1 1 0 001 1h7a1 1 0 001-1v-5a1 1 0 00-1-1zm-3.5 4.5a1 1 0 110-2 1 1 0 010 2zm1-4.5h-2v-2.5a1 1 0 012 0V13z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserLockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
