import * as React from "react";
function SvgTabletsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7 10c-2.534 0-4.61 1.828-4.997 4.21-.025.15.103.29.26.29h9.478c.156 0 .284-.14.259-.29C11.61 11.828 9.534 10 7 10zm4.738 5.5H2.263c-.157 0-.285.14-.26.29C2.391 18.173 4.466 20 7 20s4.61-1.828 4.997-4.21c.025-.15-.103-.29-.26-.29zm8.806-10.044c-1.766-1.765-4.506-1.918-6.466-.5a.255.255 0 00-.025.385l6.603 6.606c.11.11.297.1.385-.025 1.422-1.96 1.268-4.7-.497-6.466zm-7.2.597c-.11-.109-.297-.1-.385.025-1.418 1.96-1.265 4.7.497 6.466 1.766 1.766 4.507 1.919 6.466.497a.255.255 0 00.025-.385l-6.603-6.603z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTabletsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
