import * as React from "react";
function SvgAngleDoubleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.985 11.147l6.822-6.822a1.199 1.199 0 011.7 0l1.134 1.134a1.199 1.199 0 010 1.7L16.811 12l4.835 4.836a1.199 1.199 0 010 1.7l-1.133 1.14a1.199 1.199 0 01-1.701 0l-6.822-6.823a1.2 1.2 0 01-.005-1.706zm-9.632 1.706l6.822 6.822a1.199 1.199 0 001.701 0l1.134-1.134a1.199 1.199 0 000-1.7L7.179 12l4.836-4.836a1.199 1.199 0 000-1.7l-1.134-1.14a1.199 1.199 0 00-1.7 0l-6.823 6.823a1.2 1.2 0 00-.005 1.706z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngleDoubleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
