import * as React from "react";
function SvgDrumstickBiteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.076 3.936a6.618 6.618 0 00-9.354 0C9.337 5.32 8.255 6.999 8.255 9.499v3.352l-1.586 1.585c-.38.379-.938.433-1.437.235a2.356 2.356 0 00-2.539 3.855c.597.596 1.426.764 2.192.589-.176.765-.007 1.595.59 2.19a2.357 2.357 0 003.859-2.538c-.199-.497-.146-1.054.234-1.435l1.586-1.584h3.355a7.34 7.34 0 002.417-.39c-1.548-1.715-1.556-4.306.041-5.901 1.343-1.342 3.389-1.541 5.028-.656a6.569 6.569 0 00-1.919-4.865z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDrumstickBiteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
