import * as React from "react";
function SvgPlaneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.667 9.778h-3.969l-3.65-6.387a.556.556 0 00-.482-.28H8.292a.555.555 0 00-.534.708L9.46 9.778H5.89l-1.5-2a.556.556 0 00-.445-.222H2.556a.555.555 0 00-.54.69L3.112 12l-1.094 3.754c-.088.35.177.69.539.69h1.388c.175 0 .34-.082.445-.222l1.5-2H9.46l-1.702 5.959a.556.556 0 00.534.708h2.274c.2 0 .384-.107.483-.28l3.65-6.387h3.968C19.894 14.222 22 13.227 22 12c0-1.227-2.106-2.222-3.333-2.222z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlaneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
