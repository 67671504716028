import * as React from "react";
function SvgHSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 4.143v15.714C22 21.041 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.959 2.96 2 4.143 2h15.714C21.041 2 22 2.96 22 4.143zm-5 2.143h-1.429a.714.714 0 00-.714.714v3.571H9.143V7a.714.714 0 00-.714-.714H7A.714.714 0 006.286 7v10c0 .395.32.714.714.714h1.429c.394 0 .714-.32.714-.714v-3.571h5.714V17c0 .395.32.714.714.714H17c.395 0 .714-.32.714-.714V7A.714.714 0 0017 6.286z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
