import * as React from "react";
function SvgHeadphonesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.25c-5.527 0-10 4.473-10 10v1.875a1.25 1.25 0 00.691 1.118l.562.28a4.375 4.375 0 004.372 4.227h.938c.517 0 .937-.42.937-.938v-6.875A.937.937 0 008.562 12h-.937c-1.224 0-2.33.503-3.125 1.314v-.064c0-4.136 3.364-7.5 7.5-7.5s7.5 3.364 7.5 7.5v.064A4.361 4.361 0 0016.375 12h-.938a.937.937 0 00-.937.938v6.874c0 .518.42.938.938.938h.937a4.375 4.375 0 004.372-4.226l.562-.281A1.25 1.25 0 0022 15.125V13.25c0-5.527-4.473-10-10-10z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHeadphonesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
