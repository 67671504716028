import * as React from "react";
function SvgBroadcastTowerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.717 10H7.77a.492.492 0 00.464-.662 3.911 3.911 0 010-2.676A.492.492 0 007.771 6H6.717c-.22 0-.42.14-.482.35A5.946 5.946 0 006 8c0 .566.082 1.118.236 1.649.06.21.262.351.48.351zM4.81 4.73A.495.495 0 004.374 4H3.27a.506.506 0 00-.46.29A8.927 8.927 0 002 8c0 .773.098 2.135.834 3.714a.503.503 0 00.457.286h1.088a.495.495 0 00.436-.73C3.26 8.353 4.293 5.703 4.81 4.73zm16.38-.44a.505.505 0 00-.46-.29h-1.108a.494.494 0 00-.438.727c.571 1.071 1.513 3.731.009 6.538a.496.496 0 00.434.735h1.101c.196 0 .38-.112.46-.29A8.91 8.91 0 0022 8a8.916 8.916 0 00-.81-3.71zM17.282 6H16.23a.492.492 0 00-.464.662 3.912 3.912 0 010 2.676.492.492 0 00.464.662h1.054c.22 0 .42-.14.482-.351C17.918 9.119 18 8.566 18 8c0-.566-.082-1.118-.236-1.649a.498.498 0 00-.48-.351zM13.65 9.129a2 2 0 10-3.298 0l-4.078 9.794a.5.5 0 00.27.654l.921.384a.5.5 0 00.654-.269L9.655 16h4.69l1.537 3.692a.5.5 0 00.654.27l.922-.385a.5.5 0 00.269-.654l-4.078-9.794zM10.489 14L12 10.37 13.512 14h-3.024z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBroadcastTowerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
