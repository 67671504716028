import * as React from "react";
function SvgFutbolOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.508 8.911C20.138 4.685 16.218 2 12.004 2a9.97 9.97 0 00-3.093.492C3.661 4.198.786 9.839 2.491 15.089 3.864 19.314 7.783 22 11.997 22a9.971 9.971 0 003.093-.492c5.25-1.706 8.125-7.347 6.42-12.597zm-3.004 7.81l-2.105.259-1.762-2.456.984-3.032 2.867-.891 1.568 1.467a8.018 8.018 0 01-.875 3.597c-.19.375-.431.718-.677 1.057zm0-9.491l-.42 2.141-2.85.887-2.589-1.875V5.407l1.911-1.056a8.038 8.038 0 013.948 2.879zM9.456 4.355l1.899 1.052v2.976l-2.589 1.875-2.847-.887-.407-2.117a7.978 7.978 0 013.944-2.9zM7.605 16.98l-2.141-.262a8.069 8.069 0 01-1.524-4.65l1.572-1.467 2.867.895.98 2.996-1.754 2.488zm1.943 2.701l-.903-1.94 1.758-2.487h3.17l1.786 2.488-.903 1.94c-.25.072-2.323.822-4.908 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFutbolOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
