import * as React from "react";
function SvgFileContractSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM7 4.813c0-.173.14-.313.313-.313h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 5.437v-.625zm0 2.5C7 7.14 7.14 7 7.313 7h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 7.937v-.625zM14.532 17h1.843a.625.625 0 110 1.25h-1.843a1.68 1.68 0 01-1.51-.932.433.433 0 00-.397-.255.426.426 0 00-.391.242l-.3.6a.625.625 0 01-1.152-.083l-.657-1.97-.414 1.244a1.686 1.686 0 01-1.602 1.154h-.484a.625.625 0 110-1.25h.484c.189 0 .356-.12.416-.3l.71-2.134a.938.938 0 011.78 0l.542 1.627c.772-.633 2.111-.379 2.578.553a.446.446 0 00.397.254zm4.695-10.898l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileContractSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
