import * as React from "react";
function SvgCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={12} cy={12} r={9} stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
