import * as React from "react";
function SvgDharmachakraSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.336 10.791l-.673.043a8.69 8.69 0 00-1.713-4.135l.506-.446a.625.625 0 00.028-.91l-.827-.827a.625.625 0 00-.91.028l-.447.506a8.699 8.699 0 00-4.133-1.713l.041-.673A.625.625 0 0012.585 2h-1.17a.625.625 0 00-.624.664l.042.673A8.687 8.687 0 006.7 5.05l-.447-.506a.625.625 0 00-.91-.028l-.827.826a.625.625 0 00.028.91l.506.447a8.698 8.698 0 00-1.713 4.135l-.673-.043a.625.625 0 00-.664.624v1.17c0 .36.304.646.664.624l.673-.043A8.687 8.687 0 005.05 17.3l-.506.447a.625.625 0 00-.028.91l.827.827a.625.625 0 00.91-.028l.447-.506a8.698 8.698 0 004.134 1.713l-.043.673a.625.625 0 00.624.664h1.17c.36 0 .646-.304.623-.664l-.042-.673A8.69 8.69 0 0017.3 18.95l.447.506c.239.27.656.283.91.028l.827-.827a.625.625 0 00-.028-.91l-.506-.447a8.698 8.698 0 001.713-4.134l.673.043a.625.625 0 00.664-.624v-1.17a.626.626 0 00-.664-.623zm-8.327-4.939c.97.16 1.86.534 2.626 1.085L13.97 8.824a3.707 3.707 0 00-1.118-.467l.157-2.505zm-2.019 0l.157 2.505a3.721 3.721 0 00-1.118.467L8.364 6.937a6.191 6.191 0 012.626-1.085zM6.937 8.365l1.887 1.664a3.723 3.723 0 00-.467 1.118l-2.505-.156a6.19 6.19 0 011.085-2.626zm-1.085 4.644l2.505-.156c.094.402.254.777.466 1.118l-1.886 1.665a6.191 6.191 0 01-1.085-2.627zm5.138 5.139a6.19 6.19 0 01-2.626-1.085l1.665-1.887c.34.213.716.373 1.118.467l-.157 2.505zM12 13.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm1.01 4.898l-.158-2.505a3.71 3.71 0 001.118-.467l1.665 1.887a6.19 6.19 0 01-2.626 1.085zm4.053-2.513l-1.887-1.665c.213-.34.373-.715.467-1.118l2.505.157a6.187 6.187 0 01-1.085 2.626zm-1.42-4.488a3.711 3.711 0 00-.467-1.118l1.887-1.664a6.191 6.191 0 011.085 2.626l-2.505.156z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDharmachakraSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
