import * as React from "react";
function SvgLuggageCartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9 14h1V7H9a1 1 0 00-1 1v5a1 1 0 001 1zm11-1V8a1 1 0 00-1-1h-1v7h1a1 1 0 001-1zm1.5 3H6V4.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H4v11.5a.5.5 0 00.5.5h2.592a1.484 1.484 0 00-.092.5 1.5 1.5 0 003 0c0-.176-.036-.343-.092-.5h6.184a1.483 1.483 0 00-.092.5 1.5 1.5 0 003 0c0-.176-.036-.343-.092-.5H21.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM17 7V5.5A1.5 1.5 0 0015.5 4h-3A1.5 1.5 0 0011 5.5V14h6V7zm-1.5 0h-3V5.5h3V7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLuggageCartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
