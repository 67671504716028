import * as React from "react";
function SvgHeadingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20 5.333v13.334h1.333a.667.667 0 01.667.666v1.334a.667.667 0 01-.667.666h-6.666a.667.667 0 01-.667-.666v-1.334a.666.666 0 01.667-.666H16v-5.334H8v5.334h1.333a.667.667 0 01.667.666v1.334a.667.667 0 01-.667.666H2.667A.667.667 0 012 20.667v-1.334a.667.667 0 01.667-.666H4V5.333H2.667A.667.667 0 012 4.667V3.333a.667.667 0 01.667-.666h6.666a.667.667 0 01.667.666v1.334a.667.667 0 01-.667.666H8v5.334h8V5.333h-1.333A.667.667 0 0114 4.667V3.333a.666.666 0 01.667-.666h6.666a.667.667 0 01.667.666v1.334a.666.666 0 01-.667.666H20z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHeadingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
