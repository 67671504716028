import * as React from "react";
function SvgUploadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.563 16.998h-3.126a.935.935 0 01-.937-.937V9.499H6.074a.78.78 0 01-.55-1.333l5.94-5.945a.757.757 0 011.067 0l5.946 5.945a.78.78 0 01-.551 1.333H14.5v6.562c0 .52-.418.938-.938.938zM22 16.686v4.375c0 .52-.418.938-.938.938H2.938A.935.935 0 012 21.06v-4.375c0-.52.418-.938.938-.938H8.25v.313c0 1.207.98 2.188 2.188 2.188h3.124c1.208 0 2.188-.98 2.188-2.188v-.313h5.313c.519 0 .937.418.937.938zm-4.844 3.438a.783.783 0 00-.781-.782.783.783 0 00-.781.782c0 .43.351.78.781.78.43 0 .781-.35.781-.78zm2.5 0a.783.783 0 00-.781-.782.783.783 0 00-.781.782c0 .43.351.78.781.78.43 0 .781-.35.781-.78z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUploadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
