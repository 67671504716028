import * as React from "react";
function SvgHandMiddleFingerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.747 14.388a1.458 1.458 0 00-1.105-1.414l-1.392-.349v-1.937c0-.447-.379-.831-.904-.92l-1.5-.25c-.697-.115-1.346.329-1.346.92v1.25a.312.312 0 01-.625 0V3.954c0-1.027-.791-1.922-1.817-1.953a1.875 1.875 0 00-1.933 1.875v7.812a.312.312 0 01-.625 0v-1.25c0-.592-.65-1.035-1.346-.92l-1.198.2c-.703.118-1.206.63-1.206 1.226v5.744a.313.313 0 11-.625 0v-2.969l-1.068.586a1.46 1.46 0 00-.807 1.305v2.87a1.457 1.457 0 00.427 1.03l1.208 1.208A4.375 4.375 0 007.98 22h8.398a4.375 4.375 0 004.373-4.375l-.003-3.237z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandMiddleFingerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
