import * as React from "react";
function SvgScrewdriverSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 2l-5 3.75v2.424l-3.243 3.244c.265.166.518.354.744.58.227.227.415.48.581.745L15.826 9.5h2.424l3.75-5L19.5 2zM7 12.882l-4.574 4.574a1.456 1.456 0 000 2.059l2.059 2.058c.569.57 1.49.57 2.059 0L11.117 17A2.911 2.911 0 107 12.882z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgScrewdriverSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
