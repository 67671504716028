import * as React from "react";
function SvgCutSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.414 12l7.429-7.43a.535.535 0 000-.758 3.75 3.75 0 00-5.303 0L11.383 8.97l-1.11-1.11a4.286 4.286 0 10-3.391 2.67L8.352 12l-1.47 1.47a4.286 4.286 0 103.391 2.67l1.11-1.11 5.157 5.158a3.75 3.75 0 005.303 0 .535.535 0 000-.758L14.413 12zM6.286 7.714a1.43 1.43 0 01-1.429-1.428 1.43 1.43 0 011.429-1.429 1.43 1.43 0 011.428 1.429 1.43 1.43 0 01-1.428 1.428zm0 11.429a1.43 1.43 0 01-1.429-1.429 1.43 1.43 0 011.429-1.428 1.43 1.43 0 011.428 1.428 1.43 1.43 0 01-1.428 1.429z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCutSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
