import * as React from "react";
function SvgShareAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 14.5c-.883 0-1.695.305-2.335.816l-4.004-2.502a3.773 3.773 0 000-1.628l4.004-2.502a3.75 3.75 0 10-1.326-2.12L9.336 9.066a3.75 3.75 0 100 5.868l4.003 2.502A3.75 3.75 0 1017 14.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShareAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
