import * as React from "react";
function SvgThumbsDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 4.188v9.375c0 .517.42.937.938.937h3.124c.518 0 .938-.42.938-.938V4.188a.937.937 0 00-.938-.938H2.937A.937.937 0 002 4.188zM3.563 12a.937.937 0 111.874 0 .937.937 0 01-1.875 0zm10.624 10c-.788 0-1.151-1.535-1.325-2.258-.203-.846-.414-1.721-.992-2.3-1.268-1.271-1.934-2.89-3.481-4.419a.468.468 0 01-.139-.333V4.34c0-.256.205-.464.46-.469.619-.011 1.434-.354 2.057-.632 1.24-.552 2.784-1.238 4.67-1.239h.111c1.671 0 3.647.016 4.445 1.162.328.47.408 1.056.24 1.743.637.666.979 1.909.64 2.92.685.916.747 2.193.363 3.105l.005.004c.464.466.762 1.22.759 1.921-.006 1.186-1.022 2.27-2.326 2.27H15.7c.286 1.107 1.3 2.036 1.3 3.693C17 21.688 15.125 22 14.187 22z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThumbsDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
