import * as React from "react";
function SvgSmokingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.75 15h-1.5a.25.25 0 00-.25.25v4.5c0 .137.113.25.25.25h1.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25zm-2.46-8.278a.634.634 0 01-.29-.525V4.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.947c0 .687.319 1.356.894 1.731A4.603 4.603 0 0120 11.803v.947c0 .137.113.25.25.25h1.5a.25.25 0 00.25-.25v-.947a6.102 6.102 0 00-2.71-5.081zM15.5 15h-12A1.5 1.5 0 002 16.5v2A1.5 1.5 0 003.5 20h12c.275 0 .5-.225.5-.5v-4c0-.275-.225-.5-.5-.5zm-1 3.5H9v-2h5.5v2zm2.74-10.075C16.495 7.906 16 7.103 16 6.197V4.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.075c0 1.366.769 2.55 1.884 3.334.7.491 1.116 1.288 1.116 2.144v.947c0 .137.113.25.25.25h1.5a.25.25 0 00.25-.25v-.947a4.112 4.112 0 00-1.76-3.378zM18.75 15h-1.5a.25.25 0 00-.25.25v4.5c0 .137.113.25.25.25h1.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSmokingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
