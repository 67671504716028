import * as React from "react";
function SvgFolderSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 7h-7.5l-2.5-2.5h-6.25C2.839 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875v-8.75C22 7.839 21.16 7 20.125 7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFolderSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
