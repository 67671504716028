import * as React from "react";
function SvgEquilateralTriangleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.819 5.604c.562-.805 1.8-.805 2.362 0l8.578 12.289c.63.9-.047 2.107-1.18 2.107H3.421c-1.134 0-1.81-1.207-1.181-2.107l8.578-12.29zM12 7.17L4.306 18.193h15.388L12 7.17z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEquilateralTriangleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
