import * as React from "react";
function SvgSlidersHSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 17H8.25v-.625a.627.627 0 00-.625-.625h-1.25a.627.627 0 00-.625.625V17H2.625a.627.627 0 00-.625.625v1.25c0 .344.281.625.625.625H5.75v.625c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625V19.5h13.125a.627.627 0 00.625-.625v-1.25a.627.627 0 00-.625-.625zm0-6.25H18.25v-.625a.627.627 0 00-.625-.625h-1.25a.627.627 0 00-.625.625v.625H2.625a.627.627 0 00-.625.625v1.25c0 .344.281.625.625.625H15.75v.625c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625v-.625h3.125a.627.627 0 00.625-.625v-1.25a.627.627 0 00-.625-.625zm0-6.25H13.25v-.625a.627.627 0 00-.625-.625h-1.25a.627.627 0 00-.625.625V4.5H2.625A.627.627 0 002 5.125v1.25c0 .344.281.625.625.625h8.125v.625c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625V7h8.125A.627.627 0 0022 6.375v-1.25a.627.627 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSlidersHSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
