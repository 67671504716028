import * as React from "react";
function SvgHospitalOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 11.531V9.97c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v1.562c0 .26-.21.469-.469.469H8.72a.469.469 0 01-.469-.469zm5.469.469h1.562c.26 0 .469-.21.469-.469V9.97a.469.469 0 00-.469-.469H13.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469zm-2.969 3.281V13.72a.469.469 0 00-.469-.469H8.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm2.969.469h1.562c.26 0 .469-.21.469-.469V13.72a.469.469 0 00-.469-.469H13.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469zm7.031 4.844V22H3.25v-1.406c0-.26.21-.469.469-.469h.761V5.322c0-.454.42-.822.938-.822h3.457V2.937c0-.517.42-.937.938-.937h4.374c.518 0 .938.42.938.938V4.5h3.457c.518 0 .938.368.938.822v14.803h.761c.26 0 .469.21.469.469zm-14.395-.508h4.395v-2.617c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v2.617h4.395V6.375h-2.52v.938c0 .517-.42.937-.938.937H9.814a.937.937 0 01-.938-.938v-.937h-2.52v13.71zM13.641 4.5h-1.016V3.484a.235.235 0 00-.234-.234h-.782a.235.235 0 00-.234.234V4.5h-1.016a.235.235 0 00-.234.234v.782a.235.235 0 00.234.234h1.016v1.016a.235.235 0 00.234.234h.782a.235.235 0 00.234-.234V5.75h1.016a.235.235 0 00.234-.234v-.782a.235.235 0 00-.234-.234z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHospitalOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
