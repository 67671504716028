"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiInput = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiInput = exports.UiInput = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: ", ";\n\talign-items: center;\n\twidth: 100%;\n\tfont-size: 15px;\n\n\t& > .ant-input-affix-wrapper {\n\t\twidth: 100%;\n\t\tflex: 1 1 auto;\n\t}\n\n\t.label {\n\t\tmargin-right: ", ";\n\t\tcolor: rgba(0, 0, 0, 0.65);\n\t\tmargin-bottom: ", ";\n\t\tflex: 0 0 ", ";\n\t\tword-break: break-all;\n\t}\n\n\t.inputInfo {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\n\t\t.count {\n\t\t\tflex: 1;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: flex-end;\n\t\t\tfont-size: 14px;\n\t\t\tcolor: #98989e;\n\t\t}\n\t}\n\n\t.message {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-top: 5px;\n\t\tfont-size: 12px;\n\t\tcolor: #ff4d4f;\n\n\t\t& > i {\n\t\t\tmargin-right: 5px;\n\t\t}\n\t}\n\n\t&.dark {\n\t\t.ant-input-prefix,\n\t\t.ant-input-suffix {\n\t\t\t.material-icons {\n\t\t\t\tfont-size: 18px;\n\t\t\t\tcolor: #888;\n\t\t\t}\n\t\t}\n\n\t\t.ant-input-affix-wrapper {\n\t\t\tbackground-color: #626262;\n\t\t\tborder-color: #626262;\n\t\t}\n\n\t\t.ant-input {\n\t\t\tcolor: #fff;\n\t\t\tbackground-color: #626262;\n\t\t\tborder-color: #626262;\n\n\t\t\t&::placeholder {\n\t\t\t\tcolor: rgba(255, 255, 255, 0.5);\n\t\t\t}\n\n\t\t\tinput::placeholder {\n\t\t\t\tcolor: rgba(255, 255, 255, 0.5);\n\t\t\t}\n\t\t}\n\n\t\t.ant-calendar-picker-clear {\n\t\t\tbackground-color: transparent;\n\t\t}\n\n\t\t.ant-input-search-icon {\n\t\t\tcolor: rgba(255, 255, 255, 0.5);\n\t\t}\n\t}\n"])), _ref => {
  let {
    row
  } = _ref;
  return row ? "flex" : "block";
}, _ref2 => {
  let {
    row
  } = _ref2;
  return row ? "15px" : "0";
}, _ref3 => {
  let {
    row
  } = _ref3;
  return row ? "0" : "1px";
}, _ref4 => {
  let {
    row
  } = _ref4;
  return row ? "60px" : "auto";
});