import * as React from "react";
function SvgAngleDoubleDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.147 12.013L4.323 5.189a1.2 1.2 0 010-1.701l1.134-1.134a1.2 1.2 0 011.7 0l4.838 4.837 4.837-4.837a1.2 1.2 0 011.701 0l1.144 1.129a1.2 1.2 0 010 1.7l-6.824 6.825a1.2 1.2 0 01-1.706.005zm1.706 9.634l6.824-6.824a1.2 1.2 0 000-1.701l-1.134-1.134a1.2 1.2 0 00-1.7 0L12 16.82l-4.837-4.837a1.2 1.2 0 00-1.701 0l-1.14 1.134a1.2 1.2 0 000 1.7l6.825 6.825a1.2 1.2 0 001.706.005z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngleDoubleDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
