import * as React from "react";
function SvgHandLizardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.33 13.2l-5.082-7.838a2.49 2.49 0 00-2.098-1.14H3.944A1.947 1.947 0 002 6.167v.277a2.78 2.78 0 002.778 2.778h6.807l-.637 1.667H7a3.06 3.06 0 00-3.056 3.055c0 1.073.873 1.945 1.945 1.945h4.568a.83.83 0 01.302.056l3.463 1.347v2.486H22v-4.312c0-.806-.232-1.59-.67-2.267zm-.997 4.911H15.89v-.819c0-.692-.417-1.302-1.063-1.553l-3.463-1.347a2.49 2.49 0 00-.906-.17H5.889a.278.278 0 01-.278-.278A1.39 1.39 0 017 12.555h3.948c.687 0 1.312-.43 1.557-1.072l.853-2.231a1.25 1.25 0 00-1.168-1.697H4.778a1.112 1.112 0 01-1.111-1.11v-.278c0-.154.124-.278.277-.278H14.15a.83.83 0 01.7.38l5.081 7.837c.263.405.403.877.402 1.36v2.645z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandLizardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
