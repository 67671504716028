import * as React from "react";
function SvgCropAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.063 15.75H19.5v-10c0-.69-.56-1.25-1.25-1.25H9.5v3.75h6.25v12.813c0 .517.42.937.938.937h1.875c.517 0 .937-.42.937-.938V19.5h1.563c.517 0 .937-.42.937-.938v-1.875a.937.937 0 00-.938-.937zM8.25 2.937A.937.937 0 007.312 2H5.438a.937.937 0 00-.937.938V4.5H2.937A.937.937 0 002 5.438v1.875c0 .517.42.937.938.937H4.5v10c0 .69.56 1.25 1.25 1.25h8.75v-3.75H8.25V2.937z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCropAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
