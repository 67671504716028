import * as React from "react";
function SvgRecordVinylSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 7.806a4.194 4.194 0 100 8.388 4.194 4.194 0 000-8.388zm0 5.162a.967.967 0 110-1.934.967.967 0 010 1.934zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 15.161a5.162 5.162 0 110-10.323 5.162 5.162 0 010 10.323z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRecordVinylSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
