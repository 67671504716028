import * as React from "react";
function SvgTerminalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.062 12.442l-6.073 6.073a.75.75 0 01-1.061 0l-.708-.708a.75.75 0 01-.002-1.06l4.814-4.835-4.814-4.836a.75.75 0 01.002-1.06l.708-.708a.75.75 0 011.06 0l6.074 6.073a.75.75 0 010 1.06zM22 18.162v-1a.75.75 0 00-.75-.75h-9.5a.75.75 0 00-.75.75v1c0 .414.336.75.75.75h9.5a.75.75 0 00.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTerminalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
