import * as React from "react";
function SvgSignLanguageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.822 20.906c-.012-.626.512-1.138 1.138-1.138h2.433v-.223H5.476c-.626 0-1.15-.513-1.138-1.138a1.116 1.116 0 011.116-1.095h4.939v-.223H4.388c-.626 0-1.15-.512-1.138-1.138a1.116 1.116 0 011.116-1.094h6.027v-.223H5.504c-.626 0-1.15-.512-1.138-1.138a1.116 1.116 0 011.116-1.094h6.585l-1.215-.883a1.116 1.116 0 111.313-1.805l4.82 3.504a1.34 1.34 0 01.55 1.083v5.513a1.34 1.34 0 01-1.032 1.304l-3.143.74a5.358 5.358 0 01-1.227.142H7.938c-.61 0-1.105-.488-1.116-1.094zm2.861-8.817h1.423l-.437-.318a1.429 1.429 0 01-.124-2.208l-.508-.652c-.385-.494-1.11-.593-1.597-.2a1.116 1.116 0 00-.178 1.554l1.42 1.824zm11.066-.082l-.195-5.955a1.116 1.116 0 10-2.231.073l.05 1.5-4.046-5.195a1.116 1.116 0 00-1.55-.208c-.5.375-.582 1.103-.198 1.597l3.004 3.857-.177.138-3.702-4.756a1.116 1.116 0 00-1.55-.208c-.5.375-.582 1.103-.198 1.597l3.69 4.738-.177.137-3.034-3.897a1.116 1.116 0 00-1.549-.208c-.501.375-.583 1.103-.199 1.597l2.034 2.612a1.426 1.426 0 011.628.035l4.819 3.504c.426.31.68.81.68 1.336v.664l2.386-1.857a1.34 1.34 0 00.515-1.1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSignLanguageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
