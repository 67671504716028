import * as React from "react";
function SvgClosedCaptioningOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 4.5H3.875C2.84 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V6.375C22 5.34 21.16 4.5 20.125 4.5zm-.234 13.125H4.109a.235.235 0 01-.234-.234V6.609c0-.129.105-.234.234-.234h15.782c.129 0 .234.105.234.234v10.782a.235.235 0 01-.234.234zm-8.246-3.348a.236.236 0 01-.02.301c-2.094 2.219-6.75 1.254-6.75-2.652 0-3.801 4.754-4.668 6.738-2.739.082.079.098.126.04.223l-.684 1.192a.243.243 0 01-.356.066c-1.593-1.25-3.695-.582-3.695 1.219 0 1.875 1.992 2.754 3.602 1.273a.241.241 0 01.359.035l.766 1.082zm7.437 0a.236.236 0 01-.02.301c-2.093 2.223-6.75 1.254-6.75-2.652 0-3.801 4.754-4.668 6.739-2.739.082.079.097.126.039.223l-.684 1.192a.243.243 0 01-.355.066c-1.594-1.25-3.695-.582-3.695 1.219 0 1.875 1.992 2.754 3.601 1.273a.241.241 0 01.36.035l.765 1.082z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgClosedCaptioningOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
