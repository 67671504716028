import * as React from "react";
function SvgPoundSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.607 16.286h-2.03a.536.536 0 00-.537.535v2.27h-5.469V13.43h3.75c.296 0 .536-.24.536-.536v-1.786a.536.536 0 00-.536-.536h-3.75V7.734c0-1.44 1.097-2.548 2.759-2.548 1.056 0 2.048.513 2.574.841.23.144.53.092.7-.12l1.272-1.585a.535.535 0 00-.095-.763C17.052 3.007 15.42 2 13.248 2 9.59 2 7 4.355 7 7.623v2.948H5.75a.536.536 0 00-.536.536v1.786c0 .296.24.536.536.536H7v5.714H5.393a.536.536 0 00-.536.536v1.785c0 .296.24.536.536.536h13.214c.296 0 .536-.24.536-.536v-4.643a.536.536 0 00-.536-.535z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPoundSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
