import { useState } from "react";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { Input, Button, Select, Loading } from "common-components";
import { BoxEditSolid } from "@onedesign/icon";
import { Breadcrumb } from "components";
import { EDU_SUBJECT } from "constants/index";
import { setSelectOptions } from "utils/common";
import { getQuestionAvailability, updateQuestionAvailability } from "api/bank";
import { UiUpdateAvailabilityPage, UiLoadingBox } from "./UpdateAvailabilityPage.style";

const breadcrumbList = [
	{
		oneClassIcon: <BoxEditSolid />,
		name: "上下架查詢與變更",
		path: "/updateAvailability",
	},
];

const booleanToAvailability = (bool) => (bool ? "上架" : "下架");

export const UpdateAvailabilityPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [eduSubject, setEduSubject] = useState(EDU_SUBJECT[0].code);
	const [questionUid, setQuestionUid] = useState("");
	const [stateAvailability, sendAvailability] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_) => {
				const payload = {
					cmsIds: [
						{
							eduSubject: "",
							ids: [],
						},
					],
					uidIds: [{ eduSubject, ids: [questionUid] }],
				};
				try {
					setIsLoading(true);
					const res = await getQuestionAvailability(payload);
					const data = res.data?.questionList?.[eduSubject]?.[0] || {};
					const { message, isSuccess } = res;
					return {
						data,
						message,
						isSuccess,
					};
				} finally {
					setIsLoading(false);
				}
			},
			updateData: async (_, event) => {
				const { availabilityType } = event.payload;
				const { availability, message, isSuccess } = await updateQuestionAvailability(eduSubject, data.uid, {
					availabilityType,
				});
				return {
					data: availability,
					message,
					isSuccess,
				};
			},
		},
	});

	const { data, message } = stateAvailability.context.result || {};

	const suspendAvailability = (type) => {
		const availabilityType = { ...data.availability, [type]: false };
		sendAvailability(FetchEventType.Update, { payload: { availabilityType } });
	};

	return (
		<>
			<UiUpdateAvailabilityPage>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbList} />
				</div>
				<div className="search">
					<Select
						className="search__select"
						placeholder="學制科目"
						options={setSelectOptions(EDU_SUBJECT)}
						value={eduSubject}
						onChange={(val) => {
							setEduSubject(val);
						}}
					/>
					<Input
						className="search__text"
						placeholder="查詢題目 UID"
						value={questionUid}
						onChange={(e) => setQuestionUid(e.target.value)}
					/>
					<Button
						variant="green"
						full={false}
						disabled={!questionUid}
						onClick={() => sendAvailability(FetchEventType.Fetch)}>
						查詢
					</Button>
				</div>
				<div className="message">{message || ""}</div>
				<table className="result">
					<tbody>
						<tr>
							<td>轉檔結果</td>
							<td>{data?.availability ? (data.availability.convertResult ? "成功" : "失敗") : ""}</td>
						</tr>
						<tr>
							<td>轉檔錯誤訊息</td>
							<td>{data?.availability ? message || "沒有轉檔錯誤" : ""}</td>
						</tr>
						<tr>
							<td>是否封存</td>
							<td>{data?.availability ? (data.availability.archive ? "是" : "否") : ""}</td>
						</tr>
						<tr>
							<td>紙本上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.paper) : ""}</td>
						</tr>
						<tr>
							<td>速測上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.quick) : ""}</td>
						</tr>
						<tr>
							<td>線上測驗上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.onlineTest) : ""}</td>
						</tr>
						<tr>
							<td>無限命題上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.equationDojo) : ""}</td>
						</tr>
						<tr>
							<td>免費產品上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.freeProduct) : ""}</td>
						</tr>
						<tr>
							<td>自學產品上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.selfTaughtProduct) : ""}</td>
						</tr>
						<tr>
							<td>模擬考上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.mockExam) : ""}</td>
						</tr>
						<tr>
							<td>檢定試題上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.validation) : ""}</td>
						</tr>
						<tr>
							<td>特殊選項試題上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.optionQuick) : ""}</td>
						</tr>
						<tr>
							<td>單字刷題試題上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.vocabularyTest) : ""}</td>
						</tr>
						<tr>
							<td>叢書成卷上架狀態</td>
							<td>{data?.availability ? booleanToAvailability(data.availability.collectedEdition) : ""}</td>
						</tr>
					</tbody>
				</table>
				<div className="actions">
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("paper")}
						disabled={!data?.availability?.paper}>
						紙本下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("quick")}
						disabled={!data?.availability?.quick}>
						速測下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("onlineTest")}
						disabled={!data?.availability?.onlineTest}>
						線上測驗下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("collectedEdition")}
						disabled={!data?.availability?.collectedEdition}>
						叢書成卷下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("equationDojo")}
						disabled={!data?.availability?.equationDojo}>
						無限命題下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("validation")}
						disabled={!data?.availability?.validation}>
						檢定試題下架
					</Button>
					<Button
						variant="green"
						full={false}
						onClick={() => suspendAvailability("vocabularyTest")}
						disabled={!data?.availability?.vocabularyTest}>
						單字刷題試題下架
					</Button>
				</div>
			</UiUpdateAvailabilityPage>
			{isLoading && (
				<UiLoadingBox>
					<Loading />
				</UiLoadingBox>
			)}
		</>
	);
};

UpdateAvailabilityPage.propTypes = {};
