import * as React from "react";
function SvgBusinessTimeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.5 11a4.498 4.498 0 00-4.5 4.5c0 2.487 2.013 4.5 4.5 4.5s4.5-2.013 4.5-4.5-2.013-4.5-4.5-4.5zm2 4.697a.304.304 0 01-.303.303h-1.893a.304.304 0 01-.304-.303v-2.393c0-.167.137-.304.304-.304h.393c.166 0 .303.137.303.304V15h1.197c.166 0 .303.137.303.303v.394zM17.5 10c.169 0 .335.01.5.025V8.5c0-.8-.7-1.5-1.5-1.5H14V5.5c0-.8-.7-1.5-1.5-1.5h-5C6.7 4 6 4.7 6 5.5V7H3.5C2.7 7 2 7.7 2 8.5V11h12.348c.893-.628 1.98-1 3.152-1zM12 7H8V6h4v1zm.213 7H8.5a.5.5 0 01-.5-.5V12H2v4.5c0 .8.7 1.5 1.5 1.5h9.107A5.461 5.461 0 0112 15.5c0-.52.078-1.023.213-1.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBusinessTimeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
