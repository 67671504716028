import * as React from "react";
function SvgCaretSquareUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 19.857V4.143C2 2.959 2.96 2 4.143 2h15.714C21.041 2 22 2.96 22 4.143v15.714C22 21.041 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857zm15.871-6.271L12.38 8.092a.536.536 0 00-.758 0L6.13 13.585a.536.536 0 00.379.915h10.984a.536.536 0 00.38-.915z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCaretSquareUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
