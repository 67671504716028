import * as React from "react";
function SvgArrowRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.504 3.56l.99-.992c.42-.42 1.099-.42 1.514 0l8.677 8.673c.42.42.42 1.098 0 1.514l-8.677 8.677c-.42.42-1.098.42-1.513 0l-.991-.99a1.073 1.073 0 01.017-1.532l5.38-5.124H3.07A1.069 1.069 0 012 12.714v-1.428c0-.594.478-1.071 1.071-1.071h12.83L10.52 5.09a1.065 1.065 0 01-.018-1.53z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
