import * as React from "react";
function SvgAmbulanceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 15H21v-3.378a1.5 1.5 0 00-.44-1.06l-3.122-3.121A1.5 1.5 0 0016.378 7H15V5.5A1.5 1.5 0 0013.5 4h-10A1.5 1.5 0 002 5.5v10A1.5 1.5 0 003.5 17H4a3 3 0 006 0h4a3 3 0 006 0h1.5c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM7 18.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm4.5-7.75a.25.25 0 01-.25.25H9.5v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V11H5.75a.25.25 0 01-.25-.25v-1.5A.25.25 0 015.75 9H7.5V7.25A.25.25 0 017.75 7h1.5a.25.25 0 01.25.25V9h1.75a.25.25 0 01.25.25v1.5zM17 18.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm2.5-6.5H15V8.5h1.378l3.122 3.122V12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAmbulanceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
