import * as React from "react";
function SvgArrowsAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.758 18.632l-3.094 3.093a.937.937 0 01-1.325 0l-3.094-3.093c-.59-.59-.172-1.6.663-1.6h1.998v-3.938H6.97v1.998c0 .835-1.01 1.254-1.6.663l-3.094-3.093a.937.937 0 010-1.326l3.093-3.094c.59-.59 1.6-.172 1.6.663v2.001h3.938V6.97h-2c-.836 0-1.254-1.01-.664-1.6l3.094-3.094a.937.937 0 011.326 0l3.093 3.093c.59.59.172 1.6-.663 1.6h-1.998v3.938h3.937V8.908c0-.835 1.01-1.254 1.6-.663l3.094 3.093a.937.937 0 010 1.326l-3.093 3.094c-.59.59-1.6.172-1.6-.663v-2.001h-3.938v3.937h2.001c.835 0 1.254 1.01.663 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowsAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
