import * as React from "react";
function SvgHamburgerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 12H3.875a1.875 1.875 0 100 3.75h16.25a1.875 1.875 0 100-3.75zm.625 5H3.25a.625.625 0 00-.625.625v.625a2.5 2.5 0 002.5 2.5h13.75a2.5 2.5 0 002.5-2.5v-.625A.624.624 0 0020.75 17zM4.29 10.75h15.42c1.35 0 2.133-1.715 1.36-2.964C19.5 5.25 16.044 3.254 12 3.25c-4.045.004-7.5 2-9.07 4.536-.774 1.249.01 2.964 1.36 2.964zM17 6.375a.625.625 0 110 1.25.625.625 0 010-1.25zm-5-1.25a.625.625 0 110 1.25.625.625 0 010-1.25zm-5 1.25a.625.625 0 110 1.25.625.625 0 010-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHamburgerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
