import styled from "styled-components";
import { Modal as AntModal } from "antd";

export const UiAudiosImportPage = styled.div`
	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			.dateFilterBox {
				width: 250px;
			}

			> div {
				margin-right: 8px;
				width: 130px;
			}

			> button {
				margin-right: 8px;
			}
		}

		.actionBox {
			display: flex;

			> * {
				margin-left: 8px;
			}
		}

		.btnGroup {
			> button {
				gap: 16px;
			}
		}
	}

	.CheckListTable {
		overflow: hidden;
		height: calc(100vh - 250px) !important;

		> div > div > div {
			padding: 15px 15px;
		}

		.body {
			overflow: auto;
			height: calc(100vh - 364.5px) !important;

			.btnGroup {
				display: flex;
				flex-wrap: wrap;
				gap: 16px;
			}

			.loading {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.tableNoData {
				margin: 24px auto;
				width: 50%;
				font-size: 24px;
				text-align: center;
				color: #fff;
			}
		}
	}
`;

export const UiAudiosModal = styled(AntModal)`
	.ant-modal-body {
		padding: 6px 45px 40px;
	}
`;

export const StyledModal = styled(AntModal)`
	top: 50px;
	padding-bottom: unset;
	width: unset !important;

	.ant-modal-content {
		width: unset;
		height: 90vh;
	}

	.ant-modal-body {
		height: 85%;
	}

	.ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		> button {
			width: auto !important;
		}
	}

	.select {
		width: 130px;
		margin-bottom: 10px;
	}

	.questionListTable {
		> div > div > div {
			padding: 15px 15px;
		}

		> div > .loading {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.tableNoData {
			margin: 24px auto;
			width: 50%;
			font-size: 24px;
			text-align: center;
			color: #000;
		}
	}

	.controlBox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		> button {
			margin: 0 10px 10px 0;
		}
	}
`;

export const UiLoadingBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 5;
`;

export const UiSuccessText = styled.div`
	color: #4caf50;
`;
export const UiErrorText = styled.div`
	color: #ff4d4f;
`;
export const UiLoadingText = styled.div`
	color: #919eab;
`;

export const UiDeleteModal = styled(AntModal)`
	.ant-modal-body {
		padding: 32px;
	}

	.content {
		display: flex;
		align-items: center;
		gap: 16px;
	}
`;

export const UiUploadAudioModal = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 24px;

	.content {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}
`;
