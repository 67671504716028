import * as React from "react";
function SvgChevronUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.777 6.253l8.901 8.901a1.1 1.1 0 010 1.555l-1.038 1.038a1.099 1.099 0 01-1.553.001L12 10.694l-7.087 7.054a1.1 1.1 0 01-1.553-.001l-1.038-1.038a1.1 1.1 0 010-1.555l8.9-8.9a1.1 1.1 0 011.555 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
