import * as React from "react";
function SvgMapOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.445 4.222a.558.558 0 00-.207.04l-5.904 2.182L9.36 4.336a2.23 2.23 0 00-1.39-.005L2.698 6.165A1.111 1.111 0 002 7.196v12.025a.556.556 0 00.762.516l5.905-2.181 5.972 2.107c.45.151.938.153 1.39.006l5.272-1.834A1.111 1.111 0 0022 16.803V4.778a.556.556 0 00-.555-.556zM9.778 6.251l4.444 1.569v9.93l-4.444-1.57V6.25zM3.667 17.627V7.593L8.11 6.046v9.938l-.022.008-4.422 1.635zm16.666-1.22l-4.444 1.546V8.016l.022-.008 4.422-1.635v10.034z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMapOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
