import * as React from "react";
function SvgExpandSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 8.607V3.071C2 2.478 2.478 2 3.071 2h5.536c.295 0 .536.241.536.536V4.32a.537.537 0 01-.536.536h-3.75v3.75a.537.537 0 01-.536.536H2.536A.537.537 0 012 8.607zm12.857-6.071V4.32c0 .295.241.536.536.536h3.75v3.75c0 .295.24.536.536.536h1.785A.537.537 0 0022 8.607V3.071C22 2.478 21.522 2 20.929 2h-5.536a.537.537 0 00-.536.536zm6.607 12.321H19.68a.537.537 0 00-.536.536v3.75h-3.75a.537.537 0 00-.536.536v1.785c0 .295.241.536.536.536h5.536c.593 0 1.071-.478 1.071-1.071v-5.536a.537.537 0 00-.536-.536zM9.143 21.464V19.68a.537.537 0 00-.536-.536h-3.75v-3.75a.537.537 0 00-.536-.536H2.536a.537.537 0 00-.536.536v5.536C2 21.522 2.478 22 3.071 22h5.536a.537.537 0 00.536-.536z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExpandSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
