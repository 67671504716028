import * as React from "react";
function SvgSadCrySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12c0 3.633 1.944 6.802 4.839 8.552V13.29c0-.354.29-.645.645-.645.355 0 .645.29.645.645v7.932c1.19.5 2.5.778 3.871.778a9.977 9.977 0 003.871-.778V13.29c0-.354.29-.645.645-.645.355 0 .645.29.645.645v7.262C20.056 18.802 22 15.63 22 12c0-5.524-4.476-10-10-10zm-2.641 8.73c-.597-.532-1.863-.532-2.46 0l-.383.343a.49.49 0 01-.552.064.485.485 0 01-.246-.5C5.879 9.621 7.097 8.94 8.133 8.94s2.254.681 2.415 1.697a.48.48 0 01-.798.435l-.391-.342zM12 18.452c-1.069 0-1.935-1.158-1.935-2.581s.867-2.58 1.935-2.58c1.069 0 1.935 1.157 1.935 2.58s-.867 2.58-1.935 2.58zm6.04-7.319a.48.48 0 01-.552-.064l-.383-.343c-.597-.533-1.863-.533-2.46 0l-.387.347a.49.49 0 01-.552.064.485.485 0 01-.246-.5c.161-1.016 1.379-1.697 2.415-1.697s2.254.681 2.415 1.697a.499.499 0 01-.25.496z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSadCrySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
