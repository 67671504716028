import * as React from "react";
function SvgHomeworkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.13 15.584V5.706A.703.703 0 0014.425 5H4.813A2.819 2.819 0 002 7.822v9.409a2.819 2.819 0 002.813 2.822h9.613c.39 0 .703-.314.703-.706v-.47c0-.22-.102-.42-.26-.55-.124-.452-.124-1.743 0-2.196a.701.701 0 00.26-.547zm-1.953 2.588H4.813a.942.942 0 010-1.882h8.364a10.615 10.615 0 000 1.882zM17.819 18.944v-1.506c0-.153.124-.277.277-.277h3.626c.154 0 .278.124.278.277v1.506c0 .611-.497 1.108-1.11 1.11h-1.962a1.107 1.107 0 01-1.11-1.11zm4.18-2.8V7.568l-1.637-2.331a.555.555 0 00-.908 0l-1.637 2.332v8.578c0 .154.124.278.278.278h3.626a.28.28 0 00.279-.28zm-2.09-8.5l-.784.785-.593-.593.87-1.238h1.015l.87 1.238-.594.593-.784-.784z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHomeworkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
