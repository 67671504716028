import * as React from "react";
function SvgKissBeamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-1.573 8.867l-.383-.686c-.31-.552-.774-.87-1.27-.87s-.96.318-1.27.87l-.383.686c-.17.298-.63.161-.6-.182.132-1.697 1.298-2.879 2.257-2.879.96 0 2.125 1.182 2.258 2.88.02.342-.44.483-.609.18zm3.831 6.778c0 .774-1.157 1.674-2.883 1.774-.343.033-.488-.476-.145-.62l.685-.29c.525-.223.839-.545.839-.868 0-.322-.314-.645-.839-.867l-.685-.29c-.242-.1-.246-.492 0-.597l.685-.29c.525-.222.839-.545.839-.867 0-.323-.314-.645-.839-.867l-.685-.29c-.347-.146-.194-.666.145-.621 1.726.1 2.883 1 2.883 1.774 0 .524-.54 1.1-1.42 1.451.88.367 1.42.944 1.42 1.468zm2.621-6.778l-.383-.686c-.31-.552-.774-.87-1.27-.87s-.96.318-1.27.87l-.383.686c-.166.294-.63.161-.601-.182.133-1.697 1.298-2.879 2.258-2.879s2.125 1.182 2.258 2.88c.02.342-.44.483-.609.18z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKissBeamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
