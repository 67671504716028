import * as React from "react";
function SvgTrashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25h-4.688l-.367-.73a.938.938 0 00-.84-.52H9.766a.927.927 0 00-.836.52l-.367.73H3.875a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h16.25a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zM5.328 20.242A1.875 1.875 0 007.2 22h9.602a1.875 1.875 0 001.87-1.758L19.5 7h-15l.828 13.242z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTrashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
