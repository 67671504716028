import * as React from "react";
function SvgShoppingBasketSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 10.611v.556c0 .46-.373.833-.833.833h-.278l-.907 6.347a1.667 1.667 0 01-1.65 1.43H5.668c-.83 0-1.533-.61-1.65-1.43L3.11 12h-.278A.833.833 0 012 11.167v-.556c0-.46.373-.833.833-.833h2.339L8.879 4.68a1.111 1.111 0 011.797 1.307L7.92 9.777h8.162l-2.757-3.79A1.111 1.111 0 0115.12 4.68l3.707 5.098h2.339c.46 0 .833.373.833.833zm-9.167 6.111v-3.889a.833.833 0 10-1.666 0v3.89a.833.833 0 001.666 0zm3.89 0v-3.889a.833.833 0 10-1.667 0v3.89a.833.833 0 101.666 0zm-7.779 0v-3.889a.833.833 0 10-1.666 0v3.89a.833.833 0 001.666 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShoppingBasketSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
