import * as React from "react";
function SvgCloneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 2C21.16 2 22 2.84 22 3.875v11.25C22 16.16 21.16 17 20.125 17H8.875A1.875 1.875 0 017 15.125V3.875C7 2.839 7.84 2 8.875 2h11.25zM8.875 18.25a3.129 3.129 0 01-3.125-3.125V7H3.875C2.839 7 2 7.84 2 8.875v11.25C2 21.16 2.84 22 3.875 22h11.25C16.16 22 17 21.16 17 20.125V18.25H8.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
