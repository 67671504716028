import * as React from "react";
function SvgHotelSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.444 5.333A.555.555 0 0022 4.778V3.667a.555.555 0 00-.556-.556H2.556A.555.555 0 002 3.667v1.11c0 .308.249.556.556.556h.554v13.334h-.554a.555.555 0 00-.556.555v1.111c0 .307.249.556.556.556h8.333V18.11c0-.305.25-.555.555-.555h1.112c.305 0 .555.25.555.555v2.778h8.333a.555.555 0 00.556-.556v-1.11a.555.555 0 00-.556-.556h-.555V5.333h.555zM10.89 6.89c0-.222.222-.445.444-.445h1.334c.222 0 .444.223.444.445v1.333a.478.478 0 01-.444.445h-1.334a.478.478 0 01-.444-.445V6.89zm0 3.333c0-.222.222-.444.444-.444h1.334c.222 0 .444.222.444.444v1.334a.478.478 0 01-.444.444h-1.334a.478.478 0 01-.444-.444v-1.334zM6.444 6.89c0-.222.223-.445.445-.445h1.333c.222 0 .445.223.445.445v1.333a.478.478 0 01-.445.445H6.89a.478.478 0 01-.445-.445V6.89zM8.222 12H6.89a.478.478 0 01-.445-.444v-1.334c0-.222.223-.444.445-.444h1.333c.222 0 .445.222.445.444v1.334a.478.478 0 01-.445.444zm.445 4.444a3.333 3.333 0 016.666 0H8.667zm8.889-4.888a.478.478 0 01-.445.444h-1.333a.478.478 0 01-.445-.444v-1.334c0-.222.223-.444.445-.444h1.333c.222 0 .445.222.445.444v1.334zm0-3.334a.478.478 0 01-.445.445h-1.333a.478.478 0 01-.445-.445V6.89c0-.222.223-.445.445-.445h1.333c.222 0 .445.223.445.445v1.333z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHotelSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
