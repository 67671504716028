import * as React from "react";
function SvgCalendarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.719 9.5H20.28a.47.47 0 01.469.469v10.156c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V9.969a.47.47 0 01.469-.469zM20.75 7.781V6.375c0-1.035-.84-1.875-1.875-1.875H17V2.469A.47.47 0 0016.531 2H14.97a.47.47 0 00-.469.469V4.5h-5V2.469A.47.47 0 009.031 2H7.47A.47.47 0 007 2.469V4.5H5.125c-1.035 0-1.875.84-1.875 1.875v1.406c0 .258.21.469.469.469H20.28a.47.47 0 00.469-.469z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCalendarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
