import * as React from "react";
function SvgUtensilSpoonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.753 3.248c-2.148-2.152-6.44-1.347-8.897 1.114-1.926 1.925-2.153 4.296-1.125 6.265l-8.379 7.515a1.061 1.061 0 00-.039 1.543l2.004 2.003c.43.43 1.137.41 1.543-.043l7.515-8.374c1.969 1.027 4.34.8 6.265-1.125 2.461-2.457 3.266-6.75 1.113-8.898z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUtensilSpoonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
