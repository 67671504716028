import * as React from "react";
function SvgHotTubSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.18 8.94c.04.32.303.56.615.56h.63a.641.641 0 00.625-.717c-.17-1.528-.875-2.911-1.958-3.795-.679-.554-1.126-1.436-1.25-2.428a.627.627 0 00-.614-.56h-.63a.641.641 0 00-.625.717c.17 1.528.875 2.911 1.958 3.795.678.554 1.126 1.436 1.25 2.427zm-4.219 0c.04.32.303.56.616.56h.63a.641.641 0 00.624-.717c-.17-1.528-.875-2.911-1.958-3.795-.678-.554-1.126-1.436-1.25-2.428A.627.627 0 0012.01 2h-.63a.64.64 0 00-.625.717c.17 1.528.875 2.911 1.959 3.795.678.554 1.125 1.436 1.248 2.427zM20.75 12H12L7.667 8.75a2.5 2.5 0 00-1.5-.5H4.5a2.5 2.5 0 00-2.5 2.5v8.75A2.5 2.5 0 004.5 22h15a2.5 2.5 0 002.5-2.5v-6.25c0-.69-.56-1.25-1.25-1.25zM7 19.188c0 .172-.14.312-.313.312h-.625a.312.312 0 01-.312-.313v-4.375c0-.172.14-.312.313-.312h.625c.172 0 .312.14.312.313v4.374zm3.75 0c0 .172-.14.312-.313.312h-.624a.312.312 0 01-.313-.313v-4.375c0-.172.14-.312.313-.312h.624c.173 0 .313.14.313.313v4.374zm3.75 0c0 .172-.14.312-.313.312h-.624a.312.312 0 01-.313-.313v-4.375c0-.172.14-.312.313-.312h.624c.173 0 .313.14.313.313v4.374zm3.75 0c0 .172-.14.312-.313.312h-.625a.312.312 0 01-.312-.313v-4.375c0-.172.14-.312.313-.312h.625c.172 0 .312.14.312.313v4.374zM4.5 7a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHotTubSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
