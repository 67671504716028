import * as React from "react";
function SvgUmbrellaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.99 12.863c-.993-4.733-4.806-7.577-8.879-8.018v-.621a1.11 1.11 0 10-2.222 0v.621c-4.087.441-7.865 3.285-8.879 8.018-.076.35.295.74.65.396 1.805-1.91 3.74-1.82 5.507 1.284.184.33.517.3.684 0 .701-1.229 1.559-2.541 3.149-2.541 2.031 0 3.063 2.389 3.15 2.541.166.3.5.33.683 0 1.771-3.107 3.72-3.173 5.507-1.284.358.347.726-.046.65-.396zm-11.101.726v4.524a.556.556 0 01-1.08.184 1.11 1.11 0 10-2.094.74 2.779 2.779 0 002.618 1.85 2.782 2.782 0 002.778-2.777v-4.525c-.316-.274-.687-.472-1.111-.472-.427.003-.778.167-1.111.476z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUmbrellaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
