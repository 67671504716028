import * as React from "react";
function SvgFileSignatureSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.575 17.838a.385.385 0 00-.353-.226c-.072 0-.25.02-.348.215l-.266.532a.556.556 0 01-1.024-.072L7 16.534l-.368 1.107a1.499 1.499 0 01-1.424 1.026h-.43a.555.555 0 110-1.112h.43a.39.39 0 00.37-.265l.631-1.898a.834.834 0 011.582 0l.482 1.446c.685-.562 1.877-.337 2.291.492.066.131.19.206.325.217v-2.851l4.445-4.413V8.667H10.61a.836.836 0 01-.833-.834V3.111H2.833A.831.831 0 002 3.944v16.111c0 .462.372.834.833.834H14.5a.831.831 0 00.833-.834v-1.388l-4.444-.004a1.49 1.49 0 01-1.314-.825zm5.758-10.494a.83.83 0 00-.243-.587l-3.4-3.403a.832.832 0 00-.59-.243h-.211v4.445h4.444v-.212zM12 15.158v2.397h2.395l5.614-5.652-2.357-2.356L12 15.158zm9.74-6.237l-1.106-1.106a.884.884 0 00-1.25 0l-.946.946 2.357 2.357.946-.947a.884.884 0 000-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileSignatureSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
