import * as React from "react";
function SvgBabyCarriageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.656 2.665c-.441-.696-1.453-.891-2.109-.368C3.379 4.027 2 6.61 2 9.5h10L7.656 2.665zM21.375 5.75H19.5a2.502 2.502 0 00-2.5 2.5v2.5H2c0 1.977.899 3.766 2.356 5.106A3.124 3.124 0 002 18.876a3.124 3.124 0 106.25 0c0-.349-.07-.673-.172-.985a10.14 10.14 0 005.344 0 3.05 3.05 0 00-.172.984 3.124 3.124 0 106.25 0 3.124 3.124 0 00-2.356-3.02c1.457-1.34 2.356-3.128 2.356-5.105v-2.5h1.875A.627.627 0 0022 7.625v-1.25a.627.627 0 00-.625-.625zM5.125 20.125c-.687 0-1.25-.562-1.25-1.25 0-.687.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25 0 .688-.562 1.25-1.25 1.25zm12.5-1.25c0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.562-1.25-1.25 0-.687.562-1.25 1.25-1.25.687 0 1.25.563 1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBabyCarriageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
