import * as React from "react";
function SvgCloudSunRainSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.726 10.94A2.777 2.777 0 0017 8.668c-.427 0-.83.104-1.191.277a3.322 3.322 0 00-2.698-1.388 3.334 3.334 0 00-3.333 3.333c0 .017.007.038.007.055a2.777 2.777 0 00.549 5.5h8.888A2.777 2.777 0 0022 13.667a2.77 2.77 0 00-2.274-2.726zM6.309 12.136a3.34 3.34 0 010-4.715c1.201-1.201 3.094-1.278 4.4-.257a4.414 4.414 0 012.402-.719c.024 0 .045.007.07.007l.309-.927a.45.45 0 00-.573-.57l-2.615.872L9.07 3.361a.452.452 0 00-.809 0L7.028 5.826l-2.615-.871a.45.45 0 00-.57.573l.872 2.614L2.25 9.375a.452.452 0 000 .809l2.465 1.233-.871 2.614a.454.454 0 00.573.573l2.055-.684c-.007-.083-.024-.163-.024-.25 0-.434.08-.85.215-1.246-.125-.094-.246-.181-.354-.289zm2.424-2.014c.149-.85.548-1.61 1.107-2.222a2.192 2.192 0 00-1.173-.343 2.224 2.224 0 00-2.223 2.222c0 .65.285 1.229.733 1.635a3.894 3.894 0 011.556-1.292zm11.479 7.507a.554.554 0 00-.757.209l-1.271 2.222a.553.553 0 00.483.83c.19 0 .378-.1.482-.281l1.271-2.223a.556.556 0 00-.208-.757zm-3.334 0a.554.554 0 00-.756.209l-1.271 2.222a.553.553 0 00.482.83c.191 0 .379-.1.483-.281l1.27-2.223a.557.557 0 00-.207-.757zm-3.333 0a.554.554 0 00-.757.209l-1.27 2.222a.553.553 0 00.482.83c.191 0 .379-.1.483-.281l1.27-2.223a.557.557 0 00-.208-.757zm-3.333 0a.554.554 0 00-.757.209l-1.271 2.222a.553.553 0 00.483.83c.19 0 .378-.1.482-.281l1.271-2.223a.556.556 0 00-.208-.757z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudSunRainSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
