import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb as AntBreadcrumb } from "antd";
import { UiBreadcrumb } from "./Breadcrumb.style";

/**
 * 麵包屑
 */

const Breadcrumb = ({ data = [], mb }) => {
	return (
		<UiBreadcrumb mb={mb}>
			<AntBreadcrumb>
				{data.map((item, index, arr) => {
					return (
						<AntBreadcrumb.Item key={item.name}>
							<a href={item.path} disabled={arr.length === index + 1}>
								{item.oneClassIcon && item.oneClassIcon}
								{item.icon && <i className="material-icons-outlined">{item.icon}</i>}
								{item.name}
							</a>
						</AntBreadcrumb.Item>
					);
				})}
			</AntBreadcrumb>
		</UiBreadcrumb>
	);
};

export default Breadcrumb;

Breadcrumb.propTypes = {
	data: PropTypes.array,
	mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
