"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCookie = exports.exportFile = exports.deleteAllCookies = exports.checkLogin = void 0;
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const hostname = window.location.hostname;
    if (hostname.indexOf("oneclass.com.tw") > 0) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=oneclass.com.tw";
    } else {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
    }
  }
};
exports.deleteAllCookies = deleteAllCookies;
const getCookie = name => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};
const checkLogin = cookieLoginName => {
  const gettoken = getCookie(cookieLoginName);
  if (gettoken) {
    return gettoken;
  }
  return "";
};
exports.checkLogin = checkLogin;
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  const hostname = window.location.hostname;
  if (hostname.indexOf("oneclass.com.tw") > 0) {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=oneclass.com.tw";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
};
exports.setCookie = setCookie;
const exportFile = async (url, method, payload) => {
  (0, _axios.default)({
    url,
    method: method || "get",
    data: payload,
    responseType: "blob"
  }).then(res => {
    const fileName = decodeURI(res.headers["content-disposition"].replace(/\w+;filename=(.*)/, "$1")).split("UTF-8''")[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  });
};
exports.exportFile = exportFile;