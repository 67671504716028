import * as React from "react";
function SvgBoxesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.444 13.111h-2.777v3.333l-1.111-.74-1.112.74v-3.333h-2.777a.557.557 0 00-.556.556v6.666c0 .306.25.556.556.556h7.777c.306 0 .556-.25.556-.556v-6.666a.557.557 0 00-.556-.556zM8.111 10.89h7.778c.305 0 .555-.25.555-.556V3.667a.557.557 0 00-.555-.556H13.11v3.333L12 5.704l-1.111.74V3.111H8.11a.557.557 0 00-.555.556v6.666c0 .306.25.556.555.556zm2.222 2.222H7.556v3.333l-1.112-.74-1.11.74v-3.333H2.555a.557.557 0 00-.556.556v6.666c0 .306.25.556.556.556h7.777c.306 0 .556-.25.556-.556v-6.666a.557.557 0 00-.556-.556z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBoxesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
