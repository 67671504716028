import * as React from "react";
function SvgHandHoldingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.627 8.948c-.41-.371-1.048-.347-1.479 0l-3.208 2.566a2.21 2.21 0 01-1.389.486h-4.107a.557.557 0 01-.556-.556c0-.305.25-.555.556-.555h2.718c.553 0 1.066-.378 1.157-.924a1.112 1.112 0 00-1.098-1.298H8.667c-.937 0-1.843.323-2.572.913l-1.615 1.309H2.556a.557.557 0 00-.556.556v3.333c0 .305.25.555.556.555h12.388c.503 0 .993-.17 1.388-.486l5.25-4.201a1.11 1.11 0 00.045-1.698z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandHoldingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
