import * as React from "react";
function SvgTruckPickupSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 13H21v-2a1 1 0 00-1-1h-1.5l-3.4-4.25A2 2 0 0013.54 5H10a1 1 0 00-1 1v4H4a1 1 0 00-1 1v2h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1.55c-.023.165-.05.329-.05.5a3.5 3.5 0 107 0c0-.171-.027-.335-.05-.5h2.1c-.023.165-.05.329-.05.5a3.5 3.5 0 107 0c0-.171-.026-.335-.05-.5h1.55a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM11 7h2.539l2.4 3H11V7zM7.5 17c-.827 0-1.5-.673-1.5-1.5S6.673 14 7.5 14s1.5.673 1.5 1.5S8.327 17 7.5 17zm9 0c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTruckPickupSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
