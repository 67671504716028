import * as React from "react";
function SvgBackToListSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.111 6.227s.2-.4-.2-.4H3.508s-1.502-.1-1.502 1.301V19.04s-.2 1.502 1.702 1.502H16.22s1.302-.1 1.302-1.502v-4.905s-.2-.5-.701-.7c0 0-.7-.3-.7.7v4.905H3.406V7.328H5.91s.2 0 .4-.2l.801-.9z"
        fill="currentColor"
      />
      <path
        d="M12.817 4.926v2.402s6.607-.4 8.509 3.103c0 0 1.701 3.003-.3 6.107 0 0-1.302 2.302-2.303 1.901 0 0-.3-.2-.3-.7 0 0 .5-1.502.4-2.603 0 0 .6-3.503-6.206-3.303v2.602s-.1 1.001-1.302.5L6.31 10.132s-.7-.6 0-1.401l4.905-4.605c.2.1 1.402-.7 1.602.8z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBackToListSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
