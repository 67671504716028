import * as React from "react";
function SvgUserRotationSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.371 14.881c-.18.207-.382.395-.621.534v.896c.649.09.246.204.778.337 1.597.4 2.597.969 2.597 1.602 0 1.208-3.637 2.188-8.125 2.188-4.487 0-8.125-.98-8.125-2.188 0-.844 1.777-1.574 4.375-1.94v-.895a2.499 2.499 0 01-.621-.534C4.299 15.49 2 16.768 2 18.25 2 20.321 6.477 22 12 22s10-1.679 10-3.75c0-1.226-1.573-2.312-4-2.995-.508-.143-.053-.269-.629-.374zM12 7a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm-2.5 7.5v3.75c0 .69.56 1.25 1.25 1.25h2.5c.69 0 1.25-.56 1.25-1.25V14.5c.69 0 1.25-.56 1.25-1.25V9.5c0-1.036-.84-1.875-1.875-1.875h-.46A3.406 3.406 0 0112 7.938c-.505 0-.982-.117-1.414-.313h-.461c-1.036 0-1.875.84-1.875 1.875v3.75c0 .69.56 1.25 1.25 1.25z"
        fill="currentColor"
      />
      <path
        d="M18.517 14.526l-2.433.573 1.231 2.176"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserRotationSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
