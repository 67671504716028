import * as React from "react";
function SvgUserClockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.5 11a4.497 4.497 0 00-4.5 4.5c0 2.488 2.012 4.5 4.5 4.5s4.5-2.012 4.5-4.5-2.012-4.5-4.5-4.5zm2 4.697a.305.305 0 01-.303.303h-1.894a.305.305 0 01-.303-.303v-2.394c0-.166.137-.303.303-.303h.394c.166 0 .303.137.303.303V15h1.197c.166 0 .303.137.303.303v.394zM12 15.5c0-.869.21-1.69.569-2.422A4.162 4.162 0 0011.8 13h-.522a5.445 5.445 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h10.847A5.5 5.5 0 0112 15.5zM9 12a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserClockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
