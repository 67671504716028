import * as React from "react";
function SvgFilterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.062 2H2.939c-.833 0-1.253 1.01-.663 1.6L9.5 10.826v8.049c0 .306.15.593.4.768l3.125 2.187a.938.938 0 001.475-.768V10.826L21.724 3.6c.59-.588.172-1.6-.662-1.6z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFilterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
