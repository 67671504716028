import * as React from "react";
function SvgEqualsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.571 14.143H3.43c-.79 0-1.429.64-1.429 1.428V17c0 .789.64 1.429 1.429 1.429H20.57c.79 0 1.429-.64 1.429-1.429v-1.429c0-.788-.64-1.428-1.429-1.428zm0-8.572H3.43C2.639 5.571 2 6.211 2 7v1.429c0 .788.64 1.428 1.429 1.428H20.57c.79 0 1.429-.64 1.429-1.428V7c0-.789-.64-1.429-1.429-1.429z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEqualsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
