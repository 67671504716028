"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserProfile = exports.loginGetUserProfile = exports.loginByOneClub = void 0;
var _index = _interopRequireDefault(require("../index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import { checkNaniLinkLogin } from "services/oneClub";
/**
 * 取得登錄資料
 */
const loginByOneClub = async _ref => {
  let {
    url,
    jwt
  } = _ref;
  const response = await _index.default.get("".concat(url, "/api/Login"), {}, {
    headers: {
      Authorization: jwt || null
    }
  });
  return response;
};
exports.loginByOneClub = loginByOneClub;
const loginGetUserProfile = async _ref2 => {
  let {
    url
  } = _ref2;
  const response = await _index.default.get("".concat(url, "/api/Member/Profile"));
  return response;
};
exports.loginGetUserProfile = loginGetUserProfile;
const setUserProfile = async _ref3 => {
  let {
    url,
    payload
  } = _ref3;
  const response = await _index.default.post("".concat(url, "/api/Member/Profile"), payload);
  return response;
};
exports.setUserProfile = setUserProfile;