import * as React from "react";
function SvgEnvelopeSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.959 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V4.143C22 2.959 21.04 2 19.857 2zM9.952 12.273C5.903 9.334 5.944 9.327 4.857 8.48V7.357c0-.592.48-1.071 1.072-1.071H18.07c.592 0 1.072.48 1.072 1.071v1.124c-1.088.847-1.046.853-5.095 3.792-.468.341-1.401 1.166-2.048 1.155-.648.01-1.579-.813-2.048-1.155zm9.19-1.98v6.35c0 .592-.479 1.071-1.07 1.071H5.928c-.592 0-1.072-.48-1.072-1.071v-6.35c.623.482 1.488 1.127 4.255 3.135.632.462 1.695 1.435 2.888 1.43 1.2.005 2.278-.985 2.89-1.43 2.765-2.008 3.63-2.653 4.253-3.135z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEnvelopeSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
