import * as React from "react";
function SvgCodeBranchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 7.625a3.124 3.124 0 10-6.25 0c0 1.422.95 2.621 2.246 3-.023.629-.164 1.113-.43 1.441-.601.75-1.925.875-3.328 1.004-1.101.102-2.242.211-3.175.66V8.105A3.123 3.123 0 007.625 2a3.124 3.124 0 00-.938 6.105v7.786A3.132 3.132 0 004.5 18.875a3.124 3.124 0 106.25 0 3.12 3.12 0 00-2-2.914c.121-.203.305-.383.582-.524.633-.32 1.578-.406 2.582-.5 1.649-.152 3.516-.328 4.617-1.695.547-.68.825-1.555.844-2.652A3.138 3.138 0 0019.5 7.625zM7.625 4.5c.344 0 .625.281.625.625a.627.627 0 01-.625.625A.627.627 0 017 5.125c0-.344.281-.625.625-.625zm0 15A.627.627 0 017 18.875c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zM16.375 7c.344 0 .625.281.625.625a.627.627 0 01-.625.625.627.627 0 01-.625-.625c0-.344.281-.625.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCodeBranchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
