import * as React from "react";
function SvgFilePrescriptionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.677 7.01l.442.442a.625.625 0 010 .884L15.2 16.817l1.174 1.174a.625.625 0 010 .884l-.442.442a.625.625 0 01-.884 0l-1.174-1.174-1.174 1.174a.625.625 0 01-.884 0l-.442-.442a.625.625 0 010-.884l1.174-1.174-2.317-2.317H9.5v1.875c0 .345-.28.625-.625.625H8.25a.625.625 0 01-.625-.625v-6.25c0-.345.28-.625.625-.625h3.125a2.5 2.5 0 012.5 2.5 2.49 2.49 0 01-1.307 2.184l1.307 1.307 1.168-1.168a.625.625 0 01.884 0zm-4.552-1.698H9.5v-1.25h1.875a.626.626 0 010 1.25zM19.5 6.762V7h-5V2h.238c.25 0 .489.098.664.273l3.825 3.829a.935.935 0 01.273.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFilePrescriptionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
