import * as React from "react";
function SvgBlindSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.35 21.955a.312.312 0 01-.43-.105l-4.895-8.064c.197-.078.371-.206.506-.37l4.924 8.11a.311.311 0 01-.105.429zm-9.272-7.676l-1.27 3.495 1.41 3.449a1.25 1.25 0 102.314-.947l-2.454-5.997zM8.25 5.438A1.719 1.719 0 108.25 2a1.719 1.719 0 000 3.438zm6.048 6.606L9.611 6.107a.935.935 0 00-.736-.356V5.75h-1.25v.001a.934.934 0 00-.74.36L4.5 9.179v3.738c0 .526.43.969.956.959.509-.01.919-.426.919-.938V9.822L7 9.018v5.496l-2.112 5.809a1.25 1.25 0 002.35.854l2.574-7.082V9.908L8.587 8.353a.156.156 0 11.245-.195l3.996 5.048a.933.933 0 001.316.155.938.938 0 00.155-1.317z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBlindSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
