import React from "react";
import PropTypes from "prop-types";

import { Icon } from "common-components";
import { UiFilterBar, UiSearchInput, UiFilterCondition } from "./FilterBar.style";

/**
 * 篩選bar
 */

const FilterBar = ({ onChange, children, prevChildren }) => {
	return (
		<UiFilterBar>
			{prevChildren}
			<UiSearchInput>
				<Icon name="search" color="#fff" />
				<input type="text" placeholder="搜尋試題ID、試題內容" onChange={(e) => onChange(e.target.value)} />
			</UiSearchInput>
			<UiFilterCondition>{children}</UiFilterCondition>
		</UiFilterBar>
	);
};

export default FilterBar;

FilterBar.propTypes = {
	onChange: PropTypes.func,
	children: PropTypes.node,
	prevChildren: PropTypes.node,
};
