import * as React from "react";
function SvgWheelchairSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.379 17.065l.556 1.12a.625.625 0 01-.282.838l-2.557 1.284a1.25 1.25 0 01-1.688-.588L14.956 14.5H9.5a1.25 1.25 0 01-1.237-1.073C6.939 4.16 7.015 4.737 7 4.5a2.5 2.5 0 012.62-2.497c1.299.06 2.345 1.129 2.38 2.43a2.5 2.5 0 01-2.134 2.54l.182 1.277h5.077c.345 0 .625.28.625.625v1.25c0 .345-.28.625-.625.625h-4.72l.18 1.25h5.165a1.25 1.25 0 011.131.719l2.247 4.781 1.413-.717a.625.625 0 01.838.282zm-7.217-1.315h-.957a4.382 4.382 0 01-4.33 3.75A4.38 4.38 0 014.5 15.125c0-1.621.886-3.04 2.2-3.795l-.37-2.591A6.886 6.886 0 002 15.125 6.883 6.883 0 008.875 22a6.885 6.885 0 006.293-4.11l-1.006-2.14z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgWheelchairSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
