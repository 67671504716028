import * as React from "react";
function SvgMailBulkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.556 18.667c-.89 0-1.778-.778-2.223-1.111C3.111 16 2.444 15.444 2 15.11v4.667c0 .613.498 1.11 1.111 1.11H12c.614 0 1.111-.497 1.111-1.11V15.11c-.444.333-1.111.889-3.333 2.445-.445.333-1.334 1.11-2.222 1.11zM12 12H3.111C2.498 12 2 12.498 2 13.111v.556c.889.666.778.666 4 3 .333.222 1 .889 1.556.889.555 0 1.222-.667 1.555-.778 3.222-2.334 3.111-2.334 4-3v-.667c0-.613-.498-1.111-1.111-1.111zm8.889-3.333H9.778c-.614 0-1.111.497-1.111 1.11v1.112H12c1.153 0 2.104.883 2.212 2.008l.01-.008v4.667h6.667c.613 0 1.111-.498 1.111-1.112V9.778c0-.614-.498-1.111-1.111-1.111zm-1.111 4.444h-2.222V10.89h2.222v2.222zM7.556 9.778c0-1.226.996-2.222 2.222-2.222h7.778V4.222c0-.613-.498-1.11-1.112-1.11H5.334c-.614 0-1.112.497-1.112 1.11v6.667h3.334V9.778z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMailBulkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
