import * as React from "react";
function SvgCloudMoonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.902 15.357c.198-.333.32-.718.32-1.135A2.224 2.224 0 0011.998 12c-.597 0-1.139.24-1.538.621a3.317 3.317 0 00-2.906-1.732 3.334 3.334 0 00-3.333 3.333c0 .07.017.132.02.198A3.326 3.326 0 002 17.555a3.334 3.334 0 003.333 3.333h8.333a2.777 2.777 0 002.777-2.777 2.768 2.768 0 00-2.541-2.754zm7.725-1.885c-3.232.615-6.197-1.864-6.197-5.128a5.219 5.219 0 012.642-4.541c.253-.143.187-.525-.097-.58a6.665 6.665 0 00-7.881 6.555c0 .295.023.583.062.868.205.15.402.309.58.493.395-.163.822-.25 1.263-.25a3.338 3.338 0 013.313 3.705 3.83 3.83 0 011.857 1.826 6.655 6.655 0 004.76-2.444.313.313 0 00-.302-.504z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudMoonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
