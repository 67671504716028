import * as React from "react";
function SvgMarkerpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.2 15.215l4.738 4.72-.824.821c-.19.19-.5.19-.69 0l-4.029-4.038a.484.484 0 01-.002-.682l.807-.82zM3.948 17.766l3.137 2.977-.642.767a.488.488 0 01-.53.149l-3.581-1.203a.484.484 0 01-.197-.795l1.813-1.895zM11.073 19.423c-.436.023-.755-.19-1.05-.486-1.328-1.333-2.664-2.658-3.998-3.983-.758-.754-.694-1.46.103-2.142 3.648-3.125 7.296-6.25 10.941-9.38.649-.556 1.303-.597 1.928.002.867.83 1.717 1.679 2.554 2.54.598.615.589 1.278.033 1.925-3.16 3.668-6.322 7.334-9.48 11.002-.274.319-.594.532-1.03.522z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMarkerpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
