import * as React from "react";
function SvgHeartOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.906 4.51C17.648 2.612 14.16 2.897 12 5.096c-2.16-2.2-5.648-2.488-7.906-.586-2.938 2.473-2.508 6.504-.414 8.64l6.851 6.981a2.048 2.048 0 002.938.004l6.851-6.98c2.09-2.137 2.528-6.168-.414-8.645zm-.922 7.324l-6.851 6.98c-.094.095-.172.095-.266 0l-6.851-6.98C3.59 10.381 3.3 7.631 5.3 5.948c1.52-1.278 3.863-1.086 5.332.41L12 7.752l1.367-1.394c1.477-1.504 3.82-1.688 5.332-.414 1.996 1.683 1.7 4.449.285 5.89z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHeartOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
