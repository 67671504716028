import * as React from "react";
function SvgSortNumericUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.096 12.13a3.527 3.527 0 00-2.455 2.418 3.585 3.585 0 003.073 4.577c-.28.237-.594.431-.93.577-.339.152-.483.556-.366.908l.442.893c.128.385.56.602.933.442 2.59-1.105 3.85-2.75 3.85-5.893v-.48a3.582 3.582 0 00-4.547-3.442zm.975 4.334a.893.893 0 110-1.786.893.893 0 010 1.786zM15.928 4.857h.714v2.857h-.714a.714.714 0 00-.714.715v1.428a.714.714 0 00.714.714h4.286a.714.714 0 00.714-.714V8.43a.714.714 0 00-.714-.715H19.5v-5A.714.714 0 0018.785 2h-2.143a.715.715 0 00-.637.394l-.715 1.429a.716.716 0 00.638 1.034zM7.148 2.21a.714.714 0 00-1.01 0L2.566 6.495a.715.715 0 00.505 1.22h2.143v13.57a.714.714 0 00.714.715h1.429a.714.714 0 00.714-.714V7.714h2.143c.634 0 .954-.77.505-1.219L7.147 2.21z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortNumericUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
