import * as React from "react";
function SvgEuroSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.733 19.043a.535.535 0 00-.643-.418 9.378 9.378 0 01-2.029.241c-2.534 0-4.55-1.553-5.422-3.814h5.076a.535.535 0 00.522-.418l.285-1.267a.536.536 0 00-.523-.653h-5.993a9.632 9.632 0 01.006-1.886h6.545a.535.535 0 00.523-.42l.29-1.33a.536.536 0 00-.523-.65h-6.173c.921-2.008 2.8-3.349 5.251-3.349.647 0 1.275.1 1.69.185a.536.536 0 00.625-.385l.535-1.98a.535.535 0 00-.416-.667A13 13 0 0016.007 2c-4.339 0-7.84 2.69-9.132 6.429H5.398a.536.536 0 00-.535.535v1.329c0 .296.24.535.535.535h.963a13.252 13.252 0 00-.008 1.886h-.955a.536.536 0 00-.535.536v1.266c0 .296.24.536.535.536h1.345C7.86 19.084 11.348 22 16.007 22c1.174 0 2.168-.203 2.728-.348a.536.536 0 00.392-.623l-.394-1.986z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgEuroSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
