import { useState, useEffect } from "react";
import { Breadcrumb } from "components";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { intervalToDuration } from "date-fns";

import { UiAudiosSearchPage } from "./AudiosSearch.style";
import { Select, Button, Input, Table, Pagination } from "common-components";
import { useMachine } from "@xstate/react";
import { getAudiosSearchList } from "api/audios";

const breadcrumbList = [
	{
		icon: "unarchive",
		name: "音檔查詢",
		path: "/audiosSearch",
	},
];

export const audioTypeMap = {
	all: "0",
	commonAudios: "1",
	questionAudios: "2",
	questionTypeAudios: "3",
};

const allAudioTypeMap = [
	{ name: "全部音檔", value: audioTypeMap.all },
	{ name: "定義音檔", value: audioTypeMap.commonAudios },
	{ name: "題目音檔", value: audioTypeMap.questionAudios },
	{ name: "題型音檔", value: audioTypeMap.questionTypeAudios },
];

const initSendFormData = {
	fileName: "",
	audioType: allAudioTypeMap[0].value,
};

export const AudiosSearchPage = () => {
	const [sendFormData, setSendFormData] = useState(initSendFormData);
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 100,
	});
	const [isLoading, setIsLoading] = useState(false);

	const [stateAudiosSearchList, sendAudiosSearchList] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getAudiosSearchList(event.payload);
				const { audiosDetail, total } = response.data;

				setIsLoading(false);

				return {
					audioData: audiosDetail,
					audioTotal: total,
				};
			},
		},
	});

	const { audioData, audioTotal } = stateAudiosSearchList.context.result || {};

	const handleSearchAudios = (isSearch = false) => {
		setIsLoading(true);
		sendAudiosSearchList(FetchEventType.Fetch, {
			payload: {
				pagenumber: isSearch ? 1 : pageState.currentPage,
				pagesize: pageState.limit,
				audioType: sendFormData.audioType,
				fileName: sendFormData.fileName,
				startDateTime: "",
				endDateTime: "",
			},
		});

		if (isSearch) {
			setPageState({
				...pageState,
				currentPage: 1,
			});
		}
	};

	const onSelectChange = (key, value) => {
		setSendFormData({
			...sendFormData,
			[key]: value,
		});
	};

	const goTop = () => {
		const scroll = document.querySelector(".CheckListTable > .body");
		scroll.scrollTop = 0;
	};

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		goTop();
	};

	useEffect(() => {
		handleSearchAudios();
	}, [pageState]);

	const formatDuration = (second) => {
		const duration = intervalToDuration({ start: 0, end: second * 1000 });
		const formatted = `${duration.hours.toString().padStart(2, "0")}:${duration.minutes
			.toString()
			.padStart(2, "0")}:${duration.seconds.toString().padStart(2, "0")}`;
		return formatted;
	};

	return (
		<UiAudiosSearchPage id="audiosSearch">
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="searchToolBar">
				<div className="selectSearchGroup">
					<Select
						placeholder="音檔類型"
						options={allAudioTypeMap}
						value={sendFormData.audioType}
						onChange={(val) => {
							onSelectChange("audioType", val);
						}}
					/>
					<Input
						placeholder="請輸入檔案名稱"
						maxLength={30}
						value={sendFormData.fileName}
						onChange={(e) => {
							onSelectChange("fileName", e.target.value);
						}}
						style={{ width: "130px" }}
					/>
					<Button.IconButton
						variant="blue"
						full={false}
						icon={
							<i className="material-icons" style={{ fontSize: "20px" }}>
								search
							</i>
						}
						onClick={() => handleSearchAudios(true)}>
						搜尋
					</Button.IconButton>
				</div>
			</div>
			<Table margin="3" className={"CheckListTable"}>
				<Table.Header className="header">
					<Table.Row>
						<Table.Item flex="3">檔案名稱</Table.Item>
						<Table.Item flex="1">音檔類型</Table.Item>
						<Table.Item flex="1">時間長度</Table.Item>
					</Table.Row>
				</Table.Header>
				<Table.Body className="body">
					{isLoading ? (
						<div className="loading">
							<Spin indicator={<Loading3QuartersOutlined spin={true} />} size="large" />
						</div>
					) : audioData && audioData.length > 0 ? (
						audioData.map((item) => (
							<Table.Row key={item.cms + item.uid}>
								<Table.Item flex="3">{item.audioName}</Table.Item>
								<Table.Item flex="1">
									{allAudioTypeMap.find((type) => Number(type.value) === item.audioType)?.name || "未知類型"}
								</Table.Item>
								<Table.Item flex="1">{formatDuration(item.duration)}</Table.Item>
							</Table.Row>
						))
					) : (
						<div className="tableNoData">
							<img src={`/assets/noData.png`} alt="noData" />
							無資料顯示
						</div>
					)}
				</Table.Body>
			</Table>
			<Pagination
				total={audioTotal || 0}
				defaultCurrent={1}
				current={pageState.currentPage}
				onChange={pageChange}
				defaultPageSize={pageState.limit}
			/>
		</UiAudiosSearchPage>
	);
};
