import * as React from "react";
function SvgHatWizardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 19.5H2.625a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h18.75c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625zM9.5 17L7 15.75l2.5-1.25 1.25-2.5L12 14.5l2.5 1.25L12 17l-.625 1.25H19.5l-3.375-7.876a2.498 2.498 0 01-.074-1.776L18.25 2l-7.327 4.187a5 5 0 00-2.089 2.31L4.5 18.25h5.625L9.5 17zM12 8.25L12.625 7l.625 1.25 1.25.625-1.25.625-.625 1.25L12 9.5l-1.25-.625L12 8.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHatWizardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
