import * as React from "react";
function SvgSortAlphaUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.715 7.714h2.142v13.572a.714.714 0 00.715.714H7a.715.715 0 00.715-.714V7.714h2.142c.635 0 .955-.77.505-1.219L6.791 2.21a.714.714 0 00-1.01 0L2.21 6.495a.715.715 0 00.505 1.22zm17.857 5.715h-5.715a.714.714 0 00-.714.714v1.428a.714.714 0 00.714.715h2.5l-2.734 3.145a1.427 1.427 0 00-.48 1.068v.787a.715.715 0 00.714.714h5.715a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714h-2.5l2.735-3.145a1.428 1.428 0 00.479-1.068v-.787a.714.714 0 00-.714-.714zm1.386-3.812l-2.646-7.143A.714.714 0 0018.64 2h-1.85a.714.714 0 00-.673.474L13.47 9.617a.715.715 0 00.672.954h1.109a.713.713 0 00.68-.494l.197-.577h3.17l.196.577a.715.715 0 00.682.494h1.109a.714.714 0 00.672-.954zM16.983 7l.732-2.143L18.446 7h-1.463z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortAlphaUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
