import * as React from "react";
function SvgLaughWinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm.81 7.988c.162-1.016 1.38-1.698 2.416-1.698s2.254.682 2.415 1.698a.483.483 0 01-.798.435l-.383-.342c-.597-.533-1.863-.533-2.46 0l-.387.346a.487.487 0 01-.803-.44zM8.774 8.129c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm9.31 5.887a5.812 5.812 0 01-5.761 5.08h-.646a5.812 5.812 0 01-5.762-5.08.648.648 0 01.641-.726h10.887c.388 0 .69.339.642.726z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaughWinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
