import * as React from "react";
function SvgVolumeDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.2 2.367L8.564 7H3.25C2.56 7 2 7.56 2 8.25v7.5c0 .69.56 1.25 1.25 1.25h5.315l4.634 4.633c.783.782 2.134.232 2.134-.884V3.25c0-1.118-1.352-1.665-2.134-.884zm6.416 5.629a1.251 1.251 0 00-1.206 2.19A2.079 2.079 0 0119.5 12c0 .749-.418 1.444-1.09 1.813a1.251 1.251 0 001.206 2.19A4.58 4.58 0 0022 12c0-1.66-.913-3.193-2.384-4.003z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVolumeDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
