import { Api } from "common-components";

/**
 * 取得音檔入庫總表
 */
export const getAudiosRecord = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/audiorecord`, payload);
	return response;
};

/**
 * 取得音檔入庫詳細頁(看更多)
 */
export const getAudiosDetail = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/detail`, payload);
	return response;
};

/**
 * 音檔入庫總表完成作業
 */
export const getAudiosRecordCheck = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/audiorecord/check`, payload);
	return response;
};

/**
 * 音檔入庫總表刪除作業
 */
export const deleteAudiosRecord = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/audiorecord/hide`, payload);
	return response;
};

/**
 * (上傳音檔)定義音檔入庫
 */
export const importCommonAudios = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/import/common-audios`, payload);
	return response;
};

/**
 * (上傳音檔)題目音檔入庫
 */
export const importQuestionAudios = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/import/question-audios`, payload);
	return response;
};
/**
 * (上傳音檔)題型音檔入庫
 */
export const importQuestionTypeAudios = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/import/questiontype-audios`, payload);
	return response;
};

/**
 * 取得音檔入庫表 (音檔查詢)
 */
export const getAudiosSearchList = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/audio/queryaudios`, payload);
	return response;
};
