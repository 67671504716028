import * as React from "react";
function SvgUserSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.805 18.316l-8.484-6.557c1.556-.55 2.678-2.015 2.678-3.759a4 4 0 00-4-4C9.902 4 8.202 5.619 8.033 7.669L3.421 4.106a.502.502 0 00-.703.088l-.613.787a.498.498 0 00.088.7l18.387 14.21a.502.502 0 00.703-.088l.613-.79a.495.495 0 00-.09-.697zM5 17.2v1.3A1.5 1.5 0 006.5 20h10.944L8.48 13.072A4.194 4.194 0 005 17.2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
