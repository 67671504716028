import * as React from "react";
function SvgStroopwafelSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.348 10.232L7.58 12l1.768 1.768L11.116 12l-1.768-1.768zm4.42-.884L12 7.58l-1.768 1.768L12 11.116l1.768-1.768zm-3.536 5.304L12 16.42l1.768-1.768L12 12.884l-1.768 1.768zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm7.292 11.547l-.442.442a.313.313 0 01-.441 0l-1.105-1.105-1.768 1.767 1.326 1.326.663-.663a.313.313 0 01.441 0l.442.442a.313.313 0 010 .442l-.663.662.663.663a.313.313 0 010 .442l-.442.442a.313.313 0 01-.441 0l-.663-.663-.663.663a.313.313 0 01-.442 0l-.442-.442a.313.313 0 010-.442l.663-.663-1.326-1.325-1.768 1.768 1.105 1.105a.313.313 0 010 .442l-.441.441a.313.313 0 01-.442 0L12 18.187l-1.105 1.105a.313.313 0 01-.442 0l-.442-.442a.313.313 0 010-.441l1.105-1.105-1.767-1.768-1.326 1.325.663.663a.313.313 0 010 .442l-.442.442a.313.313 0 01-.442 0l-.663-.663-.662.663a.313.313 0 01-.442 0l-.442-.442a.313.313 0 010-.442l.663-.663-.663-.663a.313.313 0 010-.441l.442-.442a.313.313 0 01.442 0l.662.663 1.326-1.326-1.767-1.768-1.105 1.105a.313.313 0 01-.442 0l-.443-.442a.313.313 0 010-.442L5.813 12l-1.105-1.105a.313.313 0 010-.442l.442-.442a.313.313 0 01.441 0l1.105 1.105 1.768-1.768-1.326-1.325-.663.663a.313.313 0 01-.441 0l-.442-.442a.313.313 0 010-.442l.663-.663-.663-.663a.313.313 0 010-.442l.442-.441a.313.313 0 01.441 0l.663.662.663-.662a.313.313 0 01.442 0l.442.441a.313.313 0 010 .442l-.663.663 1.326 1.326 1.768-1.768-1.105-1.105a.313.313 0 010-.442l.441-.441a.313.313 0 01.442 0L12 5.813l1.105-1.105a.313.313 0 01.442 0l.442.442a.313.313 0 010 .441l-1.105 1.105 1.768 1.768 1.325-1.326-.662-.663a.313.313 0 010-.441l.441-.442a.312.312 0 01.442 0l.663.663.663-.663a.313.313 0 01.442 0l.441.442a.313.313 0 010 .441l-.663.663.663.663a.313.313 0 010 .442l-.441.442a.313.313 0 01-.442 0l-.663-.663-1.326 1.326 1.768 1.768 1.105-1.105a.313.313 0 01.442 0l.441.441a.313.313 0 010 .442L18.187 12l1.105 1.105a.313.313 0 010 .442zM12.884 12l1.768 1.768L16.42 12l-1.768-1.768L12.884 12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStroopwafelSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
