import * as React from "react";
function SvgMountainSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.841 18.46l-9-14a1 1 0 00-1.682 0l-9 14A1 1 0 003 20h18a1 1 0 00.841-1.54zM12 6.85L14.668 11H12l-2 2-1.19-1.19L12 6.85z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMountainSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
