import * as React from "react";
function SvgHardHatSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 13.25a7.5 7.5 0 00-4.656-6.938L14.5 9.5V5.125a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V9.5L7.906 6.312A7.5 7.5 0 003.25 13.25v2.5h17.5v-2.5zm.625 3.75H2.625a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h18.75a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHardHatSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
