import * as React from "react";
function SvgIdBadgeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 2H6.375C5.34 2 4.5 2.84 4.5 3.875v16.25C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V3.875C19.5 2.84 18.66 2 17.625 2zm0 18.125H6.375V3.875h11.25v16.25zm-7.5-13.75h3.75a.627.627 0 00.625-.625.627.627 0 00-.625-.625h-3.75a.627.627 0 00-.625.625c0 .344.281.625.625.625zM12 13.25c1.379 0 2.5-1.121 2.5-2.5s-1.121-2.5-2.5-2.5a2.502 2.502 0 00-2.5 2.5c0 1.379 1.121 2.5 2.5 2.5zm-3.5 5h7c.484 0 .875-.336.875-.75v-.75c0-1.242-1.176-2.25-2.625-2.25-.422 0-.73.313-1.75.313-1.05 0-1.305-.313-1.75-.313-1.45 0-2.625 1.008-2.625 2.25v.75c0 .414.39.75.875.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIdBadgeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
