import * as React from "react";
function SvgTorahSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.002 15.453l.553-.927h-1.108l.555.927zm3.1-5.188H13.95l.577.963.575-.963zM3.5 4C2.672 4 2 4.448 2 5v14c0 .552.672 1 1.5 1S5 19.552 5 19V5c0-.552-.672-1-1.5-1zm5.398 9.734h1.152l-.577-.963-.575.963zm5.054 0h1.151l-.576-.962-.575.962zM6 18.5h12v-13H6v13zm2.087-8.692a.663.663 0 01.577-.334h1.858l.91-1.522a.66.66 0 011.13-.005l.915 1.526h1.86a.66.66 0 01.565 1L14.988 12l.918 1.531a.647.647 0 01.008.66.664.664 0 01-.578.335h-1.858l-.91 1.522a.66.66 0 01-.892.24.65.65 0 01-.239-.234l-.914-1.528H8.664a.659.659 0 01-.566-1L9.011 12l-.917-1.531a.647.647 0 01-.007-.66zM20.5 4c-.828 0-1.5.448-1.5 1v14c0 .552.672 1 1.5 1s1.5-.448 1.5-1V5c0-.552-.672-1-1.5-1zM12 8.548l-.556.925h1.108L12 8.548zm-1.952 1.719h-1.15l.576.962.574-.962zm.925 3.468h2.056L14.065 12l-1.038-1.735H10.97L9.934 12l1.039 1.735z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTorahSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
