import * as React from "react";
function SvgLightbulbOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 5.125a3.754 3.754 0 00-3.75 3.75.625.625 0 101.25 0c0-1.379 1.122-2.5 2.5-2.5a.625.625 0 100-1.25zM8.877 19.937c0 .123.037.243.105.345l.957 1.44a.625.625 0 00.52.278h3.08c.21 0 .406-.105.521-.279l.958-1.439a.628.628 0 00.104-.345l.002-1.687H8.876l.001 1.687zM12 2C8.005 2 5.125 5.241 5.125 8.875c0 1.733.642 3.315 1.701 4.523.65.742 1.67 2.297 2.048 3.6V17h1.875v-.004c0-.187-.028-.372-.084-.55a12.319 12.319 0 00-2.428-4.284 4.984 4.984 0 01-1.235-3.287c-.008-2.876 2.33-5 4.998-5 2.757 0 5 2.243 5 5 0 1.21-.439 2.377-1.236 3.287a12.346 12.346 0 00-2.426 4.276c-.057.18-.087.369-.087.558V17h1.875v-.002c.378-1.303 1.398-2.858 2.048-3.6a6.84 6.84 0 001.701-4.523A6.875 6.875 0 0012 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLightbulbOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
