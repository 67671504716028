import * as React from "react";
function SvgRaisehandSolid1(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.817 7.906c0 2.71-2.189 4.906-4.888 4.906-2.7 0-4.888-2.197-4.888-4.906C9.04 5.196 11.229 3 13.929 3c2.7 0 4.888 2.196 4.888 4.906z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 20.608v-2.14c0-1.835-1.035-2.741-3.134-2.999H13.97c-4.59 0-8.31-3.734-8.31-8.34 0-.871.134-1.711.38-2.5C6.277 3.87 5.763 3 4.97 3H3.19a.982.982 0 00-.954.694A12.312 12.312 0 001.75 7.13c0 3.596 1.542 6.83 3.997 9.073.225.206.361.493.361.798v3.608c0 .571.464 1.034 1.035 1.034h9.817a.62.62 0 00.62-.62v-2.553a.517.517 0 011.036 0v2.553c0 .342.277.62.62.62h1.48c.57 0 1.034-.463 1.034-1.034z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRaisehandSolid1);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
