import * as React from "react";
function SvgExternalLinkSquareAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 4.143v15.714C22 21.041 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.959 2.96 2 4.143 2h15.714C21.041 2 22 2.96 22 4.143zm-3.929.714h-4.998c-.952 0-1.432 1.155-.758 1.83l1.428 1.427-8.729 8.73a.536.536 0 000 .757L6.4 18.986c.21.21.549.21.758 0l8.729-8.729 1.428 1.428c.67.671 1.829.2 1.829-.757v-5c0-.591-.48-1.07-1.072-1.07z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExternalLinkSquareAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
