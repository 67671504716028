import * as React from "react";
function SvgArrowAltCircleUpOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22c5.524 0 10-4.476 10-10S17.524 2 12 2 2 6.476 2 12s4.476 10 10 10zm0-18.065A8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064 8.062 8.062 0 013.935 12 8.062 8.062 0 0112 3.935zm.806 13.226h-1.612a.485.485 0 01-.484-.484V12H8.008a.485.485 0 01-.343-.827l3.992-3.992c.19-.19.496-.19.686 0l3.992 3.992a.484.484 0 01-.343.827H13.29v4.677a.485.485 0 01-.483.484z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowAltCircleUpOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
