import * as React from "react";
function SvgBlenderSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 17h-10a2.5 2.5 0 00-2.5 2.5v1.25c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V19.5a2.5 2.5 0 00-2.5-2.5zm-5 3.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM14.813 4.5h6.505L22 2H3.875C2.839 2 2 2.84 2 3.875v6.25C2 11.161 2.84 12 3.875 12h4.034l.341 3.75h10l.682-2.5h-4.12a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313h4.46l.682-2.5h-5.142a.312.312 0 01-.313-.313v-.624c0-.173.14-.313.313-.313h5.482l.682-2.5h-6.165a.312.312 0 01-.312-.313v-.625c0-.172.14-.312.313-.312zM4.5 9.5v-5h2.727l.455 5H4.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBlenderSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
