import { Api } from "common-components";

/**
 * 下載英聽題本
 */
export const exportEnglishListenFile = async (payload) => {
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/englishlisten/question/exportenglistenfile`,
		payload,
	);
	return response;
};
