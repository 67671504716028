import * as React from "react";
function SvgDiceD6Solid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.742 6.295l-6.484-3.94a2.41 2.41 0 00-2.517 0l-6.483 3.94a.333.333 0 00.003.565L12 11.475l7.74-4.615a.333.333 0 00.002-.565zm.54 1.743l-7.657 4.566v8.743c0 .503.523.817.94.563l5.943-3.612c.769-.467 1.242-1.326 1.242-2.254V8.322c0-.25-.26-.408-.468-.284zM3.25 8.322v7.722c0 .929.473 1.787 1.242 2.254l5.944 3.612c.416.253.939-.06.939-.564v-8.742L3.718 8.038c-.209-.124-.468.033-.468.284z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDiceD6Solid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
