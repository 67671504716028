import * as React from "react";
function SvgCartPlusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.525 14.222H9.346l.228 1.111h9.32c.534 0 .93.497.812 1.018l-.191.843a1.945 1.945 0 01-.877 3.695 1.952 1.952 0 01-1.915-1.89 1.938 1.938 0 01.583-1.444h-7.28a1.945 1.945 0 11-2.328-.297L5.261 5.333H2.833A.833.833 0 012 4.5v-.556c0-.46.373-.833.833-.833h3.56c.396 0 .737.279.817.666l.318 1.556h13.638c.535 0 .931.497.813 1.018l-1.642 7.223a.833.833 0 01-.812.648zm-3.358-5.278H14.5V7.556A.556.556 0 0013.944 7h-.555a.556.556 0 00-.556.556v1.388h-1.666a.556.556 0 00-.556.556v.556c0 .306.249.555.556.555h1.666V12c0 .307.25.556.556.556h.555A.556.556 0 0014.5 12v-1.389h1.667a.556.556 0 00.555-.556V9.5a.556.556 0 00-.555-.556z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCartPlusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
