import styled from "styled-components";

export const UiErrorQuestionReportPage = styled.div`
	/* display: flex;
	flex-direction: column;
	height: 100%; */

	.breadBlock {
		display: flex;
		padding: 8px 0;
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.selectSearchGroup {
			display: flex;
			flex: 0.8;

			> * {
				margin-right: 8px;
			}

			> div {
				margin-right: 8px;
				width: 130px;
			}

			.dateFilterBox {
				min-width: 250px;
			}
		}

		.actionBox {
			display: flex;

			> * {
				margin-left: 8px;
			}
		}
	}

	.questionSearchTableContent {
		height: calc(100% - 44px);

		.questionSearchTable {
			height: calc(100% - 38px);
			margin-bottom: 8px;

			.tableNoData {
				margin: 24px auto;
				width: 50%;
				font-size: 24px;
				text-align: center;
				color: #fff;
			}

			& > div {
				.tableItem {
					flex: 1 0 200px;
				}

				.checkTableItem {
					min-width: 60px;
					text-align: left;
					flex: 1 0 25px;

					.tableCheckHeader {
						width: 100%;
					}

					.tableCheck {
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex: 1;

						> div {
							:hover {
								color: #ffb800;
							}
						}

						.ant-checkbox-wrapper {
							align-items: center;

							.ant-checkbox {
								top: 0;
							}

							& + div {
								position: relative;
								right: 0.3em;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}

	.clickable > div {
		cursor: pointer;
	}

	.delete {
		color: red;
	}

	.availability {
		padding: 4px;
		margin: 0 2px;

		&.red {
			background-color: red;
		}

		&.green {
			background-color: green;
		}
	}
`;

export const UiPopupContainer = styled.div`
	.ant-image-preview-img-wrapper::before {
		height: 40px;
	}

	.ant-image-preview-img {
		margin: auto;
		width: 100%;
		max-width: 660px;
	}
`;
