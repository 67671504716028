import * as React from "react";
function SvgStopCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0-5.524-4.476-10-10-10S2 6.476 2 12s4.476 10 10 10 10-4.476 10-10zM3.935 12A8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064 8.062 8.062 0 013.935 12zm11.936-3.226v6.452c0 .355-.29.645-.645.645H8.774a.647.647 0 01-.645-.645V8.774c0-.355.29-.645.645-.645h6.452c.355 0 .645.29.645.645z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStopCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
