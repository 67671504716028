import * as React from "react";
function SvgIceCreamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.62 8.261h-.036A5.622 5.622 0 0012 2a5.62 5.62 0 00-5.584 6.261H6.38a1.873 1.873 0 000 3.747h11.24a1.873 1.873 0 000-3.747zm-6.737 13.024a1.23 1.23 0 002.234 0l3.879-8.028H7.004l3.879 8.028z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIceCreamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
