import * as React from "react";
function SvgRocketSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.731 2.746a.675.675 0 00-.477-.476C19.997 2 19.012 2 18.031 2 14 2 11.582 4.156 9.776 7h-4.07c-.639 0-1.39.464-1.676 1.035l-1.93 3.86a1.11 1.11 0 00-.099.418.938.938 0 00.938.937h4.055l-.878.878c-.444.444-.508 1.26 0 1.767l1.988 1.989c.436.437 1.256.514 1.769 0l.878-.878v4.056a.938.938 0 00.938.938c.144-.005.286-.039.418-.098l3.857-1.93c.57-.285 1.035-1.035 1.035-1.674v-4.08c2.836-1.809 5.001-4.234 5.001-8.245.004-.985.004-1.97-.268-3.227zm-4.73 5.816a1.563 1.563 0 11.001-3.125 1.563 1.563 0 010 3.125z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRocketSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
