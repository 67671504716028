"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.required = exports.regex = exports.createValidator = exports.checkDuplicateName = void 0;
const toSBC = str => {
  //轉半形
  let result = "";
  const len = str.length;
  for (let i = 0; i < len; i++) {
    let cCode = str.charCodeAt(i);
    //全形與半形相差（除空格外）：65248（十進位制）
    cCode = cCode >= 0xff01 && cCode <= 0xff5e ? cCode - 65248 : cCode;
    //處理空格
    cCode = cCode === 0x03000 ? 0x0020 : cCode;
    result += String.fromCharCode(cCode);
  }
  return result;
};
const isEmpty = value => value === undefined || value === null || value === "" ? false : true;
const required = value => isEmpty(value);
exports.required = required;
const regex = (value, re) => RegExp(re).test(value);
exports.regex = regex;
const checkDuplicateName = (name, checkArray) => {
  for (const item of checkArray) {
    const newItem = toSBC(item).toLowerCase();
    if (newItem === toSBC(name).toLowerCase()) return false;
  }
  return true;
};
exports.checkDuplicateName = checkDuplicateName;
const createValidator = (formData, rules) => {
  let isPass = false;
  const errorGroup = {};
  Object.entries(formData).forEach(_ref => {
    let [key, value] = _ref;
    let isFirstValidator = true;
    if (!rules[key]) return;
    rules[key].forEach(rule => {
      if (!rule.validate(value)) {
        if (!isFirstValidator) return;
        errorGroup[key] = {
          value: "",
          message: rule.message
        };
        isFirstValidator = false;
        isPass = true;
      }
    });
  });
  return [isPass, errorGroup];
};
exports.createValidator = createValidator;