import * as React from "react";
function SvgCarCrashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.477 10.9l-.388 1.45a2.946 2.946 0 00-.076 1.074l-1.1.906a.283.283 0 01-.457-.274l.483-2.396a.282.282 0 00-.25-.338l-2.433-.236a.283.283 0 01-.13-.516l2.036-1.354a.283.283 0 00.062-.415L2.671 6.913a.283.283 0 01.274-.456l2.396.482a.283.283 0 00.338-.25l.236-2.433a.283.283 0 01.517-.13l1.353 2.036c.093.14.286.168.415.062l1.888-1.553c.205-.17.51.013.457.274L10.19 6.7c-.085.095-.17.19-.247.293L8.939 8.336l-.335.448A3.014 3.014 0 006.477 10.9zm15.455 4.141l-.388 1.45a1.958 1.958 0 01-.549.917c-.085.08-.176.153-.274.218l-.437 1.633a.998.998 0 01-1.224.707l-.966-.259a1 1 0 01-.707-1.225l.258-.966-7.727-2.07-.259.966a1 1 0 01-1.225.707l-.965-.259a1 1 0 01-.708-1.224l.438-1.633a2.08 2.08 0 01-.128-.326 1.958 1.958 0 01-.016-1.069l.388-1.449a1.985 1.985 0 011.152-1.32 2.01 2.01 0 01.578-.147l.567-.757 1.005-1.343A3.511 3.511 0 0112.4 6.38a3.469 3.469 0 012.053-.07l3.833 1.027a3.472 3.472 0 012.57 2.966l.198 1.665.113.94c.324.234.571.56.71.935.137.371.166.787.055 1.2zM9.987 11.323c-.58-.155-1.07.127-1.224.704-.155.578.128 1.067.708 1.222.58.155 1.423.485 1.578-.093.155-.578-.483-1.677-1.062-1.833zm9.081.88l-.198-1.665a1.498 1.498 0 00-1.102-1.27L13.935 8.24a1.5 1.5 0 00-1.59.55l-1.004 1.343 5.375 1.44 2.352.63zm.578 1.708c-.58-.155-1.681.479-1.836 1.056-.154.578.74.715 1.32.87.58.155 1.07-.126 1.224-.704.155-.577-.128-1.066-.708-1.222z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCarCrashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
