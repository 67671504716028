import * as React from "react";
function SvgAngryOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm0-5.806a4.553 4.553 0 00-3.5 1.637.967.967 0 00.125 1.363.966.966 0 001.363-.12c1-1.199 3.024-1.199 4.024 0a.97.97 0 001.488-1.242 4.553 4.553 0 00-3.5-1.638zm-1.935-2.903a.969.969 0 00.278-1.895l-3.226-.968a.969.969 0 00-.556 1.855l1.137.343c-.125.197-.214.419-.214.669 0 .714.576 1.29 1.29 1.29.714 0 1.29-.58 1.29-1.294zm8.024-2.214a.967.967 0 00-1.206-.65l-3.226.969a.968.968 0 00.278 1.895 1.289 1.289 0 102.58 0c.001-.25-.088-.472-.213-.67l1.137-.342a.963.963 0 00.65-1.202z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAngryOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
