import * as React from "react";
function SvgSquareRootAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.837 11.837l-.785-.785a.556.556 0 00-.786 0l-1.6 1.599-1.599-1.6a.556.556 0 00-.785 0l-.786.786a.556.556 0 000 .786l1.6 1.6-1.6 1.598a.556.556 0 000 .786l.786.786a.556.556 0 00.785 0l1.6-1.6 1.599 1.6a.556.556 0 00.786 0l.785-.786a.556.556 0 000-.785l-1.6-1.6 1.6-1.6a.556.556 0 000-.785zm-.67-8.726h-8.485c-.505 0-.946.34-1.074.829L8.664 15.148l-2.029-3.684a1.112 1.112 0 00-.973-.575H2.833a.833.833 0 00-.833.833v1.667c0 .46.373.833.833.833h1.515l3.086 5.685c.434.687 1.035.982 1.682.982.6 0 1.543-.313 1.885-1.44l3.396-13.005h6.77c.46 0 .833-.373.833-.833V3.944a.833.833 0 00-.833-.833z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSquareRootAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
