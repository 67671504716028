import * as React from "react";
function SvgSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.58 19.895L2.192 5.685a.5.5 0 01-.088-.702l.614-.79a.5.5 0 01.702-.088l18.386 14.21a.5.5 0 01.088.702l-.614.79a.5.5 0 01-.702.088z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
