import * as React from "react";
function SvgHddSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 13.667V17c0 .92-.746 1.667-1.667 1.667H3.667C2.747 18.667 2 17.92 2 17v-3.333C2 12.747 2.746 12 3.667 12h16.666c.92 0 1.667.746 1.667 1.667zm-1.667-2.778c.367 0 .73.072 1.069.214L18.05 6.076a1.667 1.667 0 00-1.386-.743H7.336a1.667 1.667 0 00-1.386.742l-3.352 5.028a2.761 2.761 0 011.069-.214h16.666zm-1.666 3.333a1.111 1.111 0 100 2.222 1.111 1.111 0 000-2.222zm-3.334 0a1.111 1.111 0 100 2.223 1.111 1.111 0 000-2.223z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHddSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
