import * as React from "react";
function SvgFlaskSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.328 17.762l-4.578-7.364V4.5h.312c.52 0 .938-.418.938-.938v-.624A.935.935 0 0016.062 2H7.937A.935.935 0 007 2.938v.624c0 .52.418.938.937.938h.313v5.898l-4.578 7.364C2.527 19.602 3.847 22 6.019 22H17.98c2.176 0 3.492-2.402 2.348-4.238zM8.636 14.5l1.883-3.031c.145-.203.227-.453.227-.719V4.5h2.5v6.25c0 .27.086.516.226.719l1.883 3.031H8.636z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFlaskSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
