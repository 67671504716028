import * as React from "react";
function SvgTicketAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.444 8.667h11.112v6.666H6.444V8.667zM20.334 12c0 .92.745 1.667 1.666 1.667V17c0 .92-.746 1.667-1.667 1.667H3.667C2.747 18.667 2 17.92 2 17v-3.333a1.667 1.667 0 000-3.334V7c0-.92.746-1.667 1.667-1.667h16.666C21.253 5.333 22 6.08 22 7v3.333c-.92 0-1.667.746-1.667 1.667zm-1.667-3.611a.833.833 0 00-.834-.834H6.167a.833.833 0 00-.834.834v7.222c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833V8.389z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTicketAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
