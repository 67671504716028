import * as React from "react";
function SvgMoneyBillSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 6H3a1 1 0 00-1 1v10a1 1 0 001 1h18a1 1 0 001-1V7a1 1 0 00-1-1zM3.5 16.5v-2a2 2 0 012 2h-2zm0-7v-2h2a2 2 0 01-2 2zM12 15c-1.38 0-2.5-1.343-2.5-3s1.12-3 2.5-3 2.5 1.343 2.5 3-1.12 3-2.5 3zm8.5 1.5h-2a2 2 0 012-2v2zm0-7a2 2 0 01-2-2h2v2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoneyBillSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
