"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiIconPop = exports.UiIcon = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiIcon = exports.UiIcon = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: inline-flex;\n\ttransform: ", ";\n\tcolor: ", ";\n\tbackground-color: ", ";\n\tborder-radius: 4px;\n\tcursor: ", ";\n\t", "\n"])), _ref => {
  let {
    rotate
  } = _ref;
  return "rotate(".concat(rotate, "deg) ");
}, _ref2 => {
  let {
    color,
    focus,
    focuscolor
  } = _ref2;
  return (focus ? focuscolor : color) || "currentColor";
}, _ref3 => {
  let {
    bgcolor
  } = _ref3;
  return bgcolor || "unset";
}, _ref4 => {
  let {
    disabled,
    clickable
  } = _ref4;
  return disabled ? "not-allowed" : clickable ? "pointer" : "auto";
}, _styledSystem.space);
const UiIconPop = exports.UiIconPop = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: inline-flex;\n\n\t.ant-popover-message {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t.ant-popover-message-title {\n\t\t\tpadding-left: 0;\n\t\t}\n\t}\n"])));