import * as React from "react";
function SvgCheckCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.005 21.99c5.467 0 9.995-4.528 9.995-9.995S17.462 2 11.995 2C6.528 2 2 6.528 2 11.995s4.538 9.995 10.005 9.995zm-1.104-5.21c-.35 0-.629-.144-.887-.484l-2.434-2.97a1.102 1.102 0 01-.237-.66c0-.465.35-.836.825-.836.279 0 .506.093.753.412l1.94 2.496 4.125-6.6c.186-.32.454-.475.732-.475.444 0 .857.31.857.784 0 .216-.114.453-.248.66l-4.58 7.179c-.206.33-.495.495-.845.495z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCheckCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
