import * as React from "react";
function SvgUsersCogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.08 14.664c.081-.44.081-.89 0-1.331l.807-.466a.226.226 0 00.103-.266 4.69 4.69 0 00-1.038-1.793.228.228 0 00-.281-.044l-.807.466a3.67 3.67 0 00-1.153-.666v-.932c0-.106-.075-.2-.178-.221a4.716 4.716 0 00-2.069 0 .228.228 0 00-.178.221v.932c-.422.15-.813.375-1.153.665l-.807-.465a.229.229 0 00-.281.044 4.69 4.69 0 00-1.038 1.793.23.23 0 00.104.266l.806.466c-.081.44-.081.89 0 1.331l-.806.466a.226.226 0 00-.104.266c.21.675.57 1.284 1.038 1.793a.228.228 0 00.281.044l.807-.465c.34.29.731.515 1.153.665v.932c0 .106.075.2.178.222.697.156 1.406.15 2.069 0a.227.227 0 00.178-.222v-.932c.422-.15.813-.375 1.153-.665l.807.465c.09.053.21.035.281-.044a4.69 4.69 0 001.038-1.793.23.23 0 00-.103-.266l-.807-.466zm-3.578.85A1.517 1.517 0 0115.986 14a1.52 1.52 0 011.516-1.516 1.52 1.52 0 011.515 1.516c0 .834-.678 1.515-1.515 1.515zM5 10.998c1.104 0 2-.897 2-2s-.896-2-2-2c-1.103 0-2 .897-2 2s.897 2 2 2zm7.001 1c.06 0 .116-.015.175-.018.26-.679.64-1.316 1.135-1.85a1.232 1.232 0 011.515-.229l.247.144c.025-.016.05-.028.075-.044a3.46 3.46 0 00.35-1.5c0-1.934-1.566-3.5-3.5-3.5A3.493 3.493 0 008.5 8.498c0 1.935 1.566 3.5 3.5 3.5zm3.288 6.08c-.072-.038-.144-.082-.213-.123-.256.15-.478.307-.86.307-.34 0-.668-.144-.902-.394a5.7 5.7 0 01-1.257-2.175c-.334-1.079.778-1.554.807-1.573a3.194 3.194 0 010-.243l-.247-.144a1.276 1.276 0 01-.307-.253c-.103.006-.203.018-.306.018a4.934 4.934 0 01-2.14-.5h-.26A3.602 3.602 0 006 16.599v.9A1.5 1.5 0 007.5 19h7.983a1.203 1.203 0 01-.194-.634v-.288zM7.41 12.58A1.994 1.994 0 006 11.998H4c-1.103 0-2 .897-2 2v1a1 1 0 001 1h2.06a4.583 4.583 0 012.35-3.418z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUsersCogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
