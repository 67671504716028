import * as React from "react";
function SvgFileCsvSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM9.5 12.938c0 .172-.14.312-.313.312h-.312a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h.313c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312h-.312A1.875 1.875 0 017 15.125v-1.25C7 12.839 7.84 12 8.875 12h.313c.172 0 .312.14.312.313v.624zM11.23 17h-.48a.312.312 0 01-.313-.313v-.625c0-.172.14-.312.313-.312h.48c.232 0 .406-.137.406-.259 0-.05-.03-.104-.083-.15l-.855-.733a1.45 1.45 0 01-.52-1.099c0-.832.742-1.509 1.656-1.509h.479c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312h-.479c-.232 0-.406.137-.406.259 0 .05.029.104.082.15l.855.733c.331.282.521.683.521 1.1 0 .831-.742 1.508-1.656 1.508zm3.27-4.688v.813c0 .792.223 1.57.625 2.222.402-.652.625-1.43.625-2.222v-.813c0-.172.14-.312.313-.312h.625c.172 0 .312.14.312.313v.812c0 1.386-.503 2.691-1.417 3.675a.626.626 0 01-.916 0c-.914-.984-1.417-2.29-1.417-3.675v-.813c0-.172.14-.312.313-.312h.624c.173 0 .313.14.313.313zm4.727-6.21l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileCsvSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
