import * as React from "react";
function SvgGolfBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.625 18.25h8.75c0 .691-.559 1.25-1.25 1.25H14.5c-.691 0-1.25.559-1.25 1.25v.781a.47.47 0 01-.469.469H11.22a.47.47 0 01-.469-.469v-.781c0-.691-.559-1.25-1.25-1.25h-.625c-.691 0-1.25-.559-1.25-1.25zm12.5-8.125A8.112 8.112 0 0116.316 17H7.684a8.113 8.113 0 01-3.809-6.875A8.124 8.124 0 0112 2a8.124 8.124 0 018.125 8.125zM13.09 11.84c.715 0 1.293-.578 1.293-1.293 0-.563-.363-1.027-.863-1.207a1.27 1.27 0 01-1.637 1.637c.18.5.644.863 1.207.863zm1.918 1.832c0-.563-.363-1.027-.863-1.207a1.27 1.27 0 01-1.637 1.637c.18.5.644.863 1.207.863.715 0 1.293-.582 1.293-1.293zm2.5-2.5c0-.563-.363-1.027-.863-1.207a1.27 1.27 0 01-1.637 1.637c.18.5.644.863 1.207.863.715 0 1.293-.582 1.293-1.293z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGolfBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
