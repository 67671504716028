import * as React from "react";
function SvgPooSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.633 16.418a2.791 2.791 0 001.117-2.23 2.81 2.81 0 00-2.813-2.813h-.55A2.486 2.486 0 0018.25 9.5c0-1.379-1.121-2.5-2.5-2.5h-.23c.14-.395.23-.809.23-1.25C15.75 3.68 14.07 2 12 2c-.203 0-.398.027-.59.059.367.511.59 1.136.59 1.816A3.124 3.124 0 018.875 7H8.25a2.502 2.502 0 00-2.5 2.5c0 .75.34 1.418.863 1.875h-.55a2.81 2.81 0 00-2.813 2.813c0 .914.441 1.718 1.117 2.23A2.807 2.807 0 004.813 22h14.375a2.807 2.807 0 00.445-5.582zM9.5 12c.691 0 1.25.559 1.25 1.25s-.559 1.25-1.25 1.25-1.25-.559-1.25-1.25S8.809 12 9.5 12zm6.23 5.43c-.41 1.09-2.285 2.07-3.73 2.07-1.445 0-3.32-.98-3.73-2.07a.32.32 0 01.304-.43h6.852a.32.32 0 01.304.43zM14.5 14.5c-.691 0-1.25-.559-1.25-1.25S13.809 12 14.5 12s1.25.559 1.25 1.25-.559 1.25-1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPooSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
