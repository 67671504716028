import * as React from "react";
function SvgIciclesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.976 3.48A1.25 1.25 0 0020.75 2H3.25a1.25 1.25 0 00-1.195 1.61l3.402 10.678c.098.281.496.281.59 0L7.47 9.44l1.726 7.315c.074.325.535.325.61 0l1.816-7.69 1.332 5.21c.09.297.507.297.597 0L15.34 7.54l2.605 14.21c.067.335.547.335.613 0L21.976 3.48z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIciclesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
