import * as React from "react";
function SvgSeedlingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 5.75H2a8.75 8.75 0 008.75 8.75v5.625c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625V14.5A8.75 8.75 0 004.5 5.75zm15-2.5a8.743 8.743 0 00-7.645 4.5 10.016 10.016 0 012.305 4.203C18.562 11.496 22 7.777 22 3.25h-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSeedlingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
