import * as React from "react";
function SvgUserEditSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9 12a4 4 0 100-8 4 4 0 000 8zm2.8 1h-.522a5.447 5.447 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h8.591a1.5 1.5 0 01-.081-.666l.212-1.903.038-.347.247-.247 2.415-2.415A4.157 4.157 0 0011.8 13zm1.416 4.54l-.212 1.907a.497.497 0 00.55.55l1.903-.212 4.31-4.31-2.241-2.24-4.31 4.306zm8.566-5.137l-1.184-1.184a.749.749 0 00-1.057 0l-1.181 1.18-.128.129 2.244 2.24 1.306-1.306a.752.752 0 000-1.059z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserEditSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
