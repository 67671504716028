import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TitleContainer, StretchList, Button } from "common-components";
import EditGroup from "../EditGroup";
import { Checkbox } from "antd";
import { UiQuestionTypeContent, UiTitleHeader, UiClearButton } from "./QuestionTypeContent.style";

/**
 * 題型類型選擇內容
 */

export const QuestionTypeContent = ({
	questionTypeOptions,
	chooseQuestionTypeOptions = [],
	clearData = () => {},
	addData = () => {},
	checkAll = Boolean,
	indeterminate = Boolean,
	onCheckAllChange = () => {},
	saveOptionsHandler = () => {},
	cancelOptionsHandler = () => {},
	mode = "ruleAdd",
}) => {
	const [contentSwitch, setContentSwitch] = useState(true);
	const initQuestionTypeContentSwitch = (mode) => {
		let switchMode = true;
		switch (mode) {
			case "ruleSearch":
				setContentSwitch(false);
				switchMode = false;
				break;
			default:
				break;
		}
		return switchMode;
	};
	useEffect(() => {
		initQuestionTypeContentSwitch(mode);
	}, []);

	const onChange = (title, code, val) => {
		addData && addData(title, code, val);
	};

	const onEditHandler = () => {
		setContentSwitch(true);
	};

	const onCancelHandler = () => {
		cancelOptionsHandler();
		setContentSwitch(false);
	};

	const onSaveHandler = () => {
		saveOptionsHandler();
		setContentSwitch(false);
	};
	const formatCheckedList = (list, code) => {
		if (!list) return [];
		return list.findIndex((item) => item.code === code) !== -1
			? list[list.findIndex((item) => item.code === code)].options.map((item) => item.value)
			: [];
	};

	return (
		<UiQuestionTypeContent>
			{mode === "ruleSearch" && (
				<div className="editBlock">
					<EditGroup onEdit={onEditHandler} onSave={onSaveHandler} onCancel={onCancelHandler} />
				</div>
			)}
			<div className="content">
				<TitleContainer header={ChooseQuestionTypePreviewHeader()}>
					{chooseQuestionTypeOptions &&
						chooseQuestionTypeOptions.map((item, index) => {
							return <StretchList key={index} title={item.title} data={item.options} isCheckType={false} />;
						})}
				</TitleContainer>
				{contentSwitch && (
					<TitleContainer header={ChooseQuestionTypeHeader(clearData, checkAll, indeterminate, onCheckAllChange)}>
						{questionTypeOptions &&
							questionTypeOptions.map((item, index) => {
								return (
									<StretchList
										onChange={(val) => {
											onChange(item.title, item.code, val);
										}}
										key={index}
										title={item.title}
										code={item.code}
										data={item.options}
										isCheckType={true}
										checkedList={formatCheckedList(chooseQuestionTypeOptions, item.code)} //["MT001", "MT002"]
									/>
								);
							})}
					</TitleContainer>
				)}
			</div>
		</UiQuestionTypeContent>
	);
};

export const ChooseQuestionTypePreviewHeader = () => <UiTitleHeader>已選題型預覽</UiTitleHeader>;
export const ChooseQuestionTypeHeader = (clearData, checkAll, indeterminate, onCheckAllChange) => (
	<UiTitleHeader>
		選擇題型
		<UiClearButton>
			<Button.IconButton size="small" variant="white" full={false} iconName="close" onClick={clearData}>
				全部清除
			</Button.IconButton>
			<div className="checkAll">
				<Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll}>
					全選
				</Checkbox>
			</div>
		</UiClearButton>
	</UiTitleHeader>
);

QuestionTypeContent.propTypes = {
	questionTypeOptions: PropTypes.array,
	chooseQuestionTypeOptions: PropTypes.array,
	clearData: PropTypes.func,
	addData: PropTypes.func,
	saveOptionsHandler: PropTypes.func,
	mode: PropTypes.string,
	cancelOptionsHandler: PropTypes.func,
};
