import * as React from "react";
function SvgRightTriangleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97 4.67v15.36h15.36L3.97 4.67zM2 3.482c0-1.317 1.592-1.976 2.523-1.045l17.041 17.04c.931.932.272 2.524-1.045 2.524H3.479A1.478 1.478 0 012 20.522V3.482z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRightTriangleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
