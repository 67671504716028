import * as React from "react";
function SvgRegisteredOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.064A8.06 8.06 0 013.935 12 8.06 8.06 0 0112 3.935 8.06 8.06 0 0120.064 12 8.06 8.06 0 0112 20.064zm4.453-3.297c-2.139-3.883-2.026-3.689-2.148-3.875.979-.56 1.592-1.676 1.592-2.95 0-2.118-1.22-3.438-4.092-3.438H8.633a.484.484 0 00-.484.484V17c0 .267.217.484.484.484h1.555a.484.484 0 00.484-.484v-3.373h1.288l1.916 3.6a.483.483 0 00.427.257h1.726a.484.484 0 00.424-.718zm-4.415-5.416h-1.366V8.765h1.104c1.307 0 1.57.489 1.57 1.278 0 .844-.465 1.308-1.308 1.308z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRegisteredOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
