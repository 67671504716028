import * as React from "react";
function SvgSmileWinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12c0 5.524 4.476 10 10 10s10-4.476 10-10S17.524 2 12 2 2 6.476 2 12zm8.065-1.935a1.289 1.289 0 11-2.58 0c-.001-.714.575-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29zm6.39.665c-.596-.532-1.862-.532-2.459 0l-.383.343c-.335.298-.871.016-.798-.436.16-1.016 1.379-1.697 2.415-1.697s2.254.681 2.415 1.697a.483.483 0 01-.798.436l-.391-.343zm-8.092 4.085A4.712 4.712 0 0012 16.515c1.411 0 2.734-.62 3.637-1.701.549-.654 1.536.169.992.826A6.016 6.016 0 0112 17.806c-1.794 0-3.48-.79-4.629-2.169-.544-.657.452-1.48.992-.822z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSmileWinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
