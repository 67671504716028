import * as React from "react";
function SvgCitySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.25 10H17V4.75a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75V7H9V4.5a.5.5 0 00-.5-.5H8a.5.5 0 00-.5.5V7h-2V4.5A.5.5 0 005 4h-.5a.5.5 0 00-.5.5V7H2.75a.75.75 0 00-.75.75V19a1 1 0 001 1h18a1 1 0 001-1v-8.25a.75.75 0 00-.75-.75zM6 16.625a.375.375 0 01-.375.375h-1.25A.375.375 0 014 16.625v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25A.375.375 0 014 13.625v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25A.375.375 0 014 10.625v-1.25C4 9.168 4.168 9 4.375 9h1.25c.207 0 .375.168.375.375v1.25zm4 6a.375.375 0 01-.375.375h-1.25A.375.375 0 018 16.625v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25A.375.375 0 018 13.625v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25A.375.375 0 018 10.625v-1.25C8 9.168 8.168 9 8.375 9h1.25c.207 0 .375.168.375.375v1.25zm5 3a.375.375 0 01-.375.375h-1.25a.375.375 0 01-.375-.375v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25a.375.375 0 01-.375-.375v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25A.375.375 0 0113 7.625v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm5 9a.375.375 0 01-.375.375h-1.25a.375.375 0 01-.375-.375v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25zm0-3a.375.375 0 01-.375.375h-1.25a.375.375 0 01-.375-.375v-1.25c0-.207.168-.375.375-.375h1.25c.207 0 .375.168.375.375v1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCitySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
