import * as React from "react";
function SvgShareAltSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 4.143v15.714C22 21.041 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.959 2.96 2 4.143 2h15.714C21.041 2 22 2.96 22 4.143zm-6.429 9.643a2.49 2.49 0 00-1.686.655l-3.034-1.82a2.513 2.513 0 000-1.241l3.034-1.82a2.5 2.5 0 10-.736-1.225l-3.034 1.82a2.5 2.5 0 100 3.69l3.034 1.82a2.5 2.5 0 102.422-1.88z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShareAltSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
