import * as React from "react";
function SvgKissWinkHeartOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.064 14.083c0-.761-1.143-1.646-2.838-1.746a.31.31 0 00-.325.246.317.317 0 00.186.365l.671.286c.516.219.825.536.825.853 0 .318-.31.635-.821.854l-.675.285c-.226.095-.238.484 0 .588l.671.285c.516.219.825.536.825.854 0 .317-.31.634-.821.853l-.675.285a.318.318 0 00.123.611h.02c1.699-.099 2.838-.984 2.838-1.746 0-.515-.532-1.083-1.397-1.428.86-.361 1.393-.929 1.393-1.445zm2.797-3.313l.377.337c.151.131.37.159.544.064a.478.478 0 00.242-.492c-.159-1-1.357-1.671-2.373-1.671-1.016 0-2.218.67-2.373 1.67a.473.473 0 00.786.429l.377-.337c.587-.524 1.833-.524 2.42 0zm-9.464-.655c0 .703.567 1.27 1.27 1.27.702 0 1.27-.567 1.27-1.27 0-.702-.568-1.27-1.27-1.27-.703 0-1.27.568-1.27 1.27zm14.488 7.699c-.317-.826-1.25-1.25-2.107-1.028l-.333.087-.092-.333c-.234-.85-1.071-1.449-1.944-1.31a1.66 1.66 0 00-1.35 2.088l.91 3.277c.059.21.277.338.491.282l3.294-.853a1.655 1.655 0 001.131-2.21zm-6.63 1.34a7.878 7.878 0 01-3.414.783c-4.377 0-7.936-3.56-7.936-7.937 0-4.377 3.56-7.937 7.936-7.937 4.377 0 7.937 3.56 7.937 7.937a7.84 7.84 0 01-.413 2.508c.357.254.675.564.897.949.254.003.5.055.738.114a9.788 9.788 0 00.679-3.57c.004-5.437-4.401-9.842-9.838-9.842A9.84 9.84 0 002 12a9.84 9.84 0 009.841 9.841 9.768 9.768 0 003.945-.829c-.1-.29.17.683-.532-1.857z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKissWinkHeartOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
