import * as React from "react";
function SvgExternalLinkAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 14.5h-1.25a.624.624 0 00-.625.625V19.5H4.5V7h5.625a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625h-6.25A1.875 1.875 0 002 6.375v13.75A1.875 1.875 0 003.875 22h13.75a1.875 1.875 0 001.875-1.875v-5a.624.624 0 00-.625-.625zM21.063 2h-5c-.835 0-1.252 1.012-.665 1.602l1.396 1.395-9.52 9.518a.937.937 0 000 1.328l.885.884a.937.937 0 001.328 0l9.516-9.52 1.395 1.395c.586.585 1.602.175 1.602-.665v-5A.938.938 0 0021.062 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExternalLinkAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
