import * as React from "react";
function SvgCaretDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.346 6.328h17.308c1.198 0 1.796 1.446.949 2.293l-8.651 8.658a1.348 1.348 0 01-1.904 0l-8.65-8.658c-.848-.847-.25-2.293.948-2.293z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCaretDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
