import * as React from "react";
function SvgGreaterThanEqualSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.967 5.82l7.316 2.838-7.31 2.835c-.767.251-1.162 1.016-.884 1.708l.504 1.254c.278.692 1.124 1.05 1.89.797l12.56-4.994c.573-.188.957-.692.957-1.257v-.665c0-.565-.384-1.069-.957-1.257L6.47 2.08c-.762-.25-1.605.105-1.881.794l-.502 1.247c-.276.689.117 1.45.88 1.7zM20.333 18H3.667a1 1 0 00-1 1v2a1 1 0 001 1h16.666a1 1 0 001-1v-2a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGreaterThanEqualSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
