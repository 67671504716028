import * as React from "react";
function SvgGrinSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 6.774a1.289 1.289 0 110 2.58c-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm-6.452 0c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zM12 19.097c-2.444 0-5.423-1.545-5.798-3.762a.646.646 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613a.648.648 0 01.834.722c-.375 2.217-3.354 3.762-5.798 3.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
