import { Api } from "common-components";
import { stringify } from "query-string";
import { API_BATCH_QUEUE } from "constants/index";

/**
 * 上傳試題
 */
export const convertQueue = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/Queue?${querystring}`, payload);
	return response;
};

/**
 * 取得檔案列表
 */
export const getRecord = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/Record`);
	return response;
};

/**
 * 取得檔案列表new
 */
export const getRecordPagination = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/Record/Pagination?${queryString}`);
	return response;
};

/**
 * 取得檔案詳細
 */
export const getRecordDetail = async (eduSubject, fileUID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/${eduSubject}/File/${fileUID}`);
	return response;
};

/**
 * 刪除檔案
 */
export const deleteRecordFile = async (fileUID) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/hide/${fileUID}`);
	return response;
};

/**
 * 查詢修改轉檔列表
 */
export const getRecordEdit = async (id = "1") => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/Record/Edit?productType=${id}`);
	return response;
};

/**
 * 寫入修改轉檔排程
 */
export const convertQueueEdit = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/Queue?queueType=1&${querystring}`,
		payload,
	);
	return response;
};

/**
 * 查詢排程列表
 */
export const getScheduleList = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/QuerySeparateRecord`);
	return response;
};

/**
 * 查詢排程列表
 */
export const getSchedulePaginationList = async (payload) => {
	const queryString = stringify(payload);
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/QuerySeparateRecord/Pagination?${queryString}`,
	);
	return response;
};

// 刪除列表 項目 （隱藏）
export const putDeleteRecord = async (fileUID) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/Hide/SeparateRecord/${fileUID}`);
	return response;
};

// 取得 該filed內 所有錯誤試題原檔的zip 下載url
export const getErrorExportFile = async (fileUID) => {
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Record/ErrorExportFile?fileUID=${fileUID}`,
	);
	return response;
};

// 取得該 入題內 所有單一試題 入題資訊
export const getQuestionList = async (fileUID) => {
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/QuerySeparateRecord/Detail?fileUID=${fileUID}`,
	);
	return response;
};

// 批次重新轉檔（單一）
export const putRetry = async (fileUID) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/Retry?UID=${fileUID}`);
	return response;
};

// 取得 查詢入庫所需 下拉選單之資料
export const getQuerySeparateRecordSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Convert/QuerySeparateRecord/Selection`);
	return response;
};

// 編輯 該入庫檔案 是否已完成作業
export const putEditCheck = async (fileUID, isChecked) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/QuerySeparateRecord/Check/${fileUID}`, {
		isChecked: isChecked,
	});
	return response;
};

// 批次 上傳/修改 試題
export const postUploadQuestion = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};

// 批次 刪除 檔案 （隱藏）
export const putDeleteFile = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/BatchHide/SeparateRecord`, payload);
	return response;
};

// 批次重新上傳（複數）
export const putBatchRetry = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Convert/BatchSeparate/Retry`, payload);
	return response;
};
// 批次 上傳/修改 無限命題試題
export const postUploadQuestionEquationDojo = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};
// 批次 上傳/修改 Live試聽前測卷試題
export const postUploadQuestionLivePreTest = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};

// 批次 上傳/修改 模疑考試題
export const postUploadQuestionMockExam = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};

// 批次 上傳/修改 英文單字刷題試題
export const postUploadQuestionVocabularyTest = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};

// 批次 上傳/修改 教務試題
export const postUploadEduService = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/Separate/BatchQueue?${querystring}`,
		payload,
	);
	return response;
};

// 重新轉檔進行檢核
export const postReSeparateRecord = async (payload, params) => {
	const querystring = stringify(params);
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/Convert/ReSeparateRecord?${querystring}`,
		payload,
	);
	return response;
};

/**
 * 寫入修改轉檔排程
 */
export const importCMSCache = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/convertv2/import/cmscache`, payload);
	return response;
};
