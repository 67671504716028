import * as React from "react";
function SvgCheckDoubleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.727 8.828L20.18 7.281a.933.933 0 00-1.324 0l-9.354 9.355-4.35-4.355a.934.934 0 00-1.325 0l-1.55 1.55a.938.938 0 000 1.328l6.561 6.566a.933.933 0 001.324 0l11.56-11.569a.942.942 0 00.005-1.328zM9.06 12.968a.62.62 0 00.882 0l8.124-8.131a.627.627 0 000-.883l-1.765-1.77a.62.62 0 00-.883 0L9.502 8.103 7.338 5.934a.62.62 0 00-.882 0l-1.77 1.77a.627.627 0 000 .882l4.375 4.382z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCheckDoubleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
