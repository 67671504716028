import * as React from "react";
function SvgHatCowboySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.313 12.778c-.297-1.793-1.235-7.278-3.054-7.278-.437 0-.828.185-1.156.437a1.82 1.82 0 01-2.206 0c-.328-.25-.719-.437-1.156-.437-1.819 0-2.757 5.483-3.054 7.277 1.213.394 2.93.723 5.313.723 2.383 0 4.101-.328 5.313-.722zm4.466-1.162a.5.5 0 00-.618.047c-.03.028-3.165 2.837-9.162 2.837-5.963 0-9.132-2.81-9.164-2.839a.5.5 0 00-.805.543c.023.063 2.417 6.296 9.97 6.296 7.553 0 9.946-6.233 9.97-6.296a.5.5 0 00-.191-.588z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHatCowboySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
