import * as React from "react";
function SvgVoicemailSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.5 7.5a4.5 4.5 0 00-3.742 7h-3.516a4.5 4.5 0 10-3.742 2h11a4.5 4.5 0 000-9zM4 12a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm13.5 2.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVoicemailSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
