import * as React from "react";
function SvgCarSideSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 10h-.5l-3.4-4.25A2 2 0 0013.54 5H6.854a2 2 0 00-1.857 1.257L3.5 10.071C2.639 10.294 2 11.069 2 12v3.5a.5.5 0 00.5.5H4a3 3 0 106 0h4a3 3 0 106 0h1.5a.5.5 0 00.5-.5V13a3 3 0 00-3-3zM7 17.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zM9.25 10H5.654l1.2-3H9.25v3zm1.5 0V7h2.789l2.4 3H10.75zM17 17.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCarSideSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
