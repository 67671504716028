import * as React from "react";
function SvgChargingStationSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.667 18.667H2.556a.555.555 0 00-.556.555v1.111c0 .307.249.556.556.556h11.11a.555.555 0 00.556-.556v-1.11a.555.555 0 00-.555-.556zm7.222-11.111V5.889a.555.555 0 10-1.111 0v1.667h-1.111V5.889a.555.555 0 10-1.111 0v1.667H17a.555.555 0 00-.556.555v1.111c0 1.242.82 2.281 1.945 2.637v4.114c0 .484-.33.935-.808 1.013a.974.974 0 01-1.137-.958v-.972A3.056 3.056 0 0013.39 12h-.278V5.333a2.222 2.222 0 00-2.222-2.222H5.333a2.222 2.222 0 00-2.222 2.222v12.222h10v-3.888h.278c.767 0 1.389.622 1.389 1.389v.854c0 1.378 1.004 2.61 2.375 2.743a2.641 2.641 0 002.903-2.625v-4.17A2.768 2.768 0 0022 9.223v-1.11a.555.555 0 00-.556-.556h-.555zM11.03 9.214L7.777 14.04a.43.43 0 01-.36.182c-.267 0-.468-.218-.406-.448l.8-2.885H5.75c-.252 0-.446-.194-.413-.413l.556-3.715c.027-.181.204-.317.413-.317h2.36c.274 0 .474.228.403.46l-.402 1.763h2.003c.321 0 .521.305.36.547z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChargingStationSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
