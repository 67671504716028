import * as React from "react";
function SvgToiletSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 3.875a.627.627 0 00.625-.625v-.625A.627.627 0 0018.875 2H5.125a.627.627 0 00-.625.625v.625c0 .344.281.625.625.625h.625v6.121c-.79.395-1.25.867-1.25 1.379a7.49 7.49 0 003.39 6.27l-.835 2.742C6.809 21.187 7.41 22 8.25 22h7.5c.84 0 1.441-.813 1.195-1.613l-.836-2.742a7.49 7.49 0 003.391-6.27c0-.512-.46-.984-1.25-1.379V3.875h.625zm-11.25.938c0-.172.14-.313.313-.313h1.875c.171 0 .312.14.312.313v.625c0 .171-.14.312-.313.312H7.938a.313.313 0 01-.313-.313v-.625zM12 12.625c-3.012 0-5.453-.559-5.453-1.25s2.441-1.25 5.453-1.25c3.012 0 5.453.559 5.453 1.25s-2.441 1.25-5.453 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgToiletSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
