import * as React from "react";
function SvgRunningSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 5.75a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zm-6.184 8.652l-.578 1.348H5.125a1.25 1.25 0 000 2.5H8.15c.752 0 1.43-.447 1.723-1.136l.344-.802-.417-.246a3.72 3.72 0 01-1.484-1.664zm10.56-3.652h-1.72l-1.019-2.08a3.748 3.748 0 00-2.413-1.99l-2.777-.826a3.734 3.734 0 00-3.158.67L6.24 7.712a1.249 1.249 0 101.522 1.983l1.55-1.188c.3-.23.682-.312.987-.24l.575.171-1.464 3.414a2.507 2.507 0 001.028 3.137l3.32 1.96-1.074 3.427a1.25 1.25 0 002.386.747l1.236-3.948a1.883 1.883 0 00-.845-2.125l-2.393-1.411 1.223-3.058.792 1.618c.313.639.974 1.05 1.684 1.05h2.11a1.25 1.25 0 000-2.499z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRunningSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
