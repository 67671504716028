import * as React from "react";
function SvgRubleSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.114 14.857c3.8 0 6.457-2.654 6.457-6.47S17.914 2 14.114 2H6.821a.536.536 0 00-.535.536v9.224H3.964a.536.536 0 00-.535.536v2.025c0 .296.24.536.535.536h2.322v1.429H3.964a.536.536 0 00-.535.535v1.786c0 .296.24.536.535.536h2.322v2.321c0 .296.24.536.535.536h2.615c.296 0 .535-.24.535-.536v-2.321h7.208c.295 0 .535-.24.535-.536v-1.786a.536.536 0 00-.535-.535H9.97v-1.429h4.143zM9.971 5.07h3.515c2.085 0 3.343 1.3 3.343 3.318 0 2.046-1.258 3.373-3.4 3.373H9.97V5.07z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRubleSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
