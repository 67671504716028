import * as React from "react";
function SvgMeteorSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.974 2.812a162.597 162.597 0 01-2.395 7.333l.727.218a.627.627 0 01.37.895c-.863 1.652-3.23 5.969-5.565 8.375-.04.043-.078.098-.117.137A7.611 7.611 0 114.229 9.008c.039-.04.094-.078.137-.117 2.406-2.34 6.718-4.704 8.374-5.567a.627.627 0 01.895.371c.062.2.136.453.218.727a160.15 160.15 0 017.332-2.395.632.632 0 01.789.785zM14.498 14.5a5 5 0 10-10.001.002 5 5 0 0010-.002zm-5-1.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm1.25 3.125a.625.625 0 11-1.25 0 .625.625 0 011.25 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMeteorSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
