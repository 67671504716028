import * as React from "react";
function SvgHandPointerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 11.375v3.75c0 .12-.014.24-.041.358l-1.25 5.312A1.563 1.563 0 0117.938 22H9.813a1.563 1.563 0 01-1.264-.643l-5-6.875a1.562 1.562 0 112.527-1.838l1.237 1.7V3.562a1.562 1.562 0 113.125 0v7.812h.312V9.812a1.562 1.562 0 113.125 0v1.563h.313v-.938a1.562 1.562 0 113.124 0v.938h.313a1.562 1.562 0 113.125 0zm-10 3.125h-.313v3.75h.313V14.5zm3.438 0h-.313v3.75h.313V14.5zm3.437 0h-.313v3.75h.313V14.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandPointerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
