import * as React from "react";
function SvgPhotoVideoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 4H7a1 1 0 00-1 1v3h5V6h6v10h4a1 1 0 001-1V5a1 1 0 00-1-1zM9.25 7.219a.281.281 0 01-.281.281H8.03a.281.281 0 01-.281-.281V6.28A.281.281 0 018.031 6h.938a.281.281 0 01.281.281v.938zm11 6.5a.281.281 0 01-.281.281h-.938a.281.281 0 01-.281-.281v-.938a.281.281 0 01.281-.281h.938a.281.281 0 01.281.281v.938zm0-3.25a.281.281 0 01-.281.281h-.938a.281.281 0 01-.281-.281V9.53a.281.281 0 01.281-.281h.938a.281.281 0 01.281.281v.938zm0-3.25a.281.281 0 01-.281.281h-.938a.281.281 0 01-.281-.281V6.28A.281.281 0 0119.031 6h.938a.281.281 0 01.281.281v.938zM15 9H3a1 1 0 00-1 1v9a1 1 0 001 1h12a1 1 0 001-1v-9a1 1 0 00-1-1zM5 11a1 1 0 110 2 1 1 0 010-2zm9 7H4v-1l2-2 1 1 4-4 3 3v3z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPhotoVideoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
