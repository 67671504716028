import * as React from "react";
function SvgFingerprintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.004 11.608a.938.938 0 00-.937.937 28.56 28.56 0 01-1.082 8.264c-.107.38.084 1.191.903 1.191.41 0 .786-.27.902-.684a30.235 30.235 0 001.152-8.77.937.937 0 00-.938-.938zm-.033-3.193c-2.393-.002-4.063 1.839-4.03 3.95a25.628 25.628 0 01-.522 5.567.937.937 0 101.836.38 27.5 27.5 0 00.561-5.977c-.016-1.014.778-2.05 2.127-2.045 1.223.019 2.232.99 2.25 2.167.031 1.877-.109 3.763-.414 5.607a.937.937 0 00.772 1.078c.78.13 1.048-.59 1.078-.773a33.32 33.32 0 00.44-5.941c-.034-2.18-1.873-3.98-4.098-4.013zm-4.324-.772a.938.938 0 00-1.319.136 6.775 6.775 0 00-1.513 4.374 22.686 22.686 0 01-.356 4.377.938.938 0 00.756 1.09c.786.137 1.057-.579 1.09-.756.28-1.557.41-3.151.385-4.74a4.827 4.827 0 011.093-3.163.938.938 0 00-.136-1.318zm4.276-2.435a6.982 6.982 0 00-1.788.194.938.938 0 00.432 1.825 5.608 5.608 0 011.328-.144c2.947.044 5.38 2.402 5.425 5.257a34.42 34.42 0 01-.218 4.439c-.058.514.31.98.825 1.038.653.076.996-.464 1.038-.825.177-1.554.254-3.118.23-4.682-.06-3.857-3.323-7.044-7.272-7.102zm9.847 4.744a.937.937 0 10-1.83.404c.183.836.19 1.461.182 2.406a.937.937 0 00.93.945h.008a.937.937 0 00.937-.93c.007-.866.016-1.722-.227-2.825zm-1.567-3.544a10.39 10.39 0 00-8.328-4.407c-2.726-.033-5.248.972-7.123 2.848a9.316 9.316 0 00-2.747 6.786l-.004.838a.937.937 0 101.873.055l.007-.923a7.452 7.452 0 012.198-5.43c1.514-1.515 3.568-2.33 5.769-2.299a8.51 8.51 0 016.82 3.61.937.937 0 101.535-1.078z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFingerprintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
