import * as React from "react";
function SvgShoePrintsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8 9h1V5H8a2 2 0 100 4zm-6 8a2 2 0 002 2h1v-4H4a2 2 0 00-2 2zm10.546-4c-1.091 0-2.38.41-3.273 1-.775.512-1.391 1-3.273 1v4l1.798.499c.819.228 1.656.41 2.51.47 1.021.072 2.05.021 3.049-.193C16.778 19.04 18 17.413 18 16c0-2-2.63-3-5.454-3zm4.81-8.775a10.86 10.86 0 00-3.049-.194c-.853.06-1.69.243-2.51.47L10 5v4c1.881 0 2.498.488 3.273 1 .893.59 2.182 1 3.273 1C19.369 11 22 10 22 8c0-1.413-1.222-3.04-4.643-3.775z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShoePrintsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
