import * as React from "react";
function SvgGamepadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.002 7H7a5 5 0 103.57 8.5h2.86A5 5 0 1017.002 7zM9.75 12.375a.375.375 0 01-.375.375H7.75v1.625a.375.375 0 01-.375.375h-.75a.375.375 0 01-.375-.375V12.75H4.626a.375.375 0 01-.375-.375v-.75a.375.375 0 01.375-.375H6.25V9.625a.375.375 0 01.375-.375h.75a.375.375 0 01.375.375v1.625h1.625a.375.375 0 01.375.375v.75zm6.75 2.375a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm2-3a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGamepadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
