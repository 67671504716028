import * as React from "react";
function SvgInfoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.031 18.571h.781V12.93h-.78a.781.781 0 01-.782-.782v-1.866c0-.431.35-.781.781-.781h4.375c.432 0 .781.35.781.781v8.29h.782c.431 0 .781.35.781.782v1.866c0 .431-.35.781-.781.781H9.03a.781.781 0 01-.781-.781v-1.866c0-.432.35-.782.781-.782zM12 2a2.812 2.812 0 100 5.625A2.812 2.812 0 0012 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgInfoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
