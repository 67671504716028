"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSelect = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiSelect = exports.UiSelect = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: ", ";\n\talign-items: center;\n\twidth: 100%;\n\tmin-width: 120px;\n\tfont-size: 15px;\n\n\t& > .ant-select {\n\t\twidth: 100%;\n\t\tflex: 1 1 auto;\n\n\t\t> .ant-select-selector {\n\t\t\t", "\n\t\t}\n\n\t\t> .ant-select-arrow {\n\t\t\t", "\n\t\t}\n\n\t\t> .ant-select-clear {\n\t\t\t", "\n\t\t}\n\t}\n\n\t.label {\n\t\tmargin-right: ", ";\n\t\tcolor: rgba(0, 0, 0, 0.65);\n\t\tmargin-bottom: ", ";\n\t\tflex: 0 0 ", ";\n\t\tword-break: break-all;\n\t}\n\n\t.message {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-top: 5px;\n\t\tfont-size: 12px;\n\t\tcolor: #ff4d4f;\n\n\t\t& > i {\n\t\t\tmargin-right: 5px;\n\t\t}\n\t}\n"])), _ref => {
  let {
    row
  } = _ref;
  return row ? "flex" : "block";
}, (0, _styledSystem.variant)({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262",
      boxShadow: "inset -1px 0 0 rgba(255, 255, 255, 0.25)"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
}), _ref2 => {
  let {
    row
  } = _ref2;
  return row ? "15px" : "0";
}, _ref3 => {
  let {
    row
  } = _ref3;
  return row ? "0" : "1px";
}, _ref4 => {
  let {
    row
  } = _ref4;
  return row ? "60px" : "auto";
});