import * as React from "react";
function SvgSignalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.486 13.081H6.865a.54.54 0 00-.54.54v6.487c0 .299.241.54.54.54h1.621a.54.54 0 00.541-.54v-6.486a.54.54 0 00-.54-.541zm-4.324 3.243H2.541a.54.54 0 00-.541.54v3.244c0 .299.242.54.54.54h1.622a.54.54 0 00.54-.54v-3.243a.54.54 0 00-.54-.54zm8.649-6.486h-1.622a.54.54 0 00-.54.54v9.73c0 .299.242.54.54.54h1.622a.54.54 0 00.54-.54v-9.73a.54.54 0 00-.54-.54zm4.324-3.243h-1.622a.54.54 0 00-.54.54v12.973c0 .299.242.54.54.54h1.622a.54.54 0 00.54-.54V7.135a.54.54 0 00-.54-.54zM21.46 3.35h-1.621a.54.54 0 00-.54.54v16.217c0 .299.241.54.54.54h1.621a.54.54 0 00.541-.54V3.892a.54.54 0 00-.54-.54z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSignalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
