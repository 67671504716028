import * as React from "react";
function SvgAlbumSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.222 3a2 2 0 00-2 2v1.333H2.5a.5.5 0 00-.5.5v1.223a.5.5 0 00.5.5h1.722v2.222H2.5a.5.5 0 00-.5.5V12.5a.5.5 0 00.5.5h1.722v2.222H2.5a.5.5 0 00-.5.5v1.222a.5.5 0 00.5.5h1.722v1.334a2 2 0 002 2H20a2 2 0 002-2V5a2 2 0 00-2-2H6.222zM12.5 9a2 2 0 11-4 0 2 2 0 014 0zm-4.625 5.2L9.5 12.9a1 1 0 011.072-.114l1.737.869a1 1 0 001.2-.236l2.216-2.533a1 1 0 011.524.022l2.022 2.451a1 1 0 01.229.637V17a1 1 0 01-1 1h-10a1 1 0 01-1-1v-2.02a1 1 0 01.375-.78z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAlbumSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
