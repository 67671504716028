import * as React from "react";
function SvgStarHalfAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.976 8.847l-5.462-.795-2.44-4.94a1.188 1.188 0 00-1.076-.664c-.425 0-.85.22-1.07.664l-2.442 4.94-5.462.794c-.98.142-1.372 1.347-.662 2.037l3.952 3.844-.936 5.428c-.132.775.487 1.397 1.18 1.397.184 0 .373-.043.555-.14l4.886-2.562 4.886 2.563c.181.095.37.138.553.138.694 0 1.314-.62 1.181-1.395l-.934-5.429 3.952-3.842c.71-.69.318-1.896-.661-2.038zm-4.543 4.597l-.676.658.16.928.728 4.233-3.81-2-.836-.437L12 4.99l1.905 3.854.417.845.933.136 4.262.62-3.084 3z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStarHalfAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
