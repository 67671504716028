import * as React from "react";
function SvgPenSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.96 2.96 2 4.143 2h15.714C21.04 2 22 2.96 22 4.143v15.714C22 21.04 21.04 22 19.857 22zM12.63 8.513L6.57 14.571l-.28 2.55a.54.54 0 00.593.593l2.549-.281 6.058-6.058a.272.272 0 000-.38l-2.478-2.477a.274.274 0 00-.383-.005zm4.772-.571l-1.344-1.344a1.067 1.067 0 00-1.513 0L13.513 7.63a.272.272 0 000 .38l2.478 2.478a.272.272 0 00.38 0l1.03-1.032a1.075 1.075 0 000-1.513z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPenSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
