import * as React from "react";
function SvgBoxEditSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.489 4.478L19.944 2.61A1.613 1.613 0 0018.667 2H5.333c-.522 0-.977.233-1.289.611L2.511 4.478A2.176 2.176 0 002 5.888v13.89C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V5.888c0-.532-.189-1.032-.511-1.41zM5.6 4.222h12.8l.922 1.111H4.69l.911-1.11zM4.222 19.778V7.556h15.556v12.222H4.222z"
        fill="currentColor"
      />
      <path
        d="M8 18v-2.158l5.087-5.05 2.121 2.121L10.156 18H8zM15.77 9.233l.997.996c.31.31.31.815 0 1.125l-.852.852-2.121-2.121.852-.852a.796.796 0 011.125 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBoxEditSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
