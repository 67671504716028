import * as React from "react";
function SvgRedoAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.018 2a9.966 9.966 0 016.89 2.77l1.44-1.44c.61-.61 1.652-.178 1.652.684v5.405a.968.968 0 01-.968.968h-5.405c-.863 0-1.294-1.042-.685-1.652l1.684-1.683a6.725 6.725 0 00-4.566-1.826c-3.726-.032-6.866 2.983-6.834 6.832.03 3.652 2.991 6.716 6.774 6.716a6.724 6.724 0 004.46-1.675.483.483 0 01.66.022l1.6 1.6a.485.485 0 01-.02.702A9.963 9.963 0 0112 22C6.477 22 2 17.523 2 12 2 6.484 6.502 1.99 12.018 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRedoAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
