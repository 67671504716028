import * as React from "react";
function SvgClockOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064A8.062 8.062 0 013.935 12 8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064zm2.492-4.21l-3.424-2.487a.487.487 0 01-.197-.391V6.355c0-.266.218-.484.484-.484h1.29c.266 0 .484.218.484.484v5.713l2.694 1.96c.217.157.262.46.104.678l-.758 1.044a.487.487 0 01-.677.105z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgClockOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
