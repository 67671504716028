import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "material-icons-font/material-icons-font.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, AuthProvider } from "common-components";
import "antd/dist/antd.css";
import "./index.css";

const { Provider } = AuthProvider;
const root = document.getElementById("root");
const Main = () => (
	<Provider apiBaseUrl={process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN} productApiUrl={process.env.REACT_APP_DOMAIN}>
		<ThemeProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
	</Provider>
);

ReactDOM.render(Main(), root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
