import * as React from "react";
function SvgUserAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 13.25a5.626 5.626 0 005.625-5.625A5.626 5.626 0 0012 2a5.626 5.626 0 00-5.625 5.625A5.626 5.626 0 0012 13.25zm5 1.25h-2.152a6.808 6.808 0 01-5.696 0H7a5 5 0 00-5 5v.625C2 21.16 2.84 22 3.875 22h16.25C21.16 22 22 21.16 22 20.125V19.5a5 5 0 00-5-5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
