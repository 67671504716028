import * as React from "react";
function SvgArrowLeftOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12.168c0 .294.113.565.34.78l7.07 7.059c.248.237.497.34.769.34.577 0 1.018-.42 1.018-.996a.98.98 0 00-.294-.724l-2.398-2.444-3.428-3.133 2.545.158h13.349c.6 0 1.029-.43 1.029-1.04 0-.6-.43-1.03-1.03-1.03H7.623l-2.556.158 3.439-3.133 2.398-2.444c.18-.18.294-.44.294-.723 0-.577-.441-.996-1.018-.996-.272 0-.51.102-.747.317L2.34 11.398c-.226.215-.339.487-.339.77z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowLeftOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
