import * as React from "react";
function SvgMenorahSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.5 8h-1a.5.5 0 00-.5.5V13h2V8.5a.5.5 0 00-.5-.5zm3 0h-1a.5.5 0 00-.5.5V13h2V8.5a.5.5 0 00-.5-.5zm6 0h-1a.5.5 0 00-.5.5V13h2V8.5a.5.5 0 00-.5-.5zm3 0h-1a.5.5 0 00-.5.5V13h2V8.5a.5.5 0 00-.5-.5zM21 7a1 1 0 001-1c0-.552-1-2-1-2s-1 1.448-1 2a1 1 0 001 1zm-3 0a1 1 0 001-1c0-.552-1-2-1-2s-1 1.448-1 2a1 1 0 001 1zm-3 0a1 1 0 001-1c0-.552-1-2-1-2s-1 1.448-1 2a1 1 0 001 1zm-3 0a1 1 0 001-1c0-.552-1-2-1-2s-1 1.448-1 2a1 1 0 001 1zM9 7a1 1 0 001-1c0-.552-1-2-1-2S8 5.448 8 6a1 1 0 001 1zM6 7a1 1 0 001-1c0-.552-1-2-1-2S5 5.448 5 6a1 1 0 001 1zM3 7a1 1 0 001-1c0-.552-1-2-1-2S2 5.448 2 6a1 1 0 001 1zm17 6a1 1 0 01-1 1h-6V8.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V14H5a1 1 0 01-1-1V8.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V13a3 3 0 003 3h6v2H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H13v-2h6a3 3 0 003-3V8.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V13z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMenorahSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
