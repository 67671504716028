import * as React from "react";
function SvgMercurySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 10.125a5.61 5.61 0 00-2-4.3 3.94 3.94 0 00.281-.227c.965-.828 1.555-1.907 1.688-3.078a.464.464 0 00-.465-.52h-1.582a.469.469 0 00-.461.383c-.094.488-.375.949-.809 1.32-.597.516-1.406.797-2.277.797-.871 0-1.68-.281-2.281-.797-.434-.371-.711-.832-.809-1.32A.468.468 0 008.453 2H6.871c-.281 0-.5.242-.465.52.133 1.175.719 2.253 1.688 3.082.09.078.183.152.281.226a5.596 5.596 0 00-2 4.297 5.626 5.626 0 004.375 5.484v2.016H9.344a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469h1.406v1.406c0 .258.21.469.469.469h1.562a.47.47 0 00.469-.469v-1.406h1.406a.47.47 0 00.469-.469v-1.562a.47.47 0 00-.469-.469H13.25v-2.016a5.626 5.626 0 004.375-5.484zm-8.75 0A3.13 3.13 0 0112 7a3.13 3.13 0 013.125 3.125A3.13 3.13 0 0112 13.25a3.13 3.13 0 01-3.125-3.125z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMercurySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
