import * as React from "react";
function SvgSuperscriptSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 9.5h-.625V3.875a.625.625 0 00-.625-.625H18.25a.625.625 0 00-.558.345l-.625 1.25a.625.625 0 00.558.905h.625V9.5h-.625a.625.625 0 00-.625.625v1.25a.624.624 0 00.625.625h3.75a.624.624 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm-6.25-3.75h-2.617a.625.625 0 00-.513.268l-3.122 4.493-3.12-4.493a.625.625 0 00-.51-.268H2.624A.625.625 0 002 6.375V8.25a.625.625 0 00.625.625h1.308l3.04 4.375-3.04 4.375H2.625A.625.625 0 002 18.25v1.875a.625.625 0 00.625.625h2.617a.625.625 0 00.513-.268l3.122-4.492 3.12 4.492a.625.625 0 00.51.268h2.618a.624.624 0 00.625-.625V18.25a.624.624 0 00-.625-.625h-1.308l-3.04-4.375 3.04-4.375h1.308a.625.625 0 00.625-.625V6.375a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSuperscriptSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
