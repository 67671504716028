import * as React from "react";
function SvgStickyNoteOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 16.112V4.143C22 2.96 21.04 2 19.857 2H4.143C2.959 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22h11.97c.568 0 1.113-.226 1.515-.628l3.744-3.745c.402-.401.628-.947.628-1.515zm-5.714 3.572v-3.399h3.398l-3.398 3.398zm3.571-15.54v9.999h-4.643c-.591 0-1.071.48-1.071 1.071v4.643h-10V4.143h15.714z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgStickyNoteOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
