import * as React from "react";
function SvgGrinAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm2.569 5.19a.797.797 0 011.318 0c.5.741.609 1.487.633 2.23-.02.741-.133 1.487-.633 2.23a.797.797 0 01-1.319 0c-.5-.743-.608-1.489-.633-2.23.02-.743.133-1.489.633-2.23zm-6.452 0a.797.797 0 011.318 0c.5.741.61 1.487.633 2.23-.02.741-.133 1.487-.633 2.23a.797.797 0 01-1.318 0c-.5-.743-.609-1.489-.633-2.23.02-.743.133-1.489.633-2.23zM12 19.097c-2.444 0-5.423-1.545-5.798-3.762a.646.646 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613.46-.15.911.246.834.722-.375 2.217-3.354 3.762-5.798 3.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
