import styled from "styled-components";
import { Modal as AntModal } from "antd";

export const UiLink = styled.div`
	display: flex;
	align-items: center;
	text-decoration: underline;
	color: #82b4ff;
	cursor: pointer;
	text-underline-offset: 2px;
`;

export const StyledModal = styled(AntModal)`
	top: 50px;
	padding-bottom: unset;
	width: unset !important;

	.ant-modal-content {
		width: unset;
		height: 90vh;
	}

	.ant-modal-body {
		height: 85%;
	}

	.ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		> button {
			width: auto !important;
		}
	}

	.questionListTable {
		> div > div > div {
			padding: 15px 15px;
		}
	}

	.controlBox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		> button {
			margin: 0 10px 10px 0;
		}
	}
`;

export const StyledValidationQuestions = styled.div`
	position: relative;

	.checkboxItem {
		min-width: 30px;
	}

	.ScheduleListTable {
		/* height: auto !important; */
		overflow: hidden;
		height: calc(100vh - 310px) !important;

		> div > div > div {
			padding: 15px 15px;
		}

		.body {
			overflow: auto;
			height: calc(100vh - 364.5px) !important;
		}
	}

	.searchToolBar {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.flexStartBox {
			> div {
				margin-right: 10px;
				min-width: 160px;
			}
		}
	}

	.flexStartBox {
		display: flex;
		padding: 10px 0;
	}

	.exportListTable {
		box-shadow: none;
	}

	.emptyText {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		font-size: 16px;
		color: white;
	}

	.titleControlBox {
		position: relative;
		display: block;

		.flexStartBox {
			display: flex;
			padding: 10px 0;
		}

		.flexEndBox {
			display: flex;
			justify-content: flex-end;
			padding: 10px 0;

			> button:not(:last-child) {
				margin-right: 8px;
			}
		}

		.ruleChoice {
			background-color: red;
		}
	}

	.controlBox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		> button {
			margin: 0 15px 15px 0;
		}
	}

	.selectionBar {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		> div {
			margin-right: 8px;
			width: 130px;
		}
	}

	.searchBox {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 10px;

		> button {
			margin: 0 10px 0 0 !important;
			width: 150px !important;
		}

		> div {
			margin: 0 10px 0 0 !important;
		}

		.searchInputBox {
			display: flex;
			align-items: center;
			justify-content: center;

			label {
				color: #fff;
			}
		}
	}

	.dateFilterBox {
		width: 250px;
		margin-bottom: 5px;
	}
`;

export const UiRuleModalContent = styled.div`
	display: block;

	& > .ruleDetail {
		display: flex;
		width: 100%;

		& > div + div {
			margin: 0 0 8px 8px;
		}
	}
`;

export const UiButtons = styled.div`
	display: flex;
	justify-content: flex-end;

	> * + * {
		margin-left: 5px;
	}
`;

export const UiQuesDetail = styled(AntModal)`
	.ant-modal-body {
		padding: 6px 45px 40px;
	}
`;

export const UiPopupContainer = styled.div`
	.ant-tabs-tab-btn {
		display: flex;
		align-items: center;

		i {
			margin-right: 5px;
		}
	}

	.ant-image-preview-img-wrapper::before {
		height: 25%;
	}

	.ant-image-preview-img {
		margin: auto;
		width: 100%;
		max-width: 660px;
	}

	.container {
		position: relative;
		display: flex;
		justify-content: space-between;
		overflow-x: hidden;
		margin-top: 20px;
		max-height: 600px;

		&::after {
			position: absolute;
			top: 0;
			right: calc(50% - 2px);
			width: 3px;
			height: 100%;
			background-color: #f0f0f0;
			content: "";
		}

		> .content {
			position: relative;
			display: flex;
			justify-content: space-between;
			width: 45%;
			flex-direction: column;

			> .meta {
				padding: 2px 0;
				color: rgba(0, 0, 0, 0.85);

				> .metaContent {
					padding: 0 5px;
					color: rgba(0, 0, 0, 0.65);
				}
			}

			> .title {
				position: sticky;
				top: 0;
				padding: 10px;
				font-size: 16px;
				background-color: #dfeeff;
				margin-bottom: 10px;
			}

			.img {
				display: block;
				font-size: 0;
				flex: 1;
			}

			.info {
				margin-top: 10px;
				padding: 5px 0;
				background-color: #fafafa;

				li {
					font-size: 14px;
				}
			}
		}

		.bookTag {
			display: flex;
			align-items: center;

			& > div {
				display: flex;
				margin-bottom: 8px;

				& > div {
					margin-right: 8px;
				}
			}
		}
	}
`;

export const UiLoadingBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 2999;
`;
