import * as React from "react";
function SvgRedoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.524 2.007h-1.88a.476.476 0 00-.476.498l.159 3.282a9.812 9.812 0 00-7.492-3.463c-5.42 0-9.839 4.423-9.835 9.842.004 5.428 4.406 9.828 9.835 9.828a9.8 9.8 0 006.59-2.535.477.477 0 00.019-.691l-1.348-1.349a.476.476 0 00-.65-.021 6.98 6.98 0 111.183-9.134l-4.027-.193a.475.475 0 00-.498.476v1.88a.476.476 0 00.476.476h7.944a.476.476 0 00.476-.476V2.482a.476.476 0 00-.476-.475z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRedoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
