import * as React from "react";
function SvgKiwiBirdSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.993 10.68C21.883 8.577 19.996 7 17.89 7h-.326a9.036 9.036 0 01-5.13-1.606C10.978 4.39 9.1 3.95 7.115 4.398c-.624.14-5.117 1.43-5.114 6.49a6.66 6.66 0 003.333 5.765v2.57c0 .306.249.555.556.555h.555A.555.555 0 007 19.222v-1.884c.533.138 1.09.217 1.666.217.19 0 .371-.025.556-.04v1.707c0 .307.248.556.555.556h.556a.555.555 0 00.556-.556V17.16a6.605 6.605 0 001.4-.678c1.588-1.035 3.387-1.703 5.283-1.703.884 0 .555-.011.8-.027l2.584 4.737a.556.556 0 001.043-.266s.005-8.342-.006-8.542zm-3.883 1.042a.833.833 0 110-1.667.833.833 0 010 1.667zm2.778 5.321l-1.384-2.537a3.881 3.881 0 001.384-.902v3.44z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgKiwiBirdSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
