import styled from "styled-components";

export const UiFilterBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 0 0 8px;
	background-color: #626262;
	border-radius: 2px;
`;

export const UiSearchInput = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	> input {
		flex: 1;
		margin-left: 10px;
		color: #fff;
		background-color: transparent;
		border: none;
		outline: none;
		caret-color: #fff;
	}
`;

export const UiFilterCondition = styled.div``;
