import * as React from "react";
function SvgCompassSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.088 11.088a1.29 1.29 0 101.824 1.824 1.29 1.29 0 00-1.824-1.825zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5.086 5.97l-2.66 5.82a1.288 1.288 0 01-.636.636l-5.82 2.66c-.671.307-1.363-.385-1.056-1.056l2.66-5.82c.129-.282.355-.508.636-.637l5.82-2.66c.672-.306 1.363.385 1.056 1.057z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCompassSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
