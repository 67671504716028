import * as React from "react";
function SvgChevronRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.747 12.777l-8.901 8.901a1.1 1.1 0 01-1.555 0L6.253 20.64a1.1 1.1 0 01-.002-1.553L13.305 12 6.252 4.913a1.1 1.1 0 01.002-1.553l1.038-1.038a1.1 1.1 0 011.555 0l8.9 8.9c.43.43.43 1.126 0 1.555z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgChevronRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
