import * as React from "react";
function SvgBookMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 16V3c0-.625-.375-1-1-1H7C4.875 2 3.25 3.625 3.25 5.75v12.5C3.25 20.375 4.875 22 7 22h12.75c.5 0 1-.375 1-1v-.625c0-.25-.125-.5-.375-.75-.125-.625-.125-2.375 0-2.875.25-.125.375-.375.375-.75zM8.875 8.562a.312.312 0 01.313-.312h2.187V6.062a.312.312 0 01.313-.312h1.874a.313.313 0 01.313.313V8.25h2.188a.313.313 0 01.312.313v1.874a.313.313 0 01-.313.313h-2.187v2.188a.313.313 0 01-.313.312h-1.874a.313.313 0 01-.313-.313V10.75H9.187a.312.312 0 01-.312-.313V8.563zm9.25 10.938H7c-.75 0-1.25-.5-1.25-1.25S6.375 17 7 17h11.125v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBookMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
