"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiPickListDropdown = exports.UiClearButton = exports.UiCheckList = exports.SingleList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiPickListDropdown = exports.UiPickListDropdown = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tposition: relative;\n\tdisplay: inline-block;\n\tuser-select: none;\n\n\t> .header {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tpadding: 6px 12px;\n\t\tmin-width: 120px;\n\t\tcolor: #fff;\n\t\tbackground-color: #626262;\n\t\tcursor: pointer;\n\n\t\t&:hover {\n\t\t\tbackground-color: rgba(255, 255, 255, 0.06);\n\t\t}\n\n\t\t> .icon {\n\t\t\tmargin-left: 10px;\n\t\t\tfont-size: 0;\n\n\t\t\t> div {\n\t\t\t\tcursor: pointer;\n\n\t\t\t\t> * {\n\t\t\t\t\tfont-size: 24px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\t> .title {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t}\n\n\t\t.others {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\tmargin-left: 4px;\n\t\t\twidth: 19px;\n\t\t\theight: 19px;\n\t\t\tcolor: #fff;\n\t\t\tbackground-color: #ff8933;\n\t\t\tborder-radius: 50%;\n\t\t}\n\t}\n\n\t> .content {\n\t\tposition: absolute;\n\t\tright: 0;\n\t\tmargin-top: 4px;\n\t\tpadding: 8px;\n\t\twidth: max-content;\n\t\tmin-width: 100%;\n\t\tmax-width: 400px;\n\t\tbackground-color: #fff;\n\t\tz-index: 1;\n\t\tbox-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n\n\t\t> .action {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: flex-end;\n\t\t\tborder-top: 1px solid #f0f0f0;\n\t\t}\n\n\t\t> .optionsWrap {\n\t\t\toverflow-x: hidden;\n\t\t\tmax-height: 330px;\n\n\t\t\t.tabCheckCount {\n\t\t\t\tmargin-left: 4px;\n\t\t\t\tpadding: 4px 8px;\n\t\t\t\tcolor: #fff;\n\t\t\t\tbackground-color: #79bfff;\n\t\t\t\tborder-radius: 50%;\n\t\t\t}\n\n\t\t\t> .ant-checkbox-group {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t}\n\t\t}\n\t}\n\n\t.tabHeader {\n\t\t> .ant-checkbox-wrapper {\n\t\t\tmargin-right: 4px;\n\t\t}\n\t}\n"])));
const UiCheckList = exports.UiCheckList = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tcursor: pointer;\n\theight: 32px;\n\n\t&:hover {\n\t\tbackground-color: #dfeeff;\n\t}\n\n\t> .ant-checkbox-wrapper {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tpadding: 0 12px;\n\t\twidth: 100%;\n\t\theight: 100%;\n\n\t\t.ant-checkbox {\n\t\t\ttop: auto;\n\t\t}\n\t}\n"])));
const UiClearButton = exports.UiClearButton = _styledComponents.default.button(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\tmargin: 8px 4px 4px 0;\n\tpadding: 1px 8px;\n\tbackground-color: transparent;\n\tborder: 1px solid #d9d9d9;\n\tborder-radius: 2px;\n\toutline: none;\n\ttransition: 0.3s;\n\tcursor: pointer;\n\n\t> .icon {\n\t\tmargin-right: 10px;\n\t}\n\n\t&:hover {\n\t\tbackground-color: #dfeeff;\n\t}\n\n\t&:disabled,\n\t&[disabled] {\n\t\tcursor: auto;\n\t\tcolor: #ccc;\n\t\tbackground-color: transparent;\n\n\t\t&:hover {\n\t\t\tbackground-color: transparent;\n\t\t}\n\t}\n"])));
const SingleList = exports.SingleList = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 0 12px;\n\twidth: 100%;\n\theight: 100%;\n\n\t&:hover {\n\t\tbackground-color: #dfeeff;\n\t}\n"])));