import * as React from "react";
function SvgAtSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.473 2 2 6.473 2 12s4.473 10 10 10c1.942 0 3.844-.57 5.46-1.622a.97.97 0 00.224-1.426l-.41-.502a.965.965 0 00-1.265-.206A7.44 7.44 0 0112 19.419c-4.091 0-7.42-3.328-7.42-7.419 0-4.091 3.329-7.42 7.42-7.42 4.038 0 7.42 2.324 7.42 6.452 0 1.564-.851 3.216-2.346 3.375-.7-.018-.682-.518-.544-1.21l.945-4.884a.968.968 0 00-.95-1.152h-1.813a.545.545 0 00-.542.484v.004c-.593-.722-1.631-.878-2.419-.878-3.007 0-5.557 2.509-5.557 6.107 0 2.633 1.483 4.269 3.87 4.269 1.089 0 2.314-.63 3.024-1.546.384 1.375 1.638 1.375 2.852 1.375 4.391 0 6.06-2.887 6.06-5.944C22 5.534 17.565 2 12 2zm-.874 12.275c-.897 0-1.455-.63-1.455-1.644 0-1.814 1.242-2.932 2.365-2.932.898 0 1.435.614 1.435 1.644 0 1.817-1.366 2.932-2.345 2.932z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAtSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
