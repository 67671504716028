"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiTreeSelect = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiTreeSelect = exports.UiTreeSelect = _styledComponents.default.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tposition: relative;\n\tdisplay: block;\n\n\t> .label {\n\t\tmargin-bottom: 1px;\n\t\tcolor: rgba(0, 0, 0, 0.65);\n\t}\n"])));