import * as React from "react";
function SvgFileVideoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 6.763V7h-5V2h.237c.248 0 .487.099.663.275L19.225 6.1a.938.938 0 01.275.663zm-6.25.55V2H5.437a.937.937 0 00-.937.938v18.125c0 .517.42.937.938.937h13.125c.517 0 .937-.42.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM17 12.938v4.374c0 .837-1.013 1.25-1.6.663l-2.15-2.15v1.488c0 .517-.42.937-.938.937H7.939A.937.937 0 017 17.312v-4.375c0-.517.42-.937.938-.937h4.375c.517 0 .937.42.937.938v1.486l2.15-2.149c.586-.586 1.6-.175 1.6.663z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileVideoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
