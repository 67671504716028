import * as React from "react";
function SvgSortUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.32 17.839H3.682c-1.496 0-2.244-1.81-1.188-2.866l8.319-8.319a1.67 1.67 0 012.37 0l8.318 8.319c1.063 1.055.315 2.866-1.181 2.866z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
