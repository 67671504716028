import * as React from "react";
function SvgDollySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.215 12.754a5.058 5.058 0 011.719.858l5.607-1.868a.554.554 0 00.35-.702l-2.096-6.291a.554.554 0 00-.702-.35l-2.121.708 1.149 3.45-2.108.699-1.149-3.452-2.139.712a.554.554 0 00-.35.702l1.84 5.534zm9.756 1.691l-.354-1.056a.554.554 0 00-.701-.35l-7.413 2.472a3.318 3.318 0 00-2.552-1.285L7.5 3.872a1.113 1.113 0 00-1.056-.76H2.556A.557.557 0 002 3.668v1.11c0 .306.25.556.556.556h3.086l3.201 9.607a3.31 3.31 0 00-1.25 3.143c.212 1.368 1.316 2.51 2.684 2.75a3.331 3.331 0 003.938-3.215l7.406-2.473a.556.556 0 00.35-.701zM10.89 19.223a1.667 1.667 0 110-3.334 1.667 1.667 0 010 3.334z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDollySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
