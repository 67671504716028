import * as React from "react";
function SvgCandyCaneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.433 5.594a6.256 6.256 0 00-8.863-2.711l-1.075.64a1.248 1.248 0 00-.43 1.715l1.282 2.145a1.252 1.252 0 001.715.433l1.074-.64a1.252 1.252 0 011.285 2.148L2.61 17.531a1.248 1.248 0 00-.43 1.715l1.281 2.145a1.252 1.252 0 001.715.433l13.566-8.066c2.801-1.676 4.086-5.215 2.692-8.164zm-6.941.508l-.098.058-.801-2.402a4.915 4.915 0 011.184-.399l.804 2.414a2.493 2.493 0 00-1.09.329zM7.698 18.867l-2.363-1.504 1.203-.715 2.364 1.504-1.203.715zm4.2-2.496l-2.364-1.504 1.204-.715 2.363 1.504-1.203.715zm4.332-2.574l-2.364-1.504 1.204-.715 2.363 1.504-1.203.715zm.797-7.707l1.796-1.797c.329.254.625.55.883.883L17.921 6.96a2.58 2.58 0 00-.894-.871zm3.207 4.21l-2.325-.773a2.49 2.49 0 00.29-.668c.042-.175.066-.355.07-.53l2.36.784a5.238 5.238 0 01-.395 1.188z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCandyCaneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
