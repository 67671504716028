import * as React from "react";
function SvgFrownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 6.774a1.289 1.289 0 110 2.58c-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm-6.452 0c.714 0 1.29.577 1.29 1.29 0 .714-.576 1.29-1.29 1.29-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm6.863 8.799A4.73 4.73 0 0012 15.87a4.73 4.73 0 00-3.637 1.702c-.544.657-1.536-.17-.992-.827a6.023 6.023 0 019.254.004c.548.653-.444 1.48-.988.823z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFrownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
