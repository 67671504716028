import * as React from "react";
function SvgTransgenderAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.906 2H17.82a.47.47 0 00-.332.8l.66.66-3.152 3.153A5.598 5.598 0 0012 5.75a5.598 5.598 0 00-2.996.863l-.645-.644.774-.774a.47.47 0 000-.664L8.027 3.426a.47.47 0 00-.664 0l-.773.773-.742-.742.66-.66A.464.464 0 006.18 2H3.094a.47.47 0 00-.469.469v3.086a.47.47 0 00.8.332l.66-.66.743.742-.773.773a.47.47 0 000 .664L5.16 8.512a.47.47 0 00.664 0l.774-.774.644.645a5.544 5.544 0 00-.867 2.992 5.626 5.626 0 004.375 5.484v1.078H9.344a.47.47 0 00-.469.47v1.562c0 .258.21.468.469.468h1.406v1.094c0 .258.21.469.469.469h1.562a.47.47 0 00.469-.469v-1.093h1.406a.47.47 0 00.469-.47v-1.562a.47.47 0 00-.469-.468H13.25v-1.079a5.63 5.63 0 003.512-8.48l3.152-3.152.66.66c.297.297.801.086.801-.332V2.469A.47.47 0 0020.906 2zM12 14.5a3.13 3.13 0 01-3.125-3.125A3.13 3.13 0 0112 8.25a3.13 3.13 0 013.125 3.125A3.13 3.13 0 0112 14.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTransgenderAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
