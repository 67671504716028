import * as React from "react";
function SvgLongArrowAltLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.985 13.786h13.48c.295 0 .535-.24.535-.536v-2.5a.536.536 0 00-.536-.536H7.984V8.158c0-.954-1.153-1.432-1.828-.758l-3.842 3.842a1.071 1.071 0 000 1.516l3.842 3.841c.675.675 1.829.197 1.829-.757v-2.056z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLongArrowAltLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
