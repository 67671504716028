import * as React from "react";
function SvgFileArchiveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.227 6.102l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-9.711 9.023c-.7 0-1.266.473-1.266 1.055 0 .586.57 1.054 1.27 1.054.699 0 1.265-.472 1.265-1.054 0-.582-.57-1.055-1.27-1.055zm3.734-7.813V2h-2.484v1.25h-1.25V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM8.246 3.25h1.25V4.5h-1.25V3.25zm1.262 15A2.048 2.048 0 017.5 15.793L8.266 12v-1.25h1.25V9.5h-1.25V8.25h1.25V7h-1.25V5.75h1.25V4.5h1.25v1.25h-1.25V7h1.25v1.25h-1.25V9.5h1.25v1.25h-1.25V12h.863c.223 0 .418.16.46.379l.677 3.426a2.048 2.048 0 01-2.008 2.445z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileArchiveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
