import * as React from "react";
function SvgMapMarkedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.111a4.375 4.375 0 00-4.375 4.375c0 1.954 2.86 5.514 3.955 6.806a.548.548 0 00.84 0C13.516 13 16.375 9.44 16.375 7.486A4.375 4.375 0 0012 3.111zM2.699 10.61A1.11 1.11 0 002 11.641v8.692c0 .393.397.661.762.516l4.794-2.182v-8.093a10.512 10.512 0 01-.738-1.612l-4.12 1.647zM12 15.6a1.66 1.66 0 01-1.268-.588 36.69 36.69 0 01-2.065-2.664v6.32l6.666 2.221v-8.54a36.61 36.61 0 01-2.065 2.663A1.66 1.66 0 0112 15.6zm9.238-6.892l-4.794 2.182v10l4.857-1.943A1.11 1.11 0 0022 17.915V9.223a.556.556 0 00-.762-.516z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMapMarkedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
