import * as React from "react";
function SvgCalendarCheckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 8.25H3.72a.469.469 0 01-.469-.469V6.375c0-1.036.84-1.875 1.875-1.875H7V2.469c0-.26.21-.469.469-.469H9.03c.26 0 .469.21.469.469V4.5h5V2.469c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875v1.406c0 .26-.21.469-.469.469zM3.72 9.5H20.28c.26 0 .469.21.469.469v10.156c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V9.969c0-.26.21-.469.469-.469zm13.02 3.748l-1.101-1.11a.469.469 0 00-.663-.002l-4.142 4.109-1.796-1.81a.469.469 0 00-.663-.004l-1.11 1.1a.469.469 0 00-.002.664l3.227 3.252c.182.184.479.185.662.003l5.584-5.54a.469.469 0 00.003-.662z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCalendarCheckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
