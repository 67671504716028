import * as React from "react";
function SvgTengeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.036 7.714H3.964a.537.537 0 00-.535.536v2.5c0 .295.24.536.535.536h6.25v10.178c0 .295.241.536.536.536h2.5a.537.537 0 00.536-.536V11.286h6.25a.537.537 0 00.535-.536v-2.5a.537.537 0 00-.535-.536zm0-5.714H3.964a.537.537 0 00-.535.536v2.5c0 .294.24.535.535.535h16.072a.537.537 0 00.535-.535v-2.5A.537.537 0 0020.036 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTengeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
