"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserAuthorityPreference = exports.getUserAuthority = void 0;
var _index = _interopRequireDefault(require("../index"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const getUserAuthority = async url => {
  console.log("url", url);
  const response = await _index.default.get("".concat(url, "/api/Authority/UserProfile")).then(res => {
    return res;
  }).catch(error => {
    return {
      isSuccess: false,
      data: error.data
    };
  });
  console.log("responseresponse", response);
  return response;
};
exports.getUserAuthority = getUserAuthority;
const getUserAuthorityPreference = async (url, payload) => {
  const {
    type,
    data
  } = payload;
  const response = await _index.default.post("".concat(url, "/api/Authority/Preference/").concat(type), data);
  return response;
};
exports.getUserAuthorityPreference = getUserAuthorityPreference;