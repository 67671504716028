import * as React from "react";
function SvgBellOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.414 16.152c-.755-.811-2.167-2.03-2.167-6.027 0-3.035-2.128-5.465-4.998-6.06V3.25a1.25 1.25 0 10-2.498 0v.814c-2.87.596-4.998 3.026-4.998 6.061 0 3.996-1.412 5.216-2.167 6.027A1.22 1.22 0 003.25 17c.004.64.507 1.25 1.254 1.25h14.992c.747 0 1.25-.61 1.254-1.25a1.22 1.22 0 00-.336-.848zm-14.526.223c.829-1.093 1.735-2.903 1.74-6.227 0-.008-.003-.015-.003-.023a4.375 4.375 0 118.75 0l-.002.023c.004 3.324.91 5.135 1.74 6.227H5.887zM12 22a2.5 2.5 0 002.499-2.5H9.5A2.5 2.5 0 0012 22z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBellOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
