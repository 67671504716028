import * as React from "react";
function SvgCommentsSolid1(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.444 9.778c0-3.07-3.232-5.556-7.222-5.556S2 6.708 2 9.778c0 1.19.49 2.288 1.32 3.194-.466 1.049-1.233 1.882-1.244 1.893a.276.276 0 00-.052.302.272.272 0 00.254.166c1.27 0 2.323-.427 3.08-.868a8.834 8.834 0 003.864.868c3.99 0 7.222-2.486 7.222-5.555zm4.237 7.639c.83-.903 1.319-2.004 1.319-3.195 0-2.323-1.858-4.312-4.49-5.142.032.229.046.462.046.698 0 3.677-3.74 6.666-8.334 6.666-.375 0-.74-.027-1.1-.066 1.093 1.997 3.663 3.4 6.656 3.4 1.423 0 2.746-.32 3.864-.868.757.44 1.81.868 3.08.868a.278.278 0 00.201-.469c-.01-.01-.777-.84-1.242-1.892z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentsSolid1);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
