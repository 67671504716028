"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiAddListTableRow = exports.UiAddListTableItem = exports.UiAddListTableHeader = exports.UiAddListTableBody = exports.UiAddListTable = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _styledSystem = require("styled-system");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiAddListTable = exports.UiAddListTable = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n"])));
const UiAddListTableRow = exports.UiAddListTableRow = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\tmargin: 8px 0;\n\tpadding: 25px 16px;\n\tborder: 1px solid #e7e7ed;\n\tborder-radius: 12px;\n\t", "\n"])), _ref => {
  let {
    isadmin
  } = _ref;
  return isadmin && (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\t\t\tbackground: #dbe9ff;\n\t\t\tborder: none;\n\t\t"])));
});
const UiAddListTableItem = exports.UiAddListTableItem = _styledComponents.default.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\tflex: 1;\n\tdisplay: flex;\n\talign-items: center;\n\t", "\n"])), _styledSystem.flexbox);
const UiAddListTableHeader = exports.UiAddListTableHeader = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\t", " {\n\t\tdisplay: flex;\n\t\tmargin: 8px 0;\n\t\tpadding: 13.5px 16px;\n\t\tborder: none;\n\t}\n"])), UiAddListTableRow);
const UiAddListTableBody = exports.UiAddListTableBody = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));