import * as React from "react";
function SvgTableSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25H3.875C2.839 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.036-.84-1.875-1.875-1.875zm-9.375 15H4.5V14.5h6.25v3.75zm0-6.25H4.5V8.25h6.25V12zm8.75 6.25h-6.25V14.5h6.25v3.75zm0-6.25h-6.25V8.25h6.25V12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTableSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
