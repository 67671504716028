import * as React from "react";
function SvgFolderOpenOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.33 10.889h-1.663V9.222c0-.92-.746-1.667-1.667-1.667h-5.555L9.222 5.333H3.667C2.747 5.333 2 6.08 2 7v10c0 .92.747 1.667 1.667 1.667h13.89c.572 0 1.107-.295 1.412-.785l2.775-4.445c.694-1.107-.104-2.548-1.413-2.548zM3.668 7.209c0-.116.093-.21.208-.21h4.656l2.223 2.223h6.038c.115 0 .208.094.208.208v1.459H7.278c-.583 0-1.125.305-1.427.805l-2.184 3.618V7.209zM17.557 17H4.5l2.68-4.444h13.154L17.556 17z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFolderOpenOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
