import * as React from "react";
function SvgUserTieSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 12a5 5 0 100-10 5 5 0 000 10zm3.742 1.273l-1.867 7.477-1.25-5.313 1.25-2.187h-3.75l1.25 2.188-1.25 5.312-1.867-7.477c-2.785.133-5.008 2.41-5.008 5.227v1.625C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V18.5c0-2.816-2.223-5.094-5.008-5.227z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUserTieSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
