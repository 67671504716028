import * as React from "react";
function SvgPersonBoothSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.667 20.333c0 .306.25.556.555.556h1.111c.306 0 .556-.25.556-.556v-6.11H8.667v6.11zm1.11-9.444H8.01L6.441 9.316c-.42-.42-.98-.65-1.57-.65h-.649A2.223 2.223 0 002 10.893l.007 3.33L2 19.778a1.11 1.11 0 102.219 0l.003-3.497c.031.018.056.045.087.06l1.01 1.492v1.945a1.11 1.11 0 102.223 0v-1.962c0-.344-.08-.688-.233-.993l-1.43-2.129v-2.798l.725.725c.316.316.733.49 1.177.49h1.997a1.11 1.11 0 100-2.222zM4.223 7.556a1.667 1.667 0 100-3.335 1.667 1.667 0 000 3.335zM12 4.222l1.094 7.747-1.073 5.368c-.15.75.451 1.33 1.09 1.33.528 0 .972-.316 1.122-1.056a1.106 1.106 0 002.211-.056 1.11 1.11 0 102.223 0V3.111H12v1.111zm-3.333 0v5.556h2.222V3.11H9.778a1.11 1.11 0 00-1.111 1.111zm12.222-1.11h-1.111v17.221c0 .306.25.556.555.556h1.111c.306 0 .556-.25.556-.556V4.223a1.11 1.11 0 00-1.111-1.112z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPersonBoothSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
