import * as React from "react";
function SvgCookieBiteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.942 11.993a4.997 4.997 0 01-4.94-4.995 4.997 4.997 0 01-4.994-4.94 5.155 5.155 0 00-3.115.502l-2.7 1.375a5.165 5.165 0 00-2.257 2.258L2.565 8.884a5.181 5.181 0 00-.501 3.162l.472 2.98a5.177 5.177 0 001.451 2.85l2.14 2.138a5.157 5.157 0 002.84 1.448l2.996.474a5.153 5.153 0 003.146-.498l2.7-1.375a5.167 5.167 0 002.258-2.258l1.37-2.691c.491-.963.665-2.054.505-3.121zM8.875 16.375a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm1.25-6.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm6.25 5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCookieBiteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
