import * as React from "react";
function SvgBlogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.728 10.86c-.57-.113-1.102.348-1.102.93v1.97c0 .398.278.718.653.859a1.876 1.876 0 11-2.527 1.758V6.688a.935.935 0 00-.938-.938H2.939A.935.935 0 002 6.69v9.687a5.634 5.634 0 006.836 5.497c2.125-.446 3.84-2.164 4.286-4.286a5.634 5.634 0 00-4.395-6.726zM10.166 2a.629.629 0 00-.665.626V3.86c0 .332.258.606.586.621 5.055.274 9.118 4.376 9.41 9.434.02.329.294.586.622.586h1.254c.36 0 .644-.304.625-.664-.332-6.375-5.457-11.5-11.833-11.836zm.011 3.751a.63.63 0 00-.676.629v1.254c0 .328.254.598.579.621 3 .246 5.39 2.664 5.66 5.672a.628.628 0 00.621.574h1.258a.632.632 0 00.629-.675c-.328-4.301-3.77-7.743-8.07-8.075z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBlogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
