import * as React from "react";
function SvgDesktopSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 3.111H3.667C2.747 3.111 2 3.858 2 4.778v11.11c0 .921.747 1.667 1.667 1.667h6.666l-.555 1.667h-2.5a.831.831 0 00-.834.833c0 .462.372.834.834.834h9.444a.831.831 0 00.834-.834.831.831 0 00-.834-.833h-2.5l-.555-1.667h6.666c.92 0 1.667-.746 1.667-1.666V4.778c0-.92-.747-1.667-1.667-1.667zm-.555 12.222H4.222v-10h15.556v10z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDesktopSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
