import * as React from "react";
function SvgScrollSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.5 4C2.673 4 2 4.673 2 5.5v2a.5.5 0 00.5.5H5V5.5C5 4.673 4.327 4 3.5 4zM10 16.893V15h9V7c0-1.654-1.346-3-3-3H5.487c.317.42.513.935.513 1.5V17a2.001 2.001 0 002.336 1.973c.983-.16 1.664-1.084 1.664-2.08zM11 16v1c0 1.654-1.346 3-3 3h10.5a3.5 3.5 0 003.5-3.5.5.5 0 00-.5-.5H11z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgScrollSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
