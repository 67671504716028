import * as React from "react";
function SvgGrinTonGueWinkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.129 8.935c-1.036 0-2.254.682-2.411 1.698a.483.483 0 00.798.435l.383-.342c.597-.533 1.863-.533 2.46 0l.383.342c.1.09.322.19.552.065a.485.485 0 00.246-.5c-.157-1.016-1.375-1.698-2.411-1.698zm7.097-2.096a3.225 3.225 0 100 6.451 3.225 3.225 0 100-6.451zm0 5.161a1.936 1.936 0 110-3.872 1.936 1.936 0 010 3.872zm0-2.903a.965.965 0 00-.968.968c0 .536.432.967.968.967a.965.965 0 00.967-.967.965.965 0 00-.967-.968zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm2.58 16.129a2.58 2.58 0 01-2.616 2.58c-1.416-.02-2.545-1.2-2.545-2.62v-1.726l.714-.355a.987.987 0 011.407.665l.113.488c.085.371.613.371.698 0l.113-.488a.984.984 0 011.407-.665l.714.355v1.766h-.004zm1.138 1.02c.088-.326.153-.665.153-1.02v-1.754c.572-.5.984-1.109 1.1-1.794a.552.552 0 00-.713-.617c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.553.553 0 00-.714.617c.117.685.528 1.294 1.101 1.794v1.754c0 .355.065.694.153 1.02-2.58-1.347-4.347-4.044-4.347-7.149 0-4.448 3.617-8.065 8.065-8.065 4.448 0 8.064 3.617 8.064 8.065 0 3.105-1.766 5.802-4.346 7.15z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinTonGueWinkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
