import * as React from "react";
function SvgLemonOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.91 3.09c-1.098-1.1-2.656-1.405-3.79-.744-1.542.898-6.39-1.952-11.558 3.216C.388 10.737 3.247 15.573 2.346 17.12c-.66 1.135-.355 2.693.743 3.792 1.1 1.099 2.657 1.403 3.792.743 1.54-.898 6.389 1.951 11.557-3.216 5.174-5.175 2.315-10.011 3.216-11.557.66-1.135.355-2.693-.743-3.792zm-.875 2.847c-1.515 2.602 1.782 6.47-2.923 11.175-4.705 4.705-8.573 1.408-11.175 2.923-.886.516-2.501-1.064-1.972-1.972 1.515-2.602-1.782-6.47 2.923-11.175 4.705-4.704 8.573-1.408 11.175-2.923.888-.518 2.5 1.065 1.972 1.972zm-6.635.341a.781.781 0 01-.678.872c-2.143.268-5.304 3.43-5.572 5.572a.781.781 0 11-1.55-.194c.358-2.863 4.069-6.57 6.928-6.928a.781.781 0 01.872.678z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLemonOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
