import * as React from "react";
function SvgFileAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.5 9.218a.47.47 0 01-.469.469H8.72a.47.47 0 01-.469-.469v-.312a.47.47 0 01.469-.469h6.562a.47.47 0 01.469.469v.312zm0-2.5a.47.47 0 01-.469.469H8.72a.47.47 0 01-.469-.469v-.312a.47.47 0 01.469-.469h6.562a.47.47 0 01.469.469v.312zm0-2.812v.312a.47.47 0 01-.469.469H8.72a.47.47 0 01-.469-.469v-.312a.47.47 0 01.469-.469h6.562a.47.47 0 01.469.469zm3.75-4.457V7h-5V2h.238c.25 0 .489.098.664.273l3.825 3.829a.935.935 0 01.273.66z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
