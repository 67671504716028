import * as React from "react";
function SvgSearchDollarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.728 19.291l-3.895-3.894a.937.937 0 00-.664-.273h-.637a8.084 8.084 0 001.72-5A8.124 8.124 0 0010.125 2a8.124 8.124 0 100 16.248c1.886 0 3.621-.64 5-1.718v.636c0 .25.098.489.274.664l3.894 3.895a.934.934 0 001.325 0l1.105-1.106a.941.941 0 00.004-1.328zm-11.602-3.543A5.621 5.621 0 014.5 10.124 5.621 5.621 0 0110.126 4.5a5.621 5.621 0 015.625 5.624 5.621 5.621 0 01-5.625 5.624zm1.059-5.958l-1.758-.528c-.202-.06-.343-.264-.343-.497 0-.284.207-.515.46-.515h1.099c.178 0 .35.05.5.145a.32.32 0 00.396-.028l.46-.438a.313.313 0 00-.023-.474 2.236 2.236 0 00-1.225-.444v-.636a.312.312 0 00-.313-.313h-.625a.312.312 0 00-.312.313v.63c-.923.024-1.667.802-1.667 1.76 0 .78.507 1.477 1.233 1.695l1.758.527c.202.061.343.265.343.498 0 .284-.207.515-.461.515H9.609a.935.935 0 01-.5-.145.32.32 0 00-.396.028l-.46.438a.313.313 0 00.023.474c.356.267.785.421 1.226.444v.636c0 .173.14.312.312.312h.625c.173 0 .313-.14.313-.312v-.63c.923-.024 1.667-.802 1.667-1.76 0-.78-.508-1.477-1.234-1.695z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSearchDollarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
