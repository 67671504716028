import * as React from "react";
function SvgNeuterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.429 8.429A6.43 6.43 0 0012 2a6.43 6.43 0 00-1.429 12.696v6.768c0 .295.242.536.536.536h1.786a.537.537 0 00.536-.536v-6.768a6.43 6.43 0 005-6.267zM12 12a3.576 3.576 0 01-3.571-3.571A3.576 3.576 0 0112 4.857a3.576 3.576 0 013.571 3.572A3.576 3.576 0 0112 12z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgNeuterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
