import * as React from "react";
function SvgFeatherAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 2c-2.023.14-16.233 1.492-17.226 11.235a43.35 43.35 0 00-.234 3.134l6.987-6.98a.626.626 0 01.885.884L2.275 20.4a.937.937 0 101.327 1.326l2.232-2.23a44.156 44.156 0 004.92-.287c2.09-.213 3.79-1.034 5.18-2.209H11.99l5.734-1.91c.44-.581.834-1.199 1.19-1.84h-3.17l4.161-2.079C21.542 7.19 21.93 3.027 22 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFeatherAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
