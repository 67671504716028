import * as React from "react";
function SvgPlaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.828 10.385L6.078 2.257c-1.117-.66-2.828-.02-2.828 1.613v16.253c0 1.465 1.59 2.348 2.829 1.613l13.749-8.124c1.227-.723 1.23-2.504 0-3.227z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPlaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
