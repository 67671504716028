import * as React from "react";
function SvgArrowAltCircleDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0 5.524-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2s10 4.476 10 10zM10.226 7.323V12h-2.86a.484.484 0 00-.342.827l4.633 4.609c.19.189.492.189.682 0l4.633-4.61a.484.484 0 00-.343-.826h-2.855V7.323a.485.485 0 00-.484-.484h-2.58a.485.485 0 00-.484.484z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArrowAltCircleDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
