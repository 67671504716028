import * as React from "react";
function SvgObjectGroupSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 7V5.75h.781c.26 0 .469-.21.469-.469V3.72a.469.469 0 00-.469-.469H19.97a.469.469 0 00-.469.469V4.5h-15v-.781a.469.469 0 00-.469-.469H2.47A.469.469 0 002 3.719V5.28c0 .26.21.469.469.469h.781v12.5h-.781a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469H4.03c.26 0 .469-.21.469-.469V19.5h15v.781c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469V18.72a.469.469 0 00-.469-.469h-.781V7zm-15 5.781V7.47c0-.26.21-.469.469-.469h6.562c.26 0 .469.21.469.469v5.312c0 .26-.21.469-.469.469H6.22a.469.469 0 01-.469-.469zm12.5 3.75c0 .26-.21.469-.469.469H11.22a.469.469 0 01-.469-.469V14.5h2.813c.517 0 .937-.42.937-.938V10.75h3.281c.26 0 .469.21.469.469v5.312z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgObjectGroupSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
