import * as React from "react";
function SvgMarkerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.67 13.33a12.775 12.775 0 00-3.663 7.62l-.001.008a.938.938 0 001.035 1.036 12.774 12.774 0 007.63-3.664l2.945-2.945-5-5-2.946 2.944zM20.964 3.035a3.536 3.536 0 00-5 0l-.85.85-1.428-1.428c-.61-.61-1.6-.61-2.21 0L7.41 6.525a.625.625 0 000 .884l.884.884c.244.244.64.244.884 0l3.404-3.404.765.765L9.5 9.5l5.001 5 6.464-6.463a3.536 3.536 0 000-5.001z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMarkerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
