import * as React from "react";
function SvgCreditCardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.33 4.222H3.67C2.747 4.222 2 4.97 2 5.89V18.11c0 .92.747 1.667 1.67 1.667h16.66c.924 0 1.67-.747 1.67-1.667V5.89c0-.92-.747-1.667-1.67-1.667zM3.878 5.89h16.244c.114 0 .208.094.208.208v1.458H3.67V6.098c0-.114.094-.208.208-.208zM20.122 18.11H3.878a.209.209 0 01-.208-.208V12h16.66v5.903a.209.209 0 01-.208.208zM8.667 14.64v1.389c0 .229-.188.416-.417.416h-2.5a.418.418 0 01-.417-.416v-1.39c0-.228.188-.416.417-.416h2.5c.23 0 .417.188.417.417zm6.666 0v1.389c0 .229-.187.416-.416.416h-4.723a.418.418 0 01-.416-.416v-1.39c0-.228.187-.416.416-.416h4.723c.229 0 .416.188.416.417z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCreditCardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
