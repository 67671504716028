import * as React from "react";
function SvgIconsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.557 10.568a.613.613 0 00.884 0l3.78-3.9c1.1-1.132 1.035-3.011-.192-4.057-1.07-.912-2.662-.748-3.644.266L7 3.273l-.385-.396c-.98-1.014-2.574-1.178-3.644-.266-1.227 1.046-1.289 2.924-.195 4.058l3.78 3.9zm5.621 3.926h-1.875l-.276-.557a1.07 1.07 0 00-1.002-.694H6.223a1.07 1.07 0 00-1.002.694l-.273.557H3.073A1.073 1.073 0 002 15.566v5.362A1.072 1.072 0 003.071 22h9.107a1.072 1.072 0 001.072-1.072v-5.362a1.072 1.072 0 00-1.072-1.072zM7.625 20.28a2.031 2.031 0 110-4.062 2.031 2.031 0 010 4.062zm13.883-4.527H19.14l.874-1.983c.082-.26-.154-.516-.476-.516h-2.953c-.246 0-.455.153-.488.356l-.656 4.177c-.04.246.19.464.488.464h2.434l-.945 3.242c-.074.26.164.504.477.504a.517.517 0 00.427-.205l3.61-5.426c.19-.27-.046-.613-.425-.613zm-.833-13.741l-5.804.892c-.571.088-.996.615-.996 1.237v4.17a3.251 3.251 0 00-.625-.066c-1.38 0-2.5.84-2.5 1.875 0 1.036 1.12 1.875 2.5 1.875 1.375 0 2.49-.833 2.5-1.862V5.893l4.375-.673v1.843a3.252 3.252 0 00-.625-.066c-1.38 0-2.5.839-2.5 1.875s1.12 1.875 2.5 1.875c1.375 0 2.49-.833 2.5-1.862V3.25c0-.761-.625-1.345-1.325-1.237z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIconsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
