import * as React from "react";
function SvgDigitalTachographSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 7H3a1 1 0 00-1 1v8a1 1 0 001 1h18a1 1 0 001-1V8a1 1 0 00-1-1zm-9.5 8a.25.25 0 01-.25.25h-7A.25.25 0 014 15v-.25a.25.25 0 01.25-.25h7a.25.25 0 01.25.25V15zm-7.25-2v-.5a.25.25 0 01.25-.25H5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm2 0v-.5a.25.25 0 01.25-.25H7a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm2 0v-.5a.25.25 0 01.25-.25H9a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm2 0v-.5a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm1.25-2a.5.5 0 01-.5.5H4.5A.5.5 0 014 11V9.5a.5.5 0 01.5-.5H11a.5.5 0 01.5.5V11zm8.5 4a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-.25a.25.25 0 01.25-.25h7a.25.25 0 01.25.25V15z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDigitalTachographSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
