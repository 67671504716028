import * as React from "react";
function SvgExpandAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.495 14.648l-4.138 4.138 1.47 1.385c.675.675.197 1.829-.758 1.829h-5A1.07 1.07 0 012 20.929v-5c0-.955 1.152-1.433 1.827-.758l1.387 1.472 4.138-4.138a.714.714 0 011.01 0l1.133 1.133a.714.714 0 010 1.01zm1.01-5.296l4.138-4.138-1.47-1.385C14.498 3.154 14.976 2 15.931 2h5C21.522 2 22 2.48 22 3.071v5c0 .955-1.152 1.433-1.827.758l-1.387-1.472-4.138 4.138a.714.714 0 01-1.01 0l-1.133-1.133a.714.714 0 010-1.01z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExpandAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
