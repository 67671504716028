import * as React from "react";
function SvgCloudMeatballSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.875 15.75a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm16.25 0a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm-4.648.434a1.629 1.629 0 00-.383-1.653c-.45-.449-1.086-.562-1.653-.386-.273-.528-.808-.899-1.441-.899s-1.168.371-1.441.899a1.622 1.622 0 00-1.653.386c-.449.45-.562 1.086-.386 1.653-.528.273-.899.808-.899 1.441s.371 1.168.899 1.441a1.622 1.622 0 001.546 2.133c.168 0 .332-.043.493-.097.273.527.808.898 1.441.898s1.168-.371 1.441-.898c.16.05.325.097.493.097.421 0 .84-.16 1.16-.48.449-.45.562-1.086.383-1.653.527-.273.898-.808.898-1.441s-.371-1.168-.898-1.441zM22 10.75C22 8.68 20.32 7 18.25 7c-.023 0-.043.008-.063.008a3.124 3.124 0 00-3.063-3.758c-.96 0-1.808.441-2.382 1.125C12.016 2.969 10.566 2 8.875 2A4.373 4.373 0 004.5 6.375c0 .285.031.559.082.828A3.738 3.738 0 002 10.75c0 2.07 1.68 3.75 3.75 3.75h1.695c.14-.313.328-.602.578-.852a2.83 2.83 0 011.985-.832A2.85 2.85 0 0112 12c.754 0 1.465.3 1.992.816a2.83 2.83 0 011.985.832c.25.25.441.54.578.852h1.695c2.07 0 3.75-1.68 3.75-3.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudMeatballSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
