import * as React from "react";
function SvgHomeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.735 8.258l-6.401 5.273v5.69a.556.556 0 00.555.555l3.89-.01a.555.555 0 00.553-.555v-3.323a.555.555 0 01.556-.556h2.222a.555.555 0 01.556.556v3.32a.556.556 0 00.555.558l3.89.01a.555.555 0 00.555-.555v-5.694l-6.4-5.269a.423.423 0 00-.531 0zm10.111 3.584l-2.902-2.393V4.64a.416.416 0 00-.417-.416h-1.944a.417.417 0 00-.417.416v2.521l-3.108-2.557a1.667 1.667 0 00-2.118 0L2.15 11.842a.416.416 0 00-.055.587l.885 1.076a.416.416 0 00.587.056l8.167-6.726a.423.423 0 01.531 0l8.167 6.727a.417.417 0 00.587-.056l.885-1.076a.416.416 0 00-.059-.588z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHomeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
