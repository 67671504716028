import * as React from "react";
function SvgTiredOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm5.206-12.25a.493.493 0 00-.617-.1l-3.226 1.935a.487.487 0 000 .83l3.226 1.936c.218.13.476.065.617-.1a.482.482 0 00.004-.625l-1.355-1.625L17.21 8.44a.482.482 0 00-.004-.625zm-6.335 2.25a.487.487 0 00-.234-.415L7.411 7.714a.488.488 0 00-.617.1.482.482 0 00-.004.626l1.355 1.625L6.79 11.69a.482.482 0 00.004.625c.141.165.4.23.617.1l3.226-1.935a.487.487 0 00.234-.415zM12 12.646c-1.83 0-4.069 1.545-4.347 3.762-.06.476.278.871.625.722.916-.391 2.27-.613 3.722-.613s2.806.222 3.722.613c.342.15.685-.242.625-.722-.279-2.217-2.516-3.762-4.347-3.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTiredOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
