import * as React from "react";
function SvgGrinWinkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.226 8.935c-1.036 0-2.253.683-2.414 1.699a.484.484 0 00.8.437l.385-.342c.597-.532 1.861-.532 2.458 0l.385.342c.342.3.87.01.8-.437-.161-1.016-1.378-1.699-2.414-1.699zm-6.452 2.42a1.29 1.29 0 100-2.581 1.29 1.29 0 000 2.58zm7.482 2.606c-1.046.335-2.597.527-4.256.527-1.66 0-3.21-.192-4.256-.527a.554.554 0 00-.714.619c.32 1.901 2.876 3.226 4.97 3.226s4.65-1.325 4.97-3.226a.554.554 0 00-.714-.619zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.064c-4.447 0-8.065-3.617-8.065-8.064S7.553 3.935 12 3.935c4.447 0 8.064 3.618 8.064 8.065 0 4.447-3.617 8.064-8.064 8.064z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinWinkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
