import * as React from "react";
function SvgDoorClosedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 18H18V5.588C18 4.712 17.327 4 16.5 4h-9C6.673 4 6 4.712 6 5.588V18H2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM15 13a1 1 0 110-2 1 1 0 010 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgDoorClosedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
