import * as React from "react";
function SvgUtensilsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.996 2.594c.031.183.629 3.691.629 5.031 0 2.043-1.086 3.5-2.691 4.086l.504 9.3A.94.94 0 019.5 22H7a.94.94 0 01-.937-.988l.503-9.301c-1.609-.586-2.691-2.047-2.691-4.086 0-1.344.598-4.848.629-5.031.125-.793 1.77-.805 1.871.043v5.515c.05.133.59.125.625 0 .055-.988.309-5.437.313-5.539.128-.812 1.746-.812 1.87 0 .008.106.258 4.551.313 5.54.035.124.578.132.625 0V2.636c.102-.844 1.75-.836 1.875-.043zm4.656 11.16l-.586 7.23A.937.937 0 0017 22h2.188c.519 0 .937-.418.937-.938V2.939A.938.938 0 0019.187 2c-3.222 0-8.648 6.973-2.535 11.754z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUtensilsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
