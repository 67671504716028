import * as React from "react";
function SvgTaxiSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.047 11.44l-.86-3.315c-.375-1.375-1.625-2.375-3-2.375h-.437V4.5c0-.69-.56-1.25-1.25-1.25h-5c-.69 0-1.25.56-1.25 1.25v1.25h-.438c-1.375 0-2.625 1-3 2.375l-.859 3.314A2.498 2.498 0 002 13.875v1.875c0 .923.506 1.72 1.25 2.153V19.5c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25v-1.25h10v1.25c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25v-1.597A2.49 2.49 0 0022 15.75v-1.875a2.498 2.498 0 00-1.953-2.436zM5.75 15.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm.803-4.375l.672-2.592c.087-.319.374-.533.588-.533h8.375c.214 0 .501.214.58.502l.68 2.623H6.552zM18.25 15.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTaxiSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
