import * as React from "react";
function SvgSyncSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.213 2.491l.156 3.233a9.654 9.654 0 00-7.376-3.412c-4.73 0-8.668 3.396-9.513 7.884a.468.468 0 00.461.554h1.916a.468.468 0 00.456-.362 6.871 6.871 0 0112.383-2.224l-3.964-.19a.469.469 0 00-.49.469v1.852a.469.469 0 00.468.469h7.821a.469.469 0 00.469-.47V2.47A.469.469 0 0021.531 2h-1.85a.469.469 0 00-.468.491zm-7.22 16.384a6.859 6.859 0 01-5.703-3.04l3.977.191a.469.469 0 00.49-.469v-1.851a.469.469 0 00-.468-.469H2.47a.469.469 0 00-.469.469v7.825a.469.469 0 00.469.469h1.85a.469.469 0 00.468-.492l-.162-3.226a9.655 9.655 0 007.368 3.405c4.731 0 8.67-3.395 9.514-7.883a.468.468 0 00-.46-.554H19.13a.469.469 0 00-.456.362 6.87 6.87 0 01-6.68 5.263z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSyncSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
