import * as React from "react";
function SvgQuoteRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25h-5c-1.035 0-1.875.84-1.875 1.875v5c0 1.035.84 1.875 1.875 1.875h3.125v2.5c0 1.379-1.121 2.5-2.5 2.5h-.313a.935.935 0 00-.937.938v1.875c0 .519.418.937.938.937h.312A6.248 6.248 0 0022 14.5V5.125c0-1.035-.84-1.875-1.875-1.875zm-11.25 0h-5C2.84 3.25 2 4.09 2 5.125v5C2 11.16 2.84 12 3.875 12H7v2.5C7 15.879 5.879 17 4.5 17h-.313a.935.935 0 00-.937.938v1.875c0 .519.418.937.938.937H4.5a6.248 6.248 0 006.25-6.25V5.125c0-1.035-.84-1.875-1.875-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgQuoteRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
