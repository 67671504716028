import styled from "styled-components";

export const StyledExportListPage = styled.div`
	.exportListTable {
		margin-top: 52px;
		height: auto !important;

		> div > div > div {
			padding: 15px 15px;
		}
	}

	.flexStartBox {
		display: flex;
		padding: 10px 0;
	}
`;
