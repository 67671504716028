import * as React from "react";
function SvgLongArrowAltRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.015 10.214H2.535A.536.536 0 002 10.75v2.5c0 .296.24.536.536.536h13.48v2.056c0 .954 1.153 1.432 1.828.757l3.842-3.841a1.071 1.071 0 000-1.516L17.844 7.4c-.675-.674-1.829-.196-1.829.758v2.056z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLongArrowAltRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
