import * as React from "react";
function SvgFileExcelOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.948 5.827L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-1.476 1.176h-2.973V4.03l2.973 2.972zM6.376 20.125V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247H6.376zm8.28-9.373H13.53a.464.464 0 00-.41.246c-.703 1.293-.867 1.656-1.117 2.254-.543-1.137-.27-.676-1.117-2.254a.47.47 0 00-.414-.246H9.344a.468.468 0 00-.406.703l1.808 3.046-1.808 3.047a.467.467 0 00.406.703h1.129a.464.464 0 00.41-.246c.848-1.563.898-1.758 1.117-2.254.582 1.18.23.621 1.117 2.254a.47.47 0 00.414.246h1.125c.363 0 .586-.39.406-.703l-1.812-3.046a316.91 316.91 0 001.808-3.047.467.467 0 00-.402-.703z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFileExcelOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
