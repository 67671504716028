import * as React from "react";
function SvgGripLinesVerticalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.75 21.375V2.625A.627.627 0 0010.125 2h-1.25a.627.627 0 00-.625.625v18.75c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625zm5 0V2.625A.627.627 0 0015.125 2h-1.25a.627.627 0 00-.625.625v18.75c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGripLinesVerticalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
