"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remove = exports.put = exports.post = exports.fetch = exports.default = exports.Api = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _common = require("../utils/common");
var _index = require("../constants/index");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/* eslint-disable no-console */

_axios.default.default.timeout = 20000;
// axios.defaults.withCredentials = true;

const parseEndpoint = endpoint => {
  const url = endpoint.indexOf("http") === 0 ? endpoint : process.env.VUE_APP_API_DOMAIN + endpoint;
  return url;
};
const formatParams = data => {
  Object.entries(data).forEach(_ref => {
    let [key, value] = _ref;
    if (value === "" || value.length === 0) {
      delete data[key];
    }
  });
  return data;
};
_axios.default.interceptors.request.use(config => {
  config.headers = {
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    Authorization: (0, _common.checkLogin)(_index.COOKIE_LOGIN_TOKEN_NAME) || null,
    "Content-Type": "application/json",
    ...config.headers
  };
  return config;
}, error => {
  return Promise.reject(error);
});
_axios.default.interceptors.response.use(response => {
  // if (!response.data.isSuccess) Promise.reject(response.data.message || "");
  return response;
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 401:
        console.log("token怪怪");
        break;
      case 404:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!");
        console.log("沒有這個拉！");
        break;
      case 500:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!!");
        console.log("伺服器錯了哦！");
        break;
      case 503:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!!");
        console.log("不想服務拉！");
        break;
      default:
        console.log("\u9023\u7D50\u932F\u8AA4".concat(err.response.status));
        break;
    }
  } else {
    err.response = "敏ＣＡ夢  995!";
    console.log("連結錯誤 or CORS Error", err.response);
  }
  return Promise.reject(err.response);
});
const fetch = function (url) {
  let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let config = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise((resolve, reject) => {
    _axios.default.get(url, {
      params: formatParams(params),
      ...config
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err);
    });
  });
};
exports.fetch = fetch;
const post = function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let config = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise((resolve, reject) => {
    _axios.default.post(url, formatParams(data), config).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
};
exports.post = post;
const remove = function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise((resolve, reject) => {
    _axios.default.delete(url, formatParams(data)).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
};
exports.remove = remove;
const put = function (url) {
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise((resolve, reject) => {
    _axios.default.put(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
};
exports.put = put;
const api = {};
api.create = () => ({
  get: (url, params, config) => fetch(parseEndpoint(url), params, config),
  post: (url, data, config) => post(parseEndpoint(url), data, config),
  put: (url, data) => put(parseEndpoint(url), data),
  delete: (url, params) => remove(parseEndpoint(url), params)
});
const Api = exports.Api = api.create();
var _default = exports.default = Api;