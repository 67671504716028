import * as React from "react";
function SvgBrainSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.222 3.111c-1.038 0-1.9.712-2.146 1.674-.027 0-.048-.007-.076-.007A2.224 2.224 0 004.778 7c0 .167.02.33.059.486a2.778 2.778 0 00-1.726 2.57c0 .437.111.843.288 1.211A2.763 2.763 0 002 13.667c0 1.156.708 2.149 1.715 2.566a2.498 2.498 0 002.451 2.99c.143 0 .282-.018.417-.043a2.493 2.493 0 004.861-.791V5.333a2.224 2.224 0 00-2.222-2.222zM22 13.667c0-1.032-.566-1.92-1.4-2.4.181-.368.289-.774.289-1.211 0-1.16-.712-2.153-1.726-2.57.035-.156.06-.32.06-.486A2.224 2.224 0 0017 4.778c-.028 0-.052.007-.076.007a2.216 2.216 0 00-2.146-1.674 2.222 2.222 0 00-2.222 2.222V18.39a2.493 2.493 0 004.861.792c.135.024.274.041.416.041a2.498 2.498 0 002.452-2.99A2.778 2.778 0 0022 13.668z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBrainSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
