import * as React from "react";
function SvgShowerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.875 14.5a.625.625 0 100 1.25.625.625 0 000-1.25zm1.25-3.75a.625.625 0 100 1.25.625.625 0 000-1.25zm1.25 2.5a.625.625 0 100-1.249.625.625 0 000 1.25zm-1.25 1.25a.625.625 0 100-1.249.625.625 0 000 1.25zm-1.25-2.5a.625.625 0 100 1.25.625.625 0 000-1.25zm5-1.25a.625.625 0 100-1.249.625.625 0 000 1.25zM17 11.375a.626.626 0 101.252 0 .626.626 0 00-1.252 0zM16.375 9.5a.625.625 0 100 1.25.625.625 0 000-1.25zm3.75 1.25a.625.625 0 100 1.25.625.625 0 000-1.25zm1.25-1.25a.625.625 0 100 1.25.625.625 0 000-1.25zm-2.5 2.5a.625.625 0 100 1.25.625.625 0 000-1.25zm-1.25 1.25a.625.625 0 100 1.25.625.625 0 000-1.25zm-2.5 2.5a.625.625 0 100 1.25.625.625 0 000-1.25zM13.875 17a.625.625 0 100 1.25.625.625 0 000-1.25zm2.5-2.5a.625.625 0 100 1.25.625.625 0 000-1.25zm.846-8.53l-.442-.441a.624.624 0 00-.884 0l-.221.221a4.343 4.343 0 00-3.049-1.25c-.752 0-1.448.207-2.066.541l-.391-.39a4.755 4.755 0 00-3.346-1.4C4.168 3.23 2 5.628 2 8.282v11.842a.625.625 0 00.625.625h1.25a.625.625 0 00.625-.625V8.188c0-1.178.82-2.274 1.992-2.42a2.28 2.28 0 011.912.652l.39.39a4.284 4.284 0 00-.544 2.065c0 1.141.45 2.236 1.25 3.049l-.221.221a.625.625 0 000 .884l.441.442a.624.624 0 00.884 0l6.617-6.616a.624.624 0 000-.884z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShowerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
