import * as React from "react";
function SvgYenSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.106 2h-2.915a.536.536 0 00-.482.3l-2.473 5.053c-.648 1.549-1.21 3.21-1.21 3.21h-.058s-.563-1.661-1.21-3.21L8.285 2.299A.536.536 0 007.803 2H4.892a.536.536 0 00-.473.786L7.995 9.5H5.392a.537.537 0 00-.536.536v1.428c0 .295.241.536.536.536h3.937l.884 1.66v1.197H5.392a.537.537 0 00-.536.536v1.428c0 .295.241.536.536.536h4.821v4.107c0 .295.241.536.536.536h2.5a.537.537 0 00.536-.536v-4.107h4.821a.537.537 0 00.536-.536v-1.428a.537.537 0 00-.536-.536h-4.821v-1.196L14.669 12h3.937a.537.537 0 00.536-.536v-1.428a.537.537 0 00-.536-.536h-2.603l3.576-6.714A.534.534 0 0019.106 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgYenSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
