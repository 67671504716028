import * as React from "react";
function SvgBoxOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.304 12.001a1.515 1.515 0 01-1.294-.731L12 7.939l-2.005 3.33a1.52 1.52 0 01-1.297.735c-.14 0-.281-.019-.416-.06l-4.28-1.224v5.562c0 .46.312.86.755.969l6.756 1.69c.319.078.653.078.969 0l6.762-1.69c.444-.113.756-.513.756-.97V10.72l-4.28 1.222c-.135.04-.276.059-.416.059zm6.643-3.506l-1.61-3.212a.51.51 0 00-.521-.278L12 6l2.865 4.753c.119.197.356.29.578.228l6.184-1.765c.31-.09.46-.435.319-.722zM3.664 5.283l-1.61 3.212a.51.51 0 00.316.719l6.184 1.765a.514.514 0 00.578-.228l2.869-4.75-7.819-.996a.51.51 0 00-.518.278z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBoxOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
