import * as React from "react";
function SvgCheckCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 1.935A8.06 8.06 0 0120.064 12 8.06 8.06 0 0112 20.064 8.06 8.06 0 013.935 12 8.06 8.06 0 0112 3.935zm5.653 5.253l-.908-.916a.484.484 0 00-.685-.003l-5.7 5.654-2.41-2.43a.484.484 0 00-.685-.003l-.916.909a.484.484 0 00-.002.684l3.66 3.69c.188.19.495.191.684.003l6.96-6.903a.484.484 0 00.002-.685z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCheckCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
