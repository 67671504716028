import * as React from "react";
function SvgAudioDescriptionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.364 11.325l.345 1.197h-1l.353-1.197c.05-.173.103-.39.151-.596.048.205.101.423.151.596zm6.503-1.254H14.3v3.858h.567c1.163 0 1.804-.69 1.804-1.944 0-1.26-.683-1.914-1.804-1.914zM22 6.375v11.25c0 1.035-.84 1.875-1.875 1.875H3.875A1.875 1.875 0 012 17.625V6.375C2 5.339 2.84 4.5 3.875 4.5h16.25C21.16 4.5 22 5.34 22 6.375zM11.588 15.13l-2.23-6.562a.469.469 0 00-.444-.318H7.512a.469.469 0 00-.444.318l-2.23 6.562a.469.469 0 00.444.62H6.42a.468.468 0 00.45-.34l.335-1.168h2.007l.343 1.171a.469.469 0 00.45.337h1.14a.469.469 0 00.443-.62zm7.215-3.145c0-2.304-1.481-3.735-3.866-3.735h-2.24a.469.469 0 00-.47.469v6.562c0 .26.21.469.47.469h2.24c2.385 0 3.866-1.443 3.866-3.765z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAudioDescriptionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
