import * as React from "react";
function SvgHospitalAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.889 6.444h-4.445V4.222a1.11 1.11 0 00-1.11-1.11H8.666a1.11 1.11 0 00-1.111 1.11v2.222H3.11A1.11 1.11 0 002 7.556v12.777c0 .306.25.556.556.556h18.888c.306 0 .556-.25.556-.556V7.556a1.11 1.11 0 00-1.111-1.112zM7.556 18.25c0 .23-.188.417-.417.417H5.75a.418.418 0 01-.417-.417v-1.389c0-.23.188-.417.417-.417h1.389c.23 0 .417.188.417.417v1.389zm0-4.444c0 .229-.188.416-.417.416H5.75a.418.418 0 01-.417-.416v-1.39c0-.229.188-.416.417-.416h1.389c.23 0 .417.188.417.417v1.389zm5.555 4.444c0 .23-.187.417-.417.417h-1.388a.418.418 0 01-.417-.417v-1.389c0-.23.187-.417.417-.417h1.388c.23 0 .417.188.417.417v1.389zm0-4.444c0 .229-.187.416-.417.416h-1.388a.418.418 0 01-.417-.416v-1.39c0-.229.187-.416.417-.416h1.388c.23 0 .417.188.417.417v1.389zm.556-5.903a.209.209 0 01-.209.208h-.902v.903a.209.209 0 01-.209.208h-.694a.209.209 0 01-.209-.208V8.11h-.902a.209.209 0 01-.209-.208v-.695c0-.114.094-.208.209-.208h.902v-.903c0-.114.094-.208.209-.208h.694c.115 0 .209.094.209.208V7h.902c.115 0 .209.094.209.208v.695zm5 10.347c0 .23-.188.417-.417.417h-1.389a.418.418 0 01-.417-.417v-1.389c0-.23.188-.417.417-.417h1.389c.23 0 .417.188.417.417v1.389zm0-4.444c0 .229-.188.416-.417.416h-1.389a.418.418 0 01-.417-.416v-1.39c0-.229.188-.416.417-.416h1.389c.23 0 .417.188.417.417v1.389z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHospitalAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
