import * as React from "react";
function SvgRobotSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3 11h1v6H3a.998.998 0 01-1-1v-4a.999.999 0 011-1zm16-1.5V18a2.002 2.002 0 01-2 2H7a2.002 2.002 0 01-2-2V9.5A2.5 2.5 0 017.5 7H11V5a1 1 0 012 0v2h3.5A2.499 2.499 0 0119 9.5zM10.25 12a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM10 16H8v1h2v-1zm3 0h-2v1h2v-1zm3.25-4a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM16 16h-2v1h2v-1zm6-4v4a.998.998 0 01-1 1h-1v-6h1a.998.998 0 011 1z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRobotSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
