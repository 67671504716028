import * as React from "react";
function SvgGrinTonGueSquintOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm2.58 16.129a2.58 2.58 0 01-2.616 2.58c-1.416-.02-2.545-1.2-2.545-2.62v-1.726l.714-.355a.987.987 0 011.407.665l.113.488c.085.371.613.371.698 0l.113-.488a.984.984 0 011.407-.665l.714.355v1.766h-.004zm1.138 1.02c.088-.326.153-.665.153-1.02v-1.754c.572-.5.984-1.109 1.1-1.794a.552.552 0 00-.713-.617c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.553.553 0 00-.714.617c.117.685.528 1.294 1.101 1.794v1.754c0 .355.065.694.153 1.02-2.58-1.347-4.347-4.044-4.347-7.149 0-4.448 3.617-8.065 8.065-8.065 4.448 0 8.064 3.617 8.064 8.065 0 3.105-1.766 5.802-4.346 7.15zm1.488-11.334a.488.488 0 00-.617-.101l-3.226 1.935a.487.487 0 000 .83l3.226 1.936c.218.13.472.069.617-.1a.487.487 0 00.004-.625l-1.355-1.625L17.21 8.44a.482.482 0 00-.004-.625zm-6.569 1.834L7.411 7.714a.484.484 0 00-.62.726l1.354 1.625L6.79 11.69a.482.482 0 00.004.625c.146.169.4.23.617.1l3.226-1.935a.487.487 0 000-.83z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinTonGueSquintOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
