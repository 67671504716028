import * as React from "react";
function SvgUnlockAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 12H9.188V7.973c0-1.547 1.238-2.832 2.785-2.848a2.817 2.817 0 012.84 2.813v.625c0 .52.417.937.937.937H17c.52 0 .938-.418.938-.937v-.625A5.944 5.944 0 0011.98 2c-3.282.012-5.918 2.715-5.918 5.996V12h-.938c-1.035 0-1.875.84-1.875 1.875v6.25C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875v-6.25c0-1.035-.84-1.875-1.875-1.875zm-5.313 5.938a1.562 1.562 0 11-3.124 0v-1.875a1.562 1.562 0 113.124 0v1.875z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgUnlockAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
