import * as React from "react";
function SvgGrinStarsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm4.258-6.104c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.554.554 0 00-.714.617c.319 1.903 2.875 3.225 4.972 3.225 2.097 0 4.65-1.326 4.972-3.225a.555.555 0 00-.714-.617zm-9.19-2.319c-.04.25.218.444.444.319l1.262-.658 1.262.658c.226.125.484-.069.444-.319l-.242-1.407 1.024-.992a.315.315 0 00-.173-.532L9.68 8.508l-.625-1.274a.312.312 0 00-.56 0l-.625 1.274-1.407.202a.313.313 0 00-.174.532l1.025.992-.246 1.407zM17.54 8.71l-1.407-.202-.625-1.274a.312.312 0 00-.56 0l-.625 1.274-1.408.202a.313.313 0 00-.173.532l1.024.992-.242 1.407c-.04.25.218.444.444.319l1.262-.658 1.262.658c.226.125.484-.069.444-.319l-.242-1.407 1.024-.992a.316.316 0 00-.178-.532z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGrinStarsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
