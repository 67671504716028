import * as React from "react";
function SvgFilePdfSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.605 12.004c-.195-.625-.19-1.832-.078-1.832.329 0 .297 1.441.079 1.832zm-.066 1.844c-.3.789-.676 1.691-1.11 2.449.716-.274 1.524-.672 2.458-.856a5.06 5.06 0 01-1.348-1.593zm-3.676 4.875c0 .03.516-.211 1.364-1.57-.262.245-1.137.956-1.364 1.57zM14.187 8.25H19.5v12.813c0 .519-.418.937-.938.937H5.438a.935.935 0 01-.937-.938V2.938c0-.519.418-.937.938-.937h7.812v5.313a.94.94 0 00.938.937zm-.312 6.71c-.781-.476-1.3-1.132-1.668-2.1.176-.723.453-1.82.242-2.508-.183-1.149-1.656-1.036-1.867-.266-.195.715-.016 1.723.316 3.008-.453 1.078-1.12 2.523-1.593 3.351-.004 0-.004.004-.008.004-1.059.543-2.875 1.738-2.129 2.656.219.27.625.391.84.391.699 0 1.394-.703 2.387-2.414 1.007-.332 2.113-.746 3.085-.906.848.46 1.84.762 2.5.762 1.141 0 1.22-1.25.77-1.696-.543-.531-2.121-.379-2.875-.281zm5.352-8.858l-3.829-3.829A.937.937 0 0014.734 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-2.895 9.972c.16-.105-.098-.465-1.672-.351 1.45.617 1.672.351 1.672.351z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFilePdfSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
