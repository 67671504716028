import * as React from "react";
function SvgPasteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 9.188A2.19 2.19 0 0110.438 7h5.312V4.187a.937.937 0 00-.938-.937h-3.148A2.501 2.501 0 009.5 2a2.5 2.5 0 00-2.164 1.25H4.187a.937.937 0 00-.937.938v13.125c0 .517.42.937.938.937H8.25V9.187zM9.5 3.561a.937.937 0 110 1.875.937.937 0 010-1.875zm7.188 9.688h4.062v7.813c0 .517-.42.937-.938.937h-9.375a.937.937 0 01-.937-.938V9.188c0-.517.42-.937.938-.937h5.312v4.063a.94.94 0 00.938.937zm4.062-1.487V12H17V8.25h.237c.248 0 .487.099.663.275l2.575 2.575a.938.938 0 01.275.663z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPasteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
