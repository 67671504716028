import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { StyledFileCheck } from "./FileCheck.style";
// import { htmlParts, quesType} from './test'

export const FileCheck = ({ questionHtml: questionObject }) => {
	const htmlParts = useMemo(() => {
		if (!questionObject) return null;
		const { htmlParts: data } = questionObject;
		return data;
	}, [questionObject]);

	const quesType = useMemo(() => {
		if (!questionObject) return null;
		const { quesType: data } = questionObject;
		return data;
	}, [questionObject]);

	// metadata, questionContentHtml, questionHtml, answerHtml, analyzeHtml
	const questionHtml = useMemo(() => {
		if (!htmlParts) return null;
		return htmlParts.content;
	}, [htmlParts]);

	const answerHtml = useMemo(() => {
		if (!htmlParts) return null;
		return htmlParts.answer;
	}, [htmlParts]);

	const analyzeHtml = useMemo(() => {
		if (!htmlParts) return null;
		return htmlParts.analyze;
	}, [htmlParts]);

	// const questionHtml = htmlParts.content;
	// const answerHtml = htmlParts.answer;
	// const analyzeHtml = htmlParts.analyze;

	const [isQuestionChecked, setIsQuestionChecked] = useState(true);
	const [isAnswerChecked, setIsAnswerChecked] = useState(true);
	const [isAnalyzeChecked, setIsAnalyzeChecked] = useState(true);

	useEffect(() => {
		setIsQuestionChecked(true);
		setIsAnswerChecked(true);
		setIsAnalyzeChecked(true);
	}, [questionObject]);

	const questionType = useMemo(() => {
		if (!quesType) return "";
		switch (quesType.content.code.slice(0, 2)) {
			case "FL":
				return "填充題";
			case "GR":
				return "題組題";
			case "MT":
				return "多選題";
			case "MX":
				return "混合題";
			case "SS":
				return "單一選擇題";
			case "WR":
				return "非選題";
			case "YN":
				return "是非題";
			default:
				return "單一選擇題";
		}
	}, [quesType]);

	// --------------- 以下為 模板 ---------------

	// template

	/**
	 * question option 題目選項模板
	 *
	 * @param {number} number 選項規則尾數
	 *
	 * @returns template
	 */
	const questionOptionTemplate = (number) => {
		if (!number) return null;
		let text = "";
		const checked = questionCheckRef.current?.checked;
		switch (number) {
			case 1:
				text = "A";
				break;
			case 2:
				text = "B";
				break;
			case 3:
				text = "C";
				break;
			case 4:
				text = "D";
				break;
			case 5:
				text = "E";
				break;
			default:
				break;
		}

		return `<div>
							<input
								type="checkbox"
								id='questionOptionTitle${number}'
								checked=${checked}
								value=""
								
							/>
							<label htmlFor="questionOptionTitle${number}">題目-選項${text}</label>
						</div>
						<div>
							<input
								type="checkbox"
								id="questionOptionContent${number}"
								checked=${checked}
								value="questionOptionContent${number}"
							/>
							<label htmlFor="questionOptionContent${number}">選項${text} 內容</label>
						</div>`;
	};

	/**
	 * question option 題目選項模板 for 填充題
	 *
	 * @param {string} number 選項規則尾數
	 *
	 * @returns template
	 */
	const questionOptionTemplateForFQ = (number) => {
		const checked = questionCheckRef.current?.checked;

		return `<input
							type="checkbox"
							id="questionOption${number}"
							checked=${checked}
							value=""
						/>
						<label htmlFor="questionOption${number}">題目-作答區塊${number}</label>`;
	};

	/**
	 * question option 題目子題模板 for 題組題
	 *
	 * @param {string} number 子題規則數
	 *
	 * @returns template
	 */
	const questionOptionBoxTemplateForGQ = (number) => {
		const checked = questionCheckRef.current?.checked;
		return `
			<div>
				<input
					type="checkbox"
					id="subQuestionOption${number}"
					checked=${checked}
					value=""
				/>
				<label htmlFor="questionOption${number}">子題${number}</label>
			</div>`;
	};

	/**
	 * question option 題目選項模板 for 題組題
	 *
	 * @param {number} number 選項規則尾數
	 * @param {number} subQuestion 子題目數字
	 *
	 * @returns template
	 */
	const questionOptionTemplateForGQ = (number, subQuestion) => {
		let text = "";
		switch (number) {
			case 1:
				text = "A";
				break;
			case 2:
				text = "B";
				break;
			case 3:
				text = "C";
				break;
			case 4:
				text = "D";
				break;
			case 5:
				text = "E";
				break;
			default:
				break;
		}

		return `
				<div>
					<input
						type="checkbox"
						id="questionOption${subQuestion}${number}"
						checked="checked"
						value=""
					/>
					<label htmlFor="questionOption${subQuestion}${number}">
						子題目${subQuestion}-選項${text}
					</label>
				</div>
				<div>
					<input
						type="checkbox"
						id="questionOptionContent${subQuestion}${number}"
						checked="checked"
						value=""
					/>
					<label htmlFor="questionOptionContent${subQuestion}${number}">選項${text} 內容</label>
				</div>
		`;
	};

	/**
	 * answer option 答案選項模板 for 填充題
	 *
	 * @param {string} number 選項規則尾數
	 *
	 * @returns template
	 */
	const answerOptionTemplateForFQ = (number) => {
		return `
				<input
					type="checkbox"
					id="answerOption${number}"
					checked="checked"
					value=""
				/>
				<label htmlFor="answerOption${number}">答案-作答區塊${number}</label>
			`;
	};

	/**
	 * answer option 答案選項模板 for 題組題
	 *
	 * @param {string} number 子題目規則數
	 *
	 * @returns template
	 */
	const answerOptionTemplateForGQ = (number) => {
		return `
				<input
					type="checkbox"
					id="answerOption${number}"
					checked="checked"
					value=""
				/>
				<label htmlFor="answerOption${number}">答案-子題目${number}</label>
			`;
	};

	/**
	 * analyze option 解析選項模板
	 *
	 * @param {string} number 選項規則尾數
	 *
	 * @returns template
	 */
	const analyzeOptionTemplate = (number) => {
		let text = "";
		switch (number) {
			case 1:
				text = "A";
				break;
			case 2:
				text = "B";
				break;
			case 3:
				text = "C";
				break;
			case 4:
				text = "D";
				break;
			case 5:
				text = "E";
				break;
			default:
				break;
		}

		if (!text) return;
		return `
				<input
					type="checkbox"
					id="analyzeOption${number}"
					checked="checked"
					value=""
				/>
				<label id="analyzeOptionAText" htmlFor="analyzeOptionA">
					解析-選項${text}
				</label>
			`;
	};

	/**
	 * analyze option 解析選項模板 for 題組題
	 *
	 * @param {string} number 子題目規則數
	 *
	 * @returns template
	 */
	const analyzeOptionTemplateForGQ = (number) => {
		return `
				<input
					type="checkbox"
					id="analyzeOption${number}"
					checked="checked"
					value=""
				/>
				<label id="analyzeOptionAText" htmlFor="analyzeOptionA">
					解析-子題目${number}
				</label>
			`;
	};

	// --------------- 以上為模板 ---------------

	// new code
	const questionRef = useRef(null);
	const answerHtmlRef = useRef(null);
	const analyzeHtmlRef = useRef(null);
	const questionOptionDetailRef = useRef(null);
	const answerOptionDetailRef = useRef(null);
	const analyzeOptionDetailRef = useRef(null);

	window.onload = () => {
		questionRef.current.innerHTML = "";
		answerHtmlRef.current.innerHTML = "";
		analyzeHtmlRef.current.innerHTML = "";
		questionOptionDetailRef.current.innerHTML = "";
		answerOptionDetailRef.current.innerHTML = "";
		analyzeOptionDetailRef.current.innerHTML = "";
	};

	// useEffect(() => {
	// 	questionRef.current.innerHTML = "";
	// 	answerHtmlRef.current.innerHTML = "";
	// 	analyzeHtmlRef.current.innerHTML = "";
	// 	questionOptionDetailRef.current.innerHTML = "";
	// 	answerOptionDetailRef.current.innerHTML = "";
	// 	analyzeOptionDetailRef.current.innerHTML = "";
	// }, [questionObject]);

	// ---------------------//

	const questionNameRef = useRef(null);
	const checkOptionRef = useRef(null);
	const analyzeOptionBoxRef = useRef(null);
	const questionCheckRef = useRef(null);
	const answerCheckRef = useRef(null);
	const analyzeCheckRef = useRef(null);

	const fileUploader = document.querySelector("#fileUploader");
	const questionOptionBox = document.getElementById("questionOptionBox");

	// new code

	// 取得 JSON 資料
	useEffect(() => {
		if (!questionNameRef || !questionOptionDetailRef || !analyzeCheckRef || !checkOptionRef) return null;
		questionRef.current.innerHTML = "";
		answerHtmlRef.current.innerHTML = "";
		analyzeHtmlRef.current.innerHTML = "";
		questionOptionDetailRef.current.innerHTML = "";
		answerOptionDetailRef.current.innerHTML = "";
		analyzeOptionDetailRef.current.innerHTML = "";
		// const { metadata, questionContentHtml, questionHtml, answerHtml, analyzeHtml } = json;

		// init
		// if(!checkOption)return null
		checkOptionRef.current.style.opacity = "1";

		// 題型名稱
		questionNameRef.current.innerHTML = questionType;
		// 題目
		questionRef.current.innerHTML = questionHtml;
		// 答案
		answerHtmlRef.current.innerHTML = answerHtml;
		// 解析
		if (analyzeHtml) {
			analyzeHtmlRef.current.innerHTML = analyzeHtml;
			analyzeHtmlRef.current.style.opacity = 1;
			analyzeOptionBoxRef.current.style.opacity = 1;
		} else {
			analyzeHtmlRef.current.style.opacity = 0;
			analyzeOptionBoxRef.current.style.opacity = 0;
		}

		let currentNumber = 0;

		// ------------- 產生 題目 選項 ------------------

		// 題目 選項 for 填充題
		if (questionType === "填充題") {
			questionRef.current
				.getElementsByClassName("MsoNormal")[0]
				.querySelectorAll("a")
				.forEach((dom) => {
					if (!dom.querySelector("u")) return;

					// get rule
					const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
					// rule last number
					const lastNumber = parseInt(ruleText?.substr(ruleText.length - 2, 2));
					if (!lastNumber) return;

					// create check option
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = questionOptionTemplateForFQ(lastNumber);
					questionOptionDetailRef.current.appendChild(checkHtmlObject);

					let num = lastNumber;

					if (document.querySelector(`#questionOption${num}`)) {
						document
							.querySelector(`#questionOption${num}`)
							.addEventListener("click", () => questionOptionHandleForFQ(num, `questionOption${num}`));
					}
				});
			// 題目 產生 for 題組題、混合題
		} else if (questionType === "題組題" || questionType === "混合題") {
			questionOptionDetailRef.current.innerHTML = "";

			let currentQuestionCategoryLastNumber = null;
			questionRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
				if (!dom.innerHTML) return;
				// get rule
				let ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
				// 判斷格式是否有按照規則走
				let questionCategory = null;

				const createElement = (rule, num) => {
					const questionCategory = parseInt(num);
					// 分類子題目
					if (parseInt(currentNumber) !== questionCategory && questionCategory) {
						currentNumber = questionCategory;
						// create sub question box
						const questionCategoryObject = document.createElement("div");
						questionCategoryObject.id = `questionCategory${currentNumber}`;
						questionCategoryObject.className = "subQuestion";
						questionCategoryObject.innerHTML = questionOptionBoxTemplateForGQ(currentNumber);
						questionOptionDetailRef.current.appendChild(questionCategoryObject);
						let num = currentNumber;
						if (document.querySelector(`#subQuestionOption${num}`)) {
							document
								.querySelector(`#subQuestionOption${num}`)
								.addEventListener("click", () => subQuestionOptionHandleForFQ(num, `subQuestionOption${num}`));
						}
					}

					if (!rule || rule.split("S0").length !== 2) return;
					// 子題目選項
					if (rule.split("S0")[1].slice(0, 1) === num) {
						const questionCategoryLength = rule.split("S0")[1].length;
						// 分類題目 個別選項
						const questionCategoryLastNumber = parseInt(
							rule.split("S0")[1].slice(questionCategoryLength - 1, questionCategoryLength),
						);

						if (!questionCategoryLastNumber) return;
						if (currentQuestionCategoryLastNumber !== questionCategoryLastNumber) {
							currentQuestionCategoryLastNumber = questionCategoryLastNumber;

							// create check option
							const questionCategoryOptionObject = document.createElement("div");
							questionCategoryOptionObject.className = "option";

							questionCategoryOptionObject.innerHTML = questionOptionTemplateForGQ(
								questionCategoryLastNumber,
								currentNumber,
							);

							const questionCategoryOption = document.getElementById(`questionCategory${currentNumber}`);
							questionCategoryOption.appendChild(questionCategoryOptionObject);

							const questionOption = document.querySelector(
								`#questionOption${currentNumber}${questionCategoryLastNumber}`,
							);
							const questionOptionContent = document.querySelector(
								`#questionOptionContent${currentNumber}${questionCategoryLastNumber}`,
							);

							let num = currentNumber;

							questionOption &&
								questionOption.addEventListener("click", () => {
									questionOptionHandleForGQ(
										questionCategoryLastNumber,
										num,
										`questionOption${num}${questionCategoryLastNumber}`,
										false,
									);
								});

							questionOptionContent &&
								questionOptionContent.addEventListener("click", () => {
									questionOptionHandleForGQ(
										questionCategoryLastNumber,
										num,
										`questionOptionContent${num}${questionCategoryLastNumber}`,
										true,
									);
								});
						}
					}
				};

				if (ruleText && ruleText?.split("S0").length === 2) {
					questionCategory = ruleText.split("S0")[1].slice(0, 1);
					createElement(ruleText, questionCategory);
				} else {
					dom.childNodes.forEach((second_dom) => {
						const second_rule = getTypeRule(second_dom);
						if (second_rule && second_rule.split("S0").length === 2) {
							// ruleText = second_rule;
							questionCategory = second_rule.split("S0")[1].slice(0, 1);
							createElement(second_rule, questionCategory);
						} else {
							second_dom.childNodes.forEach((three_dom) => {
								const three_rule = getTypeRule(three_dom);
								if (three_rule && three_rule.split("S0").length === 2) {
									// ruleText = three_rule;
									questionCategory = three_rule.split("S0")[1].slice(0, 1);
									createElement(three_rule, questionCategory);
								} else {
									return null;
								}
							});
						}
					});
				}

				// if (ruleText?.split("S0").length > 1) {
				// 	// 分類子題目
				// 	const questionCategory = ruleText.split("S0")[1].slice(0, 1);
				// 	if (currentNumber !== questionCategory) {
				// 		currentNumber = questionCategory;

				// 		// create sub question box
				// 		const questionCategoryObject = document.createElement("div");
				// 		questionCategoryObject.id = `questionCategory${currentNumber}`;
				// 		questionCategoryObject.className = "subQuestion";
				// 		questionCategoryObject.innerHTML = questionOptionBoxTemplateForGQ(currentNumber);
				// 		questionOptionDetailRef.current.appendChild(questionCategoryObject);
				// 		let num = currentNumber;
				// 		if (document.querySelector(`#subQuestionOption${num}`)) {
				// 			document
				// 				.querySelector(`#subQuestionOption${num}`)
				// 				.addEventListener("click", () => subQuestionOptionHandleForFQ(num, `subQuestionOption${num}`));
				// 		}
				// 	}

				// 	// if (questionType === "混合題") return;
				// 	// 子題目選項
				// 	if (ruleText.split("S0")[1].slice(0, 1) === currentNumber) {
				// 		const questionCategoryLength = ruleText.split("S0")[1].length;
				// 		// 分類題目 個別選項
				// 		const questionCategoryLastNumber = parseInt(
				// 			ruleText.split("S0")[1].slice(questionCategoryLength - 1, questionCategoryLength),
				// 		);

				// 		if (!questionCategoryLastNumber) return;
				// 		if (currentQuestionCategoryLastNumber !== questionCategoryLastNumber) {
				// 			currentQuestionCategoryLastNumber = questionCategoryLastNumber;

				// 			// create check option
				// 			const questionCategoryOptionObject = document.createElement("div");
				// 			questionCategoryOptionObject.className = "option";

				// 			questionCategoryOptionObject.innerHTML = questionOptionTemplateForGQ(
				// 				questionCategoryLastNumber,
				// 				currentNumber,
				// 			);

				// 			const questionCategoryOption = document.getElementById(`questionCategory${currentNumber}`);
				// 			questionCategoryOption.appendChild(questionCategoryOptionObject);

				// 			const questionOption = document.querySelector(
				// 				`#questionOption${currentNumber}${questionCategoryLastNumber}`,
				// 			);
				// 			const questionOptionContent = document.querySelector(
				// 				`#questionOptionContent${currentNumber}${questionCategoryLastNumber}`,
				// 			);

				// 			let num = currentNumber;

				// 			questionOption &&
				// 				questionOption.addEventListener("click", () => {
				// 					questionOptionHandleForGQ(
				// 						questionCategoryLastNumber,
				// 						num,
				// 						`questionOption${num}${questionCategoryLastNumber}`,
				// 						false,
				// 					);
				// 				});

				// 			questionOptionContent &&
				// 				questionOptionContent.addEventListener("click", () => {
				// 					questionOptionHandleForGQ(
				// 						questionCategoryLastNumber,
				// 						num,
				// 						`questionOptionContent${num}${questionCategoryLastNumber}`,
				// 						true,
				// 					);
				// 				});
				// 		}
				// 	}
				// }
			});
			// 題目 產生 for 一般題目
		} else {
			questionRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
				if (!dom.innerHTML) return;
				// get rule
				const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
				// rule last number
				const lastNumber = parseInt(ruleText?.substr(ruleText.length - 1, 1));

				if (currentNumber !== lastNumber && lastNumber) {
					currentNumber = lastNumber;

					// create check option
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = questionOptionTemplate(lastNumber);
					questionOptionDetailRef.current.appendChild(checkHtmlObject);
					if (document.querySelector(`#questionOptionTitle${lastNumber}`)) {
						document
							.querySelector(`#questionOptionTitle${lastNumber}`)
							.addEventListener("click", () =>
								questionOptionHandle(lastNumber, `questionOptionTitle${lastNumber}`, false),
							);
					}
					if (document.querySelector(`#questionOptionContent${lastNumber}`)) {
						document
							.querySelector(`#questionOptionContent${lastNumber}`)
							.addEventListener("click", () =>
								questionOptionHandle(lastNumber, `questionOptionContent${lastNumber}`, true),
							);
					}
				}
			});
		}

		// ------------------ 答案 選項 產生 ---------------------

		// 產生 答案 選項 for 填充題
		if (questionType === "填充題") {
			answerHtmlRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
				// get rule
				const ruleText = dom.getAttribute("name")?.split("_")[1];
				// rule last number
				let lastNumber = parseInt(ruleText?.substr(ruleText?.length - 2, 2));
				if (lastNumber === 0) return;

				// create check option
				if (lastNumber) {
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = answerOptionTemplateForFQ(lastNumber);
					answerOptionDetailRef.current.appendChild(checkHtmlObject);

					let num = lastNumber;
					if (document.querySelector(`#answerOption${num}`)) {
						document
							.querySelector(`#answerOption${num}`)
							.addEventListener("click", () => answerOptionHandleForFQ(num, `answerOption${num}`));
					}
				}

				if (!ruleText) {
					dom.querySelectorAll("a").forEach((spanDom) => {
						const ruleText = spanDom.getAttribute("name")?.split("_")[1];
						lastNumber = parseInt(ruleText?.substr(ruleText?.length - 2, 2));
						if (!lastNumber || lastNumber === 0) return;
						// create check option
						const checkHtmlObject = document.createElement("div");
						checkHtmlObject.className = "option";

						let num = lastNumber;
						checkHtmlObject.innerHTML = answerOptionTemplateForFQ(num);
						answerOptionDetailRef.current.appendChild(checkHtmlObject);

						if (document.querySelector(`#answerOption${num}`)) {
							document
								.querySelector(`#answerOption${num}`)
								.addEventListener("click", () => answerOptionHandleForFQ(num, `answerOption${num}`));
						}
					});
				}
			});
		} else if (questionType === "題組題" || questionType === "混合題") {
			// 答案 選項 for 題組題
			answerHtmlRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
				if (!dom.innerHTML) return;
				// get rule
				const ruleText =
					dom.querySelector("a")?.getAttribute("name").split("_")[1] ||
					dom.getAttribute("style")?.split("_")[1] ||
					dom.getAttribute("name")?.split("_")[1];
				// 分類子題目
				const questionCategory = ruleText?.split("S0")[1]?.slice(0, 1);

				if (currentNumber !== questionCategory && questionCategory) {
					currentNumber = questionCategory;
					// create check option
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = answerOptionTemplateForGQ(currentNumber);
					answerOptionDetailRef.current.appendChild(checkHtmlObject);

					let num = currentNumber;

					if (document.querySelector(`#answerOption${num}`)) {
						document
							.querySelector(`#answerOption${num}`)
							.addEventListener("click", () => answerOptionHandleForGQ(num, `answerOption${num}`));
					}
				}
			});
		}

		// ----------------   解析  ------------------

		// 解析 選項 for 題組題
		if (questionType === "題組題" || questionType === "混合題") {
			if (analyzeHtmlRef.current.getElementsByClassName("MsoNormal").length === 0) return;
			let index = 0;
			if (analyzeHtmlRef.current.getElementsByClassName("MsoNormal").length > 1) index = 1;

			const createElement = (rule) => {
				const questionCategory = parseInt(rule?.split("S0")[1]?.slice(0, 1));
				if (currentNumber !== questionCategory && questionCategory) {
					currentNumber = questionCategory;

					let num = currentNumber;
					// create check option
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = analyzeOptionTemplateForGQ(num);
					analyzeOptionDetailRef.current.appendChild(checkHtmlObject);

					if (document.querySelector(`#analyzeOption${num}`)) {
						document
							.querySelector(`#analyzeOption${num}`)
							.addEventListener("click", () => analyzeOptionHandleForGQ(`analyzeOption${num}`, num));
					}
				}
			};

			analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[index].childNodes.forEach((dom) => {
				if (!dom.innerHTML) return;

				let ruleText = getTypeRule(dom);

				if (ruleText?.split("S0").length < 2) {
					dom.childNodes.forEach((second_dom) => {
						const second_rule = getTypeRule(second_dom);
						if (second_rule?.split("S0").length < 2) {
							second_dom.childNodes.forEach((three_dom) => {
								const three_rule = getTypeRule(three_dom);
								if (three_rule?.split("S0").length < 2) {
									three_dom.childNodes.forEach((fourth_dom) => {
										const fourth_rule = getTypeRule(fourth_dom);
										if (fourth_rule?.split("S0").length < 2) {
											fourth_dom.childNodes.forEach((fifth_dom) => {
												const fifth_rule = getTypeRule(fifth_dom);
												if (fifth_rule?.split("S0").length < 2) {
													createElement(fifth_rule);
												}
											});
										} else {
											createElement(fourth_rule);
										}
									});
								} else {
									createElement(three_rule);
								}
							});
						} else {
							createElement(second_rule);
						}
					});
				} else {
					createElement(ruleText);
				}
			});

			////
			// let currentSubQuestionRule = 0;
			// analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[1]?.childNodes.forEach((dom) => {
			// 	if (!dom.innerHTML) return;

			// 	// get rule
			// 	const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// 	console.log('ruleText:',ruleText)

			// 	if (!ruleText) return;

			// 	const subQuestionRule = ruleText?.split("S0")[1] && ruleText?.split("S0")[1].substr(0, 1);
			// 	console.log('subQuestionRule:',subQuestionRule)

			// 	if (!subQuestionRule) return;

			// 	if (currentSubQuestionRule !== subQuestionRule && subQuestionRule !== 0) {
			// 		currentSubQuestionRule = subQuestionRule;

			// 		// create check option
			// 		const checkHtmlObject = document.createElement("div");
			// 		checkHtmlObject.className = "option";
			// 		checkHtmlObject.innerHTML = analyzeOptionTemplateForGQ(subQuestionRule);
			// 		analyzeOptionDetailRef.current.appendChild(checkHtmlObject);

			// 		if (document.querySelector(`#analyzeOption${subQuestionRule}`)) {
			// 			document
			// 				.querySelector(`#analyzeOption${subQuestionRule}`)
			// 				.addEventListener("click", () =>
			// 					analyzeOptionHandleForGQ(`analyzeOption${subQuestionRule}`, subQuestionRule),
			// 				);
			// 		}
			// 	}
			// });
		} else {
			// 解析 選項 for 填充題
			analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[1]?.childNodes.forEach((dom) => {
				if (!dom.innerHTML) return;
				// get rule
				let ruleText = dom.getAttribute("style")?.split("_")[1];
				// rule last number
				let lastNumber = parseInt(ruleText?.substr(ruleText.length - 1, 1));

				const FZAnalyze = ruleText?.indexOf("FZ");
				if (FZAnalyze === 0) return; // 表示ruleText是以FZM開頭，代表是『填充題』的『解析』

				if (lastNumber === 0) {
					dom.childNodes.forEach((html) => {
						if (!html) return;
						if (html.getAttribute("name")) {
							ruleText = html?.getAttribute("name").split("_")[1];
						} else {
							if (!html.lastChild || !(html.lastChild instanceof Element)) return;
							ruleText = html.lastChild?.getAttribute("name")?.split("_")[1];
						}
						lastNumber = parseInt(ruleText?.substr(ruleText.length - 1, 1));
					});
				}

				if (currentNumber !== lastNumber && lastNumber !== 0) {
					currentNumber = lastNumber;

					// create check option
					const checkHtmlObject = document.createElement("div");
					checkHtmlObject.className = "option";
					checkHtmlObject.innerHTML = analyzeOptionTemplate(lastNumber) || "";
					analyzeOptionDetailRef.current.appendChild(checkHtmlObject);
					if (checkHtmlObject.innerHTML !== "") {
						document
							.querySelector(`#analyzeOption${lastNumber}`)
							.addEventListener("click", () => analyzeOptionHandle(`analyzeOption${lastNumber}`, lastNumber));
					}
				}
			});
		}
		// });
	}, [questionNameRef, analyzeCheckRef, checkOptionRef, questionObject]);

	//
	//
	// ------------------  以下為  Click 事件    --------------------
	//
	//

	// --------------------------------------------題目------------------------------------------------------

	// 題目 check
	const questionCheckHandle = () => {
		if (!questionRef.current || !questionOptionDetailRef.current) return null;
		// Get the checkbox
		// const questionCheck = document.getElementById("questionCheck");

		// If the checkbox is checked, display the output text
		if (questionCheckRef.current?.checked === true) {
			setIsQuestionChecked(true);
			questionRef.current.classList.remove("hide");
		} else {
			setIsQuestionChecked(false);
			questionRef.current.classList.add("hide");
		}

		// question option checkbox
		questionOptionDetailRef.current.childNodes.forEach((dom) => {
			const optionCheck = dom.querySelectorAll("input")[0];
			const optionContentCheck = dom.querySelectorAll("input")[1];

			// If the checkbox is checked, display the output text
			if (questionCheckRef.current?.checked === true) {
				optionCheck.checked = true;
				if (optionContentCheck) optionContentCheck.checked = true;
			} else {
				optionCheck.checked = false;
				if (optionContentCheck) optionContentCheck.checked = false;
			}
		});

		// 題目 選項 for 題組題
		if (questionNameRef.current.innerText === "題組題" || questionNameRef.current.innerText === "混合題") {
			// subQuestion option checkbox
			questionOptionDetailRef.current.childNodes.forEach((dom) => {
				const optionCheck = dom.querySelectorAll("input")[0];

				// get rule
				const ruleText = dom.getAttribute("id");
				// get rule length
				const ruleTextLength = ruleText.length;
				// 分類題目 個別選項
				const lastNumber = parseInt(ruleText.slice(ruleTextLength - 1, ruleTextLength));
				dom.querySelectorAll("input").forEach((contentDom) => {
					// If the checkbox is checked, display the output text
					if (questionCheckRef.current.checked === true) {
						optionCheck.checked = true;
						contentDom.checked = true;
					} else {
						optionCheck.checked = false;
						contentDom.checked = false;
					}
				});
			});
		}
	};

	/**
	 * 題目 選項
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} checkId checkbox id
	 * @param {string} isContent 判斷是否來自 選項內容 checkbox
	 *
	 */
	const questionOptionHandle = (number, checkId, isContent) => {
		if (!questionRef) return null;
		// Get the checkbox
		let checkBox = document.getElementById(checkId);
		if (!checkBox) return null;

		questionRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
			// if (!dom) return null;
			if (!dom.innerHTML) return;
			let domContent = null;

			// get rule
			let ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1]; //||
			//dom.childNodes[0].getAttribute("style")?.split("_")[1];
			// rule last number
			const lastNumber = parseInt(ruleText?.substr(ruleText.length - 1, 1));

			// get option content dom
			if (ruleText?.split("OC").length >= 2) {
				domContent = dom;
				dom = null;
			}

			if (parseInt(number) === parseInt(lastNumber)) {
				const contentCheck = checkBox.parentNode.nextElementSibling?.querySelector("input");

				// If the checkbox is checked, display the output text
				if (checkBox.checked === true) {
					dom?.classList.remove("hide");
					domContent?.classList.remove("hide");

					if (!isContent) {
						if (!contentCheck) return;
						contentCheck.checked = true;
					}
				} else {
					dom?.classList.add("hide");
					domContent?.classList.add("hide");

					if (isContent) {
						dom?.classList.remove("hide");
					} else {
						domContent?.classList.add("hide");
						if (!contentCheck) return;
						contentCheck.checked = false;
					}
				}
			}
		});
	};

	/**
	 * question option 題目選項 for 填充題
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} checkId checkbox id
	 *
	 */
	const questionOptionHandleForFQ = (number, checkId) => {
		// Get the checkbox
		let checkBox = document.getElementById(checkId);

		questionRef.current
			.getElementsByClassName("MsoNormal")[0]
			.querySelectorAll("a")
			.forEach((dom) => {
				if (!dom.querySelector("u")) return;

				// get rule
				const ruleText = dom.getAttribute("name")?.split("_")[1];
				// rule last number
				const lastNumber = parseInt(ruleText?.substr(ruleText.length - 2, 2));

				if (parseInt(number) === parseInt(lastNumber)) {
					// If the checkbox is checked, display the output text
					if (checkBox.checked === true) {
						dom.classList.remove("hide");
					} else {
						dom.classList.add("hide");
					}
				}
			});
	};

	/**
	 * 子題目選項 for 題組題
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} checkId checkbox id
	 *
	 */
	const subQuestionOptionHandleForFQ = (number, checkId) => {
		// Get the checkbox
		const checkBox = document.getElementById(checkId);
		// let currentNumber = null;
		questionRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
			if (!dom.innerHTML) return;
			let ruleText = getTypeRule(dom);
			if (ruleText?.split("S0").length > 1) {
				addRemoveHide_SubQuestion(ruleText, dom, checkBox, number);
			} else {
				dom.childNodes.forEach((second_dom) => {
					let second_rule = getTypeRule(second_dom);
					if (second_rule?.split("S0").length > 1) {
						addRemoveHide_SubQuestion(second_rule, second_dom, checkBox, number);
					} else {
						second_dom.childNodes.forEach((three_dom) => {
							let three_rule = getTypeRule(three_dom);
							if (three_rule?.split("S0").length > 1) {
								addRemoveHide_SubQuestion(three_rule, three_dom, checkBox, number);
							}
						});
					}
				});
			}

			// // get rule
			// const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// // 判斷格式是否有按照規則走
			// if (ruleText?.split("S0").length > 1) {
			// 	// 分類子題目
			// 	const questionCategory = parseInt(ruleText.split("S0")[1].slice(0, 1));
			// 	if (parseInt(number) === parseInt(questionCategory)) {
			// 		// currentNumber = questionCategory;
			// 		// If the checkbox is checked, display the output text
			// 		if (checkBox.checked === true) {
			// 			dom.classList.remove("hide");
			// 		} else {
			// 			dom.classList.add("hide");
			// 		}
			// 	}
			// }
		});

		// subQuestion option checkbox
		questionOptionDetailRef.current.childNodes.forEach((dom) => {
			const optionCheck = dom.querySelectorAll("input")[0];

			// get rule
			const ruleText = dom.getAttribute("id");
			// get rule length
			const ruleTextLength = ruleText.length;
			// 分類題目 個別選項
			const lastNumber = parseInt(ruleText.slice(ruleTextLength - 1, ruleTextLength));
			dom.querySelectorAll("input").forEach((contentDom) => {
				if (parseInt(number) === parseInt(lastNumber)) {
					// If the checkbox is checked, display the output text
					if (checkBox.checked === true) {
						optionCheck.checked = true;
						contentDom.checked = true;
					} else {
						optionCheck.checked = false;
						contentDom.checked = false;
					}
				}
			});
		});
	};

	/**
	 * 子題目裡的選項 for 題組題
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} subQuestion 子題規則數
	 * @param {string} checkId checkbox id
	 * @param {string} isContent 判斷是否來自 選項內容 checkbox
	 *
	 */
	const questionOptionHandleForGQ = (number, subQuestion, checkId, isContent) => {
		// Get the checkbox
		let checkBox = document.getElementById(checkId);

		const addRemoveHide_SubQuestionOption = (rule, el) => {
			let domContent = null;
			const questionCategory = rule.split("S0")[1].slice(0, 1);
			if (parseInt(currentNumber) !== parseInt(questionCategory)) {
				currentNumber = questionCategory;
			}

			// 子題目選項
			if (parseInt(rule.split("S0")[1].slice(0, 1)) === parseInt(currentNumber)) {
				const questionCategoryLength = rule.split("S0")[1].length;
				// 分類題目 個別選項
				const questionCategoryLastNumber = parseInt(
					rule.split("S0")[1].slice(questionCategoryLength - 1, questionCategoryLength),
				);

				if (!questionCategoryLastNumber) return;

				// get option content dom
				if (rule?.split("OC").length >= 2) {
					domContent = el;
					el = null;
				}

				if (
					parseInt(number) === parseInt(questionCategoryLastNumber) &&
					parseInt(subQuestion) === parseInt(currentNumber)
				) {
					const contentCheck = checkBox.parentNode.nextElementSibling?.querySelector("input");
					// If the checkbox is checked, display the output text
					if (checkBox.checked === true) {
						el?.classList.remove("hide");
						domContent?.classList.remove("hide");

						if (!isContent) {
							if (!contentCheck) return;
							contentCheck.checked = true;
						}
					} else {
						el?.classList.add("hide");
						domContent?.classList.add("hide");
						if (isContent) {
							el?.classList.remove("hide");
						} else {
							domContent?.classList.add("hide");
							if (!contentCheck) return;
							contentCheck.checked = false;
						}
					}
				}
			}
		};

		let currentNumber = null;
		questionRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
			if (!dom.innerHTML) return;
			// let domContent = null;

			let ruleText = getTypeRule(dom);

			if (ruleText && ruleText?.split("S0").length === 2) {
				addRemoveHide_SubQuestionOption(ruleText, dom);
			} else {
				dom.childNodes.forEach((second_dom) => {
					const second_rule = getTypeRule(second_dom);
					if (second_rule && second_rule.split("S0").length === 2) {
						addRemoveHide_SubQuestionOption(second_rule, second_dom);
					} else {
						second_dom.childNodes.forEach((three_dom) => {
							const three_rule = getTypeRule(three_dom);
							if (three_rule && three_rule.split("S0").length === 2) {
								addRemoveHide_SubQuestionOption(three_rule, three_dom);
							} else {
								return null;
							}
						});
					}
				});
			}

			// get rule
			// const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// 判斷格式是否有按照規則走
			// if (ruleText?.split("S0").length > 1) {
			// 分類子題目
			// const questionCategory = ruleText.split("S0")[1].slice(0, 1);
			// if (parseInt(currentNumber) !== parseInt(questionCategory)) {
			// 	currentNumber = questionCategory;

			// 	// If the checkbox is checked, display the output text
			// 	// if (checkBox.checked === true) {

			// 	// } else {

			// 	//  }
			// }

			// // 子題目選項
			// if (parseInt(ruleText.split("S0")[1].slice(0, 1)) === parseInt(currentNumber)) {
			// 	const questionCategoryLength = ruleText.split("S0")[1].length;
			// 	// 分類題目 個別選項
			// 	const questionCategoryLastNumber = parseInt(
			// 		ruleText.split("S0")[1].slice(questionCategoryLength - 1, questionCategoryLength),
			// 	);

			// 	if (!questionCategoryLastNumber) return;

			// 	// get option content dom
			// 	if (ruleText?.split("OC").length >= 2) {
			// 		domContent = dom;
			// 		dom = null;
			// 	}

			// 	if (
			// 		parseInt(number) === parseInt(questionCategoryLastNumber) &&
			// 		parseInt(subQuestion) === parseInt(currentNumber)
			// 	) {
			// 		const contentCheck = checkBox.parentNode.nextElementSibling?.querySelector("input");
			// 		// If the checkbox is checked, display the output text
			// 		if (checkBox.checked === true) {
			// 			dom?.classList.remove("hide");
			// 			domContent?.classList.remove("hide");

			// 			if (!isContent) {
			// 				if (!contentCheck) return;
			// 				contentCheck.checked = true;
			// 			}
			// 		} else {
			// 			dom?.classList.add("hide");
			// 			domContent?.classList.add("hide");
			// 			if (isContent) {
			// 				dom?.classList.remove("hide");
			// 			} else {
			// 				domContent?.classList.add("hide");
			// 				if (!contentCheck) return;
			// 				contentCheck.checked = false;
			// 			}
			// 		}
			// 	}
			// }
			// }
		});
	};

	// --------------------------------------------答案------------------------------------------------------
	// 答案 check
	const answerCheckHandle = () => {
		if (!answerHtmlRef.current) return null;
		// Get the checkbox
		let answerCheck = document.getElementById("answerCheck");

		// const answerChildNodes = answerHtmlRef.current.getElementsByClassName("MsoNormal");
		const answerChildNodesAll = answerHtmlRef.current.getElementsByClassName("MsoNormal")[0].childNodes;

		// If the checkbox is checked, display the output text
		if (answerCheck.checked === true) {
			setIsAnswerChecked(true);
			answerChildNodesAll.forEach((dom, idx) => {
				if (idx !== 0) {
					dom.classList.remove("hide");
				}
			});
		} else {
			setIsAnswerChecked(false);
			answerChildNodesAll.forEach((dom, idx) => {
				if (idx !== 0) {
					dom.classList.add("hide");
				}
			});
		}

		// answer option checkbox
		answerOptionDetailRef.current.childNodes.forEach((dom) => {
			const optionCheck = dom.querySelectorAll("input")[0];
			// If the checkbox is checked, display the output text
			if (answerCheck.checked === true) {
				optionCheck.checked = true;
			} else {
				optionCheck.checked = false;
			}
		});
	};

	/**
	 * answer option 答案選項模板 for 填充題
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} checkId checkbox id
	 *
	 */
	const answerOptionHandleForFQ = (number, checkId) => {
		if (!answerHtmlRef.current) return null;
		// Get the checkbox
		const checkBox = document.getElementById(checkId);

		answerHtmlRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
			if (!dom.innerHTML) return;
			const ruleText = getTypeRule(dom);
			let lastNumber = parseInt(ruleText?.substr(ruleText?.length - 2, 2));
			if (lastNumber === 0 || !lastNumber) {
				dom.childNodes.forEach((second_dom) => {
					const second_rule = getTypeRule(second_dom);
					lastNumber = parseInt(second_rule?.substr(second_rule?.length - 2, 2));
					if (lastNumber === 0 || !lastNumber) {
						second_dom.childNodes.forEach((three_dom) => {
							const three_rule = getTypeRule(three_dom);
							lastNumber = parseInt(three_rule?.substr(three_rule?.length - 2, 2));
							if (lastNumber === 0 || !lastNumber) {
								three_dom.childNodes.forEach((fourth_dom) => {
									const fourth_rule = getTypeRule(fourth_dom);
									lastNumber = parseInt(fourth_rule?.substr(fourth_rule?.length - 2, 2));
									if (lastNumber === 0 || !lastNumber) {
										fourth_dom.childNodes.forEach((fifth_dom) => {
											const fifth_rule = getTypeRule(fifth_dom);
											lastNumber = parseInt(fifth_rule?.substr(fifth_rule?.length - 2, 2));
											addRemoveHide_AnswerOption(fifth_rule, fifth_dom, checkBox, number);
										});
									} else {
										addRemoveHide_AnswerOption(fourth_rule, fourth_dom, checkBox, number);
									}
								});
							} else {
								addRemoveHide_AnswerOption(three_rule, three_dom, checkBox, number);
							}
						});
					} else {
						addRemoveHide_AnswerOption(second_rule, second_dom, checkBox, number);
					}
				});
			} else {
				addRemoveHide_AnswerOption(ruleText, dom, checkBox, number);
			}

			// // get rule
			// const ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// console.log('ruleText:',ruleText)
			// // rule last number
			// const lastNumber = parseInt(ruleText?.substr(ruleText?.length - 2, 2));

			// if (!lastNumber || lastNumber === 0) return;

			// // create check option
			// if (parseInt(number) === parseInt(lastNumber)) {
			// 	// If the checkbox is checked, display the output text
			// 	if (checkBox.checked === true) {
			// 		dom.classList.remove("hide");
			// 	} else {
			// 		dom.classList.add("hide");
			// 	}
			// }

			// if (!ruleText) {
			// 	dom.querySelectorAll("a").forEach((spanDom) => {
			// 		const ruleText = spanDom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// 		const lastNumber = parseInt(ruleText?.substr(ruleText?.length - 1, 1));

			// 		if (parseInt(number) === parseInt(lastNumber)) {
			// 			if (checkBox.checked === true) {
			// 				spanDom.classList.remove("hide");
			// 			} else {
			// 				spanDom.classList.add("hide");
			// 			}
			// 		}
			// 	});

			// 	const spanOtherDom = dom.childNodes[0]?.querySelector("a") || dom.childNodes[0]?.querySelector("span");
			// 	const spanOtherDomRuleText = spanOtherDom?.getAttribute("style")?.split("_")[1];
			// 	const spanOtherDomlastNumber = parseInt(spanOtherDomRuleText?.substr(spanOtherDomRuleText?.length - 1, 1));
			// 	if (parseInt(number) === parseInt(spanOtherDomlastNumber)) {
			// 		if (checkBox.checked == true) {
			// 			spanOtherDom.classList.remove("hide");
			// 		} else {
			// 			spanOtherDom.classList.add("hide");
			// 		}
			// 	}
			// }
		});
	};

	/**
	 * answer option 答案選項模板 for 題組題
	 *
	 * @param {string} number 選項規則尾數
	 * @param {string} checkId checkbox id
	 *
	 */
	const answerOptionHandleForGQ = (number, checkId) => {
		if (!answerHtmlRef.current) return null;
		// Get the checkbox
		const checkBox = document.getElementById(checkId);

		answerHtmlRef.current.getElementsByClassName("MsoNormal")[0].childNodes.forEach((dom) => {
			if (!dom || !dom.innerHTML) return;

			// get rule
			let ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			if (ruleText?.split("S0").length < 2) {
				if (dom.childNodes[0].childNodes[0].nodeName === "#text") return;
				if (dom.childNodes[0].childNodes.length > 1) {
					// a tag
					ruleText = dom.childNodes[0].childNodes[1].getAttribute("name").split("_")[1];
				} else {
					// span tag
					ruleText = dom.childNodes[0].childNodes[0].getAttribute("style").split("_")[1];
				}
			}

			// 分類子題目
			const questionCategory = parseInt(ruleText?.split("S0")[1].slice(0, 1));
			if (parseInt(number) === parseInt(questionCategory)) {
				if (checkBox.checked === true) {
					dom.classList.remove("hide");
				} else {
					dom.classList.add("hide");
				}
			}
		});
	};

	// --------------------------------------------解析------------------------------------------------------
	// 解析 check
	const analyzeCheckHandle = () => {
		if (!analyzeHtmlRef.current) return null;
		// Get the checkbox
		let analyzeCheck = document.getElementById("analyzeCheck");
		if (!analyzeCheck) return null;

		const analyzeChildNodes = analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[1];
		if (!analyzeChildNodes) return;

		// If the checkbox is checked, display the output text
		if (analyzeCheck.checked === true) {
			setIsAnalyzeChecked(true);
			analyzeChildNodes.classList.remove("hide");

			// analyze option checkbox
			analyzeOptionDetailRef.current.childNodes.forEach((dom) => {
				const optionCheck = dom.querySelector("input");
				if (!optionCheck) return;
				optionCheck.checked = true;
			});
		} else {
			setIsAnalyzeChecked(false);
			analyzeChildNodes.classList.add("hide");

			// analyze option checkbox
			analyzeOptionDetailRef.current.childNodes.forEach((dom) => {
				const optionCheck = dom.querySelector("input");
				if (!optionCheck) return;
				optionCheck.checked = false;
			});
		}
	};

	/**
	 * 解析 選項
	 *
	 * checkDom -> checkbox
	 * number -> rule number
	 *
	 */
	const analyzeOptionHandle = (checkDom, number) => {
		// Get the checkbox
		let checkBox = document.getElementById(checkDom);
		if (!analyzeHtmlRef.current) return null;

		analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[1].childNodes.forEach((dom) => {
			if (!dom.innerHTML) return;
			// get rule
			let ruleText = dom.getAttribute("style")?.split("_")[1];
			// rule last number
			let lastNumber = parseInt(ruleText?.substr(ruleText.length - 1, 1));

			if (parseInt(lastNumber) === 0) {
				dom.childNodes.forEach((html) => {
					if (html.getAttribute("name")) {
						if (html.getAttribute("name")) {
							ruleText = html.getAttribute("name")?.split("_")[1];
						}
					} else {
						if (html.lastChild.getAttribute("name")) {
							ruleText = html.lastChild.getAttribute("name")?.split("_")[1];
						}
					}

					lastNumber = parseInt(ruleText.substr(ruleText.length - 1, 1));
				});
			}

			if (parseInt(number) === parseInt(lastNumber)) {
				// If the checkbox is checked, display the output text
				if (checkBox.checked === true) {
					dom.classList.remove("hide");
				} else {
					dom.classList.add("hide");
				}
			}
		});
	};

	/**
	 * 解析 選項 for 題組題
	 *
	 * checkDom -> checkbox
	 * number -> rule number
	 *
	 */
	const analyzeOptionHandleForGQ = (checkDom, number) => {
		// Get the checkbox
		const checkBox = document.getElementById(checkDom);
		if (!analyzeHtmlRef.current) return null;
		let index = 0;
		if (analyzeHtmlRef.current.getElementsByClassName("MsoNormal").length === 0) return;
		if (analyzeHtmlRef.current.getElementsByClassName("MsoNormal").length > 1) index = 1;

		analyzeHtmlRef.current.getElementsByClassName("MsoNormal")[index]?.childNodes.forEach((dom) => {
			// if (!dom.innerHTML) return;
			// let a = getRuleText(dom)
			// // get rule
			// let ruleText = dom.getAttribute("name")?.split("_")[1] || dom.getAttribute("style")?.split("_")[1];
			// console.log('dom:',dom)
			// console.log('ruleText_1:', ruleText)

			// if (!ruleText || ruleText?.split("S0").length < 2) {
			// 	console.log('dom.childNodes:',dom.childNodes)
			// 	ruleText =
			// 		dom.childNodes[0]?.getAttribute("style")?.split("_")[1] ||
			// 		dom.childNodes[1]?.getAttribute("name")?.split("_")[1] ||
			// 		"";
			// 	// 	dom.childNodes[0]?.querySelectorAll("a")[0]?.getAttribute("name")?.split("_")[1] ||
			// 	// 	dom.childNodes[0]?.getAttribute("style")?.split("_")[1] ||
			// 	// 	dom.childNodes[0]?.querySelector("a")?.getAttribute("name")?.split("_")[1];
			// 	console.log('ruleText_2:', ruleText)
			// 	if (!ruleText || ruleText?.split("S0").length < 2) {
			// 		console.log('tag:',dom.childNodes[0].tagName)
			// 		ruleText =
			// 			dom.childNodes[0]?.querySelector("span")?.getAttribute("style")?.split("_")[1] ||
			// 			dom.childNodes[0]?.querySelector("a")?.getAttribute("name")?.split("_")[1];
			// 		console.log('A:',dom.childNodes[0]?.querySelector("a")?.getAttribute("name")?.split("_"))
			// 		console.log('ruleText_3:', ruleText)

			// 	}
			// }
			// console.log('ruleText_final:',ruleText)
			// let subQuestionRule = parseInt(ruleText?.split("S0")[1]?.substr(0, 1));
			// console.log('subQuestionRule:',subQuestionRule)

			// let num = getRuleText(dom);
			control_AnalyzeOption(dom, checkBox, number);

			// if (parseInt(number) === num) {
			// 	console.log('dom:',dom)
			// 	// If the checkbox is checked, display the output text
			// 	if (checkBox.checked === true) {
			// 		dom.classList.remove("hide");
			// 	} else {
			// 		dom.classList.add("hide");
			// 	}
			// }
		});
	};

	// 取得元素本身的 ruleText
	const getTypeRule = (item) => {
		if (!item) return;
		let rule = "";
		if (item.tagName === "SPAN") {
			rule = item.getAttribute("style")?.split("_")[1];
		} else if (item.tagName === "A") {
			rule = item.getAttribute("name")?.split("_")[1];
		} else {
			return null;
		}
		return rule;
	};

	const addRemoveHide_SubQuestion = (ruleText, el, checkBox, currentNum) => {
		let num = parseInt(ruleText?.split("S0")[1]?.substr(0, 1));
		if (parseInt(currentNum) === num) {
			if (checkBox.checked === true) {
				el.classList.remove("hide");
			} else {
				el.classList.add("hide");
			}
		}
	};

	const addRemoveHide_AnalyzeOption = (ruleText, el, checkBox, currentNum) => {
		let num = parseInt(ruleText?.split("S0")[1]?.substr(0, 1));
		if (parseInt(currentNum) === num) {
			if (checkBox.checked === true) {
				el.classList.remove("hide");
			} else {
				el.classList.add("hide");
			}
		}
	};

	const addRemoveHide_AnswerOption = (ruleText, el, checkBox, currentNum) => {
		let num = parseInt(ruleText?.substr(ruleText?.length - 2, 2));
		if (parseInt(currentNum) === num) {
			if (checkBox.checked === true) {
				el.classList.remove("hide");
			} else {
				el.classList.add("hide");
			}
		}
	};

	// for 解析 拆分 （題組題）
	const control_AnalyzeOption = (dom, checkBox, currentNum) => {
		if (!dom || !dom.innerHTML) return;

		let ruleText = getTypeRule(dom);

		if (ruleText && ruleText?.split("S0").length >= 2) {
			addRemoveHide_AnalyzeOption(ruleText, dom, checkBox, currentNum);
		} else {
			dom.childNodes.forEach((second_dom) => {
				let second_rule = getTypeRule(second_dom);
				if (second_rule && second_rule.split("S0").length >= 2) {
					addRemoveHide_AnalyzeOption(second_rule, second_dom, checkBox, currentNum);
				} else {
					if (!second_dom) return;
					second_dom.childNodes.forEach((three_dom) => {
						let three_rule = getTypeRule(three_dom);
						if (three_rule && three_rule.split("S0").length >= 2) {
							addRemoveHide_AnalyzeOption(three_rule, three_dom, checkBox, currentNum);
						} else {
							if (!three_dom) return;
							three_dom.childNodes.forEach((fourth_dom) => {
								let fourth_rule = getTypeRule(fourth_dom);
								if (fourth_rule && fourth_rule.split("S0").length >= 2) {
									addRemoveHide_AnalyzeOption(fourth_rule, fourth_dom, checkBox, currentNum);
								} else {
									if (!fourth_dom) return;
									fourth_dom.childNodes.forEach((fifth_dom) => {
										let fifth_rule = getTypeRule(fifth_dom);
										addRemoveHide_AnalyzeOption(fifth_rule, fifth_dom, checkBox, currentNum);
									});
								}
							});
						}
					});
				}
			});
		}

		// let num = parseInt(ruleText?.split("S0")[1]?.substr(0, 1));
		// if (num === 2) console.log('hhh:',dom)
		// return num;
	};

	return (
		<StyledFileCheck>
			<div className="title">
				{/* <input type="file" id="fileUploader" data-target="fileUploader" accept="application/json" /> */}
				<h4>
					作答方式: <span ref={questionNameRef} id="questionName"></span>
				</h4>
			</div>
			<div className="content">
				<div className="fileSource">
					{/* 題目 */}
					<div ref={questionRef} id="questionHtml"></div>
					{/* 答案 */}
					<div ref={answerHtmlRef} id="answerHtml"></div>
					{/* 解析 */}
					<div ref={analyzeHtmlRef} id="analyzeHtml"></div>
				</div>
				<div ref={checkOptionRef} id="checkOption">
					<div className="questionOptionBox">
						<div>
							<input
								type="checkbox"
								ref={questionCheckRef}
								id="questionCheck"
								checked={isQuestionChecked}
								value="questionCheck"
								onClick={questionCheckHandle}
							/>
							<label htmlFor={"questionCheck"}>題目</label>
						</div>
						<div ref={questionOptionDetailRef} id="questionOptionDetail"></div>
					</div>
					<div className="hr"></div>
					<div>
						<input
							type="checkbox"
							ref={answerCheckRef}
							id="answerCheck"
							checked={isAnswerChecked}
							value="answerCheck"
							onClick={answerCheckHandle}
						/>
						<label htmlFor="answerCheck">答案</label>
						<div ref={answerOptionDetailRef} id="answerOptionDetail"></div>
					</div>
					<div className="hr"></div>
					<div ref={analyzeOptionBoxRef} id="analyzeOptionBox">
						<div>
							<input
								type="checkbox"
								ref={analyzeCheckRef}
								id="analyzeCheck"
								checked={isAnalyzeChecked}
								value="analyzeCheck"
								onClick={analyzeCheckHandle}
							/>
							<label htmlFor="analyzeCheck">解析</label>
						</div>
						<div ref={analyzeOptionDetailRef} id="analyzeOptionDetail"></div>
					</div>
				</div>
			</div>
		</StyledFileCheck>
	);
};

FileCheck.propTypes = {
	questionObject: PropTypes.object,
	// checkMetaData: PropTypes.array,
	// outputMetaData: PropTypes.array,
	// onSaveHandle: PropTypes.func,
	// currentTab: PropTypes.number,
};
