import * as React from "react";
function SvgCheckSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.959 2.96 2 4.143 2h15.714C21.041 2 22 2.96 22 4.143v15.714C22 21.041 21.04 22 19.857 22zm-9.138-4.378l8.215-8.214a.714.714 0 000-1.01l-1.01-1.01a.714.714 0 00-1.01 0l-6.7 6.699-3.127-3.128a.714.714 0 00-1.01 0l-1.01 1.01a.714.714 0 000 1.01l4.642 4.643a.714.714 0 001.01 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCheckSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
