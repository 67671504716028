import * as React from "react";
function SvgCloudUploadAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.8 11.081a3 3 0 00-4.466-3.575 4.999 4.999 0 00-9.328 2.747A4.501 4.501 0 006.5 19H18a4 4 0 00.8-7.919zM14.294 13H12.25v3.5c0 .275-.225.5-.5.5h-1.5a.501.501 0 01-.5-.5V13H7.706a.5.5 0 01-.353-.853l3.294-3.294a.502.502 0 01.706 0l3.294 3.294a.5.5 0 01-.353.853z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCloudUploadAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
