import * as React from "react";
function SvgTemperatureLowSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 2a3.756 3.756 0 00-3.75 3.75 3.756 3.756 0 003.75 3.75A3.756 3.756 0 0022 5.75 3.756 3.756 0 0018.25 2zm0 5C17.559 7 17 6.441 17 5.75s.559-1.25 1.25-1.25 1.25.559 1.25 1.25S18.941 7 18.25 7zM12 6.375A4.373 4.373 0 007.625 2 4.373 4.373 0 003.25 6.375v6.504C2.48 13.844 2 15.047 2 16.375A5.626 5.626 0 007.625 22a5.626 5.626 0 005.625-5.625c0-1.328-.48-2.535-1.25-3.496V6.375zM7.625 19.5A3.13 3.13 0 014.5 16.375c0-.996.477-1.91 1.25-2.492V6.375a1.875 1.875 0 013.75 0v7.508a3.113 3.113 0 011.25 2.492A3.13 3.13 0 017.625 19.5zm.625-4.887v-.738a.627.627 0 00-.625-.625.627.627 0 00-.625.625v.738a1.87 1.87 0 00-1.25 1.762 1.875 1.875 0 003.75 0 1.87 1.87 0 00-1.25-1.762z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTemperatureLowSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
