import * as React from "react";
function SvgMinusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.571 9.857H3.43c-.79 0-1.429.64-1.429 1.429v1.428c0 .79.64 1.429 1.429 1.429H20.57c.79 0 1.429-.64 1.429-1.429v-1.428c0-.79-.64-1.429-1.429-1.429z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMinusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
