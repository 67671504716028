import * as React from "react";
function SvgPauseCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-.645 13.226c0 .355-.29.645-.645.645H8.774a.647.647 0 01-.645-.645V8.774c0-.355.29-.645.645-.645h1.936c.354 0 .645.29.645.645v6.452zm4.516 0c0 .355-.29.645-.645.645H13.29a.647.647 0 01-.645-.645V8.774c0-.355.29-.645.645-.645h1.936c.355 0 .645.29.645.645v6.452z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPauseCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
