import * as React from "react";
function SvgFaxSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 8.25V5.018a1.249 1.249 0 00-.366-.884l-1.768-1.768A1.25 1.25 0 0017.732 2H8.25A1.25 1.25 0 007 3.25v17.5A1.25 1.25 0 008.25 22h12.5A1.25 1.25 0 0022 20.75V9.5a1.25 1.25 0 00-1.25-1.25zm-7.5 10.625a.624.624 0 01-.625.625h-1.25a.624.624 0 01-.625-.625v-1.25a.624.624 0 01.625-.625h1.25a.624.624 0 01.625.625v1.25zm0-5a.624.624 0 01-.625.625h-1.25a.624.624 0 01-.625-.625v-1.25a.624.624 0 01.625-.625h1.25a.624.624 0 01.625.625v1.25zm5 5a.624.624 0 01-.625.625h-1.25a.624.624 0 01-.625-.625v-1.25a.624.624 0 01.625-.625h1.25a.624.624 0 01.625.625v1.25zm0-5a.624.624 0 01-.625.625h-1.25a.624.624 0 01-.625-.625v-1.25a.624.624 0 01.625-.625h1.25a.624.624 0 01.625.625v1.25zm0-4.375H9.5v-5h6.25v1.875a.625.625 0 00.625.625h1.875v2.5zM4.5 7H3.25A1.25 1.25 0 002 8.25v12.5A1.25 1.25 0 003.25 22H4.5a1.25 1.25 0 001.25-1.25V8.25A1.25 1.25 0 004.5 7z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFaxSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
