import * as React from "react";
function SvgTasksSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.454 3.7a.469.469 0 00-.665 0L4.302 6.173l-.887-.864a.468.468 0 00-.664 0l-.613.614a.469.469 0 000 .664l1.859 1.852a.5.5 0 00.688 0l.609-.61 2.82-2.82a.472.472 0 00.004-.665L7.453 3.7zm0 6.218a.469.469 0 00-.665 0l-2.487 2.49-.887-.864a.47.47 0 00-.664 0l-.613.613a.47.47 0 000 .664l1.854 1.856a.5.5 0 00.688 0l.613-.613 2.82-2.822a.469.469 0 00.004-.66l-.664-.664zM4.5 16.688c-1.035 0-1.898.84-1.898 1.875 0 1.035.864 1.875 1.898 1.875a1.875 1.875 0 000-3.75zm16.875.625h-11.25a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h11.25a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm0-12.5h-11.25a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h11.25A.625.625 0 0022 6.688v-1.25a.625.625 0 00-.625-.625zm0 6.25h-11.25a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h11.25a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTasksSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
