import * as React from "react";
function SvgAwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.294 16.165c-.34-.34-.163-.243-.981-.463a2.818 2.818 0 01-.994-.52l-1.772 4.345a.625.625 0 00.603.86l2.058-.078 1.415 1.496c.313.33.861.227 1.033-.194l2.033-4.985a2.836 2.836 0 01-1.38.374 2.833 2.833 0 01-2.015-.835zm11.159 3.362l-1.772-4.345c-.296.23-.622.42-.994.52-.823.22-.642.125-.98.463A2.834 2.834 0 0113.69 17a2.84 2.84 0 01-1.38-.374l2.034 4.985c.171.42.72.523 1.032.194l1.416-1.496 2.058.079a.625.625 0 00.603-.86zm-4.68-4.246c.597-.607.666-.555 1.516-.786.542-.148.966-.58 1.112-1.132.292-1.11.216-.976 1.013-1.787a1.62 1.62 0 00.407-1.546c-.292-1.11-.292-.954 0-2.064a1.62 1.62 0 00-.407-1.546c-.797-.812-.721-.678-1.013-1.787A1.587 1.587 0 0016.289 3.5c-1.09-.298-.958-.22-1.756-1.032a1.554 1.554 0 00-1.52-.414c-1.088.296-.936.297-2.027 0a1.553 1.553 0 00-1.519.414c-.797.812-.666.734-1.755 1.032-.543.148-.967.58-1.112 1.132-.292 1.109-.217.975-1.014 1.787a1.62 1.62 0 00-.407 1.546c.292 1.108.292.953 0 2.063-.145.552.01 1.142.407 1.546.797.812.722.678 1.014 1.788.145.552.57.984 1.112 1.132.874.238.94.2 1.515.786.516.526 1.322.62 1.942.228a1.55 1.55 0 011.662 0c.62.393 1.426.298 1.942-.228zM8.315 8.874c0-2.072 1.65-3.751 3.685-3.751s3.685 1.68 3.685 3.75c0 2.072-1.65 3.751-3.685 3.751s-3.685-1.679-3.685-3.75z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
