import * as React from "react";
function SvgCompactDiscSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM5.548 12h-1.29c0-4.27 3.472-7.742 7.742-7.742v1.29A6.46 6.46 0 005.548 12zM12 15.871a3.872 3.872 0 010-7.742 3.872 3.872 0 010 7.742zm0-5.161c-.714 0-1.29.576-1.29 1.29 0 .714.576 1.29 1.29 1.29.714 0 1.29-.576 1.29-1.29 0-.714-.576-1.29-1.29-1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCompactDiscSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
