import * as React from "react";
function SvgLaughWinkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm5.702 15.702A8.01 8.01 0 0112 20.064a8.01 8.01 0 01-5.702-2.362A8.01 8.01 0 013.935 12a8.01 8.01 0 012.363-5.702A8.01 8.01 0 0112 3.935a8.01 8.01 0 015.702 2.363A8.01 8.01 0 0120.064 12a8.01 8.01 0 01-2.362 5.702zM15.226 8.29c-1.037 0-2.254.682-2.416 1.698a.483.483 0 00.799.435l.383-.342c.597-.533 1.863-.533 2.46 0l.383.342c.342.299.87.012.798-.435-.153-1.016-1.37-1.698-2.407-1.698zm-6.452 2.42c.714 0 1.29-.577 1.29-1.29a1.289 1.289 0 10-2.58 0c0 .713.576 1.29 1.29 1.29zm7.839 2.58H7.387a.541.541 0 00-.544.605c.302 2.387 2.375 4.234 4.883 4.234h.548c2.508 0 4.58-1.847 4.883-4.234a.541.541 0 00-.544-.605z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLaughWinkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
