/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { sliceTableData } from "utils/common";
import { Table, Pagination, Button } from "common-components";
import { getTime, format } from "date-fns";
import { Breadcrumb } from "components";
import { StyledExportListPage } from "./ExportListPage.style";
import { EDU_NAME, SUBJECT_NAME } from "constants/index";
import { getExportList } from "api/export";

const { Header, Body, Row, Item } = Table;

const breadcrumbList = [
	{
		icon: "unarchive",
		name: "匯出列表",
		path: "/exportList",
	},
];

export const ExportListPage = () => {
	// 取得 匯出列表
	const [stateGetExportList, sendGetExportList] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_c, event) => {
				const { data, isSuccess, message } = await getExportList();
				return {
					data,
					isSuccess,
					message,
				};
			},
		},
	});

	const { data } = stateGetExportList.context.result || {};

	// 翻頁相關功能
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
	};

	// 學制科目轉換成中文
	const transformEduSubject = (eduSubject) => {
		if (!eduSubject) return "";
		const edu = eduSubject.substring(0, 1);
		const subject = eduSubject.substring(1, 3);
		return EDU_NAME[edu] + SUBJECT_NAME[subject];
	};

	// 觸發 下載URL
	const downloadHandle = (item) => {
		const { downloadUrl, fileName } = item;
		const link = document.createElement("a");
		link.href = downloadUrl;
		// link.target = "_blank";
		link.download = fileName;
		link.click();
	};

	// 設定 定時 30秒 更新列表資訊
	useEffect(() => {
		sendGetExportList(FetchEventType.Fetch);
		const interval = setInterval(() => {
			sendGetExportList(FetchEventType.Fetch);
		}, 1000 * 30);
		return () => clearInterval(interval);
	}, []);

	return (
		<StyledExportListPage>
			<div className="flexStartBox">
				<Breadcrumb data={breadcrumbList} />
			</div>

			<Table margin="3" className={"exportListTable"}>
				<Header>
					<Row>
						<Item flex="2">檔案名稱</Item>
						<Item>檔案狀態</Item>
						<Item>匯出日期</Item>
						<Item>學制科目</Item>
						<Item>操作</Item>
					</Row>
				</Header>
				<Body>
					{data &&
						data.length > 0 &&
						sliceTableData(data, pageState.currentPage, pageState.limit).map((item, index) => {
							return (
								<Row key={index}>
									<Item flex="2">{item?.fileName}</Item>
									<Item>{item?.message}</Item>
									<Item>{format(getTime(new Date(item?.createTime)), "yyyy-MM-dd HH:mm")}</Item>
									<Item>{transformEduSubject(item?.eduSubject)}</Item>
									<Item>
										<Button.IconButton
											variant="blue"
											full={false}
											iconName="save_alt"
											disabled={!(item?.downloadUrl && item?.status === "Finish")}
											onClick={() => downloadHandle(item)}>
											下載
										</Button.IconButton>
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			<Pagination
				total={data?.length || 0}
				defaultCurrent={1}
				current={pageState.currentPage}
				onChange={pageChange}
				defaultPageSize={pageState.limit}
			/>
		</StyledExportListPage>
	);
};
