import * as React from "react";
function SvgSadCryOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm5.806 15.58v-4.612A.97.97 0 0016.84 12a.97.97 0 00-.968.968v6.105a8.04 8.04 0 01-7.742 0v-6.105A.97.97 0 007.161 12a.97.97 0 00-.967.968v4.613A8.03 8.03 0 013.935 12c0-4.448 3.617-8.065 8.065-8.065 4.448 0 8.064 3.617 8.064 8.065a8.022 8.022 0 01-2.258 5.58zm-7.508-6.447a.486.486 0 00.246-.5c-.16-1.016-1.379-1.698-2.41-1.698-1.033 0-2.255.682-2.412 1.698a.483.483 0 00.798.435l.383-.342c.597-.533 1.863-.533 2.46 0l.383.342c.1.093.319.194.552.065zm5.573-2.198c-1.036 0-2.254.682-2.411 1.698a.482.482 0 00.798.435l.383-.342c.597-.533 1.863-.533 2.46 0l.383.342c.1.09.322.19.552.065a.486.486 0 00.246-.5c-.157-1.016-1.375-1.698-2.411-1.698zM12 12.645c-1.246 0-2.258 1.157-2.258 2.58 0 1.424 1.012 2.582 2.258 2.582s2.258-1.158 2.258-2.581c0-1.424-1.012-2.58-2.258-2.58z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSadCryOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
