import * as React from "react";
function SvgCalendarCheckOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 4.5H17V2.469A.469.469 0 0016.531 2H14.97a.469.469 0 00-.469.469V4.5h-5V2.469A.469.469 0 009.031 2H7.47A.469.469 0 007 2.469V4.5H5.125c-1.036 0-1.875.84-1.875 1.875v13.75C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V6.375c0-1.036-.84-1.875-1.875-1.875zm-.234 15.625H5.359a.235.235 0 01-.234-.234V8.25h13.75v11.64a.235.235 0 01-.234.235zm-2.065-7.838l-5.559 5.514a.469.469 0 01-.663-.002l-2.933-2.957a.469.469 0 01.003-.663l.887-.88a.469.469 0 01.663.002l1.723 1.737 4.339-4.304a.469.469 0 01.663.003l.88.887a.469.469 0 01-.003.663z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCalendarCheckOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
