import * as React from "react";
function SvgArchwaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.444 18.667h-.555V6.444H3.11v12.223h-.555a.555.555 0 00-.555.555v1.111c0 .307.248.556.555.556h5.555a.555.555 0 00.556-.556v-6.11a3.333 3.333 0 116.666 0l.001 5.555v.555c0 .307.249.556.556.556h5.554a.555.555 0 00.556-.556v-1.11a.555.555 0 00-.556-.556zm0-15.556H2.556A.555.555 0 002 3.667v1.11c0 .308.249.556.556.556h18.888A.555.555 0 0022 4.778V3.667a.555.555 0 00-.556-.556z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgArchwaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
