import * as React from "react";
function SvgRaisehandSyncSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.88 13.34a5.25 5.25 0 00-.354 1.903 5.25 5.25 0 001.816 3.977H5.109a.98.98 0 01-.98-.98V14.82c0-.288-.129-.56-.342-.755A11.608 11.608 0 010 5.47C0 4.34.16 3.25.46 2.216a.93.93 0 01.904-.658H3.05c.752 0 1.24.825 1.015 1.543a7.924 7.924 0 00-.36 2.37c0 4.127 3.154 7.516 7.175 7.87z"
        fill="currentColor"
      />
      <path
        d="M16.168 6.206c0 2.567-2.073 4.648-4.63 4.648-2.558 0-4.631-2.08-4.631-4.648 0-2.567 2.073-4.647 4.63-4.647 2.558 0 4.631 2.08 4.631 4.647zM18.119 12.768A2.374 2.374 0 0014.2 13.92a.17.17 0 01-.164.13h-.809a.17.17 0 01-.166-.2 3.501 3.501 0 015.857-1.88l.504-.504a.339.339 0 01.578.24v1.892a.339.339 0 01-.339.338H17.77a.339.339 0 01-.24-.578l.59-.589zm-4.78 2.296h1.892c.301 0 .453.365.24.579l-.59.589a2.374 2.374 0 003.919-1.151.17.17 0 01.164-.13h.809a.17.17 0 01.166.2 3.501 3.501 0 01-5.857 1.88l-.504.504a.339.339 0 01-.578-.24v-1.892c0-.187.152-.338.339-.338z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgRaisehandSyncSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
