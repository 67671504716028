import * as React from "react";
function SvgFastBackwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 19.031V4.97a.47.47 0 01.469-.469H4.03a.47.47 0 01.469.469v5.933l6.7-6.113c.804-.668 2.05-.11 2.05.961v5.152l6.7-6.113c.804-.668 2.05-.11 2.05.961v12.5c0 1.07-1.246 1.629-2.05.96l-6.7-6.066v5.106c0 1.07-1.246 1.629-2.05.96l-6.7-6.066v5.887a.47.47 0 01-.469.469H2.47A.47.47 0 012 19.031z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFastBackwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
