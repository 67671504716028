import * as React from "react";
function SvgFrownOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-4.516 8.065c0-.714.576-1.29 1.29-1.29.714 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29-.714 0-1.29-.577-1.29-1.29zm7.552 7.39c-1.258-.386-2.395-.616-3.036-.616s-1.778.23-3.036.617a.647.647 0 01-.827-.73c.282-1.613 2.423-2.468 3.863-2.468s3.58.859 3.863 2.468a.647.647 0 01-.827.73zm.19-6.1c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgFrownOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
