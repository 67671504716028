import * as React from "react";
function SvgHandshakeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.225 7.997l-1.488-1.488A1.758 1.758 0 0015.5 6H8.412c-.462 0-.909.184-1.237.51L5.688 7.996H2v7.99h2c.55 0 .994-.443.997-.99h.284l2.644 2.387a2.75 2.75 0 003.3.12c.39.337.813.496 1.284.496.57 0 1.104-.231 1.525-.75.691.272 1.507.081 2-.525l.82-1.01c.174-.215.284-.462.34-.718h1.81c.002.547.45.99.996.99h2v-7.99h-3.775zM3.5 14.987a.501.501 0 01-.5-.5c0-.274.225-.5.5-.5s.5.226.5.5a.5.5 0 01-.5.5zm12.188-.215l-.816 1.006a.252.252 0 01-.353.038l-.747-.607-.938 1.141c-.187.228-.468.15-.562.075l-1.15-.984-.488.6c-.434.534-1.225.615-1.728.206l-3.04-2.75H5V9.494h1.31l1.928-1.925.178-.072h1.771l-1.209 1.11a2.237 2.237 0 00-.137 3.165c.462.506 1.912 1.287 3.171.137l.257-.234 3.381 2.744a.255.255 0 01.037.353zM19 13.497h-2.163a1.748 1.748 0 00-.24-.24l-3.21-2.607.391-.356a.5.5 0 00.031-.707l-.34-.365a.502.502 0 00-.707-.031l-1.725 1.58c-.296.273-.803.295-1.08 0a.741.741 0 01.037-1.059l2.05-1.878c.231-.212.531-.328.843-.328l2.616-.006c.066 0 .128.025.172.072l1.928 1.925H19v4zm1.5 1.49a.501.501 0 01-.5-.5c0-.274.225-.5.5-.5s.5.226.5.5a.5.5 0 01-.5.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHandshakeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
