import * as React from "react";
function SvgPalletSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.5 12h11c.275 0 .5-.225.5-.5v-7c0-.275-.225-.5-.5-.5H14v4l-2-1-2 1V4H6.5c-.275 0-.5.225-.5.5v7c0 .275.225.5.5.5zm15 4c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-19c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5H4v2H2.5c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h19c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5H20v-2h1.5zM11 18H6v-2h5v2zm7 0h-5v-2h5v2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPalletSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
