import * as React from "react";
function SvgThumbsUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.063 10.75H2.938a.937.937 0 00-.938.938v9.374c0 .518.42.938.938.938h3.124c.518 0 .938-.42.938-.938v-9.375a.937.937 0 00-.938-.937zM4.5 20.438a.937.937 0 110-1.875.937.937 0 010 1.875zM17 5.181c0 1.657-1.014 2.586-1.3 3.693h3.974c1.304 0 2.32 1.084 2.326 2.27.003.7-.295 1.455-.76 1.921l-.004.004c.384.912.322 2.19-.363 3.105.339 1.011-.003 2.254-.64 2.92.168.687.087 1.272-.24 1.743C19.195 21.984 17.219 22 15.548 22h-.11c-1.887 0-3.43-.688-4.671-1.24-.623-.277-1.438-.62-2.057-.631a.469.469 0 01-.46-.469v-8.35c0-.125.05-.245.139-.333 1.547-1.53 2.213-3.148 3.481-4.419.578-.579.789-1.454.992-2.3.174-.723.537-2.258 1.325-2.258C15.126 2 17 2.313 17 5.182z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgThumbsUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
