"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiCheckboxer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const UiCheckboxer = exports.UiCheckboxer = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\t", "\n\t> .label {\n\t\tmargin-right: 8px;\n\t\tflex: 0 0 57px;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t\tcolor: rgba(0, 0, 0, 0.65);\n\t}\n\n\t.group {\n\t\t> .ant-checkbox-group {\n\t\t\tdisplay: flex;\n\t\t\tflex-wrap: wrap;\n\t\t\tmax-width: max-content;\n\n\t\t\t> .option {\n\t\t\t\tcolor: rgba(0, 0, 0, 0.65);\n\t\t\t}\n\t\t}\n\n\t\t> .ant-radio-group {\n\t\t\tdisplay: flex;\n\t\t\tflex-wrap: wrap;\n\t\t\tmax-width: max-content;\n\n\t\t\t> .option {\n\t\t\t\tcolor: rgba(0, 0, 0, 0.65);\n\t\t\t}\n\t\t}\n\t}\n"])), _styledSystem.space);