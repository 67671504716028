import * as React from "react";
function SvgMoreOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <circle cx={6} cy={12.5} r={1.5} fill="currentColor" />
      <circle cx={12} cy={12.5} r={1.5} fill="currentColor" />
      <circle cx={18} cy={12.5} r={1.5} fill="currentColor" />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoreOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
