import * as React from "react";
function SvgBellSlashOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.812 18.72L3.125 4.11a.5.5 0 00-.703.077l-.312.39a.5.5 0 00.078.704l18.687 14.61a.5.5 0 00.703-.079l.312-.39a.5.5 0 00-.078-.703zM7.11 15.5c.523-.688 1.078-1.744 1.294-3.456l-1.42-1.11c-.103 2.835-1.14 3.77-1.715 4.388A.976.976 0 005 16a1 1 0 001.004 1h8.739l-1.919-1.5H7.11zM12 7a3.5 3.5 0 013.5 3.5l-.002.018c0 .526.036.993.087 1.43l1.86 1.454c-.259-.692-.447-1.61-.447-2.902 0-2.428-1.703-4.372-3.999-4.849V5a1 1 0 10-1.998 0v.651c-.813.17-1.546.53-2.16 1.023l1.192.932A3.481 3.481 0 0112 7zm0 13a2 2 0 002-2H10a2 2 0 002 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgBellSlashOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
