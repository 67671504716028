import * as React from "react";
function SvgMobileAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.375 2h-8.75C6.59 2 5.75 2.84 5.75 3.875v16.25C5.75 21.16 6.59 22 7.625 22h8.75c1.035 0 1.875-.84 1.875-1.875V3.875C18.25 2.84 17.41 2 16.375 2zM12 20.75c-.691 0-1.25-.559-1.25-1.25s.559-1.25 1.25-1.25 1.25.559 1.25 1.25-.559 1.25-1.25 1.25zm4.375-4.219a.47.47 0 01-.469.469H8.094a.47.47 0 01-.469-.469V4.344a.47.47 0 01.469-.469h7.812a.47.47 0 01.469.469V16.53z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMobileAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
