import * as React from "react";
function SvgLandmarkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.595 5.598L12.438 2.08a1.249 1.249 0 00-.878 0L2.405 5.598A.625.625 0 002 6.183v1.442c0 .345.28.625.625.625h18.75c.345 0 .625-.28.625-.625V6.183a.625.625 0 00-.405-.585zM4.5 9.5v6.25h-.625a.625.625 0 00-.625.625v1.875h17.5v-1.875a.625.625 0 00-.625-.625H19.5V9.5H17v6.25h-3.75V9.5h-2.5v6.25H7V9.5H4.5zm16.875 10H2.625a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h18.75c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgLandmarkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
