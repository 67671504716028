import * as React from "react";
function SvgExchangeAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 8.563v-.625C2 7.42 2.42 7 2.938 7H17V5.125c0-.835 1.012-1.252 1.6-.663l3.125 3.125a.938.938 0 010 1.326L18.6 12.038c-.586.586-1.6.175-1.6-.663V9.5H2.937A.937.937 0 012 8.563zM21.063 14.5H7v-1.875c0-.832-1.01-1.253-1.6-.663l-3.125 3.125a.938.938 0 000 1.326L5.4 19.538c.587.587 1.6.174 1.6-.663V17h14.063c.517 0 .937-.42.937-.937v-.625a.937.937 0 00-.938-.938z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgExchangeAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
