import * as React from "react";
function SvgAtomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 10.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM20.366 7c-.425-.762-1.582-1.982-4.542-1.636C14.984 3.362 13.704 2 12 2c-1.704 0-2.984 1.362-3.824 3.364-2.96-.342-4.117.874-4.542 1.636-.64 1.147-.586 2.856.982 5-1.568 2.144-1.622 3.852-.982 5 1.138 2.046 3.97 1.704 4.542 1.636C9.016 20.638 10.296 22 12 22c1.704 0 2.984-1.362 3.824-3.364.571.068 3.404.41 4.542-1.636.64-1.148.586-2.856-.982-5 1.568-2.144 1.622-3.853.982-5zM5.724 15.75c-.156-.283-.005-.967.586-1.885.269.254.552.503.855.747.063.537.156 1.06.264 1.568-.962.034-1.558-.171-1.705-.43zm1.441-6.362c-.303.244-.586.493-.855.747-.59-.918-.742-1.602-.586-1.885.132-.24.64-.45 1.48-.45.068 0 .151.015.225.015-.113.523-.2 1.045-.264 1.573zM12 4.5c.371 0 .87.527 1.323 1.455-.439.147-.879.313-1.323.503-.444-.19-.884-.356-1.324-.503C11.131 5.027 11.63 4.5 12 4.5zm0 15c-.371 0-.87-.527-1.324-1.455.44-.147.88-.313 1.324-.503.444.19.884.356 1.323.503-.454.928-.952 1.455-1.323 1.455zm0-4.375a3.125 3.125 0 110-6.25 3.125 3.125 0 010 6.25zm6.276.625c-.142.259-.743.464-1.705.43.108-.508.2-1.03.264-1.568.303-.244.586-.493.855-.747.59.918.742 1.602.586 1.885zm-.586-5.615a14.376 14.376 0 00-.855-.747 17.286 17.286 0 00-.264-1.573c.073 0 .157-.014.225-.014.84 0 1.348.21 1.48.449.156.283.005.967-.586 1.885z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAtomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
