import * as React from "react";
function SvgAtlasSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.687 10.125H14.16c-.058 1.056-.256 2.006-.555 2.75a3.744 3.744 0 002.082-2.75zm0-1.25a3.745 3.745 0 00-2.082-2.75c.3.744.497 1.694.555 2.75h1.527zM12 5.801c-.3.291-.811 1.345-.915 3.074h1.83C12.812 7.146 12.3 6.092 12 5.801zm-1.605.324a3.744 3.744 0 00-2.082 2.75H9.84c.058-1.056.255-2.006.555-2.75zm0 6.75c-.3-.744-.497-1.694-.555-2.75H8.313c.207 1.22 1 2.233 2.082 2.75zm2.52-2.75h-1.83c.103 1.729.614 2.783.915 3.074.3-.291.811-1.345.915-3.074zM20.75 16V3c0-.625-.375-1-1-1H7C4.875 2 3.25 3.625 3.25 5.75v12.5C3.25 20.375 4.875 22 7 22h12.75c.5 0 1-.375 1-1v-.625c0-.25-.125-.5-.375-.75-.125-.625-.125-2.375 0-2.875.25-.125.375-.375.375-.75zM12 4.5a5 5 0 110 10 5 5 0 010-10zm6.25 15H7c-.75 0-1.25-.5-1.25-1.25S6.375 17 7 17h11.25v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgAtlasSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
