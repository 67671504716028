import * as React from "react";
function SvgSatelliteDishSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.932 20.07c.288.285.241.785-.118.977a8.02 8.02 0 01-10.86-10.86c.19-.357.69-.404.976-.119l4.563 4.564 1.07-1.07c-.026-.102-.062-.196-.062-.306a1.25 1.25 0 111.25 1.25c-.109 0-.203-.034-.304-.062l-1.071 1.07 4.556 4.556zm8.067-6.231a.629.629 0 01-.625.664h-1.255a.624.624 0 01-.62-.586c-.293-5.06-4.356-9.162-9.412-9.435a.619.619 0 01-.586-.622V2.626a.629.629 0 01.664-.625C16.541 2.337 21.667 7.463 22 13.839zm-3.75-.012a.633.633 0 01-.63.676h-1.258a.627.627 0 01-.62-.575c-.27-3.008-2.662-5.426-5.662-5.672a.623.623 0 01-.578-.621V6.38a.63.63 0 01.676-.628c4.302.332 7.743 3.773 8.072 8.075z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSatelliteDishSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
