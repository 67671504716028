import * as React from "react";
function SvgSnowmanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.954 7.952l-.23-.566a.63.63 0 00-.813-.34l-1.1.449V6.362a.619.619 0 00-.626-.609h-.625a.619.619 0 00-.625.61v1.831c0 .02.012.04.012.059l-2.202.898a4.31 4.31 0 00-.86-1.039c.531-.648.86-1.46.86-2.363a3.75 3.75 0 00-7.5 0c0 .902.333 1.715.86 2.363a4.4 4.4 0 00-.86 1.039l-2.202-.898c.004-.02.012-.04.012-.059V6.362a.607.607 0 00-.617-.609h-.625a.619.619 0 00-.625.61v1.132l-1.097-.45a.63.63 0 00-.813.34l-.23.567a.605.605 0 00.348.793l5.28 2.156c-.016.156-.047.312-.047.476 0 .394.066.766.164 1.129a5.904 5.904 0 00-1.73 4.183c0 2.108 1.109 3.94 2.765 4.99.363.231.793.321 1.222.321h3.874c.52 0 1.028-.16 1.453-.457 1.816-1.261 2.906-3.491 2.457-5.96a5.927 5.927 0 00-1.625-3.085c.098-.36.164-.73.164-1.12 0-.165-.031-.317-.047-.477l5.28-2.156a.608.608 0 00.348-.793zM10.75 5.753a.627.627 0 01-.625-.625c0-.343.282-.625.625-.625.344 0 .625.282.625.625a.627.627 0 01-.625.625zM12 16.376a.627.627 0 01-.625-.625c0-.343.281-.625.625-.625s.625.282.625.625a.627.627 0 01-.625.625zm0-2.5a.627.627 0 01-.625-.624c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zm0-2.499a.627.627 0 01-.625-.625c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zm0-3.437s-.625-.906-.625-1.25c0-.343.281-.624.625-.624s.625.28.625.624-.625 1.25-.625 1.25zm1.25-2.187a.627.627 0 01-.625-.625c0-.343.281-.625.625-.625.343 0 .625.282.625.625a.627.627 0 01-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSnowmanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
