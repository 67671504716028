import styled from "styled-components";
import { Modal as AntModal } from "antd";
import { Select } from "common-components";
import { useState } from "react";
import { set } from "date-fns";
export const Modal = styled(AntModal)`
	.ant-modal-body {
		padding: 6px 45px 40px;
	}
`;

const options = [
	{
		name: "未檢查",
		value: 0,
	},
	{
		name: "待檢查",
		value: 1,
	},
	{
		name: "速測待檢查",
		value: 2,
	},
	{
		name: "紙本待檢查",
		value: 3,
	},
	{
		name: "待教科修題",
		value: 4,
	},
	{
		name: "待南編修題",
		value: 5,
	},
	{
		name: "完成檢查",
		value: 10,
	},
];

export function FinishedTaskModal({ open, onOk, onCancel }) {
	const [value, setValue] = useState("");

	const onChange = (value) => {
		setValue(value);
	};

	const okHandler = () => {
		onOk(value);
	};

	return (
		<Modal open={open} title="請選擇檢查狀態" onOk={okHandler} onCancel={onCancel}>
			<Select placeholder="請選擇檢查狀態" options={options} allowClear={false} onChange={onChange} />
		</Modal>
	);
}
