import * as React from "react";
function SvgCommentMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.25c-5.523 0-10 3.638-10 8.125 0 1.936.836 3.71 2.227 5.107-.49 1.965-2.122 3.721-2.141 3.74a.312.312 0 00.227.528c2.588 0 4.53-1.24 5.492-2.007 1.341.503 2.762.76 4.195.757 5.523 0 10-3.637 10-8.125 0-4.487-4.477-8.125-10-8.125zm3.75 9.063a.313.313 0 01-.313.312H13.25v2.188a.313.313 0 01-.313.312h-1.874a.313.313 0 01-.313-.313v-2.187H8.562a.312.312 0 01-.312-.313v-1.874a.313.313 0 01.313-.313h2.187V7.937a.312.312 0 01.313-.312h1.874a.313.313 0 01.313.313v2.187h2.188a.313.313 0 01.312.313v1.874z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCommentMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
