import * as React from "react";
function SvgSortAlphaDownAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.857 16.286H7.714V2.714A.714.714 0 007 2H5.572a.714.714 0 00-.715.714v13.572H2.714a.715.715 0 00-.504 1.219l3.572 4.286a.714.714 0 001.01 0l3.571-4.286c.447-.449.129-1.22-.506-1.22zm5-5.715h5.715a.714.714 0 00.714-.714V8.43a.715.715 0 00-.715-.715h-2.5l2.735-3.145a1.43 1.43 0 00.48-1.068v-.787A.715.715 0 0020.57 2h-5.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h2.5l-2.735 3.145a1.428 1.428 0 00-.48 1.068v.787a.714.714 0 00.715.714zm7.101 10.475l-2.646-7.143a.714.714 0 00-.673-.474h-1.85a.714.714 0 00-.672.474l-2.646 7.143a.713.713 0 00.672.954h1.108a.715.715 0 00.68-.495l.198-.576h3.17l.196.576a.714.714 0 00.682.495h1.109a.715.715 0 00.672-.954zm-4.975-2.617l.731-2.143.732 2.143h-1.463z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortAlphaDownAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
