import * as React from "react";
function SvgHanukiahSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.25 9a.25.25 0 00-.25.25V13h1V9.25A.25.25 0 009.75 9h-.5zm-2 0a.25.25 0 00-.25.25V13h1V9.25A.25.25 0 007.75 9h-.5zm7 0a.25.25 0 00-.25.25V13h1V9.25a.25.25 0 00-.25-.25h-.5zm2 0a.25.25 0 00-.25.25V13h1V9.25a.25.25 0 00-.25-.25h-.5zm2.75.25a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25V13h1V9.25zM5.25 9a.25.25 0 00-.25.25V13h1V9.25A.25.25 0 005.75 9h-.5zM21.5 9h-1a.5.5 0 00-.5.5V13a1 1 0 01-1 1h-6V8a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v6H5a1 1 0 01-1-1V9.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V13a3 3 0 003 3h6v2H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H13v-2h6a3 3 0 003-3V9.5a.5.5 0 00-.5-.5zM21 8c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zM3 8c.414 0 .75-.373.75-.833C3.75 6.707 3 5.5 3 5.5s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm9-1.5c.414 0 .75-.373.75-.833C12.75 5.207 12 4 12 4s-.75 1.207-.75 1.667c0 .46.336.833.75.833zM5.5 8c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm2 0c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm2 0c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm5 0c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm2 0c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833zm2 0c.414 0 .75-.373.75-.833 0-.46-.75-1.667-.75-1.667s-.75 1.207-.75 1.667c0 .46.336.833.75.833z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHanukiahSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
