import * as React from "react";
function SvgTagsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.56 11.06l-6.62-6.62A1.5 1.5 0 009.878 4H3.5A1.5 1.5 0 002 5.5v6.379c0 .398.158.779.44 1.06l6.62 6.622a1.5 1.5 0 002.122 0l6.379-6.379a1.5 1.5 0 000-2.121zM5.5 9a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm16.06 4.182l-6.378 6.379a1.5 1.5 0 01-2.121 0l-.012-.012 5.44-5.439a2.794 2.794 0 00.823-1.989c0-.75-.292-1.457-.823-1.988L12.356 4h1.523a1.5 1.5 0 011.06.44l6.622 6.62a1.5 1.5 0 010 2.122z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTagsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
