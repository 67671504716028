import * as React from "react";
function SvgHddOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.701 11.293l-3.648-5.245a1.666 1.666 0 00-1.368-.715h-9.37a1.667 1.667 0 00-1.368.715L2.3 11.293c-.195.28-.299.612-.299.952V17c0 .92.746 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667v-4.755c0-.34-.104-.673-.299-.952zM7.316 7h9.37l2.705 3.889H4.61l2.705-3.89zm13.018 10H3.667v-4.444h16.666V17zm-1.11-2.222a1.111 1.111 0 11-2.223 0 1.111 1.111 0 012.222 0zm-3.334 0a1.111 1.111 0 11-2.222 0 1.111 1.111 0 012.222 0z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgHddOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
