import * as React from "react";
function SvgShareSquareOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.512 8.6l-5-5c-1.042-1.042-2.845-.312-2.845 1.179v1.986c-1.474.065-2.918.227-4.193.625-1.222.38-2.19.957-2.88 1.716-.836.922-1.26 2.082-1.26 3.447 0 2.143 1.151 3.905 2.946 5.027 1.304.816 2.96-.44 2.466-1.936-.539-1.636-.596-2.462 2.92-2.734v1.868c0 1.493 1.805 2.219 2.846 1.178l5-5a1.666 1.666 0 000-2.357zm-6.179 6.178v-3.606c-4.468.067-7.552.805-6.17 4.995C8.14 15.526 7 14.364 7 12.553c0-3.796 4.484-4.13 8.333-4.161V4.778l5 5-5 5zm.86 2.934a2.87 2.87 0 00.728-.323.416.416 0 01.634.354v1.48c0 .92-.746 1.666-1.666 1.666H3.667c-.92 0-1.667-.746-1.667-1.667V7c0-.92.746-1.667 1.667-1.667H8.25c.23 0 .417.187.417.417v.156c0 .17-.104.325-.263.387-.476.185-.917.4-1.321.645A.422.422 0 016.865 7h-2.99a.208.208 0 00-.208.208v11.806a.208.208 0 00.208.208h11.806a.209.209 0 00.208-.208v-.902c0-.186.124-.349.303-.4z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgShareSquareOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
