import * as React from "react";
function SvgPhoneVolumeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.302 21.803c-5.073-5.073-5.066-13.29 0-18.356a.672.672 0 01.83-.095l2.533 1.583a.671.671 0 01.267.818l-1.266 3.165a.672.672 0 01-.69.419l-2.18-.218a10.376 10.376 0 000 7.012l2.18-.218a.671.671 0 01.69.419l1.266 3.165a.67.67 0 01-.267.818l-2.532 1.583a.672.672 0 01-.83-.095zM14.153 5.73a2.502 2.502 0 010 2.542.47.47 0 01-.73.1l-.234-.223a.472.472 0 01-.088-.563 1.252 1.252 0 000-1.17.472.472 0 01.088-.563l.233-.223a.47.47 0 01.731.1zm3.586-3.562a7.51 7.51 0 010 9.666.47.47 0 01-.686.036l-.226-.217a.47.47 0 01-.037-.64 6.259 6.259 0 000-8.024.47.47 0 01.037-.64l.226-.217a.47.47 0 01.686.036zm-1.796 1.756a5.007 5.007 0 010 6.154.471.471 0 01-.697.051l-.228-.218a.468.468 0 01-.05-.622 3.755 3.755 0 000-4.576.468.468 0 01.05-.622l.227-.218a.47.47 0 01.698.05z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPhoneVolumeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
