"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.authMachine = exports.AuthStates = exports.AuthEventType = void 0;
var _xstate = require("xstate");
var _oneClub = require("../../services/oneClub");
var _common = require("../../utils/common");
var _login = require("../../api/login");
var _authority = require("../../api/authority");
var _constants = require("../../constants");
const AuthStates = exports.AuthStates = {
  Idle: "Idle",
  Loading: "Loading",
  LoginDone: "LoginDone",
  ReadySettingEducation: "ReadySettingEducation",
  SettingEducation: "SettingEducation",
  getAuthority: "getAuthority",
  UpdateUserProfile: "UpdateUserProfile"
};
const AuthEventType = exports.AuthEventType = {
  Logging: "Logging",
  LoggingOut: "LoggingOut",
  LoggingEnd: "LoggingEnd",
  SetEducation: "SetEducation",
  UpdatingUserProfile: "UpdatingUserProfile"
};
const init = {
  identity: "",
  name: "",
  eduMap: [],
  subject: "",
  education: [],
  account: ""
};
const authMachine = exports.authMachine = (0, _xstate.createMachine)({
  id: "loginMachine",
  initial: (0, _oneClub.checkNaniLinkLogin)() !== "" ? AuthStates.Loading : AuthStates.Idle,
  context: {
    result: {
      ...init
    },
    apiBaseUrl: "",
    productApiUrl: "",
    authority: {},
    error: null,
    loading: false
  },
  states: {
    [AuthStates.Idle]: {
      entry: (0, _xstate.assign)({
        result: () => {
          (0, _common.deleteAllCookies)();
          return {
            ...init
          };
        },
        loading: () => false
      }),
      on: {
        [AuthEventType.Logging]: AuthStates.Loading
      }
    },
    [AuthStates.Loading]: {
      invoke: {
        src: async (context, event) => {
          const resourcePlatformToken = (0, _common.checkLogin)(_constants.COOKIE_LOGIN_TOKEN_NAME);
          if (resourcePlatformToken) {
            const profile = await (0, _login.loginGetUserProfile)({
              url: context.apiBaseUrl
            });
            if (!(profile !== null && profile !== void 0 && profile.isSuccess)) {
              return Promise.reject(profile);
            }
          } else {
            const naniToken = (0, _oneClub.checkNaniLinkLogin)() || "";
            const result = await (0, _login.loginByOneClub)({
              url: context.apiBaseUrl,
              jwt: naniToken ? JSON.parse(naniToken).jwt : JSON.parse(event.jwt).jwt
            });
            const {
              data
            } = result;
            if (typeof data === "string") {
              //非首次登入
              (0, _common.setCookie)(_constants.COOKIE_LOGIN_TOKEN_NAME, data, 1);
              const profile = await (0, _login.loginGetUserProfile)({
                url: context.apiBaseUrl
              });
              if (profile.isSuccess) {
                return {
                  token: data,
                  ...profile.data
                };
              } else {
                return Promise.reject(profile);
              }
            } else {
              const {
                token
              } = data;
              (0, _common.setCookie)(_constants.COOKIE_LOGIN_TOKEN_NAME, token, 1);
              return data;
            }
          }
        },
        onDone: [{
          target: AuthStates.ReadySettingEducation,
          cond: (_, event) => {
            var _event$data;
            return event !== null && event !== void 0 && (_event$data = event.data) !== null && _event$data !== void 0 && _event$data.isNewMember ? true : false;
          },
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              return event.data;
            },
            error: () => null,
            loading: () => true
          })
        }, {
          target: AuthStates.getAuthority,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              return event.data;
            },
            error: () => null,
            loading: () => true
          })
        }],
        onError: {
          target: AuthStates.Idle,
          actions: (0, _xstate.assign)({
            result: _context => {
              (0, _oneClub.logoutNaniOneClass)();
              return {
                ...init
              };
            },
            error: (_, event) => {
              var _event$data2;
              const newMessage = (event === null || event === void 0 || (_event$data2 = event.data) === null || _event$data2 === void 0 || (_event$data2 = _event$data2.data) === null || _event$data2 === void 0 ? void 0 : _event$data2.message) || "您的帳號已重複登入！";
              return newMessage;
            }
          })
        }
      }
    },
    [AuthStates.ReadySettingEducation]: {
      on: {
        [AuthEventType.SetEducation]: {
          target: AuthStates.SettingEducation
        },
        [AuthEventType.LoggingOut]: {
          target: AuthStates.Idle
        }
      }
    },
    [AuthStates.SettingEducation]: {
      invoke: {
        id: "SettingEducation",
        src: async (context, event) => {
          const result = await (0, _login.setUserProfile)({
            url: context.apiBaseUrl,
            payload: {
              education: [event.chooseEdu]
            }
          });
          const {
            isSuccess,
            data
          } = result;
          if (!isSuccess) {
            throw new {
              error: "error"
            }();
          }
          return data;
        },
        onDone: {
          target: AuthStates.getAuthority,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              return {
                ...event.data,
                isNewMember: _context.result.isNewMember
              };
            },
            error: () => null,
            loading: () => true
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              (0, _oneClub.logoutNaniOneClass)();
              return {
                ...init
              };
            },
            error: event => {
              return event.error;
            }
          })
        }
      }
    },
    [AuthStates.getAuthority]: {
      invoke: {
        src: async context => {
          const {
            isSuccess,
            data
          } = await (0, _authority.getUserAuthority)(context.productApiUrl);
          if (!isSuccess) {
            return Promise.reject(data);
          }
          return {
            authority: (data === null || data === void 0 ? void 0 : data.authority) || [],
            data
          };
        },
        onDone: {
          target: AuthStates.LoginDone,
          actions: (0, _xstate.assign)({
            authority: (_, event) => {
              return event.data.authority;
            },
            result: (_context, event) => {
              return {
                ..._context.result,
                ...event.data.data
              };
            }
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              (0, _oneClub.logoutNaniOneClass)();
              return {
                ...init
              };
            },
            error: (_, event) => {
              return event.data;
            }
          })
        }
      }
    },
    [AuthStates.UpdateUserProfile]: {
      invoke: {
        src: async (context, event) => {
          const {
            isSuccess,
            data
          } = await (0, _authority.getUserAuthority)(context.productApiUrl);
          if (!isSuccess) {
            return Promise.reject(data);
          }
          return data;
        },
        onDone: {
          target: AuthStates.LoginDone,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              return {
                ..._context.result,
                ...event.data
              };
            }
          })
        },
        onError: {
          target: AuthStates.Idle,
          actions: (0, _xstate.assign)({
            result: (_context, event) => {
              (0, _oneClub.logoutNaniOneClass)();
              return {
                ...init
              };
            },
            error: event => {
              return event.error;
            }
          })
        }
      }
    },
    [AuthStates.LoginDone]: {
      on: {
        [AuthEventType.LoggingOut]: {
          target: AuthStates.Idle
        },
        [AuthEventType.UpdatingUserProfile]: {
          target: AuthStates.UpdateUserProfile
        }
      }
    }
  }
});
var _default = exports.default = authMachine;