import * as React from "react";
function SvgVrCardboardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 6H3a1 1 0 00-1 1v10a1 1 0 001 1h5.007a2 2 0 001.824-1.18l.867-1.926c.246-.548.75-.894 1.302-.894s1.056.346 1.303.894l.866 1.927A2 2 0 0015.993 18H21a1 1 0 001-1V7a1 1 0 00-1-1zM7 13.5a2 2 0 110-4 2 2 0 010 4zm10 0a2 2 0 110-4 2 2 0 010 4z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgVrCardboardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
