import * as React from "react";
function SvgTachometerAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 4.222c-5.523 0-10 4.477-10 10 0 1.833.495 3.55 1.356 5.028.195.334.566.528.953.528h15.382c.387 0 .758-.194.953-.528A9.948 9.948 0 0022 14.222c0-5.523-4.477-10-10-10zm0 2.222c.51 0 .923.352 1.053.822-.039.078-.092.146-.12.231l-.32.96c-.178.122-.381.21-.613.21a1.111 1.111 0 010-2.223zm-6.667 10a1.111 1.111 0 110-2.222 1.111 1.111 0 010 2.222zM7 10.89a1.111 1.111 0 110-2.223 1.111 1.111 0 010 2.223zm8.568-2.514l-2.13 6.389c.476.407.784 1.005.784 1.68 0 .407-.117.783-.308 1.111h-3.828a2.2 2.2 0 01-.308-1.11c0-1.18.92-2.134 2.08-2.209l2.13-6.389a.834.834 0 011.58.527zm.51 1.986l.538-1.617c.12-.044.248-.077.384-.077a1.111 1.111 0 010 2.222c-.395 0-.725-.218-.923-.528zm2.589 6.083a1.111 1.111 0 110-2.222 1.111 1.111 0 010 2.222z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgTachometerAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
