import * as React from "react";
function SvgReplySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.325 9.415L9.2 3.48c.602-.52 1.55-.098 1.55.71v3.126C17.025 7.387 22 8.645 22 14.591c0 2.4-1.546 4.778-3.255 6.02-.534.389-1.294-.098-1.097-.727 1.771-5.665-.84-7.168-6.898-7.256v3.434c0 .81-.95 1.23-1.55.71l-6.875-5.937a.938.938 0 010-1.42z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgReplySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
