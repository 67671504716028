import * as React from "react";
function SvgSortAlphaUpAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.715 7.714h2.142v13.572a.714.714 0 00.715.714H7a.714.714 0 00.715-.714V7.714h2.142c.635 0 .955-.77.505-1.219L6.791 2.21a.714.714 0 00-1.01 0L2.21 6.495a.715.715 0 00.505 1.22zm12.142 2.857h5.715a.714.714 0 00.714-.714V8.43a.715.715 0 00-.714-.715h-2.5l2.735-3.145a1.428 1.428 0 00.479-1.068v-.787A.715.715 0 0020.572 2h-5.715a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h2.5l-2.734 3.145a1.428 1.428 0 00-.48 1.068v.787a.714.714 0 00.714.714zm7.101 10.475l-2.646-7.143a.714.714 0 00-.672-.474h-1.85a.714.714 0 00-.673.474l-2.646 7.143a.715.715 0 00.672.954h1.109a.714.714 0 00.68-.495l.197-.576h3.17l.196.576a.715.715 0 00.682.495h1.109a.715.715 0 00.672-.954zm-4.975-2.617l.732-2.143.731 2.143h-1.463z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgSortAlphaUpAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
