import * as React from "react";
function SvgMoneyBillAltOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 8.5c-1.657 0-3 1.567-3 3.5s1.343 3.5 3 3.5c1.656 0 3-1.567 3-3.5s-1.343-3.5-3-3.5zm1.25 5.25A.25.25 0 0113 14h-2a.25.25 0 01-.25-.25v-.5A.25.25 0 0111 13h.5v-1.732l-.015.01a.249.249 0 01-.346-.07l-.278-.416a.25.25 0 01.07-.347l.479-.32a.75.75 0 01.416-.125h.424a.25.25 0 01.25.25V13h.5a.25.25 0 01.25.25v.5zM21 6H3a1 1 0 00-1 1v10a1 1 0 001 1h18a1 1 0 001-1V7a1 1 0 00-1-1zm-.5 8.5a2 2 0 00-2 2h-13a2 2 0 00-2-2v-5a2 2 0 002-2h13a2 2 0 002 2v5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgMoneyBillAltOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
