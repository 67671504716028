import * as React from "react";
function SvgCoffeeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.001 16h6a3 3 0 003-3h1C20.205 13 22 11.206 22 9s-1.794-4-4-4H5.751a.748.748 0 00-.75.75V13a3 3 0 003 3zm10-9c1.102 0 2 .897 2 2s-.898 2-2 2h-1V7h1zm1.49 12H3.51c-1.487 0-1.906-2-1.125-2h18.227c.78 0 .368 2-1.122 2z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgCoffeeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
