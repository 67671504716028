import * as React from "react";
function SvgGlobeEuropeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm8.064 10c0 .907-.157 1.782-.435 2.597h-.819a.637.637 0 01-.46-.194l-1.29-1.314a.48.48 0 01.005-.674l.503-.504v-.35a.458.458 0 00-.133-.323l-.378-.38a.458.458 0 00-.323-.132h-.645a.458.458 0 01-.323-.778l.38-.38a.458.458 0 01.322-.133h1.29c.25 0 .456-.205.456-.455V8.6a.458.458 0 00-.456-.455h-1.48a.647.647 0 00-.645.645v.182a.647.647 0 01-.44.613l-1.274.423a.32.32 0 00-.221.307v.088a.324.324 0 01-.323.323h-.645a.324.324 0 01-.323-.323.324.324 0 00-.322-.322h-.125a.329.329 0 00-.29.177l-.38.754a.642.642 0 01-.576.355h-.891a.647.647 0 01-.646-.645v-1.02c0-.17.069-.335.19-.456l.81-.81a.998.998 0 00.29-.706c0-.137.09-.262.223-.307l1.612-.536a.468.468 0 00.178-.109l1.08-1.08a.458.458 0 00-.322-.779h-.835l-.645.646v.322a.324.324 0 01-.322.323h-.646a.324.324 0 01-.322-.323v-.806c0-.101.048-.198.129-.258l1.165-.875c.077-.004.153-.013.23-.013 4.456 0 8.072 3.617 8.072 8.065zM7.246 7.69c0-.121.048-.238.133-.323l1.024-1.024a.458.458 0 01.778.323v.644a.458.458 0 01-.133.323l-.379.38a.458.458 0 01-.322.132h-.645a.458.458 0 01-.456-.455zm5.161 12.354v-.286a.647.647 0 00-.645-.645h-.814c-.436 0-1.077-.214-1.428-.476l-.895-.673a1.831 1.831 0 01-.734-1.468v-.964c0-.645.339-1.242.891-1.572l1.73-1.037c.286-.169.613-.262.944-.262h1.258c.44 0 .863.158 1.193.44l1.742 1.496h.738c.343 0 .67.137.911.379l.698.697a.739.739 0 00.52.214h.54a8.05 8.05 0 01-6.649 4.157z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgGlobeEuropeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
