import * as React from "react";
function SvgPagerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 4.5h-15A2.5 2.5 0 002 7v10a2.5 2.5 0 002.5 2.5h15A2.5 2.5 0 0022 17V7a2.5 2.5 0 00-2.5-2.5zM8.25 16.375H5.125a.625.625 0 01-.625-.625v-.625a.625.625 0 01.625-.625H8.25v1.875zm5-.625a.624.624 0 01-.625.625H9.5V14.5h3.125a.624.624 0 01.625.625v.625zm6.25-5A1.25 1.25 0 0118.25 12H5.75a1.25 1.25 0 01-1.25-1.25v-2.5A1.25 1.25 0 015.75 7h12.5a1.25 1.25 0 011.25 1.25v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgPagerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
